.product-thumb {
  @include clearfix;
  @include swap_direction(margin, 0);
  position: relative;
  @include swap_direction(padding, 0 17px 20px);
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    @include swap_direction(padding, 0 20px 20px);
  }
  .slick-dots {
    bottom: 10px;
    li {
      width: 10px;
      height: 10px;
    }
  }
  &__image-link {
    display: block;
    float: $ldirection;
    width: 47%;
    @include breakpoint($medium-up) {
      float: none;
      width: auto;
    }
  }
  &__image {
    display: block;
    margin: 1px auto;
    @include swap_direction(margin, 0 auto);
    text-align: center;
  }
  &__abstract {
    float: $rdirection;
    width: 53%;
    @include swap_direction(padding, 0 0 0 16px);
    @include breakpoint($medium-up) {
      float: none;
      // width: auto;
      width: 100%;
      @include swap_direction(padding, 20px 0 0);
      background: $color-white;
      background: rgba(255, 255, 255, 0.95);
    }
  }

  // CTA/ABSTRACT ANIMATIONS
  // This gets somewhat complicated, so please read if you find yourself getting
  // confused.
  // 1. Product thumbs can be set to animate just their CTA or their entire
  //    "abstract" (prod name, price, cta, etc).
  // 2. To animate the cta the class .product-thumb--animate-cta is applied to
  //    the containing .product-thumb element. This causes the cta to fade.
  // 3. To animate the entire abstract the class .product-thumb--animate-abstract
  //    is applied to the containing .product-thumb element. This causes the
  //    abstract to fade and fly up 30px over the product image.
  // 4. In either case the CTA or the abstract is hidden by default and shown on
  //    hover for desktop.
  // 5. On touch devices this is all disabled by default, but if you use the
  //    .product-thumb--animate-cta-touch or .product-thumb--animate-abstract-touch
  //    classes it will enable them on touch, meaning the cta/abstract will be
  //    hidden by default and shown only when the user activates it. This is done
  //    by adding an "active" class to the .product-thumb element (you have to
  //    do this yourself with javascript).
  // 6. 3d transforms are used by browsers that support them to switch on
  //    hardware acceleration, older browsers use margin. Browsers that don't
  //    support css3 animations don't get animated at all, they just snap
  //    between each state.
  // 7. None of this applies below the tablet breakpoint.
  @include breakpoint($medium-up) {
    &--animate-abstract,
    &--animate-cta {
      .no-touch & .product-thumb__abstract,
      &-touch .product-thumb__abstract {
        @include transition(margin-bottom 0.3s ease, opacity 0.3s ease, visibility 0.3s ease);
        margin-bottom: -30px;
      }
      .no-touch.csstransforms3d & .product-thumb__abstract,
      .csstransforms3d &-touch .product-thumb__abstract {
        @include transition(transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease);
        @include translate(0, 30px, 0);
        margin-bottom: 0;
      }
      // Animate margin-bottom property by default
      &.active .product-thumb__abstract,
      .no-touch &:hover .product-thumb__abstract {
        margin-bottom: 0;
      }
      // If css3 3d transforms are available animate those to enable
      // hardware acceleration.
      .csstransforms3d &.active .product-thumb__abstract,
      .no-touch.csstransforms3d &:hover .product-thumb__abstract {
        @include translate(0, 0, 0);
      }
    }

    // When animating the entire abstract:
    .no-touch &--animate-abstract,
    &--animate-abstract-touch {
      // Hide the abstract by default:
      .product-thumb__abstract {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        // Break the abstract out of the layout so it overlays the thumb image:
        position: absolute;
        bottom: 0;
      }
      // Fade in on hover/active:
      &.active .product-thumb__abstract,
      .no-touch &:hover .product-thumb__abstract {
        opacity: 1;
        visibility: visible;
      }
    }

    // When animating just the cta:
    .no-touch &--animate-cta,
    &--animate-cta-touch {
      // Hide the cta by default:
      .product-thumb__cta {
        @include transition(opacity 0.3s ease, visibility 0.3s ease);
        opacity: 0;
        visibility: hidden;
      }
    }
    // Fade in on hover/active:
    &.active .product-thumb__cta,
    .no-touch &:hover .product-thumb__cta {
      opacity: 1;
      visibility: visible;
    }
  }
  &__headline {
    @include h8;
    @include swap_direction(margin, 0);
    &-link {
      line-height: 18px;
      text-decoration: none;
      display: block;
      @include breakpoint($medium-up) {
        line-height: 21px;
      }
      color: $color-black;
      &:hover {
        // BB-207 - they said to remove the underlined/pink hover state.
        // color: $color-pink;
        color: $color-black;
        text-decoration: none;
      }
    }
  }
  &__subline {
    @include h14;
  }
  &__shadename {
    font-family: $brandon-text-medium;
    display: inline-block;
    width: 100%;
    letter-spacing: 1px;
    text-transform: none;
    font-size: 14px;
  }
  &--price {
    @include h8;
    height: auto;
    line-height: 38px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    @include swap_direction(margin, 10px 0 11px 0);
    &.legal_msg {
      text-align: $rdirection;
    }
    span.legal_msg {
      text-align: $ldirection;
      float: $ldirection;
      display: inline;
      text-transform: none;
      color: $color-light-gray;
      letter-spacing: 0.1em;
      font-size: 12px;
    }
  }
  .product-thumb__cta {
    border: 2px solid $color-pink;
    height: 34px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    @include swap_direction(padding, 0);
    @include breakpoint($medium-up) {
      border: none;
      border-bottom: 2px solid $color-pink;
      @include swap_direction(padding, 0);
      height: auto;
      line-height: 19px;
      width: auto;
      text-align: $ldirection;
      font-size: 14px;
    }
    @include breakpoint($small-down) {
      letter-spacing: 0.24em;
      &:hover {
        border: 2px solid $color-pink !important;
        color: $color-pink !important;
      }
    }
    &.hideqs {
      border-color: $color-light-gray;
      color: $color-light-gray;
    }
  }

  // Displays on mobile MPP instead of quickshop when the item is non-shaded or
  // palette:
  .product__button--add-to-bag {
    border: 2px solid $color-pink;
    height: 34px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    @include swap_direction(padding, 0);
    background: transparent;
    color: $color-pink;
  }
  .product__button--sku-cards {
    font-size: 14px;
    height: auto;
    line-height: normal;
    width: auto;
    border: 2px solid $color-pink;
    padding: 8px 5px;
    @include breakpoint($medium-up) {
      border: none;
      border-bottom: 2px solid $color-pink;
      padding: 0;
    }
  }
  .product__favorite {
    top: 5px;
    #{$ldirection}: 5px;
    text-decoration: none;
    font-size: 14px;
    margin-#{$ldirection}: 5px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

.product-thumb .no-touch {
  .product-thumb__image-link > div {
    display: none;
    &:first-child {
      display: block;
    }
  }
}

.product-sku-card {
  &.product--sku-product {
    .product-thumb__cta {
      &--select-shade,
      &--shop-now,
      &--quick-shop {
        display: none;
      }
    }
  }
}

.product-thumb.active .product-thumb__cta,
.no-touch .product-thumb:hover .product-thumb__cta {
  visibility: visible;
}

.csstransforms3d .product-thumb--animate-abstract.active .product-thumb__abstract,
.no-touch.csstransforms3d .product-thumb--animate-abstract:focus .product-thumb__abstract,
.csstransforms3d .product-thumb--animate-cta.active .product-thumb__abstract,
.no-touch.csstransforms3d .product-thumb--animate-cta:focus .product-thumb__abstract {
  visibility: visible;
}

.csstransforms3d .product-thumb--animate-abstract.active .product-thumb__abstract,
.no-touch.csstransforms3d .product-thumb--animate-abstract:focus .product-thumb__abstract,
.csstransforms3d .product-thumb--animate-cta.active .product-thumb__abstract,
.no-touch.csstransforms3d .product-thumb--animate-cta:focus .product-thumb__abstract {
  transform: perspective(0) translate(0, 0);
}

.product-thumb--animate-abstract.active .product-thumb__abstract,
.no-touch .product-thumb--animate-abstract:focus .product-thumb__abstract,
.product-thumb--animate-cta.active .product-thumb__abstract,
.no-touch .product-thumb--animate-cta:focus .product-thumb__abstract {
  margin-bottom: 0;
}
