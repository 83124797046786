.checkout__sidebar {
  .expando-block {
    &.loyalty-offer-code-panel {
      padding: 16px 20px 0;
      border-bottom: none;
      background: $color-gray-background;
      margin-bottom: 20px;
      padding-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .offer-code-wallet {
        .slick-dots {
          bottom: 5%;
          li {
            margin: 0;
            width: 16px;
            height: 16px;
            button {
              &:before {
                font-size: 36px;
              }
            }
          }
        }
        .slick-next,
        .slick-prev {
          top: 93%;
          &:before {
            font-size: 20px;
          }
        }
        .slick-prev {
          #{$ldirection}: 36%;
        }
        .slick-next {
          #{$rdirection}: 34%;
          &:before {
            #{$rdirection}: auto;
            top: 5px;
            @include breakpoint($medium-up) {
              #{$ldirection}: -15px;
            }
          }
        }
        &__button--apply,
        &__button--remove {
          padding: 3px 22px;
          height: inherit;
          background: none;
          border: 1px solid $color-black;
          color: $color-black;
          margin-bottom: 30px;
          @include breakpoint($medium-up) {
            margin-bottom: 40px;
          }
          &.button {
            &--disabled {
              opacity: 0.3;
            }
          }
        }
        &__button--remove {
          line-height: 2.2;
        }
        &__item {
          padding-top: 10px;
        }
        &__code {
          &-info--applied {
            width: 40%;
            float: $ldirection;
          }
          &__description {
            @include breakpoint($medium-up) {
              width: 54%;
            }
            float: $rdirection;
            width: 57%;
            padding-#{$rdirection}: 10px;
            &-title {
              text-transform: uppercase;
              font-family: $body-font-bold;
              font-size: 15px;
            }
            &-description {
              @include breakpoint($medium-up) {
                min-height: 64px;
              }
              min-height: 90px;
              padding-top: 10px;
              overflow: hidden;
            }
            &-expiration {
              @include breakpoint($medium-up) {
                padding-bottom: 10px;
              }
              @include swap_direction(padding, 10px 5px 15px 0);
              text-transform: uppercase;
              font-size: 13px;
            }
          }
          &-in-cart {
            padding-#{$ldirection}: 10px;
          }
        }
        &__button {
          margin-#{$ldirection}: 10px;
          position: fixed;
          bottom: 0;
        }
      }
    }
  }
  .loyalty-points-content {
    &__summary {
      &__non-member-content {
        font-weight: bold;
        text-align: center;
        @include breakpoint($medium-up) {
          border-bottom: 1px solid $color-black;
          border-top: 1px solid $color-black;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
      .loyalty-info-icon {
        position: absolute;
        font-size: 9px;
        border: 1px solid $color-gray-dark;
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        top: 230px;
        #{$rdirection}: 30px;
        cursor: pointer;
        line-height: 1.4;
        @include breakpoint($medium-up) {
          top: 250px;
        }
      }
      .loyalty-tooltip-content {
        display: none;
      }
    }
  }
}

.tooltipster {
  &-default,
  &-base {
    background: $color-white;
    border-radius: 10px;
    border: 1px solid $color-black;
    .tooltipster-content {
      color: $color-black;
      background: $color-white;
      border-radius: 10px;
      text-align: $ldirection;
      p {
        margin-top: 0;
      }
    }
  }
}
