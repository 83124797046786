@include breakpoint($small-down) {
  #colorbox.popup-offer-cbox {
    border: 1px solid black;
    @include swap_direction(margin, 0px);
  }
}

.popup-offer {
  width: 100%;
  height: 100%;
  &__outer-wrap {
    position: relative;
    width: 100%;
    height: auto;
    @include swap_direction(padding, 40px 25px 0 25px);
    text-align: center;
    @include breakpoint($medium-down) {
      @include swap_direction(padding, 15px 0 0 0);
    }
    .sign-in-component__birthday-program {
      .day-of-birth__collection-field {
        margin-bottom: 32px;
        @include breakpoint($small-down) {
          margin-bottom: 0;
        }
        select {
          background-position: 96%;
        }
      }
    }
  }
  &__heading {
    margin-bottom: 1em;
    font-family: $brandon-text-bold;
    font-size: 32px;
    letter-spacing: 6px;
    line-height: 1.2em;
    text-transform: uppercase;
    @include breakpoint($medium-down) {
      font-size: 24px;
      margin-bottom: 0.5em;
    }
  }
  &__body {
    font-size: 16px;
    line-height: 1.75em;
    @include breakpoint($small-down) {
      font-size: 13px;
    }
    a {
      font-family: $brandon-text-bold;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
    &--terms {
      font-size: 11px;
      line-height: 1.5em;
      text-align: $ldirection;
      a {
        font-family: $brandon-text;
        letter-spacing: default;
        text-transform: none;
      }
    }
  }
  &__email-input {
    width: 240px;
    text-transform: uppercase;
    margin-bottom: 10px;
    @include breakpoint($medium-down) {
      margin-bottom: 1em;
      width: 60%;
    }
  }
  &__submit-input {
    margin-top: -3px;
    @include breakpoint($small-down) {
      width: 37%;
    }
  }
  &__footer {
    width: 100%;
    font-size: 11px;
    line-height: 1.5em;
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
  &__sign-up-form {
    .selectbox {
      float: $ldirection;
      &.select-month {
        margin-#{$rdirection}: 5px;
        width: 34.5%;
        padding-#{$rdirection}: 0;
      }
      &.select-day {
        margin-#{$rdirection}: 5px;
        width: 28%;
        padding-#{$rdirection}: 0;
      }
      &.select-year {
        width: 34.5%;
        padding-#{$rdirection}: 0;
      }
    }
    .dob_format_yyyy_mm_dd {
      .selectbox {
        margin-#{$rdirection}: 5px;
        &.select-day {
          margin-#{$rdirection}: 0;
        }
      }
    }
    &-error-messages {
      color: $color-red;
      span {
        display: block;
      }
    }
  }
}
