.olapic-block {
  &__header {
    max-width: 866px;
    @include swap_direction(margin, 0 auto);
    text-align: center;
    color: $color-black;
  }
  &__title {
    font-family: 'Lato Black';
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0;
    @include swap_direction(margin, 0 0 3px 0);
    @include swap_direction(padding, 20px 0 0 0);
    @include breakpoint($landscape-up) {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 7px;
    }
    > p {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      line-height: inherit;
    }
  }
  &__copy {
    @include swap_direction(margin, 0 0 10px 0);
    @include breakpoint($landscape-up) {
      margin-bottom: 20px;
    }
    > p {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      line-height: inherit;
    }
  }
  &__content.bgimg {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
