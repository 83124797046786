.waitlist_thankyou {
  &_message {
    &.bis-mobile {
      @include swap_direction(margin, 10px 0px 0px 0px);
      width: 100%;
      @include swap_direction(padding, 10px 0px 10px 0px);
      font-size: 16px;
      width: 100%;
    }
    font-family: $brandon-text;
    @include swap_direction(margin, 5px 60px);
    line-height: 1.45em;
    font-size: 21px;
  }
  &_header {
    &.waitlist_header {
      &.bis-mobile {
        text-transform: none;
        @include swap_direction(margin, 10px 0px);
        font-size: 20px;
        line-height: 25px;
        display: block;
        font-weight: bold;
      }
      @include swap_direction(margin, 40px 0px 0px 60px);
      line-height: 1em;
      font-size: 40px;
    }
  }
}

.bis-mobile {
  .field {
    border-top: 1px dotted gray;
    margin-top: 20px;
    @if $cr22 == true {
      margin-top: 18px;
      border: 0;
    }
  }
}

.waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

@if $cr22 == true {
  .waitlist_thankyou {
    &_message {
      @include typography-label-text;
      @include swap_direction(margin, 0 0 0 15px);
      border: 0;
      padding: 0;
    }
    &_header {
      &.waitlist_header {
        @include typography-headline--m;
        @include swap_direction(margin, 105px 0 39px 15px);
        line-height: 1.3;
        font-size: 24px;
        font-weight: normal;
        text-transform: none;
      }
    }
  }
  input[type=button] {
    &.waitlist_thankyou_close-btn {
      @include cta-primary();
      @include swap_direction(margin, 0 0 0 15px);
      bottom: 0;
      display: block;
      padding: 20px 50px;
      position: fixed;
      height: 72px;
      text-transform: none;
      &:hover {
        background: $color-core-black;
      }
    }
  }
}
