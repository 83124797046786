/* ==========================================================================
Common Classes
========================================================================== */

.desktop-text {
  display: none;
  @include breakpoint($medium-up) {
    display: inline;
  }
}

.mobile-text {
  @include breakpoint($medium-up) {
    display: none;
  }
}

.t-mobile {
  display: inline;
  @include breakpoint($medium-up) {
    display: none;
  }
}

.t-desktop {
  display: none;
  @include breakpoint($medium-up) {
    display: inline;
  }
}

.desktop-block {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.mobile-block {
  @include breakpoint($medium-up) {
    display: none;
  }
}

.live-chat__box {
  @include h9;
  position: relative;
  background-color: #000;
  @include swap_direction(padding, 7px 5px);
  @include swap_direction(margin, 0);
  text-align: center;
  color: #fff;
}

.live-chat__arrow {
  @include arrow(bottom, $rdirection, #000, null, 10px);
}

body.device-mobile.elc-user-state-anonymous {
  span.button--log-in {
    display: block;
  }
  span.button--log-out {
    display: none;
  }
}

body.device-mobile.elc-user-state-logged-in {
  span.button--log-out {
    display: block;
  }
  span.button--log-in {
    display: none;
  }
}

.sticky-offer.expanded,
.sticky-offer:focus,
.sticky-chat.expanded,
.sticky-chat:focus {
  bottom: 0 !important;
}

.site-nav .menu__item {
  &:hover > .menu,
  &.active > .menu {
    visibility: visible;
  }
  &:hover > .menu--lvl-2,
  &.active > .menu--lvl-2 {
    opacity: 1;
  }
}

footer a.custom-outline {
  @include outline;
  border: 0px !important;
}

input[type='radio']:focus + label {
  outline: 1px dotted #2a6496;
}

.checkout .adpl input.error {
  border-color: 1px solid #ff0000 !important;
}

.invisible.focusable:active,
.invisible.focusable:focus,
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  font-size: 13px;
  position: relative !important;
  z-index: 902;
}

.outline-none {
  border: none;
  outline: none;
}

.address-form {
  span.label-content {
    display: block;
  }
}

.recommended-item {
  a.thumb {
    display: inline-block;
  }
}

.grid {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  [class*='col-'] {
    float: left;
    position: relative;
  }
  .col-50 {
    width: 50%;
  }
  .col-40 {
    width: 30%;
  }
  .col-60 {
    width: 60%;
  }
}
