.responsive-tabs {
  h2.resp-accordion,
  h2.resp-tab-active {
    @include h8;
    text-transform: uppercase;
    border: none;
    border-top: 1px solid $base-border-color;
    @include swap_direction(padding, 10px 20px);
    position: relative;
    background: $color-white;
    .spp-product-layout__content-item & {
      border-top: none;
    }
  }
  h2.resp-tab-active {
    border-bottom: none;
  }
  .resp-tab-content {
    border: none;
    @include swap_direction(padding, 0 20px 20px 20px);
  }
  .resp-tab-item.resp-tab-active {
    background: transparent;
  }
  .resp-tab-item {
    @include h8;
    background: $color-gray-background;
    margin-bottom: 40px;
    @include swap_direction(padding, 8px 56px);
    border: 1px solid $color-black;
    border-width: 0 1px 1px 0;
    .spp-product-layout__content-item & {
      border: 1px solid $color-gray-border;
      border-width: 0 0 1px 0;
    }
    &:first-child {
      border-width: 0 1px 1px 1px;
      .spp-product-layout__content-item & {
        border-width: 0 0 1px 0;
      }
    }
    &.resp-tab-active {
      border-bottom: none;
      .spp-product-layout__content-item & {
        border-bottom: 1px solid $color-black;
      }
    }
  }

  // Mobile SPP arrows for accordion
  &.responsive-tabs--how_to_use {
    .resp-arrow {
      @include transition(transform 0.1s);
      @include icon('arrow_down');
      @include rotate(-90deg);
      float: none;
      position: absolute;
      font-size: 9px;
      width: 20px;
      height: 11px;
      top: 9px;
      #{$rdirection}: 20px;
    }
    .resp-tab-active {
      .resp-arrow {
        top: 5px;
        #{$rdirection}: 13px;
        @include rotate(0);
      }
    }
  }
}

.responsive-tabs-formatter {
  max-width: $max-width;
  margin: 0 auto;
  text-align: center;
  .spp-product-layout__content-item & {
    max-width: $max-width-large;
  }
  &__header {
    text-align: center;
    p {
      margin: 0px;
    }
    @include breakpoint($medium-up) {
      margin-bottom: 15px;
    }
  }
  &__item {
    display: none;
  }
  .resp-tab-item {
    letter-spacing: 4px;
    margin-bottom: 0;
    @include swap_direction(padding, 8px 20px);
  }
  .resp-tabs-list {
    margin-bottom: 15px;
  }
  .resp-tab-content {
    p {
      margin: 1em 0 0 0;
    }
  }
}

.responsive-tabs-formatter--bundle-style {
  h2.resp-accordion,
  li.resp-tab-item {
    color: $color-bundle-gray;
    font-family: $brandon-text-bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 15px;
    border-collapse: collapse;
    height: 50px;
    line-height: 50px;
    @include icon('plus', 'after', false, false, 20px);
    vertical-align: middle;
    position: relative;
    border-top: 1px solid $color-bundle-gray;
    border-left: none;
    border-right: none;
    @include breakpoint($medium-up) {
      font-size: 16px;
      padding: 0px 25px;
      border: 1px solid $color-bundle-gray;
    }
    &:after {
      transition: transform 1s;
      color: $color-bundle-gray;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0%, -50%);
    }
    &.resp-tab-active {
      transition: none;
      border-bottom: 0px;
      @include icon('minus', 'after', false, false, 20px);
      &:after {
        //transform: translate(0%, -50%) rotate(-180deg);
      }
    }
  }
  .resp-tab-content {
    font-size: 13px;
    line-height: 22px;
    color: $color-bundle-gray;
    font-family: $brandon-text-regular;
    padding: 0px 15px;
    border-color: $color-bundle-gray;
    @include breakpoint($medium-up) {
      padding: 15px;
      border-top: 0px;
      margin-right: 1px;
    }
    p {
      margin-bottom: 1em;
    }
  }
  .resp-tabs-list {
    @include breakpoint($medium-up) {
      display: flex;
    }
    li {
      flex: 1;
    }
  }
}
