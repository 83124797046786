// Overrides to spacing on homepage
body.front {
  .bobbis-lessons {
    margin-bottom: 0;
    .slick-slider {
      margin-bottom: 0;
    }
    .bobbis-lessons__related_products--wrapper {
      padding-bottom: 0;
    }
  }
}

.bobbis-lessons {
  @include swap_direction(margin, 0 0 40px 0);
  .product-grid {
    padding-top: 25px;
    &__item {
      margin-bottom: 0;
    }
    .product-thumb {
      padding-bottom: 0;
    }
    &--carousel {
      .slick-prev {
        #{$ldirection}: 3px;
      }
      .slick-next {
        #{$rdirection}: 6px;
      }
    }
  }
  &__container {
    background: $color-white;
    color: $color-black;
    text-align: center;
    @include swap_direction(padding, 25px 0);
  }

  // Carousel
  &__carousel {
    margin-bottom: 0; // previously 35px
    .bobbis-lessons__slide {
      display: none; //Prevent FOUC
      overflow: hidden;
      &:first-child {
        display: block;
      }
    }
    .slick-track {
      transform: none !important;
    }
    &.slick-initialized .bobbis-lessons__slide {
      display: block;
    }
    // Override Slick
    .slick-next,
    .slick-prev {
      top: 33%;
      height: inherit;
      // padding: 20px;
      margin-top: -30px;
      &:before {
        color: $color-black;
      }
    }
  }
  &__content {
    @include breakpoint($tablet) {
      @include swap_direction(padding, 0 10.75%);
    }
    @include swap_direction(margin, 0 auto);
  }
  &__media {
    position: relative;
    cursor: pointer;
    video {
      width: 100%;
      height: 100%;
    }
    &--video {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      &.active {
        background-color: $color-black;
      }
    }
  }
  &__headline {
    @include h4;
    color: $color-black;
    margin-bottom: 10px;
    font-size: 16px;
    @include breakpoint($tablet) {
      font-size: 19px;
    }
  }
  &__description {
    font-size: 16px;
    font-style: italic;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($tablet) {
      width: 75%;
      font-size: 18px;
    }
  }
  &__image {
    display: block;
    margin-bottom: 32px;
  }
  &__footer {
    position: relative;
    @include pie-clearfix;
    @include breakpoint($tablet) {
      @include swap_direction(margin, 0 12.5% 0 12.5%);
    }
    .bobbis-lessons__footer--left {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 0;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      top: -4px;
    }
    .bobbis-lessons__footer--right {
      position: absolute;
      #{$rdirection}: 0;
      bottom: -5px;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      top: 0;
      @include breakpoint($tablet) {
        bottom: 0;
      }
    }
    .link--get-the-look {
      border: 0;
      display: inline-block;
      padding-top: 40px;
      @include breakpoint($tablet) {
        padding-top: 0;
      }
      .icon {
        display: inline-block;
      }
      &.active {
        .icon {
          @include transform(scale(1, -1));
        }
      }
    }
    .bobbis-lessons__social {
      li {
        display: inline-block;
        @include swap_direction(margin, 0 12px 0 0);
        @include swap_direction(padding, 0);
        list-style: none;
        font-size: 20px;
        a {
          color: $color-black;
          text-decoration: none;
          &.bobbis-lessons__social--facebook:hover {
            color: $color-facebook;
          }
          &.bobbis-lessons__social--twitter:hover {
            color: $color-twitter;
          }
          &.bobbis-lessons__social--google:hover {
            color: $color-google-plus;
          }
        }
      }
    }
  }
  &__related_products--wrapper {
    display: none;
    @include opacity(0);
    @include transition(opacity 0.2s ease-in);
    &__related_products--wrapper {
      display: none;
      @include opacity(0);
      @include transition(opacity 0.2s ease-in);
      &.is-expanded {
        @include transition(opacity 0.2s ease-in);
        @include opacity(1);
      }
      .wrap {
        position: relative;
        padding-top: 55px;
        padding-bottom: 35px;
        border-bottom: 1px solid $color-light-gray;
        &.js-add_all {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
      .bobbis-lessons__arrow {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 100%;
        text-align: center;
      }
      .bobbis-lessons__arrow--down {
        @include arrow(bottom, null, $color-black, null, 18px);
      }
      .bobbis-lessons-related-products__footer {
        text-align: center;
      }
    }
  }
  &__preview {
    position: absolute;
    #{$rdirection}: -295px;
    width: 295px;
    background: $color-white;
    text-align: $ldirection;
    @include swap_direction(padding, 16px 50px 11px 16px);
    top: 25%;
    z-index: 100;
    color: $color-black;
    @include pie-clearfix;
    @include breakpoint($medium-up) {
      top: 27%;
    }
    .thumb {
      float: $ldirection;
      margin-#{$rdirection}: 16px;
    }
    .abstract {
      overflow: hidden;
      position: relative;
      top: -4px;
      .h8,
      .h9 {
        color: $color-black;
      }
    }
    &.bobbis-lessons__preview--prev {
      #{$rdirection}: auto;
      #{$ldirection}: -295px;
      padding-#{$rdirection}: 16px;
      padding-#{$ldirection}: 50px;
    }
  }
  &__container--gray {
    background: $color-gray-background;
    .bobbis-lessons__preview,
    .section-header span {
      background: $color-gray-background;
    }
  }
  &__container--black {
    background: $color-black;
    color: $color-white;
    .bobbis-lessons__header,
    .bobbis-lessons__headline,
    .bobbis-lessons__social li a {
      color: $color-white;
    }
    .bobbis-lessons__footer .link {
      border: none;
      color: $color-white;
      &:hover {
        color: $color-pink;
      }
    }
    .section-header {
      &:before {
        background: $color-white;
      }
      span {
        background: $color-black;
        color: $color-white;
      }
    }
    .bobbis-lessons__carousel {
      .slick-next,
      .slick-prev {
        &:before {
          color: $color-white;
        }
      }
    }
    .bobbis-lessons__preview {
      background: $color-black;
      color: $color-white;
      .abstract {
        .h8,
        .h9 {
          color: $color-white;
        }
      }
    }
  }
  // This is complicated. Basically, they want a quote on the video module
  // like "-Bobbi Brown". They want this specifically to not be italicized, this
  // seemed like the fastest way. @TODO -- Cleaner fix ?
  // This assumes that in the last paragraph if there's a bolded set of characters,
  // we want them not italicized.
  .bobbis-lessons__description > p:last-child > strong {
    font-style: normal;
  }
  .bobbis-lessons__related_products--wrapper {
    @include breakpoint($tablet) {
      @include swap_direction(padding, 40px 0);
    }
    &.active {
      @include opacity(1);
    }
  }
} //.bobbis-lessons
