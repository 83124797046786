.discover-foundation-container-one {
  .rows {
    .row {
      width: 100%;
      border: none;
    }
  }
  .discover-foundation-block {
    margin-bottom: 40px;
    @include swap_direction(padding, 0 10px 30px 5px);
    @include breakpoint($large-up) {
      @include swap_direction(padding, 0);
    }
    position: relative;
    text-align: center;
    &__data {
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &__bottom {
      margin-top: 40px;
      position: relative;
    }
    &__content {
      position: relative;
    }
    &__image {
      img {
        height: auto;
      }
    }
    &__cta {
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      bottom: 0;
      margin: 0 auto;
    }
    &__note {
      position: absolute;
      width: 23%;
      @include breakpoint($tablet-down) {
        width: 19%;
      }
      h2,
      h3 {
        font-size: 16px;
        margin-bottom: 0;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          font-size: 20px;
          line-height: 25px;
        }
      }
      span {
        font-size: 16px;
        @include breakpoint($large-up) {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
    &__note1 {
      #{$ldirection}: 0;
      top: 0;
    }
    &__note2 {
      #{$rdirection}: 0;
      top: 0;
    }
    &__note3 {
      #{$ldirection}: 0;
      bottom: 0;
    }
    &__note4 {
      #{$rdirection}: 0;
      bottom: 0;
    }
    &__subheader {
      text-align: center;
      margin-bottom: 30px;
      h2 {
        font-size: 16px;
        line-height: 1em;
        margin-bottom: 5px;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
      span {
        font-size: 16px;
        @include breakpoint($large-up) {
          font-size: 20px;
        }
      }
    }
  }
  .sub_asterisk_text {
    width: 50%;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($large-up) {
      width: 60%;
    }
  }
}

.discover-foundation-container-two {
  .rows {
    .row {
      float: $ldirection;
      width: 50%;
      border-#{$rdirection}: 1px solid $color-gray-border;
    }
    .row:last-child {
      border: none;
    }
  }
  .discover-foundation-block {
    margin-bottom: 20px;
    text-align: center;
    margin-right: 30px;
    h2,
    h3,
    h4 {
      margin-#{$rdirection}: 30px;
      margin-bottom: 0px;
      text-transform: uppercase;
    }
    &__content {
      display: inline-block;
    }
    &__image {
      float: $ldirection;
      height: 265px;
      display: flex;
      align-items: center;
      @include breakpoint($large-up) {
        height: 420px;
      }
      img {
        height: 242px;
        @include breakpoint($large-up) {
          height: 420px;
        }
      }
    }
    &__header {
      margin-bottom: 25px;
      text-align: center;
      h2 {
        font-size: 28px;
        font-family: 'Lato Black';
        line-height: 28px;
        margin-bottom: 0px;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          font-size: 34px;
          line-height: 34px;
        }
      }
      span {
        font-size: 16px;
        display: none;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          display: block;
          font-size: 20px;
        }
      }
    }
    &__data {
      float: $ldirection;
      position: relative;
      width: 200px;
      min-height: 330px;
      @include breakpoint($large-up) {
        min-height: 420px;
      }
    }
    &__cta {
      bottom: 0;
      position: absolute;
      bottom: 0;
      #{$ldirection}: 0;
    }
    &__note {
      span,
      h2,
      h4 {
        font-size: 12px;
        line-height: 12px;
        @include breakpoint($large-up) {
          font-size: 16px;
          line-height: 16px;
        }
      }
      #{$ldirection}: 0;
      position: absolute;
      text-align: center;
      width: 200px;
    }
    &__note1 {
      top: 0%;
    }
    &__note2 {
      top: 40%;
    }
    &__note3 {
      bottom: 5%;
    }
    @include breakpoint($medium-up) {
      &__note1 {
        top: 8%;
      }
      &__note2 {
        top: 42%;
      }
      &__note3 {
        top: 68%;
      }
    }
    &__free {
      &-shipping {
        background: $color-gray-background;
        font-size: 14px;
        font-family: $brandon-text-bold;
        margin-bottom: 30px;
        @include swap_direction(padding, 15px 0);
        text-align: center;
        text-transform: uppercase;
        img {
          margin-#{$rdirection}: 10px;
        }
      }
    }
    &__subheader {
      text-align: center;
      margin-bottom: 15px;
      h2,
      h3 {
        font-size: 18px;
        margin-bottom: 5px;
        @include breakpoint($large-up) {
          font-size: 22px;
        }
      }
      span {
        font-size: 14px;
        @include breakpoint($large-up) {
          font-size: 18px;
        }
      }
    }
  }
}

.discover-foundation-container {
  max-width: 1024px;
  @include swap_direction(margin, 30px auto 0);
  .slick-next::before {
    bottom: auto;
    #{$ldirection}: auto;
    position: relative;
    #{$rdirection}: auto;
    top: auto;
    font-size: 30px;
  }
  .slick-slider {
    margin-bottom: 0;
  }
  .slick-prev::before {
    font-size: 30px;
  }
  .slick-next {
    #{$rdirection}: 0px;
  }
  .slick-slide {
    .discover-foundation-block {
      text-align: center;
    }
  }
  h2,
  h3,
  h4 {
    font-family: $brandon-text-bold;
  }
  .discover-foundation-block {
    &__cta {
      position: absolute;
      background: $color-white;
      height: 35px;
      border: 1px solid $color-black;
      color: $color-black;
      @include breakpoint($large-up) {
        display: block;
      }
      &:hover {
        color: $color-white;
        background: $color-black;
      }
      @include swap_direction(padding, 9px);
      width: 180px;
    }
    &__cta-small {
      width: 200px;
      @include swap_direction(margin, 0 auto);
      font-size: 12px;
    }
  }
}

@include breakpoint($small-down) {
  .discover-foundation-container-one {
    .discover-foundation-block {
      margin-bottom: 10px;
      &__data {
        position: relative;
      }
      &__cta {
        position: relative;
        display: none;
      }
      &__note {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
      }
      &__bottom {
        margin: 0;
      }
    }
    .sub_asterisk_text {
      position: relative;
      width: auto;
      margin-bottom: 20px;
    }
  }
  .discover-foundation-container-two {
    .discover-foundation-block {
      margin-#{$rdirection}: 0px;
      margin-bottom: 0px;
      &__data {
        width: 203px;
      }
      &__subheader {
        margin-bottom: 15px;
        h2 {
          font-size: 16px;
        }
      }
      &__note {
        width: 170px;
      }
      &__header {
        margin: 15px 0;
        h2 {
          font-family: 'Lato Black';
          font-size: 18px;
          line-height: 18px;
        }
      }
      &__cta {
        display: none;
      }
    }
  }
}
