.spp-bundle-layout-flow {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  p {
    margin: 0;
  }
  &__title {
    margin: 20px 0px;
  }
  &__title,
  &__title p {
    font-family: $brandon-text-bold;
    border: 0px;
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  &__subtitle {
    margin: 20px;
    @include breakpoint($medium-up) {
      margin: 40px 40px;
    }
  }
  &[data-step-id='review'] &__status-menu {
    display: none;
  }
  &__status-menu {
    display: flex;
    justify-content: center;
    &-item {
      font-family: $brandon-text-bold;
      color: $color-gray;
      position: relative;
      text-transform: uppercase;
      font-size: 12px;
      margin: 0px 15px;
      @include breakpoint($medium-up) {
        font-size: 26px;
        line-height: 31px;
        margin: 0px 40px;
      }
      &.bundle-sku-selected {
        border-bottom: 2px solid green;
        color: $color-black;
      }
      &.active {
      }
    }
  }
  .spp-bundle-layout__next-cta {
    text-align: center;
    .button {
      padding: 15px 30px;
    }
  }

  // This is a dev mode. show all steps at once.
  &.bundle-style--only_bundle_flow {
    max-width: none;
    .spp-bundle-layout__step {
      float: left;
      width: 500px;
      margin-bottom: 50px;
      &.active {
        border: 1px solid $color-bundle-pink;
      }
    }
  }
  &.bundle-style--show_single {
    height: 0px;
    overflow-y: hidden;
    visibility: hidden;
    #colorbox & {
      height: auto;
      visibility: visible;
    }
    .spp-bundle-layout__step {
      height: 0px;
      overflow-y: hidden;
      visibility: hidden;
      transition: opacity 1s;
      opacity: 0;
      margin-bottom: 0px;
      &.active {
        opacity: 1;
        height: auto;
        visibility: visible;
        min-height: calc(100vh - #{$bundle-fixed-cta-height});
        // create space for the fixed button so we can scroll to bottom on
        // colorbox.
        padding-bottom: $bundle-fixed-cta-height * 3;
        @include breakpoint($medium-up) {
          min-height: auto;
          padding-bottom: 0px;
        }
        .spp-bundle-layout__next-cta {
          text-align: center;
          display: none;
          #colorbox & {
            display: block;
          }
          .button {
            width: 100%;
            position: relative;
            @include breakpoint($medium-down) {
              @include fixed-bundle-cta-button();
            }
          }
          // special mode to fix button when dkestop viewport is too short
          &.below-viewport {
            // create spacing when we move the button into fixed space.
            // so that it doesnt overlap content
            @include breakpoint($medium-up) {
              margin-top: $bundle-fixed-cta-height;
            }
            .button {
              @include breakpoint($medium-up) {
                max-width: $bundle-overlay-max-width;
                position: fixed;
                bottom: 0px;
                // js will set button left
              }
            }
          }
        }
      }
    }
  }
  .product-gallery {
    margin: 0px auto;
    width: 62.5vw;
    @include breakpoint($medium-up) {
      max-width: $bundle-desktop-image-size;
      margin: 0 auto;
    }
    &__photo--large {
      max-width: none;
    }
    img {
      // fix slick slider
      height: auto !important;
    }
    &__main {
      padding: 0px;
      min-height: 62.5vw;
      margin-bottom: 35px; // spacing for dots
      @include breakpoint($medium-up) {
        min-height: $bundle-desktop-image-size;
      }
    }
    &__thumbs {
      display: none;
    }
    &__shade {
      display: none;
    }
    .slick-dots {
      padding: 0px;
      bottom: -15px;
      button:before {
        font-size: 30px;
      }
      li,
      button {
        width: 15px;
        height: 15px;
      }
    }
  }
  .product__button--add-to-bag {
    &[data-button-status='pending'] {
      // TODO add a loading animation for add to pag
      opacity: 0.7;
    }
  }
}

.spp-bundle-layout-flow__status-menu-item {
  color: $color-gray;
  &.bundle-sku-selected {
    cursor: pointer;
    border-bottom: none;
  }
  &.active {
    color: $color-black;
  }
  .bundle-step-indicator__product-image {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    @include breakpoint($medium-up) {
      width: 75px;
      height: 75px;
    }
  }
  .product-step-label {
    position: relative;
    z-index: 1;
  }
  &.bundle-sku-selected {
    .sku-reaction-style--replace_with_sku_image & {
      .product-step-label {
        visibility: hidden;
      }
      .bundle-step-indicator__product-image {
        display: block;
      }
    }
    .sku-reaction-style--hover_sku_image &:hover {
      .product-step-label {
        visibility: hidden;
      }
      .bundle-step-indicator__product-image {
        z-index: 0;
        display: block;
      }
    }
    .sku-reaction-style--sku_image_background & {
      .bundle-step-indicator__product-image {
        z-index: 0;
        display: block;
      }
    }
  }
}

.bundle-product-slide {
  text-align: center;
  @include breakpoint($medium-up) {
    margin-bottom: 25px;
  }
  &__product-name {
    margin: 10px 18px;
    @include breakpoint($medium-up) {
      margin: 0px 50px 10px 50px;
    }
  }
  &__product-name,
  &__product-name p {
    font-family: $brandon-text-bold;
    text-align: center;
    font-size: 21px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 28px;
      line-height: 37px;
    }
  }
  &__product-description {
    margin: 10px 18px 0px 18px;
    @include breakpoint($medium-up) {
      margin: 10px 50px 0px 50px;
    }
  }
  &__product-description,
  &__product-description p {
    font-family: $brandon-text-regular;
    font-size: 14px;
    letter-spacing: 0.0678em;
    line-height: 25px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0;
    }
  }
  // shaded products need more vertical space
  // due to swatch list
  &--shaded &__product-description,
  &--shaded &__product-description p {
    line-height: 21px;
  }
  &__swatch-info {
    min-height: 2em;
    margin: 10px 18px 0px 18px;
    @include breakpoint($medium-up) {
      margin: 25px 40px 0px 40px;
    }
  }
  &__swatch-info,
  &__swatch-info p {
    font-family: $brandon-text-regular;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0678em;
    @include breakpoint($medium-up) {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0;
    }
  }
  &__shade-name {
    font-weight: bold;
  }
  .shade-picker {
    &__shades {
      max-width: 312px;
      margin: 10px auto;
      padding: 0px;
      &.single-line {
        white-space: nowrap;
        overflow-x: auto;
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        @include breakpoint($medium-up) {
          white-space: none;
        }
      }
      &.slick-initialized {
        max-width: none;
      }
    }
    &__shades .slick-track {
      margin-left: 15px; // offset last swatch
      @include breakpoint($medium-up) {
        margin-left: 0px;
      }
    }
    &.single-line &__shade-item {
      display: inline;
      float: none;
      @include breakpoint($medium-up) {
        float: left;
      }
    }
    &.single-line &__shade {
      display: inline-block;
    }
    &__shade {
      width: 23px;
      height: 23px;
      @include breakpoint($medium-up) {
        width: 27px;
        height: 27px;
      }
    }
  }
}
