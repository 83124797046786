.product-filter {
  @include pie-clearfix;
  &__list {
    width: 100%;
    text-align: center;
  }
  &__item {
    @include h8;
    display: inline-block;
    // Use -6px instead of -5px and set a background color to get around
    // occasionally seeing a 2px borders on some items
    @include swap_direction(margin, 0 -6px 0 0);
    background-color: $color-white;
    text-align: center;
  }
  &__filter {
    @include antialiased;
    color: $color-black;
    cursor: pointer;
    height: 32px;
    line-height: 30px;
    display: block;
    border: 1px solid $color-black;
    @include swap_direction(padding, 0 12px);
    font-size: 9px;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 15px);
      font-size: 12px;
    }
    &.active {
      background: $color-black;
      color: $color-white;
    }
  }

  // Disabled, show as gray
  &.disabled {
    .product-filter {
      &__label,
      &__label:before,
      &__filter {
        color: $color-gray-alt;
      }
      &__item:first-child,
      &__filter {
        border-color: $color-gray-alt;
        cursor: default;
      }
    }
  }
}

// Show this only with javascript
.no-matches__message {
  display: none;
}

// Mix it up requires these be hidden by default.
// https://mixitup.kunkalabs.com/learn/tutorial/get-started/#hide-target-elements
.js .mixitup-initialized .js-filter-item {
  display: none;
}
