@import '../standalone/perlgem/account/account-reset-password';

.pg_wrapper {
  padding-top: 30px;
  @include breakpoint($medium-up) {
    padding-top: 0;
  }
  .nav-checkout & {
    padding-top: 0;
  }
  &.samples-page,
  &.onepage {
    @include breakpoint($small-down) {
      padding-top: 30px;
    }
  }
  .messages {
    color: $color-red;
  }
  .termsandprivacy {
    @include swap_direction(padding, 0 0 20px 20px);
  }
}
