.product-sku-quickview {
  @include transition(opacity 0.2s ease, visibility 0.2s ease);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  #{$ldirection}: -60px;
  z-index: 1;
  &.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  &__inner {
    @include icon('quickshop_arrow');
    margin-bottom: 36px;
    background-color: $color-white;
    position: relative;
    width: 287px;
    border: solid 1px #acacac;
    @include swap_direction(padding, 30px 25px);
    &:before {
      @include transform(rotate(180deg));
      position: absolute;
      z-index: 2;
      color: #acacac;
      background: $color-white;
      letter-spacing: 0;
      bottom: -14px;
      #{$ldirection}: 50%;
      margin-#{$ldirection}: -13px;
    }
  }
  &__trigger {
    position: relative;
    cursor: pointer;
  }
  &__img {
    width: 100%;
  }
  &__headline {
  }
  &__headline-link,
  &__headline-link:hover {
    color: $color-black;
    text-decoration: none;
  }
  &__info {
    @include pie-clearfix;
    border-top: solid 1px $color-black;
    border-bottom: solid 1px $color-black;
    margin-bottom: 23px;
    font-family: $brandon-text-bold;
    font-size: 12px;
    line-height: 35px;
    &--shade {
      float: $ldirection;
    }
    &--price {
      float: $rdirection;
    }
  }
  &__hex {
    @include circle(19px, #999);
    display: inline-block;
    position: relative;
    top: 5px;
  }
  &__actions {
    text-align: $rdirection;
  }
  .product__favorite {
    color: $color-black;
    font-size: 26px;
    margin-#{$rdirection}: 9px;
    .icon-heart {
      color: $color-pink;
    }
  }
}
