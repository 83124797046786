.product-guide-item {
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(margin, 0 5px);
  &:last-child {
    border: none;
  }
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 10px);
  }
  @include breakpoint($large-up) {
    max-width: 1024px;
    @include swap_direction(margin, 0 auto);
  }
  &__wrap {
    @include pie-clearfix;
    background-image: none;
    @include swap_direction(padding, 0 10px 30px);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 20px 30px);
    }
    @include breakpoint($large-up) {
      padding-bottom: 50px;
    }
  }
  &__image-container {
    padding-top: 20px;
    text-align: center;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $ldirection;
      padding-top: 60px;
      width: 26%;
    }
    @include breakpoint($large-up) {
      padding-top: 30px;
    }
  }
  &__image {
    max-width: 260px;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
  }
  &__product {
    margin-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $rdirection;
      padding-top: 60px;
      width: 69%;
    }
    @include breakpoint($large-up) {
      width: 72%;
    }
  }
  &__title {
    font-family: $brandon-text;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 25px;
    }
  }
  &__title-description {
    font-family: $brandon-text-bold;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  &__description {
    font-family: $brandon-text;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  &__shop-details {
    @include pie-clearfix;
  }
  &__price {
    font-family: $brandon-text-bold;
    font-size: 12px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      font-size: 18px;
      margin-bottom: 0;
      @include swap_direction(padding, 0.45em 15px 0 0);
    }
  }
  &__ppml {
    line-height: 35px;
    clear: both;
  }
  .product-guide-item__add-to-bag-wrapper {
    @include breakpoint($medium-up) {
      float: $ldirection;
    }
    .product__button {
      background-color: #fff;
      border: 2px solid #ff4661;
      color: #ff4661;
    }
  }
  &__meta {
    border: solid 2px #ede9e4;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 20px 18px 20px 20px);
    position: relative;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 20px);
      float: $rdirection;
      width: 69%;
    }
    @include breakpoint($large-up) {
      width: 72%;
    }
  }
  &__meta-title {
    font-family: $brandon-text-bold;
    font-size: 12px;
    margin-bottom: 15px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  &__meta-details {
    font-family: $brandon-text;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 0;
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  &.product-guide-item--image-left {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: $ldirection;
    }
  }
  .quickshop-wrapper.active:before {
    #{$ldirection}: 50%;
    margin-#{$ldirection}: -12px;
  }
}

.product-guide-item--image-both {
  .product-guide-item__wrap {
    background-repeat: no-repeat;
    background-position: bottom $rdirection;
    background-size: auto 50%;
    max-width: 600px;
    @include breakpoint($medium-up) {
      background-size: auto 75%;
      max-width: none;
    }
    @include breakpoint($large-up) {
      background-size: auto 90%;
    }
  }
  .product-guide-item__image-container {
    float: $ldirection;
    margin-top: 10px;
    padding-top: 0;
    width: 30%;
    @include breakpoint($medium-up) {
      margin-top: 15px;
      width: 20%;
    }
  }
  .product-guide-item__product {
    float: $ldirection;
    width: 68%;
  }
  .product-guide-item__price {
    float: $ldirection;
    @include swap_direction(padding, 0.75em 10px 0 0);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0.55em 15px 0 0);
    }
  }
  .product-guide-item__add-to-bag-wrapper {
    float: $ldirection;
  }
  .product-guide-item__meta {
    float: $ldirection;
    width: 84%;
    &:before,
    &:after {
      #{$ldirection}: 100%;
      top: 40px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:before {
      border-color: rgba(237, 233, 228, 0);
      border-#{$ldirection}-color: #ede9e4;
      border-width: 13px;
      margin-top: -13px;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-#{$ldirection}-color: #fff;
      border-width: 10px;
      margin-top: -10px;
    }
    @include breakpoint($medium-up) {
      min-height: none;
      @include swap_direction(padding, 20px);
      width: 70%;
    }
  }
}

.product-guide-item--image-top {
  .product-guide-item__image-container {
    float: $rdirection;
    margin-#{$rdirection}: -15px;
    width: 100%;
    @include breakpoint($medium-up) {
      float: none;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 30px 0);
    }
  }
  .product-guide-item__image {
    max-width: 100%;
  }
  .product-guide-item__image--desktop {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .product-guide-item__product {
    padding-top: 0;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $rdirection;
      width: 60%;
    }
  }
  .product-guide-item__description {
    @include swap_direction(padding, 0);
  }
  .product-guide-item__meta {
    @include breakpoint($medium-up) {
      float: $ldirection;
      position: relative;
      width: 35%;
    }
  }
}
