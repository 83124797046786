.spp-bundle-layout__review {
  &__value-proposition {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.0678em;
    margin-top: 5px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__items {
    position: relative;
    padding-top: 30px;
    width: 75%;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      padding-top: 70px;
      max-width: 320px;
    }
    &:before {
      content: '';
      background: $color-black;
      display: none;
      position: absolute;
      height: 3px;
      width: 26px;
      top: 30px;
      left: 50%;
      transform: translate(-50%, -50%);
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.bundle-review-item {
  display: flex;
  margin-bottom: 30px;
  @include breakpoint($medium-up) {
    margin-bottom: 50px;
  }
  &__image {
    width: 75px;
    height: 75px;
    @include breakpoint($medium-up) {
      width: 100px;
      height: 100px;
    }
  }
  &__abstract {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    @include breakpoint($medium-up) {
      margin-left: 30px;
    }
  }
  &__product-name {
    font-family: $brandon-text-bold;
    border: 0px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
  }
  &__product-size {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
  }
  &__shade {
    display: flex;
    align-items: center;
  }
  .shade-picker__shade {
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
  }
  &__shade__name {
    font-size: 13px;
    line-height: 24px;
    margin-left: 5px;
  }
  .bundle-edit-link {
    color: $color-bundle-pink;
    font-family: $brandon-text-bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-decoration: none;
    margin-top: 3px;
    @include breakpoint($medium-up) {
    }
    span {
      border-bottom: 2px solid;
    }
  }
}
