.customer-service-quick-info {
  @include clearfix;
  float: $rdirection;
  width: 70%;
  height: 110px;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0 20px);
  border-bottom: 1px solid $color-black;
  @include breakpoint($small-down) {
    display: none;
  }
  &__list {
    @include clearfix;
    width: 100%;
    height: 110px;
  }
  &__list-item {
    display: block;
    float: $ldirection;
    position: relative;
    width: 33.33333333%;
    height: 110px;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 2.25em 0);
    text-align: $ldirection;
    list-style-type: none;
  }
  &__link {
    display: block;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    color: $color-black;
    font-family: $body-font-bold;
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    span.icon {
      position: absolute;
      top: 30px;
      #{$ldirection}: 0;
      font-size: 3em;
    }
  }
  &__link:hover {
    color: $color-pink;
    text-decoration: none;
  }
  /* Specific styling for different icons and text on single/double lines */
  &__list-item--email > &__link {
    margin-top: 10px;
    margin-#{$ldirection}: 65px;
  }
  &__list-item--chat > &__link {
    margin-top: 10px;
    margin-#{$ldirection}: 60px;
    span.icon {
      top: 33px;
    }
  }
  &__list-item--phone > &__link {
    margin-top: 0;
    margin-#{$ldirection}: 30px;
  }
}
