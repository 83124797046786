.node-elc-mpp,
.mpp {
  .page-header {
    background-color: $color-light-brown; // Default gray background
    &__title__inner {
      background-color: $color-light-brown;
    }
  }
}

.mpp {
  &__filters {
    background: $color-light-brown;
    border-top: 1px solid $color-white;
    &__no-matches {
      display: none;
      color: red;
      @include swap_direction(padding, 50px 20px 0);
      font-family: $brandon-text-bold;
      text-align: center;
    }
    &__container {
      @include wrap;
      @include pie-clearfix;
      background: $color-light-brown;
      border-top: 1px solid $color-black;
      margin-top: -1px;
      @include swap_direction(padding, 20px 0);
      @include breakpoint($medium-up) {
        border-top: none;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: 0;
      }
    }
    &__summary {
      @include h9;
      display: none;
      text-align: center;
      width: 100%;
      @include breakpoint($medium-up) {
        position: absolute;
        top: 28px;
      }
      .icon {
        font-size: 17px;
        top: 3px;
      }
      .mpp__filters__summary-all {
        display: none;
      }
      &--empty {
        .mpp__filters__summary-all {
          display: inline;
        }
        .mpp__filters__summary-text {
          display: none;
        }
      }
    }
    &__trigger {
      position: absolute;
      #{$rdirection}: 20px;
      top: 13px;
      color: $color-black;
      font-size: 30px;
      cursor: pointer;
      @include breakpoint($medium-up) {
        top: 24px;
      }
      .icon {
        display: inline-block;
        // Original design:
        // @include breakpoint($medium-up) {
        //   @include transform(rotate(-180deg));
        // }
      }
      &.active {
        .icon {
          @include transform(rotate(270deg));
          // Original design:
          // @include breakpoint($medium-up) {
          //   @include transform(rotate(0));
          // }
        }
      }
    }
    &__headline {
      @include h8;
      @include swap_direction(margin, 0 20px 20px);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 0 0 20px);
        width: 190px;
        float: $ldirection;
      }
    }
    &__nav-container {
      // This is what the padding *should* be, according to the designs, but
      // they were missing an item so we have to compress everything a bit to
      // get them not to break to a new line at 320px.
      // padding: 0 17px;
      @include swap_direction(padding, 0 0 0 10px);
      // This looks a little better when there's enough space for all the items:
      @media screen and (min-width: 450px) {
        padding-#{$ldirection}: 10%;
      }
      @include breakpoint($medium-up) {
        // float: $ldirection;
        // width: 75%;
        position: relative;
        top: -5px;
        @include swap_direction(padding, 0 0 0 190px);
      }
      @media screen and (min-width: 830px) {
        padding-#{$ldirection}: 25%;
      }
    }
    .product-filter {
      text-align: $ldirection;
      position: relative;
      &__label {
        @include h9;
        display: block;
        @include swap_direction(margin, 0 0 13px 0);
        @include breakpoint($medium-up) {
          width: 125px;
          padding-#{$ldirection}: 25px;
          position: absolute;
          top: 6px;
          float: $ldirection;
          // width: 20%;
          // display: inline-block;
          @include swap_direction(margin, 0 12px 0 0);
        }
      }
      &__item {
        background-color: $color-light-brown;
        // When filters break to a new line on mobile they specifically asked to
        // not have this nice spacing, which is why the margin is set on &__list.
        // margin-bottom: 20px;
      }
      &__list {
        margin-bottom: 20px;
        text-align: $ldirection;
        @include breakpoint($medium-up) {
          // width: 80%;
          // float: $rdirection;
          display: inline-block;
          width: auto;
          padding-#{$ldirection}: 137px;
        }
      }
      &:last-child .product-filter__list {
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
      }
      &__filter {
        @include swap_direction(padding, 0 8px); // Helps squeeze filters onto one line. Use 0 12px to match design.
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 12px);
        }
      }
    }
    &--collapsed {
      .mpp__filters__nav-container {
        display: none;
      }
      .mpp__filters__headline {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          width: auto;
        }
      }
      @include breakpoint($medium-up) {
        .mpp__filters__container {
          padding-bottom: 30px;
        }
      }
      .mpp__filters__summary {
        display: block;
        top: 13px;
        @include breakpoint($medium-up) {
          margin-top: 13px;
        }
      }
    }
    &--sticky {
      overflow: hidden;
      position: fixed;
      top: 54px;
      width: 100%;
      display: block;
      z-index: 900; //underneath the header which is 901
      background: none;
      border-top: none;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 12px;
        padding-#{$rdirection}: 12px;
        top: 69px;
      }
      .mpp__filters__headline {
        @include breakpoint($medium-up) {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
      .mpp__filters__container {
        //padding: 10px 0;
        padding-top: 10px;
        padding-bottom: 19px;
        border-top: none;
        margin-top: 0;
        @include breakpoint($medium-up) {
          padding-bottom: 10px;
        }
      }
      .mpp__filters__trigger {
        font-size: 30px;
        top: 11px;
        @include breakpoint($medium-up) {
          font-size: 18px;
          margin-top: 3px;
          // top: auto;
        }
      }
      .mpp__filters__nav-container {
        top: 5px;
      }
      .mpp__filters__list {
        margin-bottom: 10px;
      }
      .mpp__filters__summary {
        margin-top: 4px;
        @include breakpoint($medium-up) {
          margin-top: 5px;
        }
      }
    }
  }
  .quickshop {
    .product__social {
      a {
        float: $ldirection;
        position: relative;
      }
      .wechatWrapper {
        cursor: pointer;
        .qrWrapper {
          z-index: 999;
          position: absolute;
          .qrcodeTableHidden {
            position: absolute;
            @include swap_direction(padding, 20px);
            top: 28px;
            #{$ldirection}: 10px;
            z-index: 999;
            cursor: pointer;
            background-color: white;
            box-shadow: 0 0 3px grey;
            h3 {
              font-size: 15px;
              color: #333;
              text-align: center;
              width: 180px;
            }
          }
        }
      }
    }
  }
  .product-grid {
    @include swap_direction(padding, 20px 0 50px);
    &__item {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 30px 0);
      }
    }
  }
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-size;
      }
    }
  }
  .product__title {
    h3,
    .at-h2 {
      font-size: $base-font-size;
    }
  }
}

.product {
  &__flag {
    text-transform: uppercase;
    color: $color-pink;
    font-size: 10px;
    letter-spacing: 0.24em;
    font-family: $brandon-text-bold;
    margin-bottom: 2px;
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
  }
}
