// Formatter
.online-services {
  max-width: 1024px;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &__title {
    .h2 {
      display: inline-block;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.25em;
      margin-top: 36px;
    }
  }
  &__subhead {
    font-size: 22px;
    p {
      line-height: 1.65em;
    }
  }
  &__image {
    border-bottom: 1px solid $color-border-gray;
  }
  &__content {
    @include swap_direction(margin, 25px 32px 25px 32px);
    display: inline-block;
    width: 100%;
    &__item {
    }
    div:nth-child(1),
    div:nth-child(2) {
      @include breakpoint($medium-up) {
        div.online-services-block {
          padding-top: 0;
          height: auto;
          p {
            margin-top: 0px;
          }
        }
      }
    }
    div:nth-last-child(2),
    div:last-child {
      @include breakpoint($medium-up) {
        div.online-services-block {
          border-bottom: none;
          padding-bottom: 0;
          height: auto;
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
    div:nth-child(odd) {
      div.online-services-block {
        @include breakpoint($medium-up) {
          border-#{$rdirection}: 1px solid $color-border-gray;
        }
      }
    }
  }
}

// Block items
.online-services-block {
  border-bottom: 1px solid $color-border-gray;
  cursor: pointer;
  width: 90%;
  float: $ldirection;
  @include swap_direction(padding, 20px 0);
  @include breakpoint($medium-up) {
    width: 46%;
    height: 340px;
  }
  &__image {
    height: 40px;
    @include swap_direction(margin, 20px);
  }
  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    p {
      @include swap_direction(margin, 0);
    }
  }
  &__content {
    font-size: 22px;
    line-height: 40px;
  }
}
