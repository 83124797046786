///
// Forms
///
input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'],
input[type='submit'],
textarea {
  @include appearance(none);
  border-radius: 0;
  outline: 0;
  outline: none;
  border: 0;
  border: 1px solid #000;
  color: #000;
  font-family: $body-font;
  font-size: 14px;
  letter-spacing: normal;
  &:focus {
    border-color: $color-pink;
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='number'],
input[type='time'],
input[type='url'] {
  height: 32px;
  line-height: 20px;
  padding: 5px 10px;
  @include input-placeholder {
    color: $color-gray-dark;
  }
}

input[type='submit'],
input[type='button'],
button {
  background: $color-black;
  color: $color-white;
  font-family: $body-font-bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0;
  @include swap_direction(padding, 0 11px);
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: auto;
  overflow: visible;
  &:hover,
  &:focus,
  &:active {
    @if $cr22 == false {
      color: $color-white;
      background: $color-pink;
    } @else {
      color: $color-white;
      background-color: $color-black;
    }
  }
}

input[type='checkbox'] {
  @include input-styled-check('checkbox');
}

input[type='radio'] {
  @include input-styled-check('radio');
}

// iPad specific styles
.touch,
.touchevents {
  select {
    @include appearance(none);
    -webkit-border-radius: 0;
    min-width: 150px;
    border: 1px solid #000;
    font-family: $body-font;
    font-size: 14px;
    letter-spacing: normal;
    /* background: url('/media/images/global/icon-dropdown.png') no-repeat center right 5px; */
    background: url('/media/images/global/icon-dropdown.png') no-repeat center right;
    @include swap_direction(padding, 0 25px 0 10px);
    margin-bottom: 10px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    white-space: nowrap;
  }
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
}

label {
  cursor: pointer;
}

// Remove default fieldset styles.
fieldset {
  border: 0;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
}

// Error Messages in RED
.adpl input[type='text'].error {
  border: 1px solid red;
}

input,
select,
a.selectBox,
a.selectbox {
  &.error {
    border: 1px solid $color-red;
  }
}

.error_messages.error li {
  color: $color-red;
  margin-#{$ldirection}: 20px;
}

.adpl input.error {
  border: 1px solid red;
}

///
// User Login form - custom styling for the user login form, which doesn't follow the normal DOM structure of other forms
///
#user-login {
  text-align: center;
  .device-pc & {
    @include swap_direction(padding, 40px);
    max-width: 800px;
    @include swap_direction(margin, 0 auto);
  }
  .device-mobile & {
    @include swap_direction(padding, 20px 12px);
  }
  .form-item,
  .form-actions {
    padding-top: 20px;
  }
  .form-item {
    label {
      font-weight: bold;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  .form-text {
    @include breakpoint($medium-up) {
      width: 300px;
    }
  }
  .description {
    font-size: 12px;
  }
}

button {
  &:hover {
    background: none;
  }
}

.form--errors--signin-overlay {
  margin-#{$ldirection}: -20px;
}

.login__overlay-forgot-password {
  cursor: pointer;
}
