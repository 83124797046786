.about-bobbi {
  padding: 15px 0 30px;
  &--desktop {
    display: none;
  }
  @include breakpoint($tablet) {
    &--mobile {
      display: none;
    }
    &--desktop {
      display: block;
    }
  }
  &__tabs {
    text-align: center;
    &.responsive-tabs {
      .resp-tab-item {
        padding-#{$ldirection}: 20px;
        padding-#{$rdirection}: 20px;
        margin-bottom: 0;
        &.resp-tab-active {
          background-color: $color-white;
          border-bottom: solid 1px $color-white;
        }
      }
    }
  }
  &__nav {
    width: 100%;
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  &__nav-item {
    display: block;
    width: 190px;
    text-align: center;
    text-transform: uppercase;
    .link {
      border-width: 0 0 2px 0;
      border-bottom-color: $color-white;
      &:hover {
        border-bottom-color: $color-pink;
      }
    }
  }
  .resp-tabs-container {
    text-align: $ldirection;
    border-top: solid 1px $color-black;
    margin-top: -6px;
  }
  .responsive-tabs .resp-tab-content {
    @include swap_direction(padding, 0 10px 10px 10px);
    @include breakpoint($tablet) {
      @include swap_direction(padding, 0 20px 20px 20px);
    }
  }
  &__footer {
    @include pie-clearfix;
    &__item {
      background-color: $color-black;
      color: $color-white;
      text-align: center;
      position: relative;
      overflow-x: hidden;
      padding: 34px 20px 70px;
      @include breakpoint($tablet) {
        float: $ldirection;
        width: 100%;
      }
      &--yahoo-beauty {
        background-color: #410090;
      }
      .button {
        color: $color-white;
        border-color: $color-white;
        background-color: transparent;
      }
    }
    &__title {
      font-family: $body-font-bold;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 0.24em;
      line-height: 28px;
    }
    &__img {
      display: block;
      margin: auto;
    }
    &__cta-wrapper {
      position: absolute;
      width: 100%;
      bottom: 30px;
      padding-#{$rdirection}: 40px;
    }
    &__cta {
      font-size: 14px;
      letter-spacing: 0.2em;
    }
  }
  // Big Moment
  &__moments {
    .slick-prev,
    .slick-next {
      height: 50px;
      width: 27px;
      height: 34px;
      top: 25%;
      margin-top: 7px;
      &:before {
        font-size: 24px;
      }
    }
    .slick-next {
      #{$rdirection}: 0;
      &:before {
        top: 5px;
        #{$rdirection}: 0;
        height: auto;
      }
    }
    .slick-prev {
      // margin-top: 27px;
    }
    &--desktop {
      display: none;
    }
    @include breakpoint($tablet) {
      &--desktop {
        display: block;
      }
      &--mobile {
        display: none;
      }
    }
    &-header {
      display: none;
      text-align: center;
      margin-bottom: 5px;
      margin-top: 20px;
      @include breakpoint($tablet) {
        display: block;
        margin-top: 48px;
      }
    }
    &-grid {
      font-size: 0;
      @include swap_direction(padding, 0 20px);
    }
    &-item {
      text-align: center;
      vertical-align: top;
      @include swap_direction(padding, 0 4px);
      @include breakpoint($tablet) {
        display: inline-block;
        max-width: 240px;
        width: 24.9%;
        padding-bottom: 40px;
      }
      // Default styles for WYSIWYG
      p {
        margin: 0;
      }
      a {
        text-decoration: none;
      }
      // End removal of default styles for WYSIWYG
      &__image {
        display: block;
        width: 100%;
        min-height: 240px;
      }
      &__year {
        font-family: $body-font-bold;
        font-size: 26px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-black;
        @include breakpoint($tablet) {
          padding-top: 20px;
        }
      }
      &__summary {
        @include swap_direction(padding, 13px 5px 0 5px);
        font-size: 16px;
        line-height: 1.5em;
      }
    }
  }

  // Causes
  [aria-labelledby='tab_item-2'].resp-tab-content {
    padding: 0;
  }
  &__causes {
    @include breakpoint($tablet) {
      @include swap_direction(padding, 50px 0 62px);
    }
    &__header {
      @include pie-clearfix;
      @include breakpoint($tablet) {
        @include swap_direction(padding, 0 20px);
      }
      &--desktop {
        display: none;
      }
      @include breakpoint($tablet) {
        @include swap_direction(padding, 0 30px 40px);
        &--mobile {
          display: none;
        }
        &--desktop {
          display: block;
        }
      }
    }
    &__header-img {
      display: block;
      width: 100%;
      @include breakpoint($tablet) {
        float: $ldirection;
        margin-#{$rdirection}: 15px;
        margin-bottom: 15px;
        width: 36.7%;
        margin-#{$rdirection}: 48px;
        margin-bottom: 0;
      }
    }
    &__title {
      font-family: $body-font-bold;
      font-size: 23px;
      letter-spacing: -0.025em;
      color: #e40e62;
      line-height: 1.2em;
      margin-top: 5px;
      margin-bottom: 15px;
      @include swap_direction(padding, 0 20px);
      @include breakpoint($tablet) {
        @include swap_direction(margin, 0 0 45px);
        @include swap_direction(padding, 0);
        font-size: 51px;
        line-height: 1;
      }
    }
    &__intro {
      @include swap_direction(padding, 0 20px);
      @include breakpoint($tablet) {
        @include swap_direction(padding, 0);
      }
    }
    &__intro,
    &__intro p {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.07em;
      @include breakpoint($tablet) {
        font-size: 24px;
        line-height: 38px;
      }
    }
    &__how-it-works {
      background-color: $color-gray-background;
      @include swap_direction(padding, 15px 20px);
      margin-top: 20px;
      @include breakpoint($tablet) {
        margin-top: 0;
        @include swap_direction(padding, 30px 50px 58px);
      }
    }
    &__how-it-works,
    &__how-it-works p {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0.07em;
      @include breakpoint($tablet) {
        font-size: 18px;
        line-height: 34px;
      }
    }
    &__partners-wrapper {
      @include swap_direction(padding, 15px 20px);
      @include breakpoint($tablet) {
        @include swap_direction(padding, 37px 50px 0);
      }
    }
    &__partners-title-wrapper {
      text-align: center;
      margin-bottom: 15px;
      margin-top: 10px;
      @include breakpoint($tablet) {
        margin-bottom: 26px;
        margin-top: 0;
      }
    }
    &__partners-title {
      @include h13;
      @include swap_direction(margin, 0);
    }
    &__partners-copy,
    &__partners-copy p {
      letter-spacing: 0.07em;
      font-size: 16px;
      line-height: 1.5em;
      @include breakpoint($tablet) {
        font-size: 22px;
        line-height: 35px;
      }
    }
    &__partners {
      @include pie-clearfix;
      margin-top: 48px;
    }
    &__partner {
      text-align: center;
      margin-bottom: 30px;
      @include breakpoint($tablet) {
        text-align: $ldirection;
        float: $ldirection;
        margin-bottom: 0;
        width: 50%;
        @include swap_direction(padding, 0 57px);
      }
      &:first-child {
        padding-#{$ldirection}: 0;
      }
      &:last-child {
        padding-#{$rdirection}: 0;
      }
      &__img {
        width: 100%;
        display: none;
        @include breakpoint($tablet) {
          display: block;
        }
      }
      &__title {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.07em;
        line-height: 1;
        margin: 19px 0 5px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: $ldirection;
        margin-bottom: 10px;
        @include breakpoint($tablet) {
          font-size: 24px;
          // text-align: $ldirection;
        }
      }
      &__copy {
        text-align: $ldirection;
        @include breakpoint($tablet) {
          display: none;
        }
      }
      &__copy,
      &__copy p {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0.07em;
        @include breakpoint($tablet) {
          font-size: 18px;
          line-height: 30px;
        }
      }
      &__toggle {
        margin-top: 23px;
        padding-#{$ldirection}: 20px;
        padding-#{$rdirection}: 20px;
        font-size: 14px;
        display: none;
        @include breakpoint($tablet) {
          display: inline-block;
        }
        &__text {
          &--more {
            .about-bobbi__causes__partner__toggle.active & {
              display: none;
            }
          }
          &--less {
            display: none;
            .about-bobbi__causes__partner__toggle.active & {
              display: inline;
            }
          }
        }
        .icon {
          position: relative;
          top: -1px;
          padding-#{$ldirection}: 6px;
        }
        &.active .icon {
          top: -2px;
        }
      }
    }
    p:last-child {
      margin: 0;
    }
  }

  // Landing - BB Story

  &__story {
    @include breakpoint($tablet) {
      padding-top: 45px;
    }
    &-bobbi__image {
      display: block;
      width: 100%;
      @include breakpoint($tablet) {
        width: 36%;
        display: block;
        float: $ldirection;
        margin-#{$rdirection}: 25px;
        margin-bottom: 10px;
        margin-#{$rdirection}: 45px;
        margin-bottom: 50px;
      }
    }
    &-bobbi__header {
      display: none;
      text-transform: uppercase;
      font-family: $body-font-bold;
      font-size: 16px;
      letter-spacing: 4px;
      margin-bottom: 10px;
      @include breakpoint($tablet) {
        display: block;
        font-size: 28px;
        letter-spacing: 6px;
        margin-bottom: 50px;
      }
    }
    &-bobbi__prologue {
      display: none;
      @include breakpoint($tablet) {
        display: block;
        line-height: 1.5em;
        font-size: 14px;
        font-size: 22px;
      }
    }
    &-bobbi__quote {
      text-align: center;
      font-size: 18px;
      line-height: 1.4em;
      font-style: italic;
      margin-top: 15px;
      @include breakpoint($tablet) {
        font-size: 30px;
        margin-top: 97px;
      }
      &-image {
        display: block;
        @include swap_direction(margin, auto);
        margin-top: 4px;
        margin-bottom: 10px;
        @include breakpoint($tablet) {
          margin-bottom: 116px;
        }
      }
    }
    &-chapter:last-child .about-bobbi__story-chapters__header {
      border-bottom: 1px solid $color-black;
    }
    &-chapter:last-child.active .about-bobbi__story-chapters__header {
      border-bottom: none;
    }
    &-chapters {
      p {
        font-size: 14px;
        @include breakpoint($tablet) {
          font-size: 16px;
        }
      }
      &__header {
        position: relative;
        text-transform: uppercase;
        border-top: 1px solid $color-black;
        @include swap_direction(padding, 15px 0);
        @include breakpoint($tablet) {
          line-height: 80px;
          @include swap_direction(padding, 0);
        }
        &:hover {
          cursor: pointer;
        }
      }
      &__number {
        padding-#{$ldirection}: 11px;
        font-size: 24px;
        letter-spacing: 4px;
        padding-#{$ldirection}: 10px;
        line-height: 1.5em;
        font-size: 15px;
        @include breakpoint($tablet) {
          padding-#{$ldirection}: 20px;
          width: 275px;
          font-size: 30px;
          letter-spacing: 7px;
          line-height: 80px;
        }
      }
      &__name {
        font-size: 10px;
        line-height: 23px;
        padding-#{$ldirection}: 11px;
        @include breakpoint($tablet) {
          line-height: 70px;
          margin-top: 8px;
          font-size: 20px;
        }
      }
      &__number,
      &__name {
        vertical-align: top;
        display: inline-block;
      }
      .icon-arrow_right {
        position: absolute;
        font-size: 20px;
        top: 11px;
        #{$rdirection}: 10px;
        @include transition(all 0.3s);
        @include rotate(90deg);
        @include breakpoint($tablet) {
          font-size: 36px;
          #{$rdirection}: 23px;
          top: 26px;
        }
      }
      &__content {
        display: none;
        padding-#{$ldirection}: 10px;
        padding-top: 10px;
        @include breakpoint($tablet) {
          padding-top: 0;
          padding-#{$ldirection}: 20px;
        }
      }
    }
    &-chapter.active .icon-arrow_right {
      @include rotate(-90deg);
    }
    &-chapter-one {
      &__photos {
        display: none;
        @include breakpoint($tablet) {
          display: block;
          float: $rdirection;
          width: 33%;
          margin-#{$ldirection}: 29px;
          margin-#{$rdirection}: 30px;
          margin-bottom: 15px;
        }
      }
      &__image {
        display: none;
        @include breakpoint($tablet) {
          display: block;
          width: 78%;
          margin-bottom: 20px;
          max-width: 572px;
        }
      }
      p {
        @include breakpoint($tablet) {
          width: 586px;
        }
        &:last-child {
          margin-bottom: 13px;
        }
      }
    }
    &-chapter-two {
      &__photos {
        display: none;
        @include breakpoint($tablet) {
          display: block;
          float: $ldirection;
          width: 40%;
          max-width: 390px;
          margin-#{$rdirection}: 10px;
          margin-bottom: 10px;
          margin-#{$rdirection}: 0;
          margin-bottom: 0;
        }
      }
      p {
        line-height: 1.6em;
      }
      &__text {
        @include breakpoint($tablet) {
          max-width: 420px;
          margin-#{$ldirection}: 44%;
          margin-bottom: 60px;
        }
      }
      &__text--last {
        @include breakpoint($tablet) {
          max-width: 380px;
          margin-#{$ldirection}: 0;
          margin-top: 25px;
          margin-bottom: 0;
        }
      }
      &__photo-last {
        display: none;
        @include breakpoint($tablet) {
          display: block;
          float: $rdirection;
          margin-#{$ldirection}: 10px;
          margin-bottom: 10px;
          width: 58%;
          margin-#{$rdirection}: 25px;
          margin-top: -42px;
          margin-#{$ldirection}: 20px;
        }
      }
    }
    &-chapter-three {
      .about-bobbi__story-chapters__content {
        @include breakpoint($tablet) {
          margin-top: 25px;
        }
      }
      &__photos {
        display: none;
        @include breakpoint($tablet) {
          display: block;
          width: 35.9%;
          float: $rdirection;
          margin-#{$ldirection}: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__mobile-page {
    display: none;
    &.active {
      display: block;
    }
  }
}
