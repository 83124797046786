.rtl .site-logo__text {
  text-align: right;
}

.rtl .site-nav .menu__item--lvl-2 {
  text-align: right;
}

@media (min-width: 768px) {
  .rtl .site-nav .menu__item--lvl-2 {
    float: right;
    padding-left: 20px;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .rtl .site-nav .menu__item--child-count-4 .menu__item--lvl-2.first {
    margin-right: 10%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .site-nav .menu__item--child-count-3 .menu__item--lvl-2.first {
    margin-right: 12.5%;
    margin-left: 0;
  }
}

.rtl .site-footer__main {
  text-align: right;
}

.rtl .site-footer__main .site-email-signup__submit {
  float: right;
}

.rtl .site-footer__main .site-email-signup__field {
  float: right;
  margin-left: 6px;
  margin-right: 0;
}

@media (min-width: 768px) {
  .rtl .site-footer__column {
    border-left: 1px solid #cccccc;
    border-right: none;
  }
}

@media (min-width: 1025px) {
  .rtl .site-footer__column:nth-child(2) .menu__item {
    float: right;
  }
}

.rtl .site-footer-social-links__item {
  margin: 0 0 0 10px;
}

@media (min-width: 768px) {
  .rtl .site-footer-social-links__item {
    margin-left: 7px;
  }
}

.rtl .selectBox-arrow {
  right: initial;
  left: 0;
  padding-left: 9px;
  padding-right: 0;
}

@media (min-width: 768px) {
  .rtl .site-footer__column:last-child {
    border-left: none;
  }
}

@media (min-width: 768px) {
  .rtl .site-utils__logo {
    float: right;
  }
}

@media (min-width: 768px) {
  .rtl .site-utils__links {
    float: left;
  }
}

@media (min-width: 768px) {
  .rtl .site-utils__nav {
    float: right;
  }
}

.rtl .site-utils__dropdown__close {
  left: 10px;
  right: initial;
}

.rtl .site-utils__dropdown {
  text-align: right;
}

/***************    MPP Page    **********************/
@media (min-width: 768px) {
  .rtl .product-grid__item {
    float: right;
  }
}

@media (min-width: 768px) {
  .rtl .product--full .product-gallery {
    float: right;
  }
}

@media (min-width: 768px) {
  .rtl .product--full .product__actions {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .rtl .quickshop__close {
    left: 14px;
    right: initial;
  }
}

@media (min-width: 768px) {
  .rtl .quickshop-wrapper.active:before {
    left: initial;
    margin-left: 0;
    right: 0;
    margin-right: 60px;
  }
  .rtl .quickshop-wrapper.active--2:before {
    right: 33.33%;
  }
  .rtl .quickshop-wrapper.active--3:before {
    right: 66.67%;
  }
}

.rtl .quickshop__close__text {
  text-align: right;
}

/*************************   Pravicy and Terms    ***************************/
.rtl .customer-service-header__title {
  text-align: right;
}

/************************    About Bobbi     *******************************/
@media (min-width: 768px) {
  .rtl .resp-tabs-list li {
    float: right;
  }
}

.rtl .about-bobbi .resp-tabs-container {
  text-align: right;
}

.rtl .about-bobbi__story-bobbi__image {
  float: right;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 25px;
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-bobbi__image {
    margin-right: 0;
    margin-bottom: 50px;
    margin-left: 45px;
  }
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapters__number {
    padding-right: 20px;
    padding-left: 0;
  }
}

.rtl .about-bobbi__story-chapters__name {
  padding-right: 11px;
  padding-left: 0;
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapters .icon-arrow_right {
    left: 23px;
    right: initial;
  }
}

@media (min-width: 768px) {
  .rtl .about-bobbi__footer__item {
    float: right;
  }
}

.rtl .about-bobbi__footer__cta-wrapper {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .about-bobbi__causes__header-img {
  float: right;
  margin-left: 15px;
  margin-right: 0;
}

@media (min-width: 768px) {
  .rtl .about-bobbi__causes__header-img {
    margin-left: 48px;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapters__content {
    padding-left: 0;
    padding-right: 20px;
  }
}

.rtl .about-bobbi__story-chapter-one__photos {
  float: left;
}

.rtl .about-bobbi__story-chapter-two__photos {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapter-two__photos {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapter-two__text {
    margin-left: 0;
    margin-right: 44%;
  }
}

.rtl .about-bobbi__story-chapter-two__photo-last {
  float: left;
  margin-left: 0;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapter-two__photo-last {
    margin-right: 20px;
    margin-left: 25px;
  }
}

@media (min-width: 768px) {
  .rtl .about-bobbi__story-chapter-two__text--last {
    margin-right: 0;
  }
}

.rtl .about-bobbi__story-chapter-three__photos {
  float: left;
  margin-left: 0;
  margin-right: 20px;
}

/**************************      Contact us       ***************************/
.rtl .contact-us-page ul li.contact-us-form__category {
  float: right;
  margin: 0 0 0 3%;
}

.rtl input[type='radio'] ~ label::before,
input[type='radio'] ~ .label::before {
  margin-right: 0;
  margin-left: 5px;
}

.rtl .contact-us-page .form-type-textarea {
  margin: 0.5em 0 0.5em 3%;
}

.rtl .contact-us-page .form-type-textfield {
  float: right;
  margin: 10px 0 10px 3%;
}

.rtl .contact-us-page .form-item-form-COUNTRY {
  float: none;
}

.rtl input[type='radio'] {
  right: -9999px;
}

/*******************      Email Sign Up      **********************/
.rtl .site-email-sms-signup .form-type-textfield {
  float: right;
  margin: 10px 0 10px 3%;
}

.rtl .site-email-sms-signup .form-type-checkboxes {
  float: right;
}

.rtl .site-email-sms-signup #edit-submit {
  float: right;
  letter-spacing: 0;
}

.rtl input[type='checkbox'] ~ label::before,
input[type='checkbox'] ~ .label::before {
  margin-left: 5px;
  margin-right: 0;
}

.rtl .email-unsubscribe__content .form-item-form-Email-Address {
  margin-right: 0;
  float: right;
  margin-left: 15px;
}

.rtl input[type='checkbox'] {
  right: -9999px;
}

/***********************    Store Locator     ********************************/
.rtl .store-locator--desktop .store-locator__hero a.selectBox {
  text-align: right;
  float: right;
  margin: 0 5px 0 10px;
}

.rtl .store-locator--desktop .store-locator__dutyfree {
  float: left;
}

.rtl .store-locator td.left {
  border-left: 1px solid #cccccc;
  border-right: none;
}

.rtl .store-locator--desktop .store-locator__main .column {
  float: right;
}

.rtl td,
th {
  text-align: right;
}

.rtl .store-locator .store-locator__tooltip .store-locator__tooltip-phone {
  text-align: right;
  direction: ltr;
}

.rtl .store-locator--desktop .store-locator__directions-location .store-locator__directions-destination {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}

.rtl .store-locator--desktop .store-locator__directions-location .directions-form__submit {
  margin: 0 55px 22px 0;
}

/*********************** Language switcher **************************/
.rtl .site-utils__promo {
  float: right;
}
/* remove letter spacing */
body.rtl,
.rtl .site-nav .menu__link--lvl-1,
.rtl .site-nav .menu__link--lvl-2,
.rtl .page-header__title,
.rtl .button--outline-thin,
.rtl .site-footer__main .site-email-signup__header,
.rtl .site-email-signup__error,
.rtl .site-footer-social-links__header,
.rtl .site-footer-toggle-device,
.rtl .customer-service-header__title,
.rtl .site-email-sms-signup__header h1,
.rtl .contact-us-header__title,
.rtl .contact-thanks-header__title,
.rtl .site-utils__link,
.rtl .site-utils__promo,
.rtl .site-utils__link-text,
.rtl .contact-us-page .contact-us-section h2,
.rtl .contact-thanks-page .contact-thanks-section h2,
.rtl .desktop-text,
.rtl .product-thumb__headline,
.rtl .button--secondary,
.rtl .about-bobbi__footer__title,
.rtl .responsive-tabs .resp-tab-item,
.rtl .about-bobbi__story-bobbi__header,
.rtl .section-header,
.rtl .about-bobbi__causes__intro,
.rtl .about-bobbi__causes__intro p,
.rtl .about-bobbi__causes__how-it-works,
.rtl .about-bobbi__causes__how-it-works p,
.rtl .about-bobbi__story-chapters__number,
.rtl .store-locator--desktop .store-locator__hero h1,
.rtl .search-submit__text,
.rtl .store-locator--desktop .store-locator__locate span.text,
.rtl .store-locator--desktop .store-locator__dutyfree span.text,
.rtl .store-locator .makeup-lessons-item__title,
.rtl .email-unsubscribe-thanks-header__title,
.rtl .email-singup-thanks-header__title,
.rtl .homepage-hero__headline,
.rtl .homepage-hero__description,
.rtl .homepage-hero__link.link {
  letter-spacing: 0;
}

.rtl .slick-slide {
  float: right;
}

@media (min-width: 768px) {
  .site-utils__nav .menu .only-static-text {
    display: none;
  }
}

@media (min-width: 768px) {
  .store-locator fieldset#store-search-controls {
    min-width: 600px;
  }
  .device-pc {
    .site-nav .makeuplesson .menu--lvl-2 .menu__list li {
      width: 33.3%;
    }
    .site-navc .makeuplesson .menu--lvl-2 .menu__list li a {
      font-family: 'Brandon Text';
      font-size: 16px;
      text-transform: capitalize;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .rtl .selectBox-arrow {
    right: auto;
  }
}

@media (min-width: 768px) {
  .device-pc {
    &.i18n-ar-c-ME {
      .makeup-lessons-item.makeup-lessons-item--v1.has-video {
        float: right;
      }
      .makeup-lessons-items__lessons {
        width: 74%;
      }
    }
    .site-nav .makeuplesson .menu--lvl-2 .menu__list li {
      width: 33.3%;
      a {
        font-family: 'Brandon Text';
        font-size: 16px;
        text-transform: capitalize;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }
  }
}

.homepage-hero__abstract {
  padding: 0 50px;
}

.rtl {
  .quickshop .product--full .product__title {
    letter-spacing: 0;
  }
  input {
    &[type='radio'] ~ label::before,
    &[type='checkbox'] ~ label::before {
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .device-pc .site-nav .makeuplesson .menu--lvl-2 .menu__list li a {
    font-family: 'Brandon Text Bold';
    font-size: 14px;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 0.2em;
    font-weight: normal;
  }
}

.rtl .site-nav .menu__link--lvl-2 {
  font-family: 'Brandon Text' !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  line-height: 20px !important;
  letter-spacing: 1px !important;
}

.responsive-tabs .resp-tab-item {
  border: 1px solid #000000;
  border-width: 0 1px 1px 0px;
}

.rtl .responsive-tabs .resp-tab-item:last-child {
  border-width: 0 1px 1px 1px;
}

.rtl .responsive-tabs .resp-tab-item:first-child {
  border-width: 0 1px 1px 0px;
}
