.customer-service-header {
  float: $rdirection;
  width: 70%;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0.5em 20px 1.5em 20px);
  border-bottom: 2px solid $color-black;
  @include breakpoint($small-down) {
    float: none;
    width: 100%;
    @include swap_direction(padding, 2em 20px);
    border-bottom: 1px solid $color-gray;
  }
  &__title {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    @include t3;
    letter-spacing: 5px;
    text-align: $ldirection;
    text-transform: uppercase;
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
}
