// This file is generated by `gulp icons`, do not modify

$icons: (
  arrow_down: '\E001',
  arrow_left: '\E002',
  arrow_right: '\E003',
  arrow_up: '\E004',
  atsign: '\E005',
  bus: '\E006',
  calendar: '\E007',
  car: '\E008',
  cart: '\E009',
  checkbox: '\E00A',
  checkbox_checked: '\E00B',
  circle_check: '\E00C',
  circle_check_thick: '\E00D',
  circle_close: '\E00E',
  circle_up: '\E00F',
  close: '\E010',
  douban: '\E011',
  email: '\E012',
  facebook: '\E013',
  finder: '\E014',
  flag_2: '\E015',
  flag_head: '\E016',
  flag_star: '\E017',
  glasses: '\E018',
  google: '\E019',
  hamburger: '\E01A',
  head: '\E01B',
  heart: '\E01C',
  heart_outline: '\E01D',
  instagram: '\E01E',
  kaixin: '\E01F',
  kakao_off: '\E020',
  kakao_on: '\E021',
  livechat: '\E022',
  livechat2: '\E023',
  logo: '\E024',
  marker_mobile: '\E025',
  minus: '\E026',
  phone: '\E027',
  pinterest: '\E028',
  plane: '\E029',
  play: '\E02A',
  plus: '\E02B',
  print: '\E02C',
  quickshop_arrow: '\E02D',
  radio: '\E02E',
  radio_checked: '\E02F',
  riding: '\E030',
  search: '\E031',
  share: '\E032',
  star: '\E033',
  star_outline: '\E034',
  tag: '\E035',
  tumblr: '\E036',
  twitter: '\E037',
  walking: '\E038',
  wechat: '\E039',
  weibo: '\E03A',
  weixin: '\E03B',
  xiaonei: '\E03C',
  youtube: '\E03D',
  zoom: '\E03E'
);
