.social-login {
  &__container {
    text-align: center;
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    &__container {
      margin-top: 0;
    }
    @include swap_direction(padding, 20px 0);
  }
  &__divider {
    @include h13;
    width: 100%;
    margin-bottom: 25px;
  }
  &__terms {
    @include swap_direction(padding, 0);
    text-align: $ldirection;
    margin-top: 15px;
  }
  &__email-opt-in {
    margin-top: 13px;
    text-align: $ldirection;
    @include breakpoint($medium-up) {
      float: none;
    }
  }
  &__title,
  &__info {
    display: none;
  }
  input[type='checkbox'] {
    #{$ldirection}: 88px;
    opacity: 0;
  }
}
/* GNAV styling */
/*
.site-utils__dropdown__content {
  .social-login {
    &__container {
      @include swap_direction (padding, 22px 28px 0 24px);
    }
  }
}
*/

/* Disconnect button styling */
#facebook_disconnect {
  display: inline;
  input.form-submit {
    height: 20px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 20px;
  }
}
/* Order confirmation page */
.confirmation-panel {
  .social-login {
    padding-#{$ldirection}: 20px;
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      padding-top: 40px;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  margin-top: 30px;
  &__content {
    padding-#{$ldirection}: 20px;
  }
  &__connect {
    margin-bottom: 30px;
  }
}
/* Social info section on Account sign-in page */
.account {
  .social-login__contents {
    padding: 0 20px;
  }
}
/* Social info section on checkout page */
.checkout {
  .sign-in-panel {
    .social-login__container {
      @media (max-width: 768px) {
        padding: 0 20px 20px 20px;
      }
    }
  }
}
/* Write a Review */
#pwr_signin.sign-in-page {
  .social-login {
    width: 100%;
    @include breakpoint($medium-up) {
      width: 50%;
      float: $rdirection;
      padding-#{$rdirection}: 5%;
    }
    &__email-opt-in {
      float: $ldirection;
      @include breakpoint($medium-up) {
        float: none;
      }
    }
  }
}
/* Tooltip */
.tooltip {
  text-transform: none;
  letter-spacing: 0.05em;
  font-size: 15px;
  text-align: $ldirection;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    background-color: $color-gray-fb;
    color: $color-white;
    text-align: justify;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 99;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    bottom: 125%;
  }
  a {
    color: $color-white;
    text-decoration: underline;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 10%;
    border-width: 5px;
    border-style: solid;
    border-color: $color-gray-fb transparent transparent transparent;
  }
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

.profile-page {
  .social-info {
    margin: 0 auto;
    &__header {
      font-size: 24px;
      border-bottom: 1px solid $color-gray-fb;
      padding-bottom: 24px;
      margin-bottom: 24px;
      letter-spacing: 0.01em;
      line-height: 1.33333;
      margin-top: 24px;
    }
    &__content {
      width: 100%;
      display: inline-block;
      p {
        padding-bottom: 10px;
      }
    }
    &__connect {
      border-bottom: 1px solid $color-gray-fb;
      flex-wrap: wrap;
      margin-bottom: 15px;
      padding-bottom: 10px;
      button {
        background-color: $color-black;
      }
    }
    .social_image {
      margin-#{$rdirection}: 10px;
      display: inline-block;
      img {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
    }
    .social-account {
      display: inline-block;
    }
    .social-connect {
      a {
        text-decoration: none;
      }
    }
    .social-connect,
    .social-disconnect {
      cursor: pointer;
      text-decoration: underline;
      display: inline-block;
      font-size: 16px;
      margin-#{$ldirection}: 6em;
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 35em;
      }
    }
  }
}

.disconnect-confirm-overlay {
  .disconnect-no,
  .disconnect-yes {
    float: #{$ldirection};
    padding: 12px;
    margin-top: 10px;
    background-color: $color-black;
    color: $color-white;
    margin-#{$ldirection}: 10px;
    cursor: pointer;
  }
  .disconnect-overlay {
    padding-top: 20px;
  }
  .disconnect-overlay-header {
    font-size: 16px;
  }
}
