div.mix_mask.section-samples-remedies {
  .product_collection_hero__body {
    display: none;
  }
  .product_collection_hero__content-item {
    @include swap_direction(padding, 0);
    border: 0;
  }
}

.remedies-product {
  &.is_selected {
    .samplespick__button--remove {
      display: inline-block;
    }
    .samplespick__button--add {
      display: none;
    }
  }
  &.is_soldout {
    @include opacity(0.4);
    .samplespick__button--soldout {
      display: inline-block;
    }
    .samplespick__button--add {
      display: none;
    }
  }
}

.remedies-samples-formatter {
  font-family: $brandon-text;
  line-height: normal;
  @include swap_direction(margin, auto);
  text-align: center;
  .header {
    font-family: $miller-banner-light;
    font-size: 48px;
    margin-top: 75px;
    text-transform: uppercase;
  }
  .body {
    font-size: 26px;
    margin-bottom: 25px;
    margin-top: 5px;
  }
  .live-chat {
    background-color: #f0f0f0;
    padding-#{$ldirection}: 20px;
    padding-#{$rdirection}: 20px;
    .icon-livechat {
      font-size: 54px;
      margin-top: 15px;
    }
    .live-chat-header {
      font-size: 19px;
      font-weight: bold;
      margin-top: 15px;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
    .live-chat-body {
      font-size: 20px;
      line-height: 31px;
    }
    .chat-now {
      border-bottom: 1px solid #ff526c;
      color: #ff526c;
      cursor: pointer;
      display: inline-block;
      @include swap_direction(margin, 25px 0 15px 0);
      padding-bottom: 10px;
      text-transform: uppercase;
    }
  }
  .samplespick__loader {
    text-align: center;
  }

  // div.button {
  //   padding-#{$ldirection}: 20px;
  //   padding-#{$rdirection}: 20px;
  //   &:hover {
  //     background-color: #000;
  //   }
  // }

  .samplespick__button__container {
    position: relative;
    text-align: $ldirection;
    margin-top: 26px;
    margin-bottom: 43px;
  }
  .samplespick__button__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    background: $color-white;
    padding-#{$ldirection}: 20px;
  }
  .samplespick__button--add {
  }
  .samplespick__button--remove {
    display: none;
  }
  .samplespick__button--soldout {
    @include button;
    display: none;
    border: 1px solid $color-black;
    color: $color-black;
    background: $color-white;
    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
      background: $color-white;
    }
  }
  .remedies-samples-container {
    @include swap_direction(margin, auto);
    width: 700px;
  }
  .remedies-product {
    margin-bottom: 25px;
    text-align: $ldirection;
    &.is_selected {
      .samplespick__button--remove {
        display: inline-block;
      }
      .samplespick__button--add {
        display: none;
      }
    }
    &.is_soldout {
      @include opacity(0.4);
      .samplespick__button--soldout {
        display: inline-block;
      }
      .samplespick__button--add {
        display: none;
      }
    }
    .image-container {
      background-position: 152px -21px;
      background-size: 235px 255px;
      display: inline-block;
      height: 213px;
      margin-#{$ldirection}: 80px;
      margin-#{$rdirection}: 40px;
      width: 53px;
    }
    .details-container {
      display: inline-block;
      vertical-align: top;
      width: 460px;
      .product-header {
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-top: 28px;
        text-transform: uppercase;
      }
      .product-subheader {
        font-size: 20px;
        margin-bottom: 8px;
      }
      .product-details-label {
        font-size: 16px;
        font-weight: bold;
      }
      a.button {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .remedies-samples-formatter {
    width: 100%;
    .header {
      margin-top: 25px;
    }
    .remedies-samples-container {
      width: auto;
      .remedies-product {
        text-align: center;
        .image-container {
          margin-#{$ldirection}: 0;
          margin-#{$rdirection}: 20px;
        }
        .details-container {
          text-align: $ldirection;
          width: 200px;
          .product-header {
            font-size: 16px;
            margin-top: 10px;
          }
          .product-subheader {
            font-size: 16px;
          }
          .product-details {
            font-size: 14px;
          }
        }
      }
    }
    .live-chat {
      &-body {
        font-size: 16px;
      }
      .chat-now {
        @include swap_direction(margin, 20px 0);
      }
    }
  }
}
