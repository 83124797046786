@media (min-width: 768px) {
  .site-nav .menu__link--lvl-1 {
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .homepage-hero__nav {
    top: 330px;
  }
}

@media (min-width: 768px) {
  .homepage-hero {
    margin-bottom: -195px;
  }
}

.js-sticky-header .js-site-header-sticky .site-utils__inner {
  height: 54px;
}

@media (min-width: 768px) {
  .site-utils__nav .menu__link--lvl-1 {
    padding: 0 7px;
  }
}

.site-footer {
  margin-top: 20px;
}

.site-footer__main {
  padding-bottom: 0;
}

.about-bobbi__footer__img {
  width: 100%;
  max-width: 500px;
}

.store-locator--desktop .search-submit {
  margin: 0;
}

.product-grid__item {
  height: 407px;
}

.site-utils__dropdown--email {
  .site-utils__dropdown__content {
    width: auto;
  }
}

@media (min-width: 768px) {
  .no-touch.csstransforms3d .product-thumb--animate-cta .product-thumb__abstract {
    transition: margin-bottom 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    -webkit-transform: none;
    transform: none;
  }
}

@media (min-width: 768px) {
  .no-touch .product-thumb--animate-cta .product-thumb__cta {
    opacity: 1;
    visibility: visible;
  }
}

.product-thumb__headline {
  margin: 0 0 10px;
}

@media (min-width: 768px) {
  .product-thumb .product-thumb__cta {
    border: 1px solid #ff4661;
    padding: 5px 10px;
  }
}

.product-gallery__main {
  padding-left: 10px;
}

.product__details {
  .product__header {
    padding: 20px 0;
    margin: 20px 0;
    border-bottom: 1px solid #000000;
  }
  .product__button {
    border: 1px solid #ff4661;
    color: #ff4661;
    background-color: #ffffff;
  }
}

@media (min-width: 768px) {
  .product--full .product__actions {
    text-align: left;
  }
}

.quickshop .product-gallery {
  border: none;
}

.site-footer-social-links__menu .site-footer-social-links__item .icon {
  line-height: 1;
}

@media (min-width: 768px) {
  .homepage-hero__abstract__inner {
    padding: 0;
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .site-footer-social-links {
    margin-bottom: 15px;
  }
}

.sticky-back-to-top {
  top: 50%;
}

@media (min-width: 768px) {
  .site-nav .menu__item--child-count-2 .menu__item--lvl-2.first {
    margin-left: 0;
    width: 20%;
  }
}

.about-bobbi__moments-grid {
  padding: 0;
}

@media (min-width: 768px) {
  .homepage-hero__headline {
    line-height: 47px;
  }
}

@media (min-width: 768px) {
  .homepage-hero__description {
    letter-spacing: 1px;
  }
}

.homepage-hero__abstract--center {
  width: 100% !important;
}

.homepage-hero__abstract {
  width: 460px;
}

.homepage-hero__abstract__inner--overlay {
  width: 520px !important;
  padding: 47px 20px 37px !important;
}

@charset "UTF-8";

.site-footer-social-links {
  margin-bottom: 15px;
}

.product__details {
  .product__button {
    border: 1px solid #ff4661;
    color: #ff4661;
    background-color: #ffffff;
  }
  .product__header {
    padding: 20px 0;
    margin: 20px 0;
    border-bottom: 1px solid #000000;
  }
}

.contact-us-page {
  .form-error {
    margin-top: 15px;
  }
  overflow: hidden;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
}

.contact-thanks-page {
  overflow: hidden;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
}

.contact-us-header,
.contact-thanks-header {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-bottom: 2px solid #000000;
}

.contact-us-section,
.contact-thanks-section {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.contact-us-header__title,
.contact-thanks-header__title {
  margin: 0;
  letter-spacing: 5px;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 0;
  font-family: 'Brandon Text Bold';
}

.contact-us-page {
  .form-type-textfield {
    float: left;
    margin: 10px 3% 10px 0;
    width: 47%;
  }
  .form-item-form-COUNTRY {
    float: none;
    overflow: hidden;
    padding: 10px 0;
  }
  #edit-submit {
    width: 47%;
  }
  .form-type-textfield input {
    width: 100%;
  }
  .form-type-textarea {
    width: 97%;
    margin: 0.5em 3% 0.5em 0;
    textarea {
      padding: 5px 10px;
    }
  }
  .contact-us-section h2 {
    margin: 0;
    padding: 1.5em 20px;
    font-family: 'Brandon Text Bold';
    font-size: 1em;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.contact-thanks-page .contact-thanks-section h2 {
  margin: 0;
  padding: 1.5em 20px;
  font-family: 'Brandon Text Bold';
  font-size: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.contact-us-page .drupal-embed {
  margin: 0 20px;
  padding: 0;
  border-top: 1px solid #eff0f0;
}

.contact-thanks-page .contact-thanks-section .contact-thanks-content {
  margin: 0 20px;
  border-top: 1px solid #eff0f0;
}

.contact-us-page {
  .form-type-radios {
    margin: 30px 0;
  }
  .newsletter {
    margin-bottom: 15px;
  }
  .form-type-radios .form-type-radio {
    margin: 1em 0;
  }
  p.required-field {
    padding: 0;
    margin: 30px 0 20px 0;
  }
  ul {
    &.contact-us-form__categories {
      overflow: hidden;
      width: 100%;
      margin: 1em 0;
    }
    li.contact-us-form__category {
      display: block;
      float: left;
      width: 22%;
      height: 70px;
      margin: 0 3% 0 0;
      padding: 1em 20px;
      font-family: 'Brandon Text Bold';
      letter-spacing: 3px;
      text-align: center;
      text-transform: uppercase;
      list-style-type: none;
      border: 1px solid #000000;
      cursor: pointer;
      &.active-category {
        color: #ffffff;
        background-color: #ff4661;
        border: none;
      }
    }
  }
}

.site-footer__main {
  padding-bottom: 0;
}

/*****     Emali Signup Page      *****/

.site-email-sms-signup {
  padding: 20px 90px 50px 90px;
  background: none;
  overflow: hidden;
  position: relative;
}

.site-email-sms-signup__content {
  padding: 0 110px;
}

.site-email-sms-signup__required-note {
  margin: 0;
}

.site-email-sms-signup {
  .form-error {
    margin: 10px 0;
  }
  .form-type-checkboxes {
    float: left;
    margin: 10px 0;
    dd {
      margin: 0;
    }
  }
  .form-type-textfield {
    float: left;
    margin: 10px 3% 10px 0;
    width: 47%;
    input {
      width: 100%;
    }
  }
  #edit-submit {
    float: left;
    width: 200px;
    margin-top: 10px;
  }
  .form-type-checkboxes span.form-required {
    display: none;
  }
}

.email-singup-thanks-page {
  overflow: hidden;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
}

.email-singup-thanks-header {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-bottom: 2px solid #000000;
}

.email-singup-thanks-section {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.email-singup-thanks-header__title {
  margin: 0;
  letter-spacing: 5px;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 0;
  font-family: 'Brandon Text Bold';
}

/*****     Emali Unsubscribe Page      *****/

.email-unsubscribe-page {
  overflow: hidden;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
}

.email-unsubscribe__header {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-bottom: 2px solid #000000;
  h1 {
    margin: 0;
    letter-spacing: 5px;
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 0;
    font-family: 'Brandon Text Bold';
  }
}

.email-unsubscribe__content {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  .email-unsubscribe-description {
    padding: 20px 0;
  }
  .email-unsubscribe__required-note {
    margin: 0 0 20px 0;
  }
  .form-item-form-Email-Address {
    span.form-required {
      display: none;
    }
    input.form-text {
      width: 220px;
    }
  }
}

.email-unsubscribe-thanks-page {
  overflow: hidden;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
}

.email-unsubscribe-thanks-header {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-bottom: 2px solid #000000;
}

.email-unsubscribe-thanks-header__title {
  margin: 0;
  letter-spacing: 5px;
  font-size: 1.5em;
  text-transform: uppercase;
  padding: 0;
  font-family: 'Brandon Text Bold';
}

.email-unsubscribe-thanks-section {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.email-unsubscribe-page .form-error {
  padding-bottom: 15px;
}

.email-unsubscribe__content .form-item-form-Email-Address {
  margin-right: 15px;
  float: left;
}

/*****     Store Locator      *****/

.store-locator--desktop {
  .store-locator__form-container {
    display: inline-block;
    width: auto;
  }
  .store-locator__links-container {
    overflow: hidden;
    margin: 20px auto 5px auto;
  }
}

.store-locator {
  .makeup-lessons {
    max-width: 776px;
    margin: 0 auto;
    overflow: hidden;
    border: 3px solid #eff0f0;
    border-top: none;
    padding: 15px 0;
  }
  .makeup-lessons-item {
    /* padding: 25px 25px 0 25px;
    width: 50%;
    float: left; */
  }
  td {
    vertical-align: top;
    border-bottom: none;
    padding: 0px 20px 25px 20px;
    &.left {
      border-right: 1px solid #cccccc;
    }
    &.last {
      padding-bottom: 0;
    }
  }
  .makeup-lessons-item__title {
    text-transform: uppercase;
    font-family: 'Brandon Text Bold';
    line-height: 25px;
    font-size: 18px;
    letter-spacing: 3px;
  }
  .makeup-lessons-item__content {
    padding: 5px 0;
  }
  .makeup-lessons-item__time {
    font-family: 'Brandon Text Medium';
    text-transform: uppercase;
    color: #ff4661;
  }
  .wooster {
    background: url('/media/export/cms/store_locator/BB_Redesign_StoreLocator_MUL_1.jpg');
    height: 230px;
    cursor: pointer;
    margin-top: 30px;
    &.active {
      background: url('/media/export/cms/store_locator/BB_Redesign_StoreLocator_MUL.jpg');
      height: 230px;
    }
  }
}

/**********    Customer Service Page      *************/

.customer-service {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  max-width: 1024px;
  margin: 3em auto;
  padding: 0;
  /* Hide the menu if on mobile, and not the landing page. */
}

.customer-service-header {
  width: 100%;
  float: none;
  margin: 0;
  padding: 0.5em 20px 1.5em 20px;
  border-bottom: 2px solid #000000;
}

.customer-service-section {
  width: 100%;
  float: none;
}

.customer-service-header__title {
  margin: 0;
  padding: 0;
  font-family: 'Brandon Text Bold';
  font-size: 1.5em;
  letter-spacing: 5px;
  text-align: left;
  text-transform: uppercase;
}

.customer-service-section div.active-subsection {
  display: block;
  margin: 0 20px;
  padding: 0;
  border-top: 1px solid #eff0f0;
  p {
    margin: 1em 0;
    padding: 0;
  }
}

/**********  Fix layout for iPad **********/

@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .contact-us-page ul li.contact-us-form__category {
    width: 25%;
  }
  .about-bobbi__story-bobbi__quote-image {
    margin-right: 125px;
  }
  .about-bobbi__moments-item__image {
    max-height: 240px;
    min-height: 173px !important;
  }
}

/**********  Store Locator **********/

.store-locator .wooster {
  background: url('/media/export/cms/store_locator/BB_Redesign_StoreLocator_MUL_1.jpg');
  height: 230px;
  cursor: pointer;
  margin-top: 30px;
}

.site-email-signup .site-email-signup__error,
.store-locator .directions-form__errors {
  color: red;
}

/******** IE 8 *****************/

html[data-useragent*='MSIE 8.0'] {
  li.site-utils__item--stores {
    margin-right: 1px;
  }
  .quickshop .product__details {
    padding: 0 20px 0 20px;
  }
}

/**********  language-switcher **********/

.language-switcher-elc-l10n-domain li {
  float: left;
  padding: 0 10px;
  a {
    text-decoration: none;
    color: white;
    font-size: 11px;
  }
}
/* mpp */

.desktop-text {
  display: inline;
}

@media (max-width: 767px) {
  .quickshop {
    .product__divider {
      display: none;
    }
    .product__details {
      border-top: solid 1px #d0d0d0;
    }
  }
}

html[data-useragent*='MSIE 8.0'] {
  li.site-utils__item--stores {
    margin-right: 1px;
  }
  .quickshop .product__details {
    padding: 0 20px 0 20px;
  }
}

@media (min-width: 768px) {
  .site-utils__nav .menu--lvl-2 {
    top: 54px;
  }
}

@media (min-width: 768px) {
  .site-nav .menu__item--child-count-2 .menu__item--lvl-2 {
    width: 20%;
  }
}

.site-email-sms-signup__header {
  margin-bottom: 30px;
}

.site-email-sms-signup__header h1 {
  margin-bottom: 18px;
}
