#search-wrapper {
  #col-1,
  .results-summary {
    display: none;
  }
  .loading {
    text-align: center;
    padding-bottom: 20px;
  }
  #summary,
  #search-summary {
    @include swap_direction(padding, 20px 0);
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
    @if $cr22 == true {
      padding: 0;
      margin-bottom: 15px;
      .corrected-results {
        @include typography-link-text();
      }
    }
    .search-results__no-results {
      .no-results-error {
        color: $color-core-black;
        font-size: 12px;
        line-height: 1.3;
      }
      .no-results-header {
        color: $color-core-black;
        margin: 20px 0 0;
      }
      .no-results-message {
        @include swap_direction(margin, 20px 36.5px 60px 35.5px);
        color: $color-gray-op65;
        font-size: 15px;
        line-height: 1.3;
        text-transform: none;
        @include breakpoint($portrait-up) {
          color: $color-gray-on-light;
        }
      }
    }
  }
}

// @TODO: I'm not sure this should be here; however, when it's not, and the search results
// grid initially loads, the eqaul-height stuff doesn't work very well becasue the images aren't
// yet loaded. This seems to prevent that issue.
// Since this issues seems to be specific to the search results product grid, I'm going to leave
// it here for now.
.esearch_results--pc {
  &.wrap {
    @include swap_direction(padding, 0);
  }
  .product-thumb {
    &__image {
      height: 272px;
    }
  }
  .quickshop-wrapper {
    clear: both;
  }
}

@if $cr22 == false {
  .esearch_results--mobile {
    @include swap_direction(padding, 12px 0);
  }
}

#product-results {
  .results-header {
    display: none;
  }
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-size;
      }
    }
  }
  .product__flag {
    display: none;
  }
  .product-grid__content {
    .p-w-r .pr-category-snippet__item {
      display: inline-block;
    }
  }
}

// This is the main wrapper for the "no results" content
#bestseller-results {
  border-top: 1px solid $color-gray;
  padding-top: 40px;
  .results-header,
  .results {
    display: none;
  }
  .tabbed-products-block {
    .grid--mpp__carousel {
      .grid--mpp__items {
        .mpp-grid {
          &.product-grid-default {
            min-width: 310px;
          }
        }
      }
    }
  }
  .product-brief-shades {
    &__shade-item {
      min-width: 20px;
      @include breakpoint($medium-up) {
        min-width: 30px;
      }
    }
    .slick-track {
      display: flex;
      flex: 1;
      min-width: 310px;
    }
  }
  @if $cr22 == false {
    .product-brief-shades {
      .slick-list {
        width: 100%;
      }
      &__shade-item {
        width: 23px !important;
        @include breakpoint($medium-up) {
          width: 25px !important;
        }
      }
      .slick-track {
        min-width: unset;
        @include breakpoint($medium-up) {
          min-width: 1060px;
        }
      }
    }
    .tabbed-products-block {
      .grid--mpp__carousel {
        .grid--mpp__items {
          .mpp-grid {
            min-width: 320px;
            @include breakpoint($xxsmall-up) {
              min-width: 370px;
            }
            @include breakpoint($medium-up) {
              min-width: 34%;
            }
          }
        }
      }
    }
  }
}
