.product-replenishment {
  .product--full &-select {
    position: relative; // set up positioning context for the whole template, to position the icon over the AR dropdown

    // styling for the selectbox itself, to make it match the shade picker selectbox above it on shaded SPPs
    .selectBox {
      &-dropdown {
        padding: 0;
      }
      &-label {
        padding: 0 6px;
      }
    }
    &__info-icon {
      // AR info-icon specific variables
      $ar-info-icon-size: 1.3em;
      display: block;
      position: absolute;
      top: 0.35em;
      #{$rdirection}: 2em;
      width: $ar-info-icon-size;
      height: $ar-info-icon-size;
      border: 1px solid $color-black;
      border-radius: 50%;
      @include swap_direction(
        padding,
        0.1em 0 0 0.075em
      ); // first value is for vertical centering; last is for a slight content nudge, to align '?' in the middle of our circle
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: $color-pink;
        border-color: $color-pink;
      }
    }
  }
  &-info {
    text-align: center;
    &__heading {
      @include h1;
      letter-spacing: 0.15em;
    }
    &__content {
      width: 75%;
      @include swap_direction(margin, 0 auto 1em);
      font-size: 1.5em;
      text-align: #{$ldirection};
    }

    $border-ar-steps: 1px solid $color-light-gray;
    &__steps {
      list-style: none;
      border-top: $border-ar-steps;
      @include clearfix;
    }
    &__step {
      display: block;
      float: #{$ldirection};
      width: 25%;
      min-height: 225px;
      @include swap_direction(padding, 1.5em 1.5em 0);
      border-#{$ldirection}: $border-ar-steps;
      &:first-child {
        border-#{$ldirection}: none;
      }
      &-heading {
        @include h16;
        @include swap_direction(margin, 1em 0 0.25em);
      }
      &-icon {
        height: 50px; // height setting to standardize icon image display for the steps
        @if $cr22 == true {
          display: none;
        } @else {
          display: inline-block;
        }
      }
    }
    &__footnote {
      position: absolute;
      bottom: 1em;
      width: 95%;
      margin: 0 0 12px 0;
      font-size: 0.9em;
      line-height: 120%;
      a {
        color: $color-black;
        font-weight: bold;
      }
    }
  }
}

@if $og-enabled {
  // View Cart and Product Rules
  .product-replenishment-select {
    margin: 20px 0;
    border-bottom: 1px solid $color-black;
    input[type='radio'] {
      position: relative;
      #{$ldirection}: 0;
      vertical-align: top;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      border: 1px solid $color-light-gray;
      background: $color-white;
      height: 19px;
      width: 19px;
      outline: 0;
      margin-bottom: 0;
      display: inline-block;
      -webkit-appearance: none;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 2px 2px 10px 1px);
      }
    }
    input[type='radio']:checked {
      box-shadow: inset 0 0 0 4px $color-white !important;
      @if $cr22 == false {
        background: $color-black !important;
        border: 1px solid $color-light-gray !important;
      } @else {
        background: $color-core-black !important;
        border: 1px solid $color-gray-on-light !important;
      }
    }
    input[type='radio'] ~ label:before {
      position: absolute;
      #{$ldirection}: -99999px;
    }
    label {
      @if $cr22 == false {
        line-height: 22px;
      }
    }
    .purchase-replenishment label {
      color: $color-pink;
      font-weight: 600;
    }
    a.product-replenishment-select__select {
      /* a.selectBox in forms.scss uses important, this is the only way to override */
      display: inline-block !important;
      margin: 10px 0;
      width: 30%;
      .quickshop-wrap & {
        width: 100%;
        margin: 0;
      }
    }
    .product-replenishment-select__select {
      width: 150px;
      @include breakpoint($medium-up) {
        margin-top: 0;
      }
    }
  }
  .og-deliver-text {
    position: relative;
    .og-deliver-text__inner {
      display: none;
      height: 50px;
      vertical-align: top;
      @include swap_direction(padding, 16px 12px 0 28px);
      font-size: 14px;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 20px 12px 0 28px);
        display: inline;
      }
    }
    .product-replenishment-select__info-icon {
      top: 16px;
      #{$rdirection}: 0;
      @include breakpoint($medium-up) {
        #{$rdirection}: 2em;
      }
    }
  }

  // View Cart Rules
  .cart-item__replenishment {
    .product-replenishment-select {
      margin-bottom: 10px;
    }
    .product-replenishment-select {
      border-bottom: 0;
    }
    a.js-replenish-select {
      display: inline-block !important;
      margin: 10px 0;
      width: 30%;
    }
    .og-deliver-text {
      display: inline;
    }
    .product-replenishment-select__input ~ .product-replenishment-select__input {
      color: $color-pink;
      font-weight: 600;
    }
  }

  // Auto Replenishment Pop-up Info
  $border-ar-steps: 1px solid $color-light-gray;
  .product-replenishment-info__step:first-child {
    @include breakpoint($medium-down) {
      border-top: 0;
    }
  }
  .product-replenishment-info__step {
    @include breakpoint($medium-down) {
      width: 100%;
      min-height: 125px;
      border-#{$ldirection}: 0;
    }
  }
  .product-replenishment-info__footnote {
    @include breakpoint($medium-down) {
      position: relative;
      bottom: auto;
      margin-top: 12px;
    }
  }
}
