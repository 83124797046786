.overlay-offer-signup {
  @include swap_direction(padding, 0 40px);
  &__title {
    width: 100%;
    @include swap_direction(margin, 0.5em 0);
    font-family: $brandon-text-bold;
    font-size: 24px;
    letter-spacing: 5px;
    line-height: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }
  &__content {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
  &__text {
    @include swap_direction(margin, 1em 0);
  }
  &__form {
    margin-bottom: 1.5em;
  }
  &__email-input {
    width: 200px;
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
  &__submit-button {
    margin-top: -3px;
    @include breakpoint($small-down) {
      width: 100%;
      margin-top: 1em;
    }
  }
  &__disclaimer {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }
}
