ul.resp-tabs-list {
  display: none;
}

h2.resp-accordion {
  display: block;
}

.resp-vtabs .resp-tab-content {
  border: 1px solid #c1c1c1;
}

.resp-vtabs .resp-tabs-container {
  border: none;
  float: none;
  width: 100%;
  min-height: initial;
  clear: none;
}

.resp-accordion-closed {
  display: none !important;
}

.resp-tab-content {
  display: none;
  padding: 15px;
}

.resp-tab-active {
  border-bottom: none;
  background-color: #fff;
}

h2.resp-accordion {
  font-size: 13px;
  border: 1px solid #c1c1c1;
  border-top: 0px solid #c1c1c1;
  @include swap_direction(margin, 0px);
  @include swap_direction(padding, 10px 15px);
}
/* -----------Accordion styles----------- */

.resp-easy-accordion h2.resp-accordion {
  display: block;
}

.resp-easy-accordion .resp-tab-content {
  border: 1px solid #c1c1c1;
}

.resp-jfit {
  width: 100%;
  @include swap_direction(margin, 0px);
}

.resp-tab-content-active {
  display: block;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: $rdirection;
  margin-top: 3px;
}

h2.resp-tab-active span.resp-arrow {
  border: none;
}
/* Here your can change the breakpoint to set the accordion, when screen resolution changed */
@include breakpoint($medium-up) {
  ul.resp-tabs-list {
    display: inline-block;
  }
  .resp-tabs-list li {
    font-weight: 600;
    font-size: 13px;
    display: inline-block;
    @include swap_direction(padding, 13px 15px);
    @include swap_direction(margin, 0);
    list-style: none;
    cursor: pointer;
    float: $ldirection;
  }
  .resp-tabs-container {
    @include swap_direction(padding, 0px);
    background-color: #fff;
    clear: $ldirection;
  }
  h2.resp-accordion {
    cursor: pointer;
    @include swap_direction(padding, 5px);
    display: none;
  }
  .resp-content-active,
  .resp-accordion-active {
    display: block;
  }
  .resp-tab-content {
    border: 1px solid #c1c1c1;
  }
}
