.loyalty__panel__transactions {
  &__title {
    @include h8;
    line-height: 1;
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: normal;
    font-size: 20px;
  }
  &__subtitle {
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
    @include h7;
    padding: 0 40px;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    margin-bottom: 5px;
    font-size: 18px;
  }
  &__liner {
    @include h7;
    line-height: 1.5;
  }
  &__header {
    padding: 20px 0;
  }
  &__table {
    @include h7;
    margin: 0;
    width: 100%;
    td {
      padding: 10px;
      @include breakpoint($medium-up) {
        padding: 10px 0;
      }
    }
  }
  &__transaction {
    @include breakpoint($medium-up) {
      padding: 0 20px;
      font-size: 15px;
    }
    &:first-child {
      @include breakpoint($medium-up) {
        border-top: none;
        padding-#{$ldirection}: 0;
      }
      border-top: 1px solid $color-light-gray;
    }
    &__collapsed {
      &__content {
        padding: 0 32%;
      }
    }
  }
  &__list {
    @include breakpoint($medium-up) {
      margin: 0;
    }
    margin: 0 10px;
    &__column {
      &-total,
      &-points {
        text-align: #{$rdirection};
      }
      &-date,
      &-total,
      &-points,
      &-activity {
        @include breakpoint($medium-up) {
          padding: 10px 0;
          width: 18%;
        }
      }
      &-points {
        @include breakpoint($medium-up) {
          display: table-cell;
        }
        display: none;
      }
      &-activity {
        @include breakpoint($medium-up) {
          width: 46%;
        }
        width: 60%;
        &__collapsed {
          border-bottom-color: $color-white;
        }
        &__expanded {
          border-bottom-color: $color-light-gray;
        }
      }
      &-plus,
      &-minus {
        @include swap_direction(padding, 10px 12px 13px 8px);
        color: $color-grey-footer;
        font-size: 15px;
        position: relative;
        display: table-cell;
      }
      &-date {
        @include breakpoint($medium-up) {
          width: 20%;
        }
        width: 30%;
      }
      &-total {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 0;
        }
        font-family: $body-font;
        padding-#{$rdirection}: 20px;
      }
    }
    &__header {
      @include h8;
      padding: 10px 0;
      font-size: 16px;
      letter-spacing: normal;
    }
    &__paginate {
      padding: 20px;
      text-align: center;
    }
  }
  &__paginate {
    @include breakpoint($medium-up) {
      padding-bottom: 50px;
    }
    padding: 20px 0 30px;
    border-bottom: 1px solid $color-border-lighter-gray;
    &__link {
      color: $color-black;
      font-family: $body-font-bold;
      font-size: 16px;
    }
  }
}
