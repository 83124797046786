.site-utilities {
  display: none;
  @include wrap;
  font-family: $brandon-text-bold;
  color: #fff;
  font-size: 13px;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.site-utilities__container {
  @include pie-clearfix;
  @include swap_direction(padding, 0 13px 0 15px);
  background: #000;
  min-height: 30px;
}

.site-utilities__promotion {
  width: 60%;
  float: $ldirection;
  letter-spacing: 1px;
  line-height: 15px;
  @include swap_direction(padding, 7px 0);
  @include breakpoint($large-up) {
    width: auto;
  }
}

.site-utilities__menu {
  float: $rdirection;
  @include pie-clearfix;
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
}

.site-utilities__item {
  @include swap_direction(margin, 0);
  list-style: none;
  display: block;
  float: $ldirection;
  @include swap_direction(padding, 0 10px);
  &.site-utilities__item--last {
    padding-#{$rdirection}: 0;
  }
  > a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 11px;
    &:hover {
      color: $color-light-gray;
    }
    .icon {
      font-size: 17px;
      top: 7px;
      letter-spacing: 0;
    }
    .icon-marker,
    .icon-avatar,
    .icon-bag {
      top: 4px;
    }
    .icon-cart {
      font-size: 18px;
      top: 6px;
    }
    em {
      display: none;
      line-height: 30px;
      font-style: normal;
      top: 1px;
      position: relative;
    }
  }

  // Start hiding icons and displaying text
  @include breakpoint($medium-up) {
    &:nth-child(1) {
      .icon {
        display: none;
      }
      em {
        display: block;
      }
    }
  }
  @media (min-width: 785px) {
    &:nth-child(2) {
      .icon {
        display: none;
      }
      em {
        display: block;
      }
    }
  }
  @media (min-width: 980px) {
    &:nth-child(3) {
      .icon {
        display: none;
      }
      em {
        display: block;
      }
    }
  }
  @media (min-width: 1024px) {
    &:nth-child(4) {
      .icon {
        display: none;
      }
      em {
        display: block;
      }
    }
  }
} //.site-utilities__item

.site-utilities__item--shopping-bag {
  position: relative;
  .site-utilities__bag--qty {
    font-size: 10px;
    display: block;
    position: absolute;
    top: 11px;
    #{$ldirection}: 9px;
    color: #000;
    line-height: normal;
    letter-spacing: 0;
    text-align: center;
    width: 20px;
  }
  a:hover {
    .site-utilities__bag--qty {
      color: #fff;
    }
  }
}
