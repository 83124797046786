.page-header {
  background-color: $color-light-brown;
  &--bg-transparent {
    background-color: transparent;
  }
  &--bg-black {
    background-color: $color-black;
  }
  &--bg-white {
    background-color: $color-white;
  }
  &--borders {
    border-top: solid 2px $color-black;
    border-bottom: solid 2px $color-black;
  }
  &__inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    @include swap_direction(margin, 0 auto);
    max-width: 1024px;
    position: relative;
    text-align: center;

    // This can be a link:
    display: block;
    color: $color-black;
    text-decoration: none;
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 40px;
      padding-#{$rdirection}: 40px;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__text-wrapper {
    z-index: 1;
    @include swap_direction(padding, 20px 0);
    width: 100%;
    position: relative;
    top: 0;
    #{$ldirection}: 0;
    .page-header--fit-to-img &--center-vertically {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  &__text {
    @include swap_direction(margin, 0 auto);
  }
  &__title {
    font-size: 18px;
    line-height: 23px;
    font-family: $brandon-text-bold;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    @include swap_direction(margin, 0);
    .page-header--has-subtitle & {
      @include swap_direction(margin, 0 0 15px);
    }
    @include breakpoint($medium-up) {
      font-size: 34px;
      line-height: 38.16px;
      .page-header--has-subtitle & {
        margin-bottom: 26px;
      }
    }
    &--small {
      @include h13;
      @include swap_direction(margin, 0);
      .page-header--has-subtitle & {
        @include swap_direction(margin, 0);
      }
    }
    &--medium {
      // Being a little redundant here just cause I think it makes it more clear.
      font-size: 25px;
      font-family: $brandon-text-bold;
      line-height: 34px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      @include swap_direction(margin, 0);
      .page-header--has-subtitle & {
        @include swap_direction(margin, 0 0 26px);
      }
    }
    &--large {
      // This is the default state.
    }
    &__inner.inner {
      background-color: $color-light-brown;
      .page-header--bg-transparent & {
        background-color: $color-white;
      }
      .page-header--bg-black & {
        background-color: $color-black;
      }
      .page-header--bg-white & {
        background-color: $color-white;
      }
    }
  }
  &__subtitle {
    margin-top: 15px;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    &--italic {
      font-style: italic;
    }
    &--medium {
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.06em;
      @include breakpoint($medium-up) {
        font-size: 22px;
        line-height: 29px;
      }
    }
    p {
      // WYSINWYG
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
    }
  }
  &--fit-to-img .page-header__text-wrapper {
    position: absolute;
  }
  &--no-max-width .page-header__inner {
    max-width: none;
  }
}
