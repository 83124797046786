.search-microphone--hidden {
  display: none;
}

#js-voice-output {
  display: none;
}

.site-header__smart-menu.nav-pc-hidden {
  .site-header__search {
    padding-#{$ldirection}: 0;
  }
  .popular-search-wrapper {
    color: $color-black;
  }
  .header-search.bobbi-voice-search {
    .site-util__icon {
      padding-#{$ldirection}: 5px;
    }
    .search-form input[type='text'].search-form__field.search-term {
      padding-#{$ldirection}: 30px;
      line-height: 36px;
    }
    .popular-search-wrapper {
      color: $color-black;
      position: absolute;
    }
  }
}

.search-form__fields {
  .search-form__mic {
    position: absolute;
    right: -5px;
    width: 2.5rem;
    height: 100%;
    overflow: hidden;
    top: 0;
    text-decoration: none;
    text-align: center;
    background: $color-black;
    &__img {
      width: 20px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .search-form__mic-overlay {
    width: 100vw;
    height: 100vh;
    background: $color-light-brown;
    font-size: 11px;
    font-family: Arial;
    position: fixed;
    top: 36px;
    left: 0;
    padding-top: 1rem;
    opacity: 0.98;
    &.search-form__mic-overlay--show {
      display: block;
    }
  }
}

.search-form__mic-overlay-results {
  padding: 0 2.5em;
  color: $color-black;
}

.can-say__title {
  font-family: $body-font-bold;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  border-bottom: solid 1px $color-black;
  padding-bottom: 1rem;
  margin: 0;
}

.can-say__examples {
  &.can-say__examples--hidden {
    visibility: hidden;
  }
  li {
    > a {
      text-decoration: none;
    }
    p {
      margin: 0;
      padding: 10px 1rem 9px;
      border-bottom: solid 1px $color-gray-dim;
      font-size: 15px;
      letter-spacing: 1px;
      color: $color-gray;
    }
  }
}

.search-form {
  &__mic-overlay-results-img {
    overflow: hidden;
    margin-bottom: 3rem;
    .icon-circle_check {
      color: $color-pink;
      font-size: 30px;
      display: none;
      &.fadeIn {
        animation: fadein 2s;
        display: block;
      }
    }
    img {
      color: $color-pink;
      font-size: 30px;
      &.inv {
        visibility: hidden;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.site-utils__dropdown__close {
  overflow: hidden;
  height: 21px;
  width: 20px;
  top: 15px;
}

.search-form__mic-overlay-close {
  overflow: hidden;
  color: $color-black;
  font-size: 20px;
  position: absolute;
  right: 0.925em;
}

.search-form__mic-overlay-close .icon {
  overflow: hidden;
}

.search-form input[type='text'].search-form__field {
  width: 80%;
}

.search-form input[type='text'].search-form__field.search-form__field--hidden {
  display: none;
}

.search-form__mic-overlay-top {
  p {
    margin: 0;
    color: $color-black;
    font-family: $body-font-bold;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: 0.2em;
  }
  &__search,
  &__listen {
    font-size: 18px;
    text-transform: uppercase;
    padding: 2em 0 1em;
  }
  &__search-sub {
    font-size: 14px;
    padding-bottom: 1.25em;
  }
}
