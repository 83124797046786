$bundle-desktop-image-size: 250px;
$bundle-fixed-cta-height: 54px; // line-height + padding
$bundle-bg-height: 770px;
$bundle-overlay-max-width: 700px;

@mixin bundle-section-header() {
  margin-bottom: 15px;
  p {
    margin: 0px;
  }
}

@mixin fixed-bundle-cta-button() {
  background-color: $color-bundle-pink;
  padding: 20px 0px;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  bottom: 0px;
  left: 0px;
  &--disabled {
    background-color: $color-light-gray;
  }
}

body.bundle-product-page {
  @include breakpoint($medium-up) {
    background: linear-gradient(180deg, rgba(200, 200, 200, 0.1) $bundle-bg-height, transparent $bundle-bg-height);
  }
}

.spp-bundle-layout {
  max-width: $max-width;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    margin-top: 20px;
  }
  &__breadcrumb {
    color: $color-bundle-gray;
    font-size: 15px;
  }
  &__get-set-cta {
    @include fixed-bundle-cta-button();
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    @include breakpoint($medium-up) {
      position: unset;
    }
    &.button--disabled {
      @include button--disabled;
    }
  }

  // bundle specific font sizing
  .responsive-tabs-formatter {
    &__header {
      .section-header {
        @include bundle-section-header();
      }
    }
  }
}

.spp-bundle-layout__product-step {
  // states
  .bundle-product-slide {
    display: flex;
    flex-direction: column;
    &__swatch-info {
      &__shade-blurb {
        display: none;
      }
    }
    .product-gallery {
      order: 0;
    }
    .product-header {
      order: 1;
    }
    .bundle-product-slide__product-price {
      order: 1;
    }
    .bundle-product-slide__product-size {
      order: 1;
    }
    .bundle-product-slide__product-description {
      order: 2;
    }
    .bundle-product-slide__swatch-list {
      order: 3;
    }
    .bundle-product-slide__swatch-info {
      order: 4;
    }
  }
  // once we select a sku we hide swatch select shade blurb
  // and on mobile we switch the swatch info and description.
  &.bundle-sku-selected,
  &.swatch-list-hover {
    .bundle-product-slide {
      @include breakpoint($medium-down) {
        .bundle-product-slide__swatch-info {
          order: 2;
        }
        .bundle-product-slide__product-description {
          order: 4;
        }
      }
      &__swatch-info {
        &__select-blurb {
          display: none;
        }
        &__shade-blurb {
          display: block;
        }
      }
    }
  }
}

.bundle-items-inside {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 15px;
  @include breakpoint($medium-up) {
    padding: 0px;
  }
  &__header {
    text-align: center;
    margin-bottom: 0px;
    .section-header {
      @include bundle-section-header();
    }
  }
  .product-grid {
    &__item {
      margin: 0px;
    }
  }
  .product-thumb {
    padding-bottom: 0px;
  }
}

#colorbox.colorbox--bundle {
  border: none;
  width: auto !important;
  height: auto !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto !important;
  @include colorbox-fixed-overlay($bundle-overlay-max-width, 0px);
  #cboxClose {
    color: $color-black;
    width: 18px;
    height: 18px;
    font-size: 18px;
    right: 20px;
    top: 20px;
  }
  #cboxLoadedContent {
    @include breakpoint($medium-up) {
      border: 1px solid $color-light-gray;
    }
  }
}
