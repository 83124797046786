.fb-live {
  max-width: $max-width;
  background-color: $color-black;
  color: $color-white;
  text-align: center;
  padding-top: 30px;
  @include swap_direction(margin, 0 auto);
  h1 {
    font-family: MillerBanner light;
    font-size: 52px;
    line-height: 1em;
  }
  .product_collection_hero__html_block {
    font-size: 22px;
    line-height: 1.5em;
    padding: 0 0 40px;
    max-width: 840px;
    @include swap_direction(margin, 0 auto);
  }
  .basic-responsive {
    max-width: 656px;
    @include swap_direction(margin, 0 auto);
  }
  .facebook-comments-v1 {
    width: 368px;
    background-color: #fff;
    @include swap_direction(margin, 0 auto 30px);
    @include swap_direction(padding, 0);
  }
  .facebook-comments__refresh-button {
    background: #4080ff;
    border: none;
    text-align: center;
    position: relative;
    color: #fff;
    font-size: $base-font-size;
    line-height: 22px;
    font-weight: bold;
    font-family: Helvetica, Arial, sans-serif;
    margin-top: 24px;
    max-width: 304px;
    padding: 12px;
    text-shadow: none;
    width: 100%;
    vertical-align: middle;
    @include swap_direction(margin, 18px 8px 10px 8px);
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    word-break: break-word;
    word-wrap: break-word;
    @include font-smoothing(antialiased);
  }
  .product-grid-products {
    background-color: #fff;
  }
}
