.foundation-finder {
  text-align: center;
  @include swap_direction(padding, 26px 0 34px);
  max-width: 1024px;
  @include swap_direction(margin, 0 auto);
  position: relative;
  &__image {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
  }
  &__image--mobile {
    display: block;
    position: relative;
    top: -17px;
    width: 100%;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &__image--tablet {
    top: 45px;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__image--desktop {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  @include breakpoint($medium-up) {
    border-bottom: solid 1px $color-gray;
    margin-bottom: 25px;
  }
  .section-header {
    @include swap_direction(margin, 0 auto 14px);
  }
  &__header {
    position: relative;
    @include swap_direction(padding, 0);
    @include breakpoint($medium-up) {
      height: 354px;
      @include swap_direction(padding, 0 20px);
      @include swap_direction(margin, 0 auto);
      max-width: 1024px;
      text-align: $ldirection;
    }
  }
  &__title {
    display: none;
    font-family: $brandon-text-medium;
    font-size: 40px;
    letter-spacing: 0.2em;
    line-height: 43px;
    text-transform: uppercase;
    width: 420px;
    @include swap_direction(margin, 0 0 24px);
    @include swap_direction(padding, 19px 0 0);
    position: relative;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__subtitle {
    font-family: $brandon-text-medium;
    font-size: 21px;
    line-height: 1;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    @include swap_direction(padding, 0 20px);
    position: relative;
    z-index: 1;
    @include breakpoint($medium-up) {
      font-family: $brandon-text;
      text-transform: none;
      letter-spacing: 0.1em;
      @include swap_direction(padding, 0);
    }
  }
  &__find-match,
  &__hide,
  .product-filter__label:before {
    @include breakpoint($medium-up) {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      color: $color-pink;
      letter-spacing: 0.14em;
      font-size: 18px;
    }
  }
  &__find-match,
  &__hide {
    cursor: pointer;
    &:after {
      @include swap_direction(margin, -5px 0 0 13px);
    }
  }
  &__find-match {
    @include icon('arrow_down', 'after');
    position: absolute;
    bottom: 60px;
  }
  &__hide {
    @include icon('arrow_up', 'after');
    @include swap_direction(padding, 40px 0);
  }
  &__quiz {
    @include icon('quickshop_arrow');
    display: none;
    position: relative;
    border: solid 1px $color-light-gray;
    @include swap_direction(margin, 0 20px);
    @include swap_direction(padding, 0 32px);
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 0 auto);
      @include swap_direction(padding, 0 20px);
    }
    &:before {
      position: absolute;
      z-index: 2;
      color: $color-gray;
      background: $color-white;
      letter-spacing: 0;
      top: -14px;
      #{$ldirection}: 50%;
      margin-#{$ldirection}: -13px;
    }
  }
  &__mpp-cta,
  &__shop-all-link {
    font-size: 14px;
    letter-spacing: 0.2em;
  }
  &__shop-all-link {
    color: $color-black;
    border-color: $color-black;
  }
  &__footer {
    @include pie-clearfix;
    margin-top: 42px;
  }
  &__tips {
    border-top: solid 1px $color-light-gray;
    border-bottom: solid 1px $color-light-gray;
    @include swap_direction(padding, 28px 20px);
    margin-bottom: 32px;
    @include breakpoint($landscape-up) {
      float: $ldirection;
      width: 68%;
      min-height: 197px;
      margin-bottom: 0;
    }
    &__header {
      // Intentionally removed, apparently Bobbi Brown does not like these.
      // @include icon("glasses");
      // &:before {
      //   margin-#{$rdirection}: 3px;
      //   font-size: 20px;
      // }
      font-family: $brandon-text-bold;
      font-size: 14px;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      @include swap_direction(margin, 0 0 20px);
    }
    &__copy {
      font-style: italic;
      font-size: 18px;
      line-height: 1.65;
      letter-spacing: 0.12em;
      @include swap_direction(margin, 0);
    }
  }
  &__chat {
    background: #eff0f0;
    @include swap_direction(padding, 18px 28px 20px);
    position: relative;
    @include breakpoint($landscape-up) {
      float: $rdirection;
      width: 31%;
      min-height: 197px;
      padding-bottom: 35px;
    }
    &__copy {
      @include icon('livechat');
      font-size: 18px;
      line-height: 1.47;
      letter-spacing: 0.06em;
      font-family: $brandon-text-medium;
      @include swap_direction(margin, 0 0 10px);
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0);
      }
      &:before {
        display: block;
        font-size: 34px;
        margin-bottom: 10px;
      }
    }
    &__link-wrapper {
      @include breakpoint($landscape-up) {
        position: absolute;
        #{$ldirection}: 0;
        bottom: 15px;
        width: 100%;
      }
    }
    &__link {
      color: $color-pink;
      border-color: $color-pink;
      &:hover {
        color: $color-black;
        border-color: $color-black;
      }
      a {
        text-decoration: none;
        &:hover {
          color: $color-black;
        }
      }
    }
  }
  .content .node-elc-nodeblock {
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 28px 0 0);
    }
  }
  .product-filter {
    border-bottom: solid 1px $color-light-gray;
    @include swap_direction(padding, 28px 0);
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    @include breakpoint($landscape-up) {
      float: $ldirection;
      width: 50%;
      @include swap_direction(padding, 0 0 8px);
      border-bottom: none;
    }
    &__label {
      font-size: 20px;
      letter-spacing: 0.15em;
      line-height: 1.53em;
      text-transform: uppercase;
      font-family: $brandon-text-medium;
      @include swap_direction(margin, 0 auto 10px);
      min-height: 98px;
      max-width: 335px;
      &:before {
        display: block;
      }
    }
    &:first-child {
      @include breakpoint($landscape-up) {
        border-#{$rdirection}: solid 1px $color-light-gray;
      }
      .product-filter__label:before {
        content: '1.';
      }
    }
    &:last-child .product-filter__label:before {
      content: '2.';
    }
  }
  &__results {
    .product-grid {
      // Show with js after filtering
      display: none;
    }
  }
  &__no-matches {
    display: none;
    color: red;
    @include swap_direction(padding, 8px 0 28px);
    font-family: $brandon-text-bold;
  }
  &--no-matches {
    .foundation-finder__no-matches {
      display: block;
    }
  }
  &__shop-all {
    display: none;
    margin-top: 17px;
  }
  &--filtered {
    .foundation-finder__shop-all {
      display: block;
    }
    .product-filter:last-child {
      border-bottom: solid 1px $color-light-gray;
      padding-bottom: 28px;
      @include breakpoint($landscape-up) {
        padding-bottom: 8px;
        border-bottom: none;
      }
    }
    .content .node-elc-nodeblock {
      margin-bottom: 25px;
      @include breakpoint($landscape-up) {
        border-bottom: solid 1px $color-light-gray;
        padding-bottom: 28px;
      }
    }
  }
}
