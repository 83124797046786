.hotspot-helper {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background: transparent;
  border: 1px solid red;
  border-radius: 50%;
  z-index: 5;
}

.hotspot-helper:after {
  content: "";
  width: 1px;
  height: 1px;
  background: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.hotspot-helper span {
  display: none;
  width: 10em;
  color: black;
  font-size: 12px;
  background: rgb(255, 255, 255);
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 100%);
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hotspot-helper:hover span,
body.hotspot-helper-active .hotspot-helper span
{
  display: block;
  opacity: 1;
}

.hotspot-helper span.top {
  color: black;
  font-size: 12px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%);
}

.hotspot-helper span.bottom {
  color: black;
  font-size: 12px;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 100%);
}

body.hotspot-helper-enabled {
  .hotspot-offset-parent {
    //background-color: rgba(255, 255, 255, .2);
    //filter: url("#{$base-theme-path}/img/highlight-overlay.svg#color-overlay");
  }
}
