.mantle-form-options-field {
  $options-field: &;

  .mantle-custom-text {
    p {
      margin: 0px;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 10px;
  }

  #{$options-field}__items {
    justify-content: center;
    display: flex;
  }
  &__empty {
    text-align: center;
  }
  &__errors {
    text-align: center;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px 10px;
  }
}

.mantle-ajax-form-options-field {
  position: relative;

  &.ajax-loading {
    opacity: .5;
  }

  &__spinner {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }

  &.ajax-loading &__spinner {
    display: block;
  }

  &__placeholder {
    transition: opacity .3s ease;
    .mantle-form-options-field__items {
      min-height: 300px;
    }
  }

  &.ajax-loaded &__placeholder {
    opacity: 0;
    height: 0;
    overflow: hidden;
    min-height: auto;
    padding-bottom: 0px;
    .mantle-form-options-field__items {
      min-height: auto;
    }
  }

  &__target {
    transition: opacity .7s ease;
    transition-delay: .3s;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  &.ajax-loaded &__target {
    height: auto;
    overflow: visible;
    opacity: 1;
  }

}
