//
// All lists have stripped out styles. No bullets, no left padding.
// To add back the expected browser default styles
// add @extend %default-ul; or @extend %default-ol; to the <ul> or <ol> respectively.
//

ul,
ol {
  @include swap_direction(margin, 0);
  @include swap_direction(padding, 0);
  list-style-type: none;
  &%default-ul {
    list-style-type: disc;
    margin-bottom: $base-line-height / 2;
    padding-#{$ldirection}: $base-line-height;
  }
  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $base-line-height / 2;
    padding-#{$ldirection}: $base-line-height;
  }
}

dl {
  margin-bottom: $base-line-height / 2;
  dt {
    font-weight: bold;
    margin-top: $base-line-height / 2;
  }
  dd {
    @include swap_direction(margin, 0);
  }
}
