.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__header {
          text-align: center;
          font-size: 20px;
        }
        &__item {
          margin: 15px 0;
          input {
            width: 100%;
          }
          .button {
            width: 48%;
          }
          .form-cancel {
            float: $ldirection;
            margin-#{$rdirection}: 4%;
          }
          .form-submit {
            padding-#{$ldirection}: 0;
            padding-#{$rdirection}: 0;
          }
        }
        &__rules {
          margin: 10px 0;
          li {
            display: inline-block;
            color: $color-red;
            font-style: italic;
            &:before {
              @include swap_direction(margin, 0 6px 0 0);
              content: $cross;
              display: inline-block;
              color: $color-red;
              font-size: 16px;
              line-height: 0;
            }
            &:after {
              content: ', ';
            }
            &.pass {
              color: $color-green;
              &:before {
                content: $tick;
                color: $color-green;
              }
            }
            &:last-child {
              &:after {
                content: ' ';
              }
            }
          }
        }
        &__meter-status {
          font-weight: bold;
        }
        &__meter {
          height: 8px;
          border-radius: 4px;
          &--container {
            margin-top: 6px;
            background-color: $color-gray;
          }
          &--progress {
            background-color: $color-gray;
            width: 0;
            transition: all 0.3s linear;
          }
        }
        &__meter-wrapper {
          .profile-info {
            &__strength-list {
              display: inline-block;
              li {
                display: none;
                font-style: italic;
              }
            }
          }
          &.weak,
          &.no-score {
            .profile-info {
              &__meter {
                &--progress {
                  width: 0;
                }
              }
              &__strength-list {
                .no-score {
                  display: inline-block;
                }
              }
            }
          }
          &.bad {
            .profile-info {
              &__meter {
                &--progress {
                  width: 25%;
                  background-color: $color-red;
                }
              }
              &__strength-list {
                .bad {
                  display: inline-block;
                }
              }
            }
          }
          &.average {
            .profile-info {
              &__meter {
                &--progress {
                  width: 50%;
                  background-color: $color-average;
                }
              }
              &__strength-list {
                .average {
                  display: inline-block;
                }
              }
            }
          }
          &.good {
            .profile-info {
              &__meter {
                &--progress {
                  width: 75%;
                  background-color: $color-good;
                }
              }
              &__strength-list {
                .good {
                  display: inline-block;
                }
              }
            }
          }
          &.strong {
            .profile-info {
              &__meter {
                &--progress {
                  width: 100%;
                  background-color: $color-green;
                }
              }
              &__strength-list {
                .strong {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  fieldset {
    overflow: visible;
  }
  .password-reset-page {
    .password-reset {
      &__fieldset {
        .form-item {
          float: none;
          width: auto;
          padding-#{$rdirection}: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        position: absolute;
        #{$rdirection}: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 53%;
        border: 1px solid $color-gray;
        margin-#{$rdirection}: 3%;
      }
      @include breakpoint($medium-portrait-only) {
        #{$rdirection}: 100%;
        width: 54%;
        margin-#{$rdirection}: 2%;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        width: 37%;
      }
      &-reset {
        bottom: auto;
        top: 20%;
        #{$ldirection}: 23%;
        @include breakpoint($medium-up) {
          width: 170px;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 32%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$ldirection}: 23%;
        }
      }
      &-confirm {
        bottom: auto;
        top: 20%;
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 2%;
      }
      &-checkout {
        width: 100%;
        margin-#{$ldirection}: 8%;
        @include breakpoint($medium-portrait-only) {
          width: 42%;
          #{$rdirection}: 79%;
        }
        #{$rdirection}: 67%;
      }
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$ldirection}: 100%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      &-reset,
      &-confirm {
        &:before {
          #{$ldirection}: 0;
          top: 45%;
          transform: translate(-110%, -50%);
        }
      }
    }
    &__rules {
      column-count: 2;
      padding: 10px;
      font-size: 12px;
      list-style: none;
      @include breakpoint($medium-up) {
        column-count: 1;
      }
      li {
        padding: 3px 0;
        display: flex;
        align-items: center;
        color: $color-gray;
        transition: 0.2s;
        white-space: nowrap;
        &:before {
          @include swap_direction(margin, 0 6px 0 0);
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          transition: 0.2s;
        }
        &.pass {
          color: $color--success;
          &:before {
            color: $color--success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
    &__popup {
      column-count: 2;
    }
  }
  // Password reset overlay in profile preference page
  .profile-password-update {
    &__body {
      position: relative;
    }
    &__header {
      font-size: 22px;
    }
    &__rules {
      margin: 10px 0;
      li {
        font-style: italic;
        &:before {
          @include swap_direction(margin, 0 6px 0 0);
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
        }
        &.pass {
          color: $color-green;
          &:before {
            content: $tick;
            color: $color-green;
          }
        }
      }
    }
    &__fieldset {
      margin-top: 12px;
      padding-top: 20px;
      .form-item {
        margin-bottom: 20px;
        width: 100%;
        input[type='password'] {
          width: 100%;
        }
      }
    }
    &__meter-status {
      font-weight: 700;
    }
    &__meter {
      height: 8px;
      border-radius: 4px;
      &--container {
        margin-top: 6px;
        background-color: $color-light-gray;
      }
      &--progress {
        background-color: $color-light-gray;
        width: 0;
        transition: all 0.3s linear;
      }
    }
    &__meter-wrapper {
      .profile-password-update {
        &__strength-list {
          display: inline-block;
          li {
            display: none;
            font-style: italic;
          }
        }
      }
      &.weak,
      &.no-score {
        .profile-password-update {
          &__meter {
            &--progress {
              width: 0;
            }
          }
          &__strength-list {
            .no-score {
              display: inline-block;
            }
          }
        }
      }
      &.bad {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-red;
              width: 25%;
            }
          }
          &__strength-list {
            .bad {
              display: inline-block;
            }
          }
        }
      }
      &.average {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-average;
              width: 50%;
            }
          }
          &__strength-list {
            .average {
              display: inline-block;
            }
          }
        }
      }
      &.good {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-good;
              width: 75%;
            }
          }
          &__strength-list {
            .good {
              display: inline-block;
            }
          }
        }
      }
      &.strong {
        .profile-password-update {
          &__meter {
            &--progress {
              background-color: $color-green;
              width: 100%;
            }
          }
          &__strength-list {
            .strong {
              display: inline-block;
            }
          }
        }
      }
    }
    &__action {
      margin: 20px 0;
      display: flex;
      width: 100%;
      input[type='submit'] {
        &.disabled {
          color: $color-light-gray;
          background-color: $color-white;
          border: 1px solid $color-gray;
          cursor: not-allowed;
        }
      }
    }
    &__button {
      flex-grow: 1;
      height: 40px;
      &:first-child {
        margin-#{$rdirection}: 10px;
      }
    }
    &__loader {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      display: none;
      background: $color-white;
      opacity: 0.5;
      &.show {
        display: block;
      }
    }
    &__loading {
      top: 20%;
      #{$ldirection}: 45%;
      position: absolute;
    }
    .error_messages {
      width: auto;
      word-wrap: break-word;
      color: $color-red;
    }
    &.registration-page__content {
      .profile-password-update__button {
        margin-top: 0;
        width: inherit;
        height: 40px;
        flex-grow: 1;
        font-size: 16px;
      }
    }
  }
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($medium-up) {
        margin-top: 25px;
      }
    }
  }
  .checkout-confirmation-page {
    .registration-panel__content__left {
      .show-password__link {
        top: 10px;
      }
    }
  }
}
/* Password GDPR validation */
.change-password-gnav {
  .cms-password-field,
  .password-field {
    position: relative;
    &__info {
      border: 1px solid $color-gray-light;
      margin-top: -8px;
      width: 188px;
      &::before {
        content: '';
        position: absolute;
        top: 16%;
        right: 91%;
        border: 5px solid;
        border-color: transparent transparent $color-black transparent;
      }
      .cms-password-field__rules,
      .password-field__rules {
        padding-#{$ldirection}: 20px;
        li {
          display: flex;
          align-items: center;
          padding: 3px 0;
          color: $color-black;
          transition: 0.2s;
          white-space: nowrap;
          line-height: 1.5;
          &.pass {
            color: $color-green;
            &::before {
              color: $color-green;
              content: $tick;
            }
          }
          &::before {
            @include swap_direction(margin, 0 6px 0 0);
            content: $cross;
            display: inline-block;
            color: $color-red;
            font-size: 1em;
            line-height: 0;
            transition: 0.2s;
          }
        }
      }
    }
  }
  .account__section {
    .return-user {
      &__fieldset {
        .sign-in-page {
          &__submit {
            top: 100%;
          }
        }
      }
    }
  }
  .profile-info {
    .password-field {
      &__info {
        margin-top: 4px;
        &::before {
          top: 18%;
        }
      }
    }
  }
}
