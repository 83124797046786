.bobbis_top_10 {
  .product_collection_hero {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
    height: 232px;
    &__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $color-white;
    }
    h1,
    .at-h2 {
      font-family: $lato-black-bold;
      font-size: 51px;
      line-height: 1em;
      margin: 0;
    }
    &__subtitle {
      font-family: $brandon-text-medium;
      font-size: 17px;
      letter-spacing: 2px;
      line-height: 1em;
      margin-top: 18px;
    }
    &__html_block {
      text-align: center;
      margin-top: 18px;
    }
    &__content-item {
      &:nth-child(n + 2) {
        border-top: 1px solid $color-black;
      }
    }
  }
  .top_10-product {
    width: 100%;
    position: relative;
    &__image {
      @include swap_direction(margin, 0 auto);
      display: block;
    }
    &__product {
      text-align: center;
      margin-top: 20px;
      @include swap_direction(padding, 30px);
    }
    &__product-name {
      font-family: $brandon-text-bold;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 1.25em;
      margin-bottom: 9px;
    }
    &__description {
      font-family: $brandon-text;
      font-size: 18px;
      @include swap_direction(margin, 9px 0 0 0);
    }
    &__customer {
      font-family: $brandon-text-bold;
      @include swap_direction(margin, 9px 24px 0 0);
    }
    &__link a {
      font-family: $brandon-text-bold;
      font-size: 18px;
      color: $color-black;
      text-decoration: none;
      border: 1px solid $color-black;
      display: inline-block;
      @include swap_direction(padding, 10px 18px);
      margin-top: 36px;
      &:hover {
        color: $color-pink;
        border: 1px solid $color-pink;
      }
    }
  }
  @include breakpoint($medium-up) {
    .product_collection_hero {
      height: 296px;
      padding-bottom: 18px;
      &__body {
      }
      h1,
      .at-h2 {
        font-size: 110px;
        &:before {
          width: 80%;
          #{$ldirection}: 10%;
        }
      }
      &__subtitle {
        font-size: 34px;
      }
      &__content {
        @include swap_direction(padding, 0 60px);
      }
    }
    .top_10-product {
      min-height: 344px;
      &__image {
        float: $ldirection;
      }
      &__product {
        margin-#{$ldirection}: 50%;
      }
      &__link a {
        margin-top: 72px;
      }
    }
  }
  @include breakpoint($landscape-up) {
    .product_collection_hero {
      height: 350px;
      h1 {
        &:before {
          width: 70%;
          #{$ldirection}: 15%;
        }
      }
      &__subtitle {
        margin-top: 24px;
      }
      &__html_block {
        margin-top: 24px;
      }
    }
    .top_10-product {
      &__image {
        margin-#{$ldirection}: 46px;
      }
    }
  }
}
