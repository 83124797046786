.product_collection_hero {
  h1,
  .at-h2 {
    font-size: 30px;
    letter-spacing: 6px;
    text-transform: uppercase;
    text-align: center;
    @include swap_direction(margin, 0 auto 18px auto);
    font-family: $body-font-bold;
  }
  &__subtitle {
    font-size: 20px;
    letter-spacing: 4px;
    text-align: center;
    @include swap_direction(margin, 0 auto 0 auto);
  }
}

.product_colection_hero {
  background-position: 35px 0;
  background-repeat: no-repeat;
}

.product-collection-tabs {
  text-align: center;
  height: 46px;
  border-bottom: 1px solid $color-black;
  ul.tabs-list {
    display: inline-block;
  }
  .tabs-list {
    li {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      display: block;
      @include swap_direction(margin, 0);
      list-style: none;
      cursor: pointer;
      float: $ldirection;
    }
  }
  .tab-item {
    a {
      color: black;
      text-decoration: none;
      display: block;
      @include swap_direction(padding, 13px 15px);
      background-color: $color-gray-background;
      border-#{$ldirection}: 1px solid $color-black;
      &.current {
        background-color: $color-white;
        border-bottom: solid 1px $color-white;
      }
    }
    &:last-child a {
      border-#{$rdirection}: 1px solid $color-black;
    }
  }
}
