.mantle-placement {
  $xaxis: &--x;
  $yaxis: &--y;

  &--x {
    &-middle {
      transform: translateX(-50%);
      &.mantle-placement--y-middle {
        transform: translateX(-50%) translateY(-50%);
      }
      &.mantle-placement--y-bottom {
        transform: translateX(-50%) translateY(-100%);
      }
    }
    &-right {
      transform: translateX(-100%);
      &.mantle-placement--y-middle {
        transform: translateX(-100%) translateY(-50%);
      }
      &.mantle-placement--y-bottom {
        transform: translateX(-100%) translateY(-100%);
      }
    }
  }
  &--y {
    &-middle {
      transform: translateY(-50%);
    }
    &-bottom {
      transform: translateY(-100%);
    }
  }
}
