.curtains {
  width: 100%;
  visibility: hidden;
  .touch &,
  .touchevents & {
    visibility: visible;
    @include swap_direction(margin, 0 0 100px 0);
  }
}

.curtains.loaded {
  visibility: visible;
}

.curtain {
  position: relative;
  width: 100%;
  //padding: 200px 0;
  /* This is the buffer before the next curtain comes into view: */
  margin-bottom: 800px;
  background: #fff;
  .touch &,
  .touchevents & {
    margin-bottom: 80px;
    .link {
      @include link--inverted;
    }
  }
  .wrap {
    padding: 140px 0 100px 0;
    .touch &,
    .touchevents & {
      @include swap_direction(padding, 0);
    }
  }
  .h5 {
    margin-bottom: 10px;
  }
  .align-center {
    @include swap_direction(padding, 0 15%);
  }
}

.curtain--1 {
  text-align: center;
  .wrap {
    padding-top: 300px;
    .touch &,
    .touchevents & {
      padding-top: 80px;
    }
  }
}

.curtain.pinned {
  position: fixed;
  top: 0;
  #{$ldirection}: 0;
}
