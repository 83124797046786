///
/// @file global/_functions.scss
///
/// \brief Functions
///
/// Useful functions to help us calculate various values
/// (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
///

// http://stackoverflow.com/a/11752227
@function compact(
  $var-1,
  $var-2: false,
  $var-3: false,
  $var-4: false,
  $var-5: false,
  $var-6: false,
  $var-7: false,
  $var-8: false,
  $var-9: false,
  $var-10: false
) {
  $full: $var-1;
  $vars: $var-2, $var-3, $var-4, $var-5, $var-6, $var-7, $var-8, $var-9, $var-10;

  @each $var in $vars {
    @if $var {
      $full: $full, $var;
    }
  }
  @return $full;
}

// line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

@function get_vh_value_for_popup($value-px, $viewing-height-value: null) {
  $default-viewing-height: 920px;

  @if type-of($viewing-height-value) == 'null' {
    $viewing-height-value: $default-viewing-height;
  }

  $calc-value-px: ($viewing-height-value / 1px * 0.01) * 1px;
  $vhSize: ($value-px / $calc-value-px) * 1vh;

  @return  #{$vhSize};
}
