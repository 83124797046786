.double-feature {
  background: #000;
  color: #fff;
  @include swap_direction(padding, 60px 0 75px 0);
  margin-bottom: 54px;
  @include antialiased;
}

.double-feature__container {
  @include pie-clearfix;
  background: image-url('/media/images/global/hr-line.png') repeat-y 50%;
  @include swap_direction(padding, 0 20px);
}

.double-feature__column {
  float: $ldirection;
  width: 46.2%;
  text-align: center;
  position: relative;
  &.double-feature__column--right {
    float: $rdirection;
  }
}

.double-feature__image {
  @include swap_direction(margin, 0 auto 50px 0);
}

.double-feature__headline {
  @include h4;
  @include swap_direction(margin, 0 0 10px 0);
  > a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      color: $color-pink;
    }
  }
}

.double-feature__description {
  margin-bottom: 45px;
  .touch &,
  .touchevents & {
    margin-bottom: 80px;
  }
}

.double-feature__link-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.double-feature__icon {
  margin-bottom: 20px;
  text-align: center;
  .icon {
    color: #fff;
    font-size: 24px;
  }
}

.double-feature--secondary {
  padding-top: 55px;
  margin-bottom: 0;
  .double-feature__container {
    background: none;
  }
  .double-feature__link-wrapper {
    position: static;
    .link {
      border: 0;
    }
  }
}
