.overlay-offer-thanks {
  @include swap_direction(padding, 1em 40px);
  &__title {
    width: 100%;
    @include swap_direction(margin, 1em 0);
    font-family: $brandon-text-bold;
    font-size: 24px;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
  }
  &__text {
    width: 100%;
    text-align: center;
  }
}
