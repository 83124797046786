.viewcart {
  .checkout {
    .adpl {
      .form-item {
        margin: 0;
      }
    }
  }
  .gift-message {
    &__title {
      margin: 30px 0 0;
      @include breakpoint($medium-up) {
        margin: 0 0 12px;
      }
    }
    &__text {
      margin-bottom: 20px;
    }
  }
  .label-content {
    display: inline-block;
    font-size: 12px;
  }
  .giftwrap-panel {
    &__checkbox {
      cursor: pointer;
      height: 18px;
      opacity: 0;
      position: absolute;
      top: 5px;
      width: 40px;
      z-index: 2;
      #{$ldirection}: 0;
      &:checked {
        & ~ .giftwrap-panel__label {
          &::before {
            background-color: $color-black;
            content: '';
          }
          &::after {
            position: absolute;
          }
          .gw-chkbx-label {
            &__yes {
              display: block;
            }
            &__no {
              display: none;
            }
          }
        }
      }
    }
  }
  .giftwrap-panel__content {
    .validation {
      border: 1px solid $color-gray-alt;
      padding: 20px;
    }
  }
  .giftwrap {
    .giftwrap-message-container {
      .card-message {
        margin: 0;
        .gw-error {
          color: $color-red;
          padding-bottom: 10px;
        }
        .label-content {
          display: none;
        }
        .giftmessage_count {
          padding: 16px;
          &[data-error] {
            border: 1px $color-red solid;
          }
        }
      }
    }
    .custom-outline {
      display: none;
    }
    &-panel {
      &__max-count {
        font-style: italic;
      }
      &__label {
        display: inline-block;
        position: relative;
        padding: 0 0 12px;
        &::before {
          @include border-radius(8px);
          #{$rdirection}: 5px;
          #{$ldirection}: 0;
          background-color: $color-gray-alt;
          border-radius: 10px;
          height: 14px;
          padding: 1px;
          top: 0;
          width: 34px;
          content: '';
        }
        &::after {
          @include border-radius(50%);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          content: '';
          height: 20px;
          position: absolute;
          top: 0;
          transition: 0.4s;
          width: 20px;
          -webkit-transition: 0.4s;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
          #{$ldirection}: 1px;
          background: $color-white;
          background-image: none;
          border: 0;
          box-shadow: 0 1px 3px 0 $color-black;
          opacity: 1;
        }
        .gw-chkbx-label__yes {
          display: none;
        }
      }
      &__submit {
        margin-top: 20px;
      }
      &__form {
        padding: 0;
        @include breakpoint($medium-up) {
          padding-left: 20px;
        }
      }
      &__form-container {
        display: flex;
      }
      &__question {
        width: 100%;
        @include swap_direction(padding, 0 0 12px 108px);
        @include breakpoint($medium-up) {
          padding: 0 0 12px;
        }
      }
    }
    &__image {
      height: 80px;
      width: 90px;
      max-width: none;
      position: absolute;
      @include breakpoint($medium-up) {
        position: relative;
      }
    }
    .giftwrap-success-message {
      &__edit {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .giftwrap-label {
    #{$ldirection}: 110px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 0;
    }
  }
  .checkout__content {
    .giftwrap-panel {
      &__checkbox:checked {
        ~ .giftwrap-label::after {
          #{$ldirection}: 16px;
        }
      }
      &__title.open {
        .title-icon {
          background: url(#{$base-theme-path}/svg-icons/src/icon--minus.svg) no-repeat;
          background-size: contain;
          top: 22px;
          float: $rdirection;
        }
      }
    }
    .gift-options {
      &__title {
        .title-icon {
          #{$rdirection}: 16px;
          background: url(#{$base-theme-path}/svg-icons/src/icon--add.svg) no-repeat;
          background-size: contain;
          height: 24px;
          top: 11px;
          width: 24px;
          float: $rdirection;
        }
      }
    }
  }
}
