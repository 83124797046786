// Landing page
.slww.influencers {
  .product_collection_hero {
    padding: 0;
    max-width: 1024px;
    margin: 0 auto;
    h1 {
      font-family: $lato-black-bold;
      font-size: 36px;
      letter-spacing: 0;
      line-height: 1em;
      margin: 0 auto 18px auto;
    }
    h2 {
      font-family: $brandon-text-bold;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    h4 {
      font-family: $lato-black-bold;
      font-size: 30px;
      line-height: 1em;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 52px;
      }
    }
  }
  .product_collection_hero__body {
    margin: 30px 0 0 0;
  }
  .product_collection_hero__subtitle {
    font-size: 18px;
    letter-spacing: 1px;
    padding: 0 12px 18px;
    line-height: 1.25em;
  }
  .product_collection_hero-v3 .quickshop-wrapper {
    position: unset;
  }
  .wrap {
    padding: 0;
    position: relative;
  }
  .quickshop_container {
    text-align: center;
    margin: 0;
    .basic-responsive {
      overflow: hidden;
    }
    .quickshop {
      text-align: left;
    }
    .button--outline {
      color: $color-black;
      border: none;
      border-bottom: 2px solid $color-black;
      padding: 0.75em 0;
    }
  }
  .videos-plus-module_page_nav {
    @media (min-width: 768px) {
      top: 86.5em;
    }
  }
  .videos-plus-module_hero__title {
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
  .video-carousel__block {
    @media (max-width: 767px) {
      //background-image: unset;
    }
  }
  .video-carousel__block__header {
    @media (max-width: 767px) {
      font-size: 36px;
      letter-spacing: 0;
      background-image: unset;
    }
  }
  .homepage-slide.module {
    margin-top: 30px;
  }
}

.product_collection_hero-v3.slww .quickshop-wrapper {
  position: unset;
}
