.product-quote {
  &__body {
    text-align: center;
    font-size: 24px;
    line-height: 1.5em;
    font-style: italic;
  }
  &__bb {
    text-align: center;
    font-family: $brandon-text-bold;
    margin-top: 10px;
  }
}
