.account__section {
  &--loyalty {
    @include account-layout;
    .account-utilities {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.earning-points {
  text-align: center;
  &--marketing {
    @include breakpoint($medium-up) {
      text-align: center;
    }
    .earning-points {
      &__header {
        font-family: $bb-sans;
        font-size: 34px;
        margin-top: 30px;
        @include breakpoint($medium-up) {
          font-size: 90px;
        }
      }
      &__subheader {
        font-size: 16px;
      }
    }
  }
  &__header {
    font-family: $body-font-bold;
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
    line-height: 1;
    padding-top: 20px;
    @include breakpoint($medium-up) {
      font-size: 20px;
    }
  }
  &__subheader {
    margin: 15px;
    font-size: 15px;
    text-align: center;
    @include breakpoint($medium-up) {
      margin: 15px 15px 30px;
      font-size: 16px;
    }
  }
  &__section {
    padding: 30px;
    margin: 15px;
    text-align: center;
    color: $color-white;
    background-size: cover;
    @include breakpoint($medium-up) {
      margin: 5px;
      display: inline-block;
      width: 30%;
    }
  }
  &__data {
    font-family: $body-font;
    font-size: 15px;
    &__header {
      margin-bottom: 20px;
      text-transform: uppercase;
      .earning-points--marketing & {
        margin-bottom: 30px;
        font-size: 16px;
      }
      @include breakpoint($medium-up) {
        margin-bottom: 50px;
      }
    }
    &__text {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 20px;
      line-height: 1;
      .earning-points--marketing & {
        margin-bottom: 30px;
        @include breakpoint($medium-up) {
          line-height: 2;
          font-size: 33px;
        }
        @include breakpoint($landscape-up) {
          line-height: 4;
        }
        @include breakpoint($xlarge-up) {
          line-height: 6;
        }
        @include breakpoint($max-width-large) {
          line-height: 9;
        }
      }
      @include breakpoint($medium-up) {
        margin-bottom: 50px;
        font-size: 35px;
      }
    }
    &__footer {
      margin: 0 auto;
      max-width: 160px;
      .earning-points--marketing & {
        font-size: 16px;
      }
    }
  }
  @if $lr2021 == true {
    &__section--redesign2021 {
      position: relative;
      margin: 10px 10px 105px 10px;
      min-height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 10px;
      @include breakpoint($medium-up) {
        width: 100%;
      }
      @include breakpoint($large-up) {
        display: inline-flex;
        width: 30%;
      }
      .earning-points {
        &__data__header {
          margin-bottom: 0;
        }
        &__data__text {
          margin-bottom: 0;
          font-family: $bb-sans;
          font-size: 40px;
          line-height: 1;
        }
        &__data__footer {
          margin-bottom: 0;
          width: 80%;
          max-width: none;
        }
        &__cta_abs {
          position: absolute;
          bottom: -70px;
          left: 50%;
          transform: translate3d(-50%,0,0);
          padding: 20px 40px;
          white-space: nowrap;
          @include breakpoint($large-up) {
            bottom: -90px;
          }
        }
      }
    }
  }
}

@if $lr2021 == true {
  #loyalty__panel__earnpoints-landing {
    .earning-points {
      &__section--redesign2021 {
        margin: 10px;
      }
      &__cta_abs {
        display: none;
      }
    }
  }
}
