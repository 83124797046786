$ff-static-button-height: 62px;

.helper-text-link {
  position: absolute;
  right: 0px;
  top: calc(50% + 3px);
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: none;
  line-height: 15px;
  text-decoration: none;
  font-size: 0px;
  background: url(#{$base-theme-path}/img/question-mark.png) no-repeat;
  background-size: cover;
  [dir='rtl'] & {
    left: 0px;
    right: auto;
  }
  @include breakpoint($medium-up) {
    position: static;
    display: inline-block;
    margin-left: 10px;
    [dir='rtl'] & {
      margin: 0px 10px 0px 0px;
    }
  }
}

.mantle-form-options-field {
  padding: 15px 0px;
  &__header {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
    .style--bare {
      font-family: $bb-sans;
      font-size: 40px;
      line-height: 1em;
      @include tracking(0);
      @include breakpoint($medium-up) {
        font-size: 46px;
        @include tracking(25);
      }
    }
  }
  &__subheader {
    margin-bottom: 15px;
    max-width: 80%;
    @include breakpoint($small-down) {
      br {
        display: none;
        [data-field-name='undertone'] &,
        [data-field-name='shade'] & {
          display: block;
        }
      }
      [data-field-name='undertone'] & {
        max-width: 90%;
      }
    }
    @include breakpoint($medium-up) {
      padding: 0px 5px;
      margin-bottom: 20px;
      max-width: 550px;
    }
    .style--bare {
      font-family: $brandon-text;
      line-height: 1.1;
      font-size: 20px;
      @include tracking(0);
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: 1.33;
      }
      a {
        line-height: 1.33;
      }
    }
  }
  &__footer {
    margin-top: 20px;
    .button {
      @include ff-button;
      width: 100%;
      padding: 20px;
      @include breakpoint($medium-up) {
        width: auto;
        padding: 1.3em 4.5em;
        margin-#{$ldirection}: 105px;
      }
    }
    .range-slider-view-all-link {
      @include breakpoint($small-down) {
        display: none;
      }
    }
  }
  &__footer-cta {
    @include breakpoint($small-down) {
      display: none;
      background: $color-gray-bg;
      position: fixed;
      bottom: 0px;
      z-index: 99;
      width: 100vw;
      padding: 3vw;
      [data-field-name='intro'] & {
        display: block;
        position: relative;
        width: auto;
        padding: 0;
      }
    }
  }
  &__footer_text_below_cta {
    margin-top: 20px;
  }
  &__intro {
    position: relative;
    padding: 0 3vw;
    @include breakpoint($medium-up) {
      padding: 0px;
    }
  }
  &__items {
    padding: 0 3vw;
    width: 100vw;
    @include breakpoint($medium-up) {
      padding: 0px;
      width: auto;
    }
    [data-field-name='formula'] & {
      padding: 0;
    }
  }
  &__helper-text {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    font-size: 16px;
    line-height: 1.5;
    @include tracking(0);
    #colorbox & {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
    .overlay-header {
      font-family: $bb-sans;
      font-size: 35px;
      border-bottom: none;
      line-height: 1;
      margin-bottom: 1em;
      @include breakpoint($medium-up) {
        margin-bottom: 0;
      }
    }
    .overlay-subheader {
      font-family: $brandon-text-medium;
      font-size: 18px;
      margin-top: 1em;
    }
    .button {
      @include ff-button;
      width: auto;
      display: inline-flex;
      padding: 0px 50px;
      margin: 10px 0px;
      font-size: 12px;
      @include breakpoint($medium-up) {
        padding: 0px;
        width: 100%;
      }
    }
    em {
      color: $color-italic-gray;
      font-size: 14px;
      @include tracking(25);
    }
    strong {
      font-family: $brandon-text-medium;
      font-weight: normal;
    }
  }
  .range-slider-view-all-link {
    @include ff-view-all-link();
  }
}

.foundation-finder-quiz {
  .mantle-ajax-form-options-field {
    &__spinner {
      @include ajaxSpinner($color-gray-dark, 30px, 30px, 8px);
    }
    &__placeholder {
      transition: opacity 0.3s ease;
      .mantle-form-options-field__items {
        min-height: 60vh;
        @include breakpoint($medium-up) {
          min-height: 650px;
        }
      }
    }
  }
}

/*
 * Form step specific overrides
 */
// First step
.mantle-form-options-field[data-field-name='intro'] {
  .mantle-form-options-field {
    &__header {
      .style--bare {
        font-size: 32px;
        line-height: 1em;
        @include tracking(25);
        @include breakpoint($medium-up) {
          font-size: 46px;
        }
      }
    }
    &__subheader {
      .style--bare {
        font-size: 15px;
        @include breakpoint($medium-up) {
          font-size: 18px;
        }
      }
    }
    .button {
      @include tracking(100);
      margin-#{$ldirection}: 0;
    }
    &__footer {
      .button {
        margin-#{$ldirection}: 0;
      }
    }
  }
}

// Second step
.mantle-form-options-field[data-field-name='intensity'] {
  .mantle-form-options-field {
    &__items {
      padding: 0px;
    }
    &__footer {
      // let the widget slider handle margin top
      margin-top: 0px;
    }
  }
}

// Shade step. Need tighter spacing.
.mantle-form-options-field[data-field-name='shade'] {
  padding-top: 5px;
  .mantle-form-options-field {
    &__header {
      margin-bottom: 10px;
    }
    &__subheader {
      margin-bottom: 10px;
      max-width: 95%;
    }
  }
}
// form indicators are form level and not field level. So we have to modify
// shade by targetting the active field on form.
.form-formatter[data-active-field='shade'] {
  .form-step-track {
    .js-back-link {
      top: 44px;
    }
  }
}

/*
 * hide stick buttons on quiz page
 */
body.quiz-page {
  .sticky-chat {
    display: none !important;
  }
  .sticky-offer {
    display: none !important;
  }
}

.form-step-track {
  font-family: $brandon-text-bold;
  text-align: center;
  font-size: 12px;
  padding: 30px 15px 0 15px;
  @include breakpoint($medium-up) {
    padding: 15px 15px 0 15px;
  }
  color: $color-black;
  .js-back-link {
    position: static;
    top: 58px;
    left: 16px;
    font-size: 12px;
    cursor: pointer;
    z-index: 10;
    display: inline-block;
    [dir='rtl'] & {
      left: auto;
      right: 16px;
    }
    &.active {
      display: inline-block;
      @include breakpoint($medium-up) {
        position: static;
        display: inline-block;
      }
    }
    &:before {
      [dir='rtl'] & {
        @include transform(rotate(180deg));
      }
      font-size: 16px;
      @include breakpoint($medium-up) {
        display: inline-block;
        font-size: 12px;
        margin-top: -8px;
      }
    }
  }
  &__back-link {
    .entire-quiz-one-page & {
      font-size: 3.2vw;
      @include breakpoint($medium-up) {
        font-size: 15px;
      }
    }
  }
  &__of {
    text-transform: uppercase;
    display: inline-block;
  }
}

#cboxOverlay.colorbox--quiz--loading {
  opacity: 0.5;
}

#colorbox.colorbox--quiz--loading {
  border: none;
  #cboxContent {
    background: none;
    padding: 0px;
  }
  #cboxLoadedContent {
    overflow: hidden !important;
  }
}

.ff-personalization {
  opacity: 0;
  transition: all 0.5s ease;
  height: 0px;
  width: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &.active {
    opacity: 1;
    height: auto;
    width: auto;
    overflow: visible;
  }
  &__shade_name {
    font-size: 13px;
    font-family: $brandon-text-bold;
    color: #1b1b1b;
    text-transform: uppercase;
  }
  &__hex {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 5px;
  }
  &__msg {
    font-family: $brandon-text;
    font-size: 13px;
    @include tracking(100);
    text-transform: uppercase;
  }
}

#colorbox.colorbox--quiz {
  $cboxPadding: 60px;
  background-color: $color-gray-bg;
  #cboxContent {
    background-color: $color-gray-bg;
    padding: 4vw;
    padding-top: 40px;
    @include breakpoint($medium-up) {
      padding: 30px;
    }
  }
  #cboxLoadedContent {
  }
  #cboxClose {
    background: url(#{$base-theme-path}/img/x-out.png) no-repeat;
    background-size: cover;
    height: 15px;
    width: 15px;
    font-size: 0px;
    right: 4vw;
    top: 50px;
    padding: 0px;
    @include breakpoint($medium-up) {
      top: 40px;
      right: $cboxPadding;
    }
  }
  #cboxLoadingGraphic {
    display: none !important;
  }
  #cboxLoadingOverlay {
    display: none !important;
  }
}

#colorbox.colorbox--quiz--loading {
  #cboxLoadingGraphic {
    display: none !important;
  }
  #cboxLoadingOverlay {
    display: none !important;
  }
}

#colorbox.colorbox--view-all {
  $cboxPadding: 25px;
  #cboxContent {
    padding: $cboxPadding;
  }
  #cboxLoadedContent {
    position: relative;
    overflow: hidden !important;
  }
  #cboxClose {
    top: $cboxPadding + 10px;
    right: $cboxPadding;
  }
  .form-options-formatter {
    &__content {
      overflow-y: auto;
      max-height: calc(100vh - 100px - 50px);
    }
    @include breakpoint($medium-up) {
      height: 700px;
      max-height: calc(100vh - 100px);
    }
    &__intro {
      color: $color-black;
      top: 0px;
      left: 0px;
      @include breakpoint($medium-up) {
      }
    }
    &__items {
    }
    &__footer {
      padding-bottom: 50px;
    }
  }
  .range-slider-carousel {
    &__carousel {
      opacity: 1;
    }
    &__content-well {
      display: none;
    }
  }
}

#colorbox.colorbox--helper-text {
  .mantle-form-options-field__helper-text {
    padding-bottom: 0;
  }
  #cboxLoadedContent {
    overflow-y: hidden;
    overflow: hidden;
    max-height: calc(100vh - 55px);
    @include breakpoint($medium-up) {
      max-height: none;
    }
  }
  #cboxClose {
    @include breakpoint($small-down) {
    }
  }
}

#cboxOverlay.colorbox--quiz {
  background: rgb(0, 0, 0);
}

.js-form-answer-reaction[data-reaction-type='count-attr'][data-answer-count='1'] .plural {
  display: none;
}

.js-form-answer-reaction[data-reaction-type='count-attr']:not([data-answer-count='1'])
  .single-answer {
  display: none;
}
