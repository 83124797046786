/* eGift Card Landing - Cashstar */
.egiftcard-cashstar {
  color: $color-white;
  font-size: 18px;
  letter-spacing: 0.06em;
  @include breakpoint($medium-up) {
    background-color: $color-black;
  }
  &__container {
    @include swap_direction(margin, 0 auto);
    max-width: 1024px;
    display: flex;
    flex-flow: column;
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: $ldirection;
      padding: 0 20px;
      position: relative;
      flex-flow: unset;
      @if $cr22 == false {
        max-width: 1200px;
      } @else {
        max-width: 1367px;
      }
    }
    @if $cr22 == true {
      @include breakpoint($medium-only) {
        flex-flow: wrap;
      }
    }
  }
  @include breakpoint($medium-up) {
    &__left,
    &__right {
      float: $ldirection;
      width: 50%;
    }
    &__bottom {
      position: absolute;
      top: 270px;
      width: 45%;
      #{$ldirection}: 15px;
    }
  }
  &__left {
    order: 2;
    @include breakpoint($medium-up) {
      margin-top: 7%;
      order: unset;
    }
  }
  &__right {
    padding: 0;
    order: 1;
    @include breakpoint($medium-up) {
      order: unset;
    }
  }
  &__bottom {
    order: 3;
    color: $color-black;
    @include breakpoint($medium-up) {
      order: unset;
      color: $color-white;
    }
    @if $cr22 == true {
      @include breakpoint($medium-only) {
        position: unset;
        min-height: 100px;
        margin-top: 10px;
      }
      @include breakpoint($xlarge-up) {
        position: absolute;
      }
    }
  }
  &__header {
    text-transform: uppercase;
    font-size: 40px;
    letter-spacing: 0.26em;
    @include swap_direction(margin, 0 0 10px);
    line-height: 1;
    font-family: $bb-sans;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 0;
    }
    .perfect__gift {
      font-size: 44px;
      letter-spacing: -2px;
      color: $color-black;
      margin: 40px 0;
      padding: 0;
      font-family: $bb-sans;
      @include breakpoint($medium-up) {
        font-size: 58px;
        margin: 0;
        color: $color-white;
        line-height: 0.95;
        letter-spacing: 1.5px;
      }
    }
  }
  &__subtitle {
    font-size: 15px;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      font-size: 24px;
      margin-#{$ldirection}: 0;
      margin-bottom: 0;
    }
    p {
      font-family: $brandon-text;
      font-size: 15px;
      letter-spacing: 0;
      color: $color-black;
      @include breakpoint($medium-up) {
        color: $color-white;
      }
    }
  }
  &__desc {
    font-size: 14px;
    margin: 10px 0 15px;
    color: $color-black;
    @include breakpoint($medium-up) {
      font-size: 18px;
      width: 98%;
      padding-top: 53px;
      @include swap_direction(margin, 0 0 0 6px);
      color: $color-white;
    }
  }
  &__link {
    width: auto;
    background: $color-black;
    color: $color-white;
    padding: 11px 23px;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0 0 65px 3px);
      padding: 8px 23px;
      width: auto;
      background: $color-white;
      color: $color-black;
    }
    @if $cr22 == false {
      letter-spacing: 0.2em;
    } @else {
      @include typography-link-text;
      @include breakpoint($xlarge-screen) {
        margin: 0 auto;
      }
      @include breakpoint($xlarge-up) {
        @include swap_direction(margin, 0 0 105px 3px);
      }
    }
  }
  &__disclaimer {
    font-size: 12px;
    letter-spacing: 0.009em;
    font-style: italic;
    color: $color-gray-dim;
    @if $cr22 == true {
      margin-top: 15px;
    }
    @include breakpoint($medium-up) {
      @if $cr22 == true {
        margin-top: -10px;
        padding-top: 10px;
      }
      p {
        font-size: 14px;
        letter-spacing: 0;
      }
    }
  }
  &__desc,
  &__subtitle {
    letter-spacing: 0;
    line-height: 1.5;
    width: 84%;
    font-size: 15px;
    margin: 0 auto 20px;
    @include breakpoint($medium-up) {
      width: 100%;
      margin: 0;
    }
  }
}
/* eGift Card Landing - Check Balance */
.giftcard-balance__container.giftcard-balance-cashstar {
  background: $color-white;
  @include swap_direction(margin, 30px 20px 0 20px);
  padding: 20px 0;
  border-width: 2px;
  @include breakpoint($medium-up) {
    width: 470px;
    border-width: 0;
    margin: 20px auto 0;
    border-top: 0;
  }
  .giftcard-balance {
    min-height: 0;
    margin-bottom: 0;
    @include breakpoint($medium-up) {
      border-bottom: 0;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
    &__header {
      text-align: center;
      font-size: 31px;
      letter-spacing: 0;
      @include swap_direction(padding, 0 0 10px);
      @include swap_direction(margin, 0 0 11px);
      line-height: 1;
      position: relative;
      font-family: $bb-sans;
      border-bottom: 0;
      pointer-events: none;
      @include breakpoint($medium-up) {
        text-align: center;
        font-size: 36px;
        @include swap_direction(margin, 0 20px 11px 0);
        @include swap_direction(padding, 0);
      }
    }
    &__arrow {
      position: absolute;
      #{$rdirection}: 0;
      display: none;
    }
    &__subheader {
      font-size: 15px;
      text-align: center;
      font-family: $brandon-text;
      letter-spacing: 0;
      margin: 10px auto;
      width: 70%;
      @include breakpoint($medium-up) {
        margin: 10px auto 20px;
        text-align: center;
        width: 60%;
      }
    }
    &__form {
      text-align: $ldirection;
      @include swap_direction(padding, 0);
      #giftcard_balance {
        height: auto;
      }
      .errors {
        margin-bottom: 15px;
        li {
          margin-#{$ldirection}: 0;
        }
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
      }
      &-number,
      &-pin {
        width: 100%;
        display: block;
        margin: 0 0 15px;
      }
      &-button {
        width: 100%;
        display: block;
        height: auto;
        margin: 0;
      }
      input[type='text'] {
        height: 40px;
        text-align: center;
      }
      input[type='submit'] {
        height: 40px;
        width: 100%;
        vertical-align: baseline;
        @if $cr22 == false {
          background-color: $color-black;
          &:hover {
            background-color: $color-pink;
          }
        } @else {
          @include cta-primary;
          padding: 0;
        }
      }
    }
    &__remaining {
      @include swap_direction(padding, 0);
      font-size: 18px;
      @include breakpoint($medium-up) {
        font-size: 24px;
      }
      @include breakpoint($landscape-up) {
        font-size: 30px;
      }
    }
    &__amount,
    &__amount-text {
      @include breakpoint($medium-up) {
        display: inline-block;
        margin-top: 40px;
      }
    }
  }
}
