.product-grid {
  &__content {
    @include pie-clearfix;
    @include wrap;
    @include swap_direction(padding, 0);
  }
  &__item {
    @include swap_direction(margin, 0 0 20px);
    @include breakpoint($medium-up) {
      float: $ldirection;
      @include swap_direction(padding, 0 20px);
      width: 33.33%;
      &--colspan-2 {
        width: 66.67%;
      }
      &--colspan-3 {
        width: 100%;
      }
    }
    &--promo > .node {
      /*       background: $color-light-brown;
 */
      height: 100%;
    }
    .slick-slide & {
      @include breakpoint($medium-up) {
        width: 100%;
      }
    }
  }
  &__add_all_to_bag {
    text-align: center;
  }

  /**
   * Override Slick Styling
   */
  .slick-slider {
    margin-bottom: 0;
  }
  &--carousel {
    .slick-prev {
      #{$ldirection}: -3px;
      @include breakpoint($tablet) {
        #{$ldirection}: -12px;
      }
    }
    .slick-next {
      #{$rdirection}: -6px;
      @include breakpoint($tablet) {
        #{$rdirection}: -16px;
      }
      &:before {
        position: relative;
        top: auto;
        #{$rdirection}: auto;
        #{$ldirection}: auto;
        bottom: auto;
      }
    }
    .slick-prev,
    .slick-next {
      top: 24%;
      width: 18px;
      height: 22px;
      &:before {
        font-size: 22px;
      }
      @include breakpoint($medium-up) {
        height: 50px;
        width: 50px;
        top: 30%;
        &:before {
          font-size: 50px;
        }
      }
    }
    .product-brief__shades {
      .slick-prev,
      .slick-next {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.product-grid-alt {
  &__content {
    @include pie-clearfix;
    text-align: justify;
    font-size: 0;
  }
  &__item,
  &__gap-item {
    // @include transition(width 1s ease);
    display: inline-block;
    .product-thumb,
    .product-thumb__image-link {
      width: 100%;
    }
  }

  // Default state BEFORE filtering
  &__item,
  &__gap-item {
    // 7 columns for tablet
    width: 14.28%;
    // 10 columns for desktop
    @include breakpoint($large-up) {
      width: 10%;
    }
  }

  // 15+ results
  &--results-gte-15 .product-grid-alt__item,
  &--results-gte-15 .product-grid-alt__gap-item {
    // 7 columns for tablet
    width: 14.28%;
    // 9 columns for desktop
    // NOTE: this state was removed as the designs don't account for very long
    // product names in the rollover state.
    // @include breakpoint($large-up) {
    //   width: 11.11%;
    // }
  }
  // 5-14 results
  &--results-gte-5 .product-grid-alt__item,
  &--results-gte-5 .product-grid-alt__gap-item {
    // 5 columns for tablet
    width: 20%;
    // 7 columns for desktop
    @include breakpoint($large-up) {
      width: 14.28%;
    }
  }
  // 4 or less results
  &--results-lt-5 {
    text-align: center;
    .product-grid-alt__content {
      text-align: center;
    }

    // Prevents inline-blocks from centering if not display: none
    .product-grid-alt__gap-item {
      display: none;
    }
  }
  &--results-lt-5 .product-grid-alt__item,
  &--results-lt-5 .product-grid-alt__gap-item {
    width: 25%;
  }
  // 3 or less results
  &--centered .product-grid-alt__item {
    text-align: center;
  }
}

.product__flag_preorder {
  @include font-smoothing(antialiased);
  background-color: $base-button-color;
  color: $color-white;
  display: none;
  font-family: $brandon-text-bold;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1;
  @include swap_direction(padding, 0.75em);
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 13px;
}

.product-grid-products {
  &--peeking {
    @include breakpoint($medium-down) {
      .slick-list {
        // add peeking
        padding: 0 30% 0 0;
      }
      .slick-prev,
      .slick-next {
        top: -10px;
      }
      .slick-prev {
        right: 20px;
        left: auto;
      }
      .slick-prev,
      .slick-next {
        // for now disabling arrows since we have peeking.
        display: none !important;
      }
      .product-grid__carousel__slide {
        .product-thumb {
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          transform: scale(0.7);
          transition: transform 1s;
          transform-origin: left;
          &__image-link {
            display: block;
            float: none;
            width: auto;
          }
          &__image {
            transition: opacity 1s;
            opacity: 0.4;
            display: block;
            margin: 1px auto;
            @include swap_direction(margin, 0 auto);
            text-align: center;
          }
          &__abstract {
            @include swap_direction(padding, 0 0 0 16px);
            float: none;
            // width: auto;
            width: 100%;
            @include swap_direction(padding, 20px 0 0);
            background: $color-white;
            background: rgba(255, 255, 255, 0.95);
            opacity: 0;
            transition: opacity 1s;
            @include breakpoint($medium-up) {
              opacity: 1;
            }
          }
        }
        &.slick-active .product-thumb {
          filter: none;
          -webkit-filter: none;
          transform: scale(1);
          &__image {
            opacity: 1;
          }
          &__abstract {
            opacity: 1;
          }
        }
      }
    }
  }
  &--plus-between {
    .product-grid__item {
      position: relative;
      &:before {
        content: '+';
        font-size: 50px;
        position: absolute;
        top: calc(30% + 20px);
        left: 0px;
        transform: translate(-50%, -50%);
        display: none;
      }
      // assuming that we will have 3 cols.
      &:nth-child(3n + 2):before,
      &:nth-child(3n + 3):before {
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
    .product-grid__carousel__slide {
      & ~ .slick-active .product-grid__item:before {
        display: none;
      }
      &.slick-active ~ .slick-active .product-grid__item:before {
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
  }
  &--hide-price {
    .product-thumb--price {
      display: none;
    }
  }
  &--hide-cta {
    .product-thumb__cta {
      display: none;
    }
  }
}
