.product-palette {
  position: relative;
  @include icon('quickshop_arrow');
  @include breakpoint($small-down) {
    overflow: hidden;
    &:before {
      position: absolute;
      z-index: 2;
      color: $color-gray;
      background: $color-white;
      letter-spacing: 0;
      top: 0;
      margin-#{$ldirection}: -13px;
    }
  }
  @include breakpoint($medium-up) {
    &:before {
      content: '';
    }
  }
  &__image {
    &-container {
      @include breakpoint($small-down) {
        margin-top: 13px;
        border-top: 1px solid $color-gray;
      }
      @include breakpoint($medium-up) {
        float: $ldirection;
        width: 49%;
      }
      text-align: center;
      .product-palette__photo {
        display: block;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 0 20px 0);
        }
      }
    }
  }
  &__details {
    &-container {
      margin-top: 20px;
      @include breakpoint($medium-up) {
        border: 0;
        @include swap_direction(margin, 0);
        float: $rdirection;
        width: 49%;
        display: flex;
        flex-direction: column;
        min-height: 44vh;
      }
      @include breakpoint($small-down) {
        position: relative;
        @include swap_direction(margin, 1em);
        overflow: hidden;
      }
    }
    &-wrapper {
      flex: 1 0 0;
      overflow: auto;
      padding-top: 10px;
      border-top: 1px solid $color-gray;
      border-bottom: 1px solid $color-gray;
      @include swap_direction(margin, 0 40px 0 40px);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0);
        border: 0;
        @include swap_direction(padding, 0);
      }
      .slick-list {
        #{$rdirection}: 5px;
      }
    }
    &-title {
      @include breakpoint($small-down) {
        display: none;
      }
    }
    &-group {
      &.first {
        @include breakpoint($medium-up) {
          border: none;
          @include swap_direction(padding, 0);
        }
      }
      &-shades {
        display: inline-block;
        @include breakpoint($medium-up) {
          display: block;
        }
        .slick-track {
          &.shade-picker__zoom-carousel {
            zoom: 1.15;
            display: block;
          }
        }
      }
      &-shade {
        float: $ldirection;
        @include swap_direction(margin, 0 10px 10px 0);
        .shade-picker__palette & {
          @include swap_direction(margin, 1em 0 0 0);
        }
      }
      @include breakpoint($small-down) {
        @include swap_direction(margin, 0.25em 2px 0.75em -1px);
        line-height: 0px;
        @include swap_direction(padding, 5px);
        border-#{$ldirection}: 1px solid $color-gray;
        .shade-picker__palette & {
          @include swap_direction(margin, 0.5em 2px 0.5em -1px);
        }
      }
    }
  }
  &__link-launch {
    text-transform: uppercase;
    @include breakpoint($small-down) {
      @include h4;
      font-size: 14px;
      border-bottom: 2px solid $color-red;
      @include swap_direction(margin, 20px 0 10px 0);
      display: inline-block;
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
    @include breakpoint($medium-up) {
      border: 2px solid black;
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &-desktop {
      @include breakpoint($small-down) {
        display: none;
      }
    }
    &-mobile {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &__stack {
    .product-palette__details-group-title {
      padding-bottom: 15px;
    }
    .product-palette__details-group-shades {
      display: table;
      @include swap_direction(margin, 0 auto);
    }
  }
  &__actions {
    text-align: $rdirection;
    @include breakpoint($small-down) {
      @include swap_direction(padding, 20px 0 30px 0);
      text-align: center;
    }
    .js-palette-mobile-container & {
      @include breakpoint($small-down) {
        width: 100%;
        @include swap_direction(padding, 17px 20px 17px 20px);
      }
    }
    .product__info--palette {
      @include breakpoint($small-down) {
        display: none;
      }
      @include swap_direction(margin, 0 0 20px 0);
    }
  }
}
