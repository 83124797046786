.bundle-hero {
  $c: &;
  $card-right: #{$c}--style--card_right;
  $card-left: #{$c}--style--card_left;
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    flex-direction: row;
  }
  &__card {
    order: 1;
    max-width: 350px;
    padding: 15px;
    @include breakpoint($medium-up) {
      padding: 0px;
      padding-right: 10px;
      width: 34%;
      order: 0;
    }
    #{$card-right} & {
      @include breakpoint($medium-up) {
        order: 1;
        padding-right: 0px;
        padding-left: 10px;
      }
    }
    &__title {
      text-transform: uppercase;
      letter-spacing: 0px;
      font-family: $lato-black-bold;
      margin-bottom: 10px;
      &,
      & p {
        font-size: 36px;
        line-height: 34px;
        @include breakpoint($medium-up) {
          font-size: 54px;
          line-height: 65px;
        }
      }
    }
    &__text {
      letter-spacing: 0px;
      font-size: 14px;
      line-height: 23px;
      letter-spacing: 0.95px;
      font-family: $roboto-text;
      @include breakpoint($medium-up) {
        font-size: 15px;
        line-height: 24px;
      }
      .subheading {
        font-family: $brandon-text-regular;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.86px;
        margin-bottom: 10px;
        @include breakpoint($medium-up) {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
    .button {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0px;
      padding: 18px 22px;
    }
    &__cta-row {
      display: flex;
      &--count--1 {
        .cta-wrapper {
          width: calc(100% - 10px);
        }
        .button {
          width: 100%;
        }
      }
    }
  }
  .product__favorite {
    .icon {
      vertical-align: top;
    }
    @include breakpoint($medium-down) {
      display: none;
      background-color: transparent;
      &__text {
        display: none;
      }
      .icon {
        color: $color-black;
        &:before {
          font-size: 30px;
        }
      }
    }
  }
  &__gallery {
    position: relative;
    max-width: 773px;
    order: 0;
    @include breakpoint($medium-up) {
      width: calc(66% - 40px);
      order: 1;
    }
    #{$card-right} & {
      @include breakpoint($medium-up) {
        order: 0;
      }
    }
    .product-full__image__main {
      white-space: nowrap;
      overflow: hidden;
      & > img {
        display: inline;
        white-space: normal;
      }
      &.slick-initialized {
        white-space: normal;
        & > img {
          display: block;
        }
      }
    }
    .slick-slider {
      margin-bottom: 0px;
    }
    // note color choosing keys off of --gallery-dots-color
    .slick-dots {
      padding: 0px;
      bottom: 15px;
      @include breakpoint($medium-up) {
        display: none !important;
      }
      button:before {
        font-size: 30px;
      }
      li {
        margin: 0px 2px;
      }
      li,
      button {
        width: 15px;
        height: 15px;
      }
    }
    // right now this is unused. but keeping it around.
    // these turn the dots into flat refactangles.
    .slick-dots--squares {
      bottom: 10px;
      text-align: left;
      margin-left: 10px;
      @include breakpoint($medium-up) {
        display: none !important;
      }
      li {
        background-color: $color-white;
        opacity: 0.5;
        height: 5px;
        width: 30px;
        &.slick-active {
          opacity: 1;
        }
        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  // white dots
  &__gallery,
  &--gallery-dots-color--white &__gallery {
    .slick-dots {
      button:before {
        color: $color-white;
        opacity: 0.6;
      }
      li.slick-active {
        button:before {
          opacity: 1;
          color: $color-white;
        }
      }
    }
  }
  // black dots
  &--gallery-dots-color--black &__gallery {
    .slick-dots {
      button:before {
        color: $color-black;
        opacity: 0.4;
      }
      li.slick-active {
        button:before {
          opacity: 1;
          color: $color-black;
        }
      }
    }
  }
  .product-full__image__thumbs {
    $thumbs-container: &;
    $thumb-size: 80px;
    position: absolute;
    top: 30px;
    right: 0px;
    display: none;
    width: $thumb-size;
    transform: translate(50%, 0%);
    @at-root #{$card-right}#{&} {
      right: auto;
      left: 0px;
      transform: translate(-50%, 0%);
    }
    @include breakpoint($medium-up) {
      display: block;
    }
    .slick-list {
      overflow: visible;
    }
    .product-full__image__thumb {
      position: relative;
      margin-bottom: 10px;
      filter: grayscale(100%);
      // add a delay so each thumb comes in one by one
      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: -20px;
        height: 100%;
        width: 5px;
        background-color: $color-black;
        display: none;
        @at-root #{$card-right}#{&} {
          right: auto;
          left: -20px;
        }
      }
      &.thumb-active {
        filter: none; // turn of grayscale
        &:before {
          display: block;
        }
      }
    }

    // initial state
    // due to how slick loads slides. we have to set initial state
    // on .slick-loading as well. or else when slick re-attaches dom the
    // css *starts* on the active state and does not transition from anything
    &.slick-initialized.slick-loading,
    & {
      .product-full__image__thumb {
        visibility: hidden;
        opacity: 0;
        transform: translate(100px, 0);
        transition: visibility 0s, opacity 2s, transform 2s;
        @at-root #{$card-right}#{&} {
          transform: translate(-100px, 0);
        }
      }
    }

    //active
    &.slick-initialized {
      visibility: visible;
      .product-full__image__thumb {
        transform: translate(0, 0);
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
