@import 'search_vars';

.search-form {
  @include swap_direction(padding, 10px 20px 0 20px);
  background: $color-light-brown;
  background: rgba(240, 240, 240, 0.95);
  @include breakpoint($medium-up) {
    background: $color-white;
    background: rgba(255, 255, 255, 0.95);
    @include swap_direction(padding, 25px 43px 25px 25px);
    width: 744px;
  }
  &__fields {
    @include clearfix;
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
    font-family: $body-font-bold;
    letter-spacing: 0.24em;
  }
  input[type='text'].search-form__field {
    border: 0;
    border-bottom: 1px solid $color-black;
    width: 100%;
    background: transparent;
    height: auto;
    @include swap_direction(padding, 0);
    text-transform: uppercase;
    line-height: 35px;
    letter-spacing: 1.5px;
    &:focus {
      border-color: $color-black;
    }
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      width: $pc-search-width;
      @include swap_direction(padding, 0 7px);
      float: $ldirection;
      height: 35px;
      &:focus {
        border-color: $color-black;
      }
    }
  }
  input[type='submit'].search-form__submit {
    display: none;
    @include breakpoint($medium-up) {
      height: 35px;
      line-height: 35px;
      display: block;
      width: 117px;
      @include swap_direction(margin, 0 0 0 5px);
      float: $ldirection;
      @if $cr22 == true {
        @include cta-primary();
        padding: 0 11px;
      }
    }
  }
  &__results {
    @include breakpoint($medium-up) {
      width: $pc-search-width; // width of text field
    }
  }
  .typeahead-wrapper {
    text-align: $ldirection;
  }
  .popular-search-wrapper {
    background-color: $color-gray-alt;
  }
  .product-results {
    max-height: 60vh;
    overflow: auto;
  }
  .search-suggestions__link {
    @include h8;
    font-size: 14px;
    display: block;
    // height: 35px;
    line-height: 35px;
    // border-bottom: solid 1px $color-black;
    @include swap_direction(padding, 0 7px 0 0);
    @include breakpoint($medium-up) {
      // font-size: 12px;
      @include swap_direction(padding, 0 7px);
      // border-bottom-color: $color-gray-alt;
    }
  }
  // .search-suggestions__item:last-child .search-suggestions__link {
  //   border-bottom: none;
  //   @include breakpoint($medium-up) {
  //     border-bottom: solid 1px $color-gray-alt;
  //   }
  // }
  .highlighted {
    color: $color-pink;
  }
  .content-results {
    .product_result_header {
      text-transform: capitalize;
      @include breakpoint($medium-up) {
        text-transform: uppercase;
      }
    }
  }
  .terms-suggestions-title {
    display: none;
  }
  .result {
    @include clearfix;
    // display: none;
    @include swap_direction(padding, 8px 0);
    border-bottom: solid 1px $color-gray-alt;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 15px 0);
      // display: block;
    }
    a,
    a:active {
      text-decoration: none;
      color: $color-pink;
    }
  }
  .product {
    &__image {
      width: 80px;
    }
    &__image-wrapper,
    &__abstract {
      display: block;
      float: $ldirection;
    }
    &__image-wrapper {
      margin-#{$rdirection}: 15px;
    }
    &__abstract {
      @include h9;
      width: 289px;
    }
    &__headline {
      @include h15;
      margin-bottom: 10px;
      a {
        color: $base-font-color;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    &__description {
      margin-bottom: 6px;
      line-height: normal; // JGA - not crazy about having to do this
      p {
        margin-bottom: 8px; // overrides default typography
        line-height: normal; // JGA - not crazy about having to do this
      }
    }
    &__price {
      @include h15;
      float: $rdirection;
      text-align: $rdirection;
      width: 70px;
      font-weight: bold;
    }
    // @TODO: re-enable this once the rating stars are working
    &__rating {
      display: none;
    }
  }
  .search-related-stories {
    margin-top: 15px;
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .search-related-story {
    @include clearfix;
    color: $color-white;
    background-color: $color-black;
    height: 80px;
    a {
      color: $color-white;
    }
    &__image-wrapper {
      float: $ldirection;
      margin-#{$rdirection}: 15px;
    }
    &__abstract {
      @include swap_direction(padding, 10px 20px 0 0);
    }
    &__headline {
      @include h15;
      margin-bottom: 10px;
    }
    &__description {
      @include h9;
      margin-bottom: 6px;
    }
  }
  &--results {
    @include swap_direction(margin, 0 auto);
    position: relative;
    // background-color: $color-white;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($medium-up) {
      min-height: auto;
    }
    .search-form__results-count {
      @include h9;
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      #{$rdirection}: 169px;
      // background-color: $color-white;
      height: 35px;
      line-height: 35px;
      @include swap_direction(padding, 0 12px);
      @if $cr22 == true {
        @include typography-link-text();
        color: $color-core-black;
      }
    }
  }
}

// Search results page:
.search-results {
  @include swap_direction(padding, 0);
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 0 20px);
  }
}

.search-results-spacer {
  height: 100px;
}

.search-results__more-link-wrapper {
  text-align: center;
  padding-bottom: 33px;
}

.search-results__header--mobile {
  text-align: center;
  @if $cr22 == true {
    @include typography-link-text();
    height: auto;
  } @else {
    background: $color-light-brown;
    height: $mobile_search_header_height;
    line-height: $mobile_search_header_height;
  }
}

.search-results__header--desktop {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

@include breakpoint($medium-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// endeca body class
body.section-esearch {
  // Always hide the search dropdown on the non-mobile dedicated search page,
  // reuse the static field instead.
  @include breakpoint($medium-up) {
    .site-utils__dropdown {
      display: none !important;
    }
  }
}

.endeca-product-result {
  .product__button--add-to-bag {
    display: none;
  }
  // &--1 means shaded, &--0 means un-shaded
  &--0 {
    @include breakpoint($small-down) {
      .product__button--add-to-bag {
        display: inline-block;
      }
      .product__button--quickshop {
        display: none;
      }
    }
  }
}
