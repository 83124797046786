// Skin Foundation Weightless
.skin__foundation__weightless {
  margin: 0 auto 40px;
  .skin__foundation {
    &__slide__container {
      .slick-slider {
        @include breakpoint($small-down) {
          margin-bottom: 20px;
        }
      }
    }
    &__dropdown {
      &__container {
        border-bottom: none;
        @include breakpoint($large-up) {
          padding: 44px 10% 0px 13%;
        }
        @include breakpoint($medium-down) {
          padding: 44px 5% 0 5%;
        }
        @include breakpoint($small-down) {
          padding: 22px 3.3% 4px 3.3%;
        }
      }
      &__header {
        font-family: $lato-black-bold;
        font-size: 40px;
        @include breakpoint($medium-up) {
          margin-bottom: 3px;
        }
        @include breakpoint($small-down) {
          font-size: 22px;
        }
        @include breakpoint($small-screen) {
          font-size: 20px;
        }
      }
      &__subheader {
        font-size: 19px;
        @include breakpoint($medium-up) {
          font-family: $brandon-text-medium;
          width: 67%;
          margin: 0 auto;
        }
        @include breakpoint($small-down) {
          font-size: 12px;
          margin-top: 4px;
        }
        @include breakpoint($small-screen) {
          font-size: 10px;
          margin-top: 4px;
        }
      }
    }
    &__finder__container {
      display: block;
      @include breakpoint($medium-up) {
        margin: 28px 0 -13px 0;
      }
    }
    &__finishes {
      display: block;
    }
    &__finish {
      @include breakpoint($medium-up) {
        width: 31%;
        margin-left: 10px;
        &:last-child {
          margin-right: 10px;
        }
      }
      @include breakpoint($medium-down) {
        width: 30%;
      }
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    &__models__container {
      display: block;
      // slide down
      @include breakpoint($medium-up) {
        margin-bottom: 17px;
      }
    }
    &__model {
      &__border {
        margin-bottom: 7px;
      }
      &__shade {
        margin-top: 10px;
        @include breakpoint($small-down) {
          padding: 10px 0 30px 0;
        }
      }
      &__shadename {
        @include breakpoint($small-down) {
          font-size: 14px;
        }
      }
      &__id {
        height: auto !important;
        cursor: pointer;
        @include breakpoint($small-down) {
          opacity: 0.6;
          &.slick-center {
            opacity: 1;
          }
        }
        @include breakpoint($small-down) {
          margin-left: 0;
          padding: 0 5px;
          &:nth-child(2n + 1) {
            margin-right: 0;
          }
          img.skin__foundation__model__image {
            margin: 0 auto;
            width: 100%;
            height: auto;
          }
        }
      }
    }
    &__shade__container {
      border-bottom: 1px solid $color-black;
      background: url(/media/export/cms/skin_foundation/bg_result_arrow.gif) no-repeat top center;
      @include breakpoint($medium-up) {
        padding: 30px 0 45px 8px;
      }
      padding: 30px 0 0 0;
      .quickshop--mobile {
        padding: 20px;
      }
      .quickshop-wrapper {
        .shade-picker__shade:hover {
          border: 1px solid $color-black;
          box-shadow: 0 0 1px 1px $color-white;
        }
        margin: 0;
        padding: 0;
        position: relative;
        overflow: visible !important;
        &.active::before {
          display: block;
          margin-top: -13px;
          @include breakpoint($medium-up) {
            margin-top: 7px;
          }
        }
        .quickshop__container {
          padding: 0;
          margin: 0;
        }
      }
      .shade-picker__shade {
        height: 41px;
        width: 41px;
      }
      .skin__foundation__product__header {
        font-family: $brandon-text-bold;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 22px;
        letter-spacing: 0.4px;
        @include breakpoint($small-down) {
          font-size: 18px;
        }
      }
      .skin__foundation__product__subheader {
        font-family: $brandon-text;
        font-size: 20px;
        text-align: center;
        margin-top: 4px;
        @include breakpoint($small-down) {
          font-size: 18px;
        }
      }
      .product-thumb {
        .product__button--add-to-bag {
          font-size: 14px;
          height: auto;
          line-height: normal;
          width: auto;
          border: 2px solid $color-pink;
          padding: 8px 5px;
          @include breakpoint($medium-up) {
            border: none;
            border-bottom: 2px solid $color-pink;
            padding: 0;
          }
        }
      }
      .product-thumb {
        padding: 0 20px;
      }
      .product-grid {
        padding: 20px 0 0 0;
      }
      .product-grid__item {
        margin: 0;
        @include breakpoint($small-down) {
          margin-bottom: 40px;
          height: auto !important;
        }
      }
    }
  }
}
