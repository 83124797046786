.customer-service {
  @include clearfix;
  position: relative;
  max-width: 1024px;
  @include swap_direction(margin, 3em auto);
  @include swap_direction(padding, 0);
  @include breakpoint($small-down) {
    max-width: none;
    width: 100%;
    @include swap_direction(margin, 0);
  }
  /* Hide the menu if on mobile, and not the landing page. */
  &.customer-service-orders,
  &.customer-service-returns,
  &.customer-service-shipping,
  &.customer-service-new-to-bobbi,
  &.customer-service-account,
  &.customer-service-corporate,
  &.customer-service-faqs,
  &.customer-service-contact-us {
    .customer-service-menu {
      @include breakpoint($small-down) {
        display: none;
      }
    }
  }
}
