// Common styles used by all icons in HTML and CSS
@mixin font-icon-base() {
  font-family: 'icons';
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Replace text with a font icon
@mixin font-icon-replace($pseudo) {
  position: relative;
  right: 9999px;
  &:#{$pseudo} {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    right: -9999px;
  }
}

// Mixins for Scss
// icon, after/before, mq, text-replace, size, color, vertical-align
@mixin icon(
  $icon: $icon,
  $pseudo: before,
  $mq: false,
  $text-replace: false,
  $size: inherit,
  $color: inherit,
  $align: middle
) {
  &:#{$pseudo} {
    @include font-icon-base();
    content: map-get($icons, $icon);
    font-size: $size;
    color: $color;
    vertical-align: $align;
    @content; // use a content block for all other params, and maybe even the size, color and alignment properties
    // already supported in the arguments list, so that invocations of this mixin don't become unwieldy
  }
  // Replace text with icon, like classic sprites
  @if $text-replace {
    @include font-icon-replace($pseudo);
  }
}
