.tiny-waitlist-overlay {
  &.bis-signup {
    &.bis-mobile {
      .email_input {
        display: inline-block;
        @if $cr22 == true {
          margin-bottom: 70px;
        }
        input[type='text'] {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .waitlist_header {
        text-transform: none;
        @include swap_direction(margin, 10px 0px);
        font-size: 18px;
        line-height: 25px;
        display: block;
        font-weight: bold;
      }
      p.waitlist_message {
        font-size: 16px;
        margin-bottom: 20px;
        padding-top: 10px;
        .back_to_shop {
          display: block;
          padding-top: 10px;
        }
      }
      .field {
        .align-r {
          @if $cr22 == true {
            position: relative;
          }
          input[type='submit'] {
            margin-bottom: 3px;
            color: #ffffff;
            @if $cr22 == true {
              background: $color-core-black;
              height: 70px;
              width: 100%;
            } @else {
              background: $color-pink;
              height: 32px;
            }
          }
        }
      }
    }
    .email_label {
      margin-bottom: 5px;
    }
    .email_input {
      input[type='text'] {
        width: 100%;
        margin-bottom: 37px;
      }
    }
    .waitlist_header {
      @include swap_direction(margin, 10px 0px);
      font-size: 38px;
      line-height: 40px;
      text-transform: uppercase;
      display: block;
    }
    h2 {
      @include swap_direction(margin, 0 0 15px 0);
      text-align: $ldirection;
    }
    p.waitlist_message {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .error_messages.error li {
      margin-#{$ldirection}: 0px;
    }
    .field {
      border-top: 1px dotted gray;
      padding-top: 10px;
    }
    .align-r > input {
      width: 100%;
    }
  }
}

.spp-mobile-page {
  .mobile-notify-me {
    height: 280px;
    display: block;
  }
}

.product__notify-me {
  display: inline-block;
}

@if $cr22 == true {
  .waitlist-overlay {
    &#cboxOverlay {
      background: $color-gray-op20;
    }
    #cboxClose {
      #{$rdirection}: 22px;
      top: 22px;
      color: $color-core-black;
      font-size: 12px;
      height: 12px;
      width: 12px;
      @include breakpoint($portrait-up) {
        #{$rdirection}: 42px;
        top: 42px;
      }
    }
  }
  .tiny-waitlist-overlay {
    &.bis-signup {
      padding: 0;
      @include breakpoint($portrait-up) {
        padding: 0 15px;
      }
      .email_input {
        width: 100%;
        input[type='text'] {
          border: 0;
          border-bottom: 1px solid $color-core-black;
          color: $color-core-black;
          padding: 4px 0;
          margin-bottom: 70px;
          @include input-placeholder {
            opacity: 0;
          }
        }
      }
      .waitlist_header {
        @include typography-headline--m;
        line-height: 1.2;
        font-size: 24px;
        font-weight: normal;
        margin: 0;
        padding-top: 95px;
        text-transform: none;
      }
      p.waitlist_message {
        @include typography-label-text;
        color: $color-gray-on-light;
        margin: 40px 0 0;
        padding: 0;
      }
      .error_messages.error li {
        @include typography-label-text;
      }
      .field {
        border: 0;
        margin: 0;
        padding: 0;
        .align-r {
          position: fixed;
          bottom: 0;
          .email_promotions {
            input[type=checkbox] {
              position: relative;
              #{$ldirection}: auto;
            }
            &__label {
              position: relative;
            }
          }
          input[type='submit'] {
            @include cta-primary();
            background: $color-core-black;
            height: 70px;
            margin-top: 20px;
            padding: 20px 50px;
            width: auto;
            &:hover {
              background: $color-core-black;
            }
          }
        }
      }
    }
  }
}
