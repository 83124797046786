// @TODO: merge this with base/_generic.scss
.featured-products-carousel {
  @include swap_direction(margin, 0 0 40px 0);
  .featured-products-carousel__subhead {
    @include swap_direction(margin, 0 auto 4px auto);
    display: block;
    width: 82px;
    height: 12px;
    background: image-url('global/full-stars@2x.png');
    background-size: cover;
    // IE8 does not support background-size
    .lt-ie9 & {
      background: image-url('global/full-stars.png') no-repeat;
      background-size: 82px 12px;
    }
  }
  .featured-products-carousel__container {
    @include swap_direction(margin, 0);
  }
  // Override Slick
  .slick-next,
  .slick-prev {
    top: 24%;
    @include breakpoint($medium-up) {
      top: 30%;
    }
  }
  .wrap {
    padding-bottom: 25px;
    border-bottom: 1px solid $color-light-gray;
  }
  &__subtext {
    text-align: center;
    font-size: 18px;
    margin-bottom: 25px;
  }
  &__cta {
    text-align: center;
    margin-top: 0px;
  }
}
