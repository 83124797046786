/* How To */
.how_to {
  &_cta-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    @include swap_direction(padding, 40px 0);
    background-color: $color-black;
    @include breakpoint($small-down) {
      position: relative;
      @include swap_direction(padding, 90px 0 18px);
    }
  }
  &_header-content {
    max-width: 965px;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($medium-down) {
      width: 96%;
    }
    @include breakpoint($small-down) {
      width: 92%;
    }
  }
  &_image-block {
    width: 50%;
    height: 633px;
    margin-#{$rdirection}: 27px;
    overflow: hidden;
    float: $ldirection;
    @include breakpoint($medium-down) {
      height: auto;
    }
    @include breakpoint($small-down) {
      width: 100%;
      height: auto;
      margin-#{$rdirection}: 0;
    }
    img {
      width: 100%;
    }
  }
  &_hero {
    background-color: $color-black;
    @include swap_direction(padding, 24px 0 68px);
    &-content {
      color: $color-white;
      text-align: center;
    }
  }
  &_hed p {
    font-family: 'MillerBanner Light';
    font-size: 60px;
    line-height: 1em;
    @include swap_direction(margin, 0 0 12px 0);
    text-transform: uppercase;
  }
  &_dek p {
    text-transform: uppercase;
    font-size: 20px;
  }
  &_content {
    width: 47%;
    float: $ldirection;
    text-align: center;
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint($medium-down) {
      width: 46%;
    }
    @include breakpoint($small-down) {
      width: 96%;
      position: absolute;
      top: 9px;
    }
    @include breakpoint($tablet) {
      height: 480px;
    }
    @include breakpoint($desktop) {
      height: 633px;
    }
    h1 {
      font-family: 'MillerBanner Light';
    }
    h2 {
      text-transform: uppercase;
      font-size: 23px;
      @include swap_direction(margin, 24px 0);
      @include breakpoint($small-down) {
        font-size: 12px;
        margin: 3px 0;
      }
    }
    p {
      font-size: 23px;
      line-height: 28px;
      @include swap_direction(margin, 16px 0);
      @include breakpoint($small-down) {
        display: none;
      }
    }
  }
  &_head-img {
    width: 258px;
    margin: 0 auto 18px;
    @include breakpoint($small-down) {
      width: 140px;
      margin: 0 auto 10px;
    }
    img {
      width: 100%;
    }
  }
  &_underline {
    width: 415px;
    @include swap_direction(margin, 0 auto);
    border-top: 1px solid $color-white;
    @include breakpoint($medium-down) {
      width: 310px;
    }
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &_slogan-img {
    width: 360px;
    @include swap_direction(margin, 113px auto 0);
    @include breakpoint($medium-down) {
      width: 328px;
    }
    @include breakpoint($small-down) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  &_video-carousel {
    .video-carousel__block__header {
      font-family: 'Lato Black';
      color: $color-black;
      background-color: $color-white;
    }
    .video-carousel__block {
      background-color: $color-white;
      padding-top: 19px;
    }
    .video-carousel__block .video-block__media--title {
      color: $color-black;
    }
    .video-carousel__block .video__image__thumb {
      background-size: cover;
    }
    .video-carousel__block .slick-dots .slick-active {
      opacity: 0.5;
    }
    .video-carousel__block .video-carousel__menu .selectbox {
      color: $color-black;
      border: 1px solid $color-black;
    }
  }
}

.device-mobile .how_to_video-carousel .video-carousel__block {
  min-height: 360px;
  & .video-carousel__related_products__button {
    display: none;
  }
}

.howto {
  .bobbis-lessons {
    @include swap_direction(margin, 0);
    &__slide {
      width: 100%;
      height: 410px;
      @include swap_direction(margin, 50px 0);
      float: $ldirection;
      @include breakpoint($small-down) {
        height: auto;
        @include swap_direction(margin, 0);
        background-color: $color-black;
      }
    }
    &__content {
      max-width: 750px;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 auto);
      @include breakpoint($small-down) {
        @include swap_direction(margin, 0 auto);
        width: 92%;
      }
    }
    &__preview {
      display: none;
    }
  }
}

.how_to-lesson__step {
  border-top: 1px solid $color-black;
  position: relative;
  max-width: 1920px;
  @include swap_direction(margin, 0 auto);
  @include breakpoint($medium-down) {
    width: 96%;
    margin-bottom: 8px;
  }
  &:nth-child(3) {
    @include breakpoint($small-down) {
      border-top: none;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  &-hed {
    font-family: 'MillerBanner Light';
    font-size: 26px;
    text-transform: uppercase;
  }
  .icon-arrow {
    @include icon('arrow_down');
    @include transition(all 0.3s);
    position: absolute;
    #{$rdirection}: 5px;
    color: $color-gray-dark;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &-header--desktop {
    display: none;
  }
  &-header {
    text-transform: uppercase;
    @include swap_direction(padding, 5px 0);
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  &-info {
    @include breakpoint($small-down) {
      width: 75%;
      @include swap_direction(margin, 0 auto);
      position: relative;
      padding-top: 16px;
    }
    &.active {
      display: table;
      width: 100%;
    }
    &__row {
      display: table-row;
    }
    &__cell {
      &--image {
        width: 106px;
        padding-bottom: 10px;
        padding-#{$rdirection}: 10px;
        @include breakpoint($small-down) {
          width: 100%;
          padding: 0;
        }
      }
      &--info {
        padding-#{$ldirection}: 0;
        padding-bottom: 50px;
        @include breakpoint($small-down) {
          margin-top: 15px;
          padding-bottom: 20px;
        }
      }
    }
    @include breakpoint($tablet) {
      display: table;
      width: 100%;
      @include swap_direction(padding, 50px 0 40px);
      &__row {
        display: table-row;
      }
      &__cell {
        &--image {
          width: auto;
          padding-bottom: 0;
          margin-#{$rdirection}: 37px;
          float: $ldirection;
          @include breakpoint($medium-down) {
            max-width: 50%;
          }
        }
        &--info {
          bottom: 5px;
          float: $ldirection;
          width: 44%;
        }
      }
    }
  }
  &-number {
    color: $color-black;
    font-family: $brandon-text-medium;
    display: inline-block;
  }
  &-name {
    display: inline-block;
  }
  &-image {
    width: 96px;
    height: auto;
    @include breakpoint($small-down) {
      width: auto;
    }
    @include breakpoint($tablet) {
      width: 100%;
      float: none;
      margin: 0;
    }
  }
  &-description {
    font-size: 12px;
    margin-top: -3px;
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 0;
    }
  }
  &-tip {
    font-size: 10px;
    font-style: italic;
    @include swap_direction(margin, 15px 0 0 0);
    p {
      @include swap_direction(margin, 0);
    }
    strong,
    b {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      font-style: normal;
    }
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 0;
      font-size: 16px;
    }
  }
  &-cta .button {
    @include opacity(0);
    border-width: 1px;
    font-size: 9px;
    color: $color-black;
    border: 1px solid $color-black;
    margin-top: 10px;
    @include transition(all 0.3s);
    @include breakpoint($small-down) {
      @include opacity(1);
    }
    @include breakpoint($tablet) {
      @include opacity(1);
      font-size: 14px;
      border: 1px solid $color-black;
      color: $color-black;
      @include swap_direction(padding, 7px 16px);
    }
    @include breakpoint($medium-up) {
      position: absolute;
      margin-top: 0;
      bottom: 10px;
    }
    .active-text {
      display: none;
    }
    &.active {
      .non-active-text {
        display: none;
      }
      .active-text {
        display: inline;
      }
    }
  }
  &.active .icon-arrow {
    @include transform(rotate(-180deg));
  }
  &.active .makeup-lesson__step-cta .button {
    @include opacity(1);
  }
  @include breakpoint($tablet) {
    &-header {
      display: none;
    }
    &-header--desktop {
      font-family: $brandon-text-medium;
      display: block;
      font-size: 24px;
      @include swap_direction(padding, 0);
      &:hover {
        cursor: auto;
      }
    }
    &-description p {
      font-size: 20px;
      @include swap_direction(margin, 0);
      line-height: 1.5em;
      position: relative;
      bottom: 5px;
    }
    &-cta {
      position: absolute;
      bottom: 39px;
      width: 270px;
    }
    &-info {
      display: block;
      position: relative;
    }
  }
  &-product-grid__wrapper {
    position: relative;
    .icon-close {
      position: absolute;
      #{$rdirection}: 0;
      top: 10px;
      font-size: 14px;
      @include breakpoint($tablet) {
        top: 20px;
        font-size: 24px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .product-brief__image {
      min-height: 320px;
      @include breakpoint($medium-up) {
        min-height: 290px;
      }
    }
    .product-full__review-snippet {
      min-height: 40px;
    }
  }
  &-product-grid {
    @include swap_direction(padding, 30px 0 0);
    border-top: 1px solid $color-gray-light-alt;
    @include breakpoint($tablet) {
      @include swap_direction(padding, 40px 0 0);
    }
    .product-grid__add_all_to_bag .product-thumb__cta {
      border-bottom-width: 1px;
      @include swap_direction(padding, 0.75em 1em);
    }
    .product-brief-shades {
      &__grid {
        max-width: 82%;
        @include breakpoint($landscape-up) {
          max-width: 270px;
        }
      }
    }
    .slick-next,
    .slick-prev {
      top: 49%;
    }
  }
}
