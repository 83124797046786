.skin-glow-collection {
  overflow: auto;
  h1 {
    font-family: 'MillerBanner Light';
    font-size: 60px;
    text-align: center;
    line-height: 0.8em;
    letter-spacing: 0px;
  }
  .product_collection_hero {
    background-position-x: 50%;
    &__body {
      width: 50%;
      margin-#{$ldirection}: 50%;
      top: 20%;
      position: relative;
      text-align: center;
    }
    &__subtitle {
      font-size: 22px;
      text-transform: uppercase;
      text-align: center;
    }
    &__html_block {
      font-size: 24px;
      line-height: 1.75em;
      max-width: 380px;
      @include swap_direction(padding, 0 20px);
      @include swap_direction(margin, 80px auto 0);
    }
    &__content-item {
      float: $ldirection;
      &:nth-child(1) {
        border-bottom: 1px solid $color-black;
        @include breakpoint($landscape-up) {
          width: $max-width;
        }
      }
      &:nth-child(n + 2):nth-child(-n + 3) {
        width: 50%;
      }
      &:nth-child(4) {
        width: 100%;
      }
    }
  }
  .product-collection-product {
    &__product {
      text-align: center;
    }
    &__callout {
      font-family: $brandon-text-light;
      font-size: 22px;
      text-transform: uppercase;
      line-height: 1em;
      max-width: 512px;
    }
    &__product-name {
      font-family: $brandon-text-bold;
      font-size: 22px;
      text-transform: uppercase;
      line-height: 1.25em;
      max-width: 512px;
      margin-bottom: 20px;
    }
    &__price {
      border-bottom: 1px solid $color-black;
      padding-bottom: 4px;
      &:before {
        content: ' ';
        display: block;
      }
    }
    &__description {
      font-size: 18px;
      line-height: 1.75em;
      max-width: 512px;
    }
    &__shop-details {
      text-align: center;
      max-width: 512px;
    }
  }
  .button--outline {
    border: 2px solid $color-black;
    color: $color-black;
    &:hover {
      border: 2px solid $color-pink;
      color: $color-pink;
    }
  }
  .callout-alt .button {
    border: 2px solid $color-black;
    background-color: $color-black;
    &:hover {
      border: 2px solid $color-pink;
      background-color: $color-pink;
    }
  }
  .block_cheek-glow-palette {
    position: relative;
    .product-collection-product {
      &__product {
        float: $rdirection;
        padding-top: 40px;
        padding-#{$ldirection}: 50%;
        width: 100%;
        height: 670px;
        position: relative;
        @include breakpoint($landscape-up) {
          height: 710px;
        }
        img {
          position: absolute;
          top: 120px;
          #{$ldirection}: 0;
          z-index: -1;
          @include breakpoint($landscape-up) {
            top: 30px;
          }
        }
      }
      &__shop-details {
        position: absolute;
        bottom: 36px;
        #{$rdirection}: 0;
        #{$ldirection}: 0;
        @include swap_direction(margin, 0 auto);
      }
    }
    .text-block_palette-components {
      text-transform: uppercase;
      position: absolute;
      #{$ldirection}: 24px;
      top: 200px;
      .cream {
        font-family: $brandon-text-bold;
        &:after {
          height: 20px;
          width: 66px;
          border-#{$ldirection}: 1px solid #000;
          border-bottom: 1px solid #000;
          position: absolute;
          content: '';
          bottom: 0;
          #{$ldirection}: 50px;
          @include breakpoint($landscape-up) {
            width: 100px;
          }
        }
      }
      .rouge {
        font-family: $brandon-text-bold;
        position: relative;
        top: 100px;
        &:after {
          height: 20px;
          width: 66px;
          border-#{$ldirection}: 1px solid #000;
          border-bottom: 1px solid #000;
          position: absolute;
          content: '';
          bottom: -20px;
          #{$ldirection}: 50px;
          @include breakpoint($landscape-up) {
            width: 100px;
          }
        }
      }
    }
    .text-block_palette-name {
      text-align: center;
      top: 490px;
      @include breakpoint($landscape-up) {
        top: 530px;
        font-size: 18px;
      }
    }
    .pink {
      position: absolute;
      #{$ldirection}: 23%;
    }
    .bronze {
      position: absolute;
      #{$ldirection}: 47%;
    }
    .bare {
      position: absolute;
      #{$ldirection}: 66%;
    }
    .pearl {
      position: absolute;
      #{$ldirection}: 83%;
    }
  }
  .block_extra-lip-tint {
    position: relative;
    .product-collection-product__product {
      @include swap_direction(padding, 350px 20px 0);
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 450px 20px 0);
      }
      img {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        z-index: -1;
      }
    }
    .product-collection-product__shadelist {
      width: 112px;
      height: 36px;
      @include swap_direction(margin, 0 auto);
      display: flex;
    }
    .product-collection-product__shadename {
      color: #fff;
      float: #{$ldirection};
      width: 36px;
      height: 36px;
      flex: 1;
    }
    .shade-picker__shade {
      height: 24px;
      width: 24px;
      @include swap_direction(margin, 0 auto);
    }
  }
  .block_intensive-skin-serum {
    border-#{$ldirection}: 1px solid $color-black;
    padding-bottom: 20px;
    .callout-alt {
      visibility: hidden;
    }
    .text-block_body {
      text-align: center;
      @include swap_direction(padding, 20px);
    }
    .product_description {
      font-size: 18px;
      line-height: 1.75em;
    }
    .serum-cta {
      text-align: center;
      @include swap_direction(margin, 20px);
    }
    img {
      margin-top: 3px;
    }
  }
  .block_moisture-balm {
    position: relative;
    border-top: 1px solid $color-black;
    .product-collection-product {
      padding-top: 10%;
      height: 472px;
      &__product-name {
        width: 512px;
        float: $rdirection;
        clear: $rdirection;
      }
      &__description {
        width: 512px;
        @include swap_direction(padding, 0 27px);
        float: $rdirection;
        clear: $rdirection;
      }
      &__shop-details {
        width: 512px;
        float: $rdirection;
        clear: $rdirection;
      }
      &__product img {
        min-width: 1024px;
        position: absolute;
        top: 0;
        #{$ldirection}: -100px;
        z-index: -1;
        @include breakpoint($landscape-up) {
          #{$ldirection}: 0;
        }
      }
    }
  }
}
