.offerspick {
  width: 100%;
  @include min-height(739px); //for page anchors to work
  &--selected {
    @include min-height(auto);
  }
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 20px 0);
    @include min-height(1287px); //for page anchors to work
    &--selected {
      @include min-height(auto);
    }
    &.offerspick__grid {
      @include swap_direction(padding, 0 0 20px 0);
      .offerspick__offer {
        width: 33.33%;
        border: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        @include swap_direction(margin, 0 0 50px 0);
        @include swap_direction(padding, 0);
        &__prod-header {
          @include min-height(385px);
          @include swap_direction(margin, 0 0 20px 0);
        }
        &__header {
          text-align: center;
        }
        &__details {
          text-align: center;
          @include swap_direction(margin, 0 10px 10px);
          @include min-height(100px);
        }
        &__price {
          display: inline-block;
        }
        &__product_size {
          display: inline-block;
        }
      }
      .offerspick__header {
        width: #{$max-width};
        @include swap_direction(margin, 30px auto);
        background: $color-gray-background;
        &__subtitle {
          display: inline-block;
          width: 70%;
        }
        &__choices {
          width: 29%;
          display: inline-block;
          text-align: #{$rdirection};
        }
      }
      .offerspick__footer {
        width: #{$max-width};
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 10px auto);
        a {
          float: #{$rdirection};
          width: auto;
        }
      }
    }
  }
  &__header {
    background: $color-gray-background;
    @include swap_direction(padding, 20px);
    &__inner {
      img {
        width: 100%;
      }
    }
    &__choices {
      @include swap_direction(margin, 10px 0 0 0);
      text-align: #{$rdirection};
      font-weight: bold;
      letter-spacing: 0.05em;
    }
  }
  &__footer {
    @include swap_direction(padding, 20px 6.25%);
    a {
      width: 100%;
      @include swap_direction(margin, 10px 0);
    }
  }
  &__offers {
    @include swap_direction(padding, 20px 6.25%);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 10px auto);
      width: #{$max-width};
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__offer {
    @include clearfix;
    @include swap_direction(margin, 12px 0 0 0);
    padding-bottom: 14px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    &__header {
      font-size: 16px;
      line-height: 1.3;
      letter-spacing: 0.06em;
      font-family: #{$brandon-text-bold};
      text-align: #{$ldirection};
      text-transform: uppercase;
      margin-bottom: 18px;
    }
    &__prod-header {
      flex: 1;
      @include breakpoint($medium-up) {
        flex: none;
      }
    }
    &__footer {
      flex: 1;
      @include breakpoint($medium-up) {
        flex: none;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    &.is_selected {
      border-bottom: 0;
      .offerspick__button--remove {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
    &.is_soldout {
      @include opacity(0.4);
      .offerspick__button--soldout {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
    &.not_selected {
      opacity: 0.4;
      pointer-events: none;
      .button--disabled {
        border: none;
      }
    }
  }
  &__button__container {
    text-align: #{$ldirection};
    @include swap_direction(margin, 10px 0);
    @include breakpoint($medium-up) {
      text-align: center;
      @include min-height(35px);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__button--remove {
    display: none;
  }
  &__button--soldout {
    @include button;
    display: none;
    border: 1px solid $color-black;
    color: $color-black;
    background: $color-white;
    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
      background: $color-white;
    }
  }
  &__shades {
    @include clearfix;
    margin-top: 26px;
  }
  &__shade {
    margin-bottom: 6px;
    &__name,
    &__price,
    &__unit_price {
      font-size: 16px;
      display: none;
    }
    &__unit_price {
      display: inherit;
    }
    &__name {
      @include swap_direction(margin, 10px 0);
    }
    &__image {
      @include breakpoint($medium-up) {
        display: inline;
        width: 100%;
        padding-bottom: 20px;
      }
    }
    &__dot {
      display: none;
      @include circle(20px, #999);
      @include swap_direction(margin, 0);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
      }
    }
  }
  &__tc {
    @include swap_direction(padding, 0 6.25%);
    @include breakpoint($medium-up) {
      text-align: center;
    }
  }
}
