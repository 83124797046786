$header-sticky-pc: 54px;
$header-sticky-mob: 22px;

// Formatter
.foundation-dramming {
  .menu {
    ul {
      width: 101%;
      background-color: $color-white;
    }
    li {
      width: 33%;
      background-color: $color-white;
      border: 1px solid $color-black;
    }
  }
  .block_container__title {
    .section_number {
      &:before {
        content: '';
        display: block;
        height: $header-sticky-mob; /* fixed header height */
        @include swap_direction(margin, -$header-sticky-mob 0 0); /* negative fixed header height */
        @include breakpoint($medium-up) {
          height: $header-sticky-pc; /* fixed header height */
          @include swap_direction(margin, -$header-sticky-pc 0 0); /* negative fixed header height */
        }
      }
    }
  }
  &.mobile {
    .product_collection_hero {
      @include swap_direction(margin, 0 auto);
      height: 179px;
      max-width: 100%;
      &__content-item {
        border-bottom: 0;
      }
      &__body {
        line-height: 1.75em;
        width: 52%;
        position: absolute;
        top: 20%;
        #{$ldirection}: 5%;
      }
      &__title {
        font-size: 20px;
        letter-spacing: 2px;
        @include swap_direction(margin, 0 auto 9px auto);
      }
      &__subtitle {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 1.125em;
      }
      &__content-item {
        position: relative;
      }
    }
    .product_intro {
      width: 50%;
      img {
        @include swap_direction(margin, 25% auto 0);
      }
    }
    .text-block_intro {
      @include swap_direction(padding, 9% 3%);
      .text-block_title {
        font-size: 9px;
        margin-bottom: 0;
      }
      .text-block_body {
        font-size: 9px;
        line-height: 1.25em;
      }
    }
    .block_container__title {
      font-size: 15px;
      letter-spacing: 2px;
      @include swap_direction(margin, 1px auto);
      border: 1px solid $color-black;
      @include swap_direction(padding, 9px 0);
      .section_number {
        font-family: $brandon-text-bold;
        font-size: 15px;
      }
    }
    .video-block {
      &__slide {
        border-bottom: 0;
        margin-bottom: 14px;
      }
      &__description {
        display: none;
      }
    }
    .how-to-swatch {
      .block_container__content {
        @include swap_direction(padding, 20px);
      }
      .product-image {
        &.product_step-2 {
          margin-bottom: 0;
        }
        img {
        }
        .product-image {
          &_title {
            font-size: 9px;
            @include swap_direction(margin, 0 0);
          }
          &_caption {
            font-size: 9px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .product_collection_hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    @include swap_direction(margin, 1px auto);
    height: 360px;
    max-width: 1024px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1,
    h2 {
      font-family: $miller-banner-light;
      font-size: 42px;
      letter-spacing: 0;
      line-height: 0.9em;
    }
    &__content-item {
      border-bottom: 1px solid #d0d0d0;
    }
    &__body {
      line-height: 2em;
      width: 52%;
    }
    &__subtitle {
      letter-spacing: 1px;
      line-height: 1.5em;
    }
    &__content-item {
      position: relative;
    }
  }
  .product_intro {
    width: 50%;
    img {
      display: block;
      @include swap_direction(margin, 5% auto);
      float: $ldirection;
    }
  }
  .text-block_intro {
    width: 45%;
    margin-#{$rdirection}: 5%;
    @include swap_direction(padding, 9% 3% 0);
    float: $rdirection;
    text-align: center;
    @include breakpoint($tablet) {
      @include swap_direction(padding, 6% 3%);
    }
    .text-block_title {
      font-family: $brandon-text-bold;
      font-size: 30px;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin-bottom: 9px;
    }
    .text-block_body {
      font-size: 18px;
      line-height: 1.5em;
    }
  }
  .text-block_disclaimer {
    font-size: 11px;
    line-height: 1.5em;
    @include swap_direction(margin, 18px 0);
    position: relative;
  }
  .block_container__title {
    font-family: $brandon-text-bold;
    font-size: 30px;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.5em;
    @include swap_direction(margin, 20px auto);
    .section_number {
      font-family: $brandon-text-medium;
      font-size: 40px;
    }
  }
  .video-block {
    &__slide {
      border-bottom: 1px solid $color-gray;
      margin-bottom: 40px;
    }
    &__media {
      width: 100%;
      position: relative;
      @include swap_direction(margin, 0 auto);
      @include breakpoint($medium-up) {
        max-width: 754px;
      }
    }
    &__media--video {
      position: absolute;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      @include swap_direction(margin, 0 auto);
      @include breakpoint($medium-up) {
        max-width: 754px;
        height: 409px;
      }
    }
    &__description {
      font-size: 16px;
      letter-spacing: 4px;
      text-align: center;
      max-width: 685px;
      @include swap_direction(margin, 42px auto);
    }
  }
  .how-to-swatch {
    .product-image {
      max-width: 754px;
      @include swap_direction(margin, 28px auto);
      overflow: auto;
      &.product_step-2 {
        margin-bottom: 40px;
      }
      img {
        width: 39%;
        float: $ldirection;
        margin-#{$rdirection}: 2%;
      }
      .product-image {
        &_title {
          font-size: 18px;
          @include swap_direction(margin, 48px 0 6px);
          float: $ldirection;
          width: 50%;
        }
        &_caption {
          font-size: 18px;
          float: $ldirection;
          width: 50%;
        }
      }
    }
  }
  .foundation-finder {
    .foundation-finder {
      &__header {
        height: 469px;
        overflow: visible;
      }
      &__image--desktop {
        display: block;
      }
      &__find-match {
        bottom: 30px;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
        text-align: center;
      }
    }
  }
}
