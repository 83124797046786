.spp {
  &.wrap {
    @include swap_direction(padding, 0);
    .product__social {
      a {
        float: $ldirection;
        position: relative;
      }
      .wechatWrapper {
        cursor: pointer;
        .qrWrapper {
          z-index: 999;
          position: absolute;
          .qrcodeTableHidden {
            position: absolute;
            @include swap_direction(padding, 20px);
            top: 28px;
            #{$ldirection}: 10px;
            z-index: 999;
            cursor: pointer;
            background-color: white;
            box-shadow: 0 0 3px grey;
            h3 {
              font-size: 15px;
              color: #333;
              text-align: center;
              width: 180px;
            }
          }
        }
      }
    }
  }
  .product--full {
    margin-bottom: 50px; // for OMNICCI-303 brand review comment margin bottom change from 80px to 50px
  }
  .spp-page__breadcrumb {
    padding-bottom: 6px;
    a {
      @include h9;
      color: $color-gray-dark;
      text-decoration: none;
      &:after {
        content: ' /';
      }
      &:last-child:after {
        content: '';
      }
    }
  }

  // SPP Desktop Styles
  @include breakpoint($tablet) {
    margin-top: 16px;
    &.wrap {
      @include swap_direction(padding, 0 20px);
    }
    .product-gallery {
      margin-top: 20px;
      padding-top: 22px;
      &__main {
        min-height: 415px;
      }
    }
    .product__sku-categories {
      @include swap_direction(padding, 10px 0);
    }
    .product__sku-categories-nav-item {
      text-transform: none;
      font-family: $brandon-text;
      font-size: 14px;
      margin-bottom: 0;
      @include swap_direction(padding, 12px);
      letter-spacing: 1px;
      margin-bottom: 15px;
      line-height: 1em;
    }
    .shade-picker__shades {
      @include swap_direction(padding, 0);
    }
  }
  .responsive-tabs--how_to_use-wrap {
    .resp-tab-item {
      letter-spacing: 4px;
      margin-bottom: 0;
      @include swap_direction(padding, 8px 20px);
    }
    .resp-tabs-list {
      margin-bottom: 15px;
    }
  }
  .responsive-tabs--how_to_use {
    text-align: center;
  }
  &__related-item {
    margin-bottom: 20px;
  }
  @include breakpoint($small-down) {
    .product--full {
      @include swap_direction(padding, 0 20px);
      margin-bottom: 0;
      .product__actions {
        @include swap_direction(padding, 17px 0);
      }
    }
    .responsive-tabs--how_to_use-wrap {
      margin-top: 0;
    }
    .product__details .resp-accordion {
      display: none;
    }
    .resp-accordion {
      position: relative;
    }
    .responsive-tabs--bazaarvoice {
      display: none !important; //there is an inline display:block on this element
    }
  }

  // Reuse the existing desktop block for share icons
  &-mobile-page {
    .spp__share {
      @include h8;
      text-transform: uppercase;
      border: none;
      @include swap_direction(padding, 10px 20px);
      position: relative;
      background: $color-white;
      .product__social {
        display: block;
        float: $rdirection;
        a {
          display: inline-block;
          margin-#{$rdirection}: 12px;
          font-size: 18px;
          color: $color-black;
          text-decoration: none;
          &:hover {
            color: $color-pink;
          }
        }
      } // end .product__social
    } // end .spp__share
  } // end &-mobile-page

  .product__title {
    h3,
    h2 {
      font-size: 18px;
    }
    .product__flag {
      display: none;
    }
  }
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-size;
      }
    }
  }
}
/* there is inline styles applied on these elements. So here used !important
#colorbox.addtocart-fail-msg {
  height: auto !important;
  position: fixed !important;
  #{$ldirection}: 50% !important;
  top: 50% !important;
  width: 300px !important;
  @include transform(translateX(-50%) translateY(-50%));
  #cboxWrapper {
    position: relative;
    height: auto !important;
    width: 300px !important;
    #cboxMiddleLeft,
    #cboxContent,
    #cboxLoadedContent {
      width: 250px !important;
      height: auto !important;
    }
    #cboxLoadedContent {
      #cboxClose {
        #{$rdirection}: 25px !important;
        top: 25px !important;
      }
    }
  }
} */

/* Styles for sticky header in SPP page */
.sticky-at-h3 {
  display: block;
  font-size: 16.38px;
  font-weight: bold;
}
