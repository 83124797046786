.contact-us {
  &__salesforce {
    .contact-form {
      &__section {
        padding: 0 20px;
        .form-item {
          label {
            display: none;
          }
          input[type="radio"] {
            & + label {
              display: inline;
            }
          }
        }
        .newsletter {
          float: #{$ldirection};
          width: 100%;
        }
        .form-submit {
          float: #{$ldirection};
          width: 97%;
          @include breakpoint($medium-up) {
            width: 47%;
          }
        }
        .error {
          & + input {
            border-color: $color-red;
          }
        }
        &-headline {
          @include swap_direction (margin, 20px 20px 20px 0);
          font-family: $brandon-text-bold;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 3px;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            border-bottom: 1px solid $color-gray-light-alt;
            margin-bottom: 0;
            padding-bottom: 20px;
          }
        }
        textarea {
          width: 97%;
          margin-bottom: 7px;
          padding: 5px 10px;
          @include breakpoint($medium-up) {
            width: 100%;
          }
        }
      }
      &__question {
        width: 100%;
        @include breakpoint($medium-up) {
          display: flex;
          margin-bottom: 2em;
          padding-top: 1em;
        }
        &-type {
          @include swap_direction (margin, 0 3% 3% 0);
          display: inline-block;
          width: 44%;
          height: 70px;
          padding: 14px 20px;
          font-family: $brandon-text-bold;
          letter-spacing: 3px;
          text-align: center;
          text-transform: uppercase;
          border: 1px solid $color-black;
          cursor: pointer;
          @include breakpoint($medium-up) {
            @include swap_direction (margin, 0 3% 0 0);
            width: 22%;
          }
          input[type="radio"] {
            display: none;
          }
          .text--checkbox-label {
            &:before,
            &:after {
              display: none;
            }
          }
          &.active-category {
            color: $color-white;
            background-color: $color-pink;
            border: none;
          }
        }
      }
      &__sub-question-type {
        margin: 1em 0;
      }
    }
    .error_messages {
      margin: 15px 0;
    }
    .error {
      color: $color-red;
    }
  }
}

.rtl {
  .contact-us {
    &__salesforce {
      .contact-form {
        &__section {
          .form-submit {
            float: #{$rdirection};
          }
          &-headline {
            margin: 20px 0 20px 20px;
          }
        }
        &__question-type {
          margin: 0 0 3% 3%;
          @include breakpoint($medium-up) {
            margin: 0 0 0 3%;
          }
        }
      }
    }
    &-page {
      input {
        margin-#{$rdirection}: 0;
      }
    }
  }
  .store-locator {
    .store-search-controls {
      .city,
      .country {
        @include breakpoint($medium-up) {
          text-align: #{$rdirection};
          float: #{$rdirection};
        }
      }
    }
  }
  .site-footer {
    .basic-responsive {
      .footer-column {
        @include breakpoint($medium-up) {
          #{$ldirection}: 15%;
          #{$rdirection}: auto;
        }
      }
      .copyright {
        @include breakpoint($medium-up) {
          text-align: #{$rdirection};
        }
      }
    }
    &__column {
      text-align: #{$rdirection};
      @include breakpoint($medium-up) {
        border-#{$ldirection}: 0;
      }
    }
    &_v2 {
      .selectBox {
        .selectBox-arrow {
          @include breakpoint($medium-up) {
            #{$rdirection}: 170px;
          }
          svg {
            &.country-dropdown-arrow {
              @include breakpoint($medium-up) {
                #{$rdirection}: auto;
                #{$ldirection}: 35px;
              }
            }
          }
        }
      }
    }
    .language-switcher {
      @include breakpoint($medium-up) {
        text-align: #{$rdirection};
      }
    }
    .site-footer-social-links {
      .icon {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 0;
          margin-#{$ldirection}: 15px;
        }
      }
    }
  }
  .site-header {
    .site-utils {
      &__item--email {
        @include breakpoint($medium-up) {
          margin-#{$rdirection}: 0;
        }
        .site-utils__dropdown--email {
          @include breakpoint($medium-up) {
            #{$rdirection}: 5px;
          }
          .site-utils__dropdown__arrow {
            @include breakpoint($medium-up) {
              #{$rdirection}: 37px;
            }
          }
        }
      }
    }
  }
}

.store-locator {
  .store-search-controls {
    .city,
    .country {
      @include breakpoint($medium-up) {
        @include swap_direction (margin, 0 10px 0 5px);
        text-align: #{$ldirection};
        display: inline-block;
        width: 220px;
        float: #{$ldirection};
        height: 32px;
        font-family: $brandon-text-medium;
        text-transform: uppercase;
        border: 1px solid $color-black;
        background-color: $color-white;
      }
    }
  }
  &--mobile {
    .search-form {
      .city {
        width: 80%;
        text-transform: uppercase;
      }
      .country {
        margin-bottom: 10px;
      }
    }
  }
}
