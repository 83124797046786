.terms-tooltip {
  bottom: 5px;
  cursor: pointer;
  position: relative;
  vertical-align: top;
  @include breakpoint($landscape-up) {
    bottom: 2px;
  }
  .icon--information {
    height: 30px;
    width: 25px;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    background-color: $color-white;
    border: 1px solid $color-gray-darker;
    font-family: $bbtext-regular;
    font-size: 14px;
    font-weight: normal;
    height: auto;
    letter-spacing: normal;
    line-height: 1.4;
    opacity: 0;
    padding: 10px;
    position: absolute;
    #{$ldirection}: -120px;
    text-align: $ldirection;
    text-transform: initial;
    visibility: hidden;
    width: 260px;
    z-index: 1;
  }
  .tooltiptext-under {
    top: 75%;
    @include breakpoint($landscape-up) {
      top: 115%;
    }
    &::after {
      border-color: transparent transparent $color-black;
      border-style: solid;
      border-width: 5px;
      bottom: 100%;
      content: '';
      #{$ldirection}: 45%;
      position: absolute;
    }
  }
  .tooltiptext-over {
    bottom: 140%;
    &::before {
      border-color: $color-black transparent transparent;
      border-style: solid;
      border-width: 5px;
      content: '';
      position: absolute;
      top: 100%;
      #{$ldirection}: 45%;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      opacity: 1;
      visibility: visible;
    }
  }
}
