@import 'search_vars';

.header-search {
  @include transition($ldirection 0.3s ease);
  position: fixed;
  width: 100%;
  top: 112px;
  height: 55px;
  @include swap_direction(padding, 0);
  #{$ldirection}: 0;
  z-index: 9991;
  @include breakpoint($medium-up) {
    position: static;
    width: auto;
    height: auto;
  }
  .js-nav-expanded & {
    #{$ldirection}: 260px;
  }
  &__close {
    background: transparent;
    position: absolute;
    z-index: 1;
    #{$rdirection}: 20px;
    top: 17px;
    text-decoration: none;
    .icon {
      color: $color-black;
      font-size: 20px;
    }
  }
}
