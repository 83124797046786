.loyalty__panel__offers {
  @include swap_direction(padding, 40px 0 24px);
  border-bottom: 1px solid $color-gray;
  &__list {
    &-container {
      .slick-dots {
        li {
          @include swap_direction(margin, 0);
          height: 17px;
          width: 17px;
          bottom: 35px;
          button {
            &:before {
              font-size: 36px;
            }
          }
        }
      }
      .slick-prev {
        @include breakpoint($medium-up) {
          #{$ldirection}: 10px;
          border-top-#{$rdirection}-radius: 100px;
          border-bottom-#{$rdirection}-radius: 100px;
          background: $color-gray-background;
        }
        #{$ldirection}: 3.5%;
        &:before {
          @include breakpoint($medium-up) {
            border-bottom-#{$ldirection}-radius: 0;
            border-top-#{$ldirection}-radius: 0;
            padding-#{$ldirection}: 0;
            #{$rdirection}: 9px;
          }
          border-bottom-#{$ldirection}-radius: 20px;
          border-top-#{$ldirection}-radius: 20px;
          #{$rdirection}: 2px;
          padding-#{$ldirection}: 6px;
        }
      }
      .slick-next {
        #{$rdirection}: 2%;
        @include breakpoint($medium-up) {
          #{$ldirection}: 97%;
          border-top-#{$ldirection}-radius: 100px;
          border-bottom-#{$ldirection}-radius: 100px;
          background: $color-gray-background;
        }
        &:before {
          @include breakpoint($medium-up) {
            border-bottom-#{$rdirection}-radius: 0;
            border-top-#{$rdirection}-radius: 0;
            padding-#{$rdirection}: 0;
            #{$ldirection}: 7px;
          }
          #{$rdirection}: auto;
          border-bottom-#{$rdirection}-radius: 20px;
          border-top-#{$rdirection}-radius: 20px;
          padding-#{$rdirection}: 6px;
        }
      }
      .slick-next,
      .slick-prev {
        @include breakpoint($medium-up) {
          opacity: 0.8;
          width: 18px;
          height: 30px;
          background: $color-gray-background;
        }
        top: 34%;
        &:before {
          @include breakpoint($medium-up) {
            font-size: 18px;
            position: relative;
            background: none;
          }
          font-size: 23px;
          background: $color-gray-background;
          padding-top: 6px;
          position: relative;
          width: 20px;
          height: 36px;
        }
        &:hover {
          @include breakpoint($medium-up) {
            background: $color-gray-background !important;
          }
        }
      }
    }
  }
  &__title {
    @include h8;
    font-size: 21px;
    line-height: 1;
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: normal;
  }
  &__subtitle {
    @include h7;
    line-height: 1.5;
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
  }
  &__select {
    @include h7;
    text-align: center;
    line-height: 1.5;
  }
  &__benefits {
    text-align: center;
    margin-top: 5px;
    &__link {
      text-decoration: underline;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  &__loader {
    display: none;
    &__img {
      display: inline-block;
    }
  }
  &__offer {
    @include swap_direction(padding, 10px);
    text-align: center;
    &-image-container {
      height: 320px;
      @include breakpoint($medium-up) {
        height: auto;
      }
      @if $lr2021 {
        img {
          display: inline;
        }
      }
    }
    &-header {
      @include h8;
      font-size: 15px;
      line-height: 1;
      margin-bottom: 10px;
      letter-spacing: normal;
      padding-top: 16px;
      min-height: 22px;
    }
    &-description {
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 1.15;
      font-family: $body-font;
      min-height: 55px;
    }
    &-valid {
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
  &__button {
    &-container {
      .button {
        &--disabled {
          background: $color-light-gray;
          cursor: not-allowed;
          pointer-events: none;
          &:hover {
            background: $color-light-gray;
          }
        }
      }
    }
  }
  &__button,
  &__selected,
  &__remove,
  &__redeemed {
    padding: 14px 42px;
    font-size: 15px;
    background: $color-black;
  }
  &__limits {
    @include breakpoint($medium-up) {
      border-top: 1px solid $color-gray;
      padding-top: 45px;
      margin-top: 30px;
    }
    margin-top: 18px;
    &__title {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
      }
      line-height: 0;
      padding-#{$ldirection}: 20px;
    }
    &__content {
      &-container {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
        }
        @include swap_direction(padding, 0 20px);
      }
    }
    &__terms-conditions {
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
      }
      @include swap_direction(padding, 0 20px);
      &__link {
        color: $color-gray-dark;
      }
    }
  }
}
