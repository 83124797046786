.product-grid__item--promo {
  padding-bottom: 55px;
  position: relative;
  @if $cr22 == false {
    .node,
    .content,
    .block {
      height: 100%;
    }
  }
}

.product-promo {
  @include swap_direction(padding, 20px);
  margin-bottom: 40px;
  text-align: center;
  @include breakpoint($medium-up) {
    height: 100%;
    margin-bottom: 0;
    @include swap_direction(margin, 0 auto);
    min-height: 420px;
    padding-bottom: 20px;
  }
  &__wrapper-link {
    height: 100%;
    display: table;
    color: $color-black;
    text-decoration: none;
    width: 100%;
    @include breakpoint($medium-up) {
      display: block;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__wrapper {
    @include swap_direction(margin, 0 auto);
    max-width: 420px;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0);
      max-width: none;
    }
  }
  &__image {
    display: none;
    margin-bottom: 15px;
    vertical-align: bottom;
    width: 100%;
    @include breakpoint($medium-up) {
      margin-bottom: 7%;
    }
  }
  &__headline {
    font-size: 12px;
    font-family: $brandon-text-bold;
    letter-spacing: 0.24em;
    @include swap_direction(padding, 0 0 15px);
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 14px;
      padding-bottom: 50px;
    }
    &.product-promo__headline--hide {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__title {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 22px;
    margin-bottom: 15px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 22px;
      line-height: 27px;
    }
    p {
      line-height: inherit;
      @include swap_direction(margin, 0);
    }
    span {
      color: $color-pink;
      display: block;
      font-size: 12px;
      line-height: 17px;
      @include breakpoint($medium-up) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  &__copy {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 19px;
    }
    &.product-promo__copy--hide {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__link {
    @include breakpoint($medium-up) {
      bottom: 20px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      position: absolute;
      width: 100%;
    }
    span {
      @include l1;
      border-bottom: 2px solid $color-pink;
      color: $color-pink;
      font-size: 12px;
      line-height: 17px;
      @include breakpoint($medium-up) {
        font-size: 14px;
        line-height: 19px;
      }
      &:hover {
        border-color: $color-black;
        color: $color-black;
      }
    }
  }
  &__link--alt {
    span {
      border: 1px solid $color-pink;
      @include swap_direction(padding, 5px 10px);
      &:hover {
        border-color: $color-black;
      }
    }
  }
  &.product-promo--transparent {
    background-color: transparent;
  }
  &.product-promo--gray {
    background-color: #eff0f0;
  }
  &.product-promo--white {
    background-color: #fff;
  }
  &.product-promo--mobile-transparent {
    @include breakpoint($small-down) {
      background-color: transparent;
    }
  }
  &.product-promo--mobile-gray {
    @include breakpoint($small-down) {
      background-color: #eff0f0;
    }
  }
  &.product-promo--mobile-white {
    @include breakpoint($small-down) {
      background-color: #fff;
    }
  }
}

.product-promo--text {
  @include swap_direction(padding, 30px 20px);
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 20px);
  }
  .product-promo__content-middle {
    @include swap_direction(margin, 0 10%);
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 0);
    }
  }
  .product-promo__title {
    font-size: 22px;
    font-weight: normal;
    line-height: 27px;
    text-transform: none;
    @include breakpoint($medium-up) {
      font-size: 37px;
      line-height: 42px;
    }
  }
  .product-promo__copy {
    font-weight: bold;
    @include swap_direction(padding, 0 15px);
  }
}

.product-promo--top {
  .product-promo__image--top {
    display: block;
    @include swap_direction(margin, 0 auto 15px);
    max-width: 215px;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
      max-width: 230px;
    }
  }
  &.product-promo--has-mobile {
    .product-promo__image--top {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.product-promo--middle {
  .product-promo__image--middle {
    display: block;
    @include swap_direction(margin, 5px auto 15px);
    width: auto;
  }
  &.product-promo--has-mobile {
    .product-promo__image--middle {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.product-promo--bottom {
  padding-bottom: 0;
  @include breakpoint($medium-up) {
    position: relative;
  }
  .product-promo__image--bottom {
    display: block;
    @include breakpoint($medium-up) {
      bottom: 0;
      #{$ldirection}: 0;
      margin-bottom: 0;
      position: absolute;
    }
  }
  .product-promo__content-middle {
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0 20px);
    }
  }
  .product-promo__link {
    margin-bottom: 15px;
    position: static;
  }
  &.product-promo--has-mobile {
    .product-promo__image--bottom {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.product-promo--has-mobile {
  @include pie-clearfix;
  .product-promo__wrapper {
    background-repeat: no-repeat;
    background-size: 40% auto;
    display: table;
    min-height: 200px;
    width: 100%;
    @include breakpoint($medium-up) {
      display: block;
      background-image: none !important;
      @include swap_direction(padding, 20px 0 0);
    }
  }
  .product-promo__content-middle {
    display: table-cell;
    vertical-align: middle;
    @include breakpoint($medium-up) {
      display: block;
      @include swap_direction(padding, 0 20px);
    }
  }
}

.product-promo--mobile-left {
  .product-promo__wrapper {
    background-position: center $ldirection;
  }
  .product-promo__content-middle {
    padding-#{$ldirection}: 42%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
  }
}

.product-promo--mobile-right {
  .product-promo__wrapper {
    background-position: center $rdirection;
  }
  .product-promo__content-middle {
    padding-#{$rdirection}: 42%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
  }
}

.product-promo--mobile-bottom-left {
  .product-promo__wrapper {
    background-position: bottom $ldirection;
  }
  .product-promo__content-middle {
    padding-#{$ldirection}: 42%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
  }
}

.product-promo--mobile-bottom-right {
  .product-promo__wrapper {
    background-position: bottom $rdirection;
  }
  .product-promo__content-middle {
    padding-#{$rdirection}: 42%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
  }
}

.product-promo a.product-promo__wrapper {
  display: inline-block;
}
