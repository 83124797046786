.responsive-tabs--how_to_use-wrap {
  @include wrap;
  @include swap_direction(margin, 20px auto 0 auto);
  @include swap_direction(padding, 0);
  border-bottom: 1px solid $color-gray-light;
  @include breakpoint($tablet) {
    @include swap_direction(margin, 20px auto);
  }
  @include breakpoint($small-down) {
    margin-top: 0;
    z-index: 0;
  }
}

.how-to-use {
  &__content {
    @include breakpoint($medium-up) {
      text-align: center;
    }
    font-size: 16px;
    .disclaimer {
      font-style: italic;
      text-align: center;
      padding-top: 0.75em;
      display: none;
    }
  }
  &__price {
    margin-top: 10px;
  }
  &__size-text {
    display: inline;
    font-family: $brandon-text-bold;
    font-size: 12px;
    text-transform: uppercase;
  }
}
