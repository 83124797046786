.product_collection_hero-v3 {
  .menu {
    height: 46px;
    text-align: center;
    border-bottom: 1px solid #000000;
    ul.menu__list {
      display: inline-block;
    }
    li {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      @include swap_direction(padding, 13px 15px);
      display: inline-block;
      @include swap_direction(margin, 0);
      list-style: none;
      cursor: pointer;
      float: $ldirection;
      background-color: #eff0f0;
      border-#{$ldirection}: 1px solid #000000;
      &.active-trail {
        background-color: #ffffff;
        border-bottom: #ffffff;
        padding-bottom: 14px;
      }
      &.last {
        border-#{$rdirection}: 1px solid #000000;
      }
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
  .callout-alt {
    .button {
      background-color: $color-pink;
      color: $color-white;
      &:hover {
        background-color: $color-black;
      }
    }
    .button--disabled {
      background-color: $color-light-gray;
      border: 1px solid $color-light-gray;
    }
  }
  .product__inventory-status {
    font-family: $brandon-text;
    text-align: $ldirection;
    @include swap_direction(padding, 3px 0);
  }
  .quickshop .product-gallery {
    border-#{$rdirection}: none;
  }
  .quickshop__container {
    background-color: $color-white;
    @include swap_direction(margin, 0);
  }
  .quickshop-wrapper {
    position: fixed;
    z-index: 100;
    max-width: $max-width;
    max-height: 77%;
    overflow: auto !important;
    top: 12.5%;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    @include swap_direction(margin, 5% auto);
    &.active::before {
      display: none;
    }
    .wrap {
      @include swap_direction(padding, 0);
    }
    .shade-picker__shade {
      width: 39px;
      height: 39px;
    }
    .shade-picker__shade:hover {
      @include box-shadow($color-white 0 0 1px 1px);
    }
    .button {
      float: $ldirection;
    }
  }
  .product__promo,
  .product__full-details-link-wrapper {
    display: none;
  }
  .product--full .product__sku-categories {
    min-height: 0;
  }
}

.product_collection_hero_page {
  .quickshop__container {
    .product__promo {
      display: block;
    }
  }
  .product__actions {
    .product__full-details-link-wrapper {
      display: block;
    }
  }
  .quickshop-wrapper {
    .product__details {
      .button {
        float: none;
      }
    }
    a.shade-picker__shade {
      &:hover {
        box-shadow: 0 0 1px 1px $color-black;
      }
    }
  }
}

/** **/
