// RTL support

$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;
$arrow_rdirection: arrow_right !default;

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

@import 'style';

///
/// SMACSS/State
/// Helper classes that allow us to control dynamic states throughout css, like
/// on/off, active/disabled, alternate, etc
///
/// These are placed last so that they can override any prior styling
///
@import 'state/state';
