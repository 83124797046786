.gtl__steps {
  border-top: 1px solid;
  padding-#{$ldirection}: 9px;
  padding-#{$rdirection}: 9px;
  &__header {
    font-size: 24px;
    color: $color-black;
    position: relative;
    text-transform: uppercase;
    @include swap_direction(padding, 10px 0);
    letter-spacing: 6px;
    line-height: 30px;
    height: 56px;
    cursor: pointer;
    .icon {
      float: $rdirection;
      text-decoration: none;
      -ms-transform: rotate(270deg);
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    &.open .icon {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  &__number {
    float: $ldirection;
  }
  &__name {
    font-family: $brandon-text-bold;
    float: $ldirection;
    padding-#{$ldirection}: 12px;
  }
  &__body {
    font-size: 16px;
  }
  .product-grid-products {
    padding-bottom: 36px;
  }
  .product-grid {
    max-width: 955px;
  }
  .product-grid__item {
    height: 420px !important;
  }
}
