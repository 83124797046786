.sign-in {
  width: 374px;
  color: $color-black;
  a {
    color: $color-black;
  }
  &.is_signed_in {
    width: 216px;
  }
  &-page {
    .account__return-user {
      input[type='email'] {
        width: 100%;
      }
    }
  }
  .login {
    &__container {
      @include swap_direction(margin, 22px 28px 20px 24px);
    }
    &__header {
      @include h9;
      text-transform: none;
      margin-bottom: 18px;
    }
    &__signin_overlay_error {
      color: $color-red;
    }
    &__email {
      width: 100%;
      margin-bottom: 10px;
    }
    &__password {
      width: 100%;
      margin-bottom: 18px;
    }
    &__forgot-password {
      @include l6;
      display: inline-block;
      margin-bottom: 20px;
    }
    &__submit {
      width: 100%;
      height: 34px;
      line-height: 34px;
    }
    &__registration-alt {
      background: $color-black;
      @include swap_direction(padding, 18px 28px 25px 24px);
    }
    &__no-account {
      @include h9;
      text-transform: none;
      color: $color-white;
      margin-bottom: 18px;
    }
    &__register {
      @include button--inverted;
      width: 100%;
      height: 34px;
      line-height: 34px;
      @include swap_direction(padding, 0);
    }
  }
  .registration {
    display: none;
    &__container {
      @include swap_direction(margin, 22px 28px 20px 24px);
      .day-of-birth__collection-field {
        margin-bottom: 32px;
        @include breakpoint($small-down) {
          margin-bottom: 0;
        }
      }
      .optional-info__header {
        text-indent: 0;
      }
    }
    &__header {
      @include h9;
      text-transform: none;
      margin-bottom: 18px;
    }
    &__email {
      width: 188px;
      margin-bottom: 10px;
    }
    &__password {
      width: 188px;
      margin-bottom: 18px;
    }
    &__password-container {
      @include clearfix;
      .registration__password {
        float: $ldirection;
      }
    }
    &__overlay-container {
      display: grid;
    }
    &__show-pass {
      display: block;
      margin: 5px 0;
      float: $ldirection;
    }
    &__show-pass-text {
      letter-spacing: 0em;
    }
    &__terms,
    &__email-list {
      line-height: 18px;
      display: block;
    }
    &__terms-text {
      position: relative;
      display: block;
      padding-#{$ldirection}: 20px;
      &::before {
        position: absolute;
        top: 4px;
        #{$ldirection}: 0;
      }
    }
    &__email-list {
      @include swap_direction(margin, 10px 0 18px 0);
    }
    &__submit {
      width: 100%;
      height: 34px;
      line-height: 34px;
    }
    &__signin-alt {
      background: $color-black;
      @include swap_direction(padding, 18px 28px 25px 24px);
    }
    &__have-account {
      @include h9;
      text-transform: none;
      color: $color-white;
      margin-bottom: 18px;
    }
    &__login {
      @include button--inverted;
      width: 100%;
      height: 34px;
      line-height: 34px;
      @include swap_direction(padding, 0);
    }
  }
  .account-links {
    display: none;
    @include swap_direction(margin, 23px 25px 10px 25px);
    &__welcome {
      @include h9;
      text-transform: none;
      margin-bottom: 24px;
    }
    &__profile,
    &__orders,
    &__favorites,
    &__loyalty,
    &__appointments,
    &__login,
    &__logout {
      @include h8;
      line-height: 39px;
      height: 41px;
      border-bottom: 2px solid $color-gray-light;
    }
    &__loyalty {
      letter-spacing: 1px;
    }
    &__login,
    &__logout {
      border-bottom: none;
    }
  }
  &-component__birthday-program {
    width: 100%;
    overflow: hidden;
    @include swap_direction(margin, 10px 0);
    .site-utils__item--account &,
    .site-utils__item--email & {
      margin-top: 0px;
    }
    // !important is used to override the inline styles from jquery.selectBox.js library
    .day-of-birth__collection-field {
      @include swap_direction(padding, 10px 0);
      a {
        width: 27%;
        min-width: 27%;
        float: $ldirection;
        margin-#{$rdirection}: 3%;
        &.select-month {
          width: 33%;
        }
        &.select-year {
          width: 34%;
          float: $ldirection;
          margin-#{$rdirection}: 0px;
        }
      }
      &.dob_format_yyyy_mm_dd {
        a {
          margin-#{$rdirection}: 3%;
          &.select-day {
            margin-#{$rdirection}: 0;
          }
        }
      }
    }
  }
}

.password-request-page,
.password-sent-page,
.password-reset-page {
  &__content {
    @include swap_direction(margin, 20px);
  }
  &__header {
    margin-bottom: 20px;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .request-form__item {
    @include swap_direction(margin, 20px 0);
  }
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 auto);
    max-width: 1024px;
    text-align: center;
    &__content {
      text-align: $ldirection;
      margin-#{$ldirection}: 50px;
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: 20px;
        @include swap_direction(margin, 4px 0 17px 0);
        padding-bottom: 16px;
        border-bottom: 2px solid $color-black;
      }
    }
    &__subheader {
      @include breakpoint($small-down) {
        @include h13;
        font-size: 12px;
      }
      @include breakpoint($medium-up) {
        @include h19;
        font-size: 14px;
        text-indent: 20px;
        @include swap_direction(margin, 4px 0 10px 0);
        padding-bottom: 16px;
        border-bottom: 1px solid $color-gray-light;
      }
    }
    &__header,
    &__subheader {
      @include breakpoint($medium-up) {
        width: 65%;
        float: $ldirection;
        @include clearfix;
      }
    }
    .password-request,
    .password-sent,
    .password-reset {
      @include swap_direction(margin, 20px 20px 0);
      &__text {
        margin-top: 10px;
        margin-bottom: 25px;
        clear: $ldirection;
        width: 65%;
        &-paragraph,
        .sent-info__text .button {
          @include swap_direction(margin, 0 0 10px 20px);
        }
      }
      .request-form__item.label {
        @include swap_direction(margin, 0 0 25px);
      }
      &__item {
        margin-bottom: 10px;
      }
    }
    .account-utilities {
      &__section {
        background-color: $color-nude;
        @include breakpoint($medium-up) {
          float: $rdirection;
          @include swap_direction(margin, 0 20px 20px 20px);
          @include swap_direction(padding, 15px);
          width: calc(30% - 20px);
        }
      }
      &__header {
        @include swap_direction(margin, 0 0 10px 47px);
        padding-bottom: 16px;
        font-size: 30px;
        text-align: $ldirection;
        @include breakpoint($medium-up) {
          @include h19;
          font-size: 14px;
          @include swap_direction(margin, 0 0 10px);
          border-bottom: 1px solid $color-gray-light;
        }
      }
    }
  }
  .password-reset {
    &__text {
      @include swap_direction(margin, 10px 0 20px);
    }
    &__item {
      margin-bottom: 10px;
      .field {
        display: block;
        width: 200px;
      }
    }
  }
}

.br-space {
  display: block;
}

.password-request-page {
  .account-utilities {
    &__section {
      @include breakpoint($medium-up) {
        min-height: 300px;
      }
    }
  }
}

.password-request-page {
  .account-utilities {
    &__section {
      @include breakpoint($medium-up) {
        min-height: 200px;
      }
    }
  }
}

.site-utils__dropdown--account {
  @include breakpoint($medium-up) {
    height: 550px;
    overflow-y: scroll;
  }
}
