.lbo--ref {
  display: none;
}

@if $lbo-enabled {
  .lbo {
    &__overlay {
      -moz-transition: opacity 0.4s, visibility 0.4s;
      -o-transition: opacity 0.4s, visibility 0.4s;
      -webkit-transition: opacity 0.4s, visibility 0.4s;
      transition: opacity 0.4s, visibility 0.4s;
      visibility: visible;
      opacity: 1;
      position: absolute;
      z-index: 9998;
      top: 129px;
      #{$rdirection}: 0;
      #{$ldirection}: 0;
      @include swap_direction(margin, auto);
      width: 92%;
      background-color: $color-white;
      .js-sticky-header & {
        position: fixed;
        top: 66px;
      }
      &--bg {
        cursor: pointer;
        visibility: visible;
        display: block;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        background-color: $color-black;
        border: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        #{$ldirection}: 0;
        z-index: 9997;
        overflow: hidden;
        outline: none;
      }
    }
    &__cart {
      background: url('/media/images/background-images/background-image2.png') no-repeat 0 0;
      position: absolute;
      top: -12px;
      #{$rdirection}: 12px;
      width: 22px;
      height: 12px;
    }
    &__message {
      margin-bottom: 17px;
      font-family: $brandon-text-medium;
      h1 {
        font-size: 14px;
        margin-bottom: 15px;
      }
      h2 {
        font-size: 10px;
        margin-bottom: 15px;
      }
      a {
        display: none;
      }
    }
    &__content {
      text-align: center;
      @include swap_direction(padding, 20px 40px);
    }
    &__link {
      background-color: $color-white;
      color: $color-pink;
      border: 1px solid $color-pink;
      cursor: pointer;
      display: inline-block;
      font-family: $brandon-text-bold;
      font-size: 11px;
      font-weight: normal;
      letter-spacing: 2px;
      line-height: 1;
      @include swap_direction(padding, 0.75em 1em);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.1s linear 0s, background 0.1s linear 0s;
      a {
        text-decoration: none;
      }
    }
    &__wrapper {
      &--close {
        background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
        width: 18px;
        height: 18px;
        display: block;
        text-indent: -10000px;
        float: $rdirection;
        @include swap_direction(margin, 2px 15px);
        position: absolute;
        top: 10px;
        #{$rdirection}: 0;
      }
    }
  }
  .viewcart {
    .lbo {
      &__header::before {
        content: '';
        background: $color-black;
        width: 100%;
        height: 2px;
        display: inline-block;
        position: absolute;
        top: 43%;
        #{$ldirection}: 0;
        &--content {
          background: $color-white;
          display: inline-block;
          position: relative;
          @include swap_direction(padding, 0 7px 0 9px);
        }
      }
    }
    .viewcart-panel {
      .lbo-message {
        @include swap_direction(padding, 16px 20px);
        border: 1px solid $color-gray-background;
        text-align: center;
        h1 {
          line-height: normal;
          text-transform: uppercase;
          font-size: 20px;
          font-family: $brandon-text-bold;
          margin-bottom: 10px;
        }
        h2 {
          font-size: 11px;
          margin-bottom: 7px;
        }
      }
      .lbo-msg {
        font-family: $brandon-text-bold;
        h1 {
          font-size: 14px;
          margin-bottom: 15px;
        }
        h2 {
          font-size: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .touch,
  .touchevents {
    .lbo-message {
      a {
        background-color: $color-white;
        color: $color-pink;
        border: 1px solid $color-pink;
        cursor: pointer;
        display: inline-block;
        font-family: $brandon-text-bold;
        font-size: 11px;
        font-weight: normal;
        letter-spacing: 2px;
        line-height: 1;
        @include swap_direction(padding, 0.75em 1em);
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.1s linear 0s, background 0.1s linear 0s;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .lbo {
    &__header::before {
      background: $color-black none repeat scroll 0 0;
      content: '';
      display: inline-block;
      height: 2px;
      #{$ldirection}: 0;
      position: absolute;
      top: 43%;
      display: inline-block;
      @include swap_direction(padding, 0 7px 0 9px);
    }
    &__link {
      background-color: $color-white;
      color: $color-pink;
      border: 1px solid $color-pink;
      cursor: pointer;
      display: inline-block;
      font-family: $brandon-text-bold;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 2px;
      line-height: 1;
      @include swap_direction(padding, 0.75em 1em);
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color 0.1s linear 0s, background 0.1s linear 0s;
      a {
        text-decoration: none;
      }
    }
    &__cart {
      background: url('/media/images/background-images/background-image2.png') no-repeat 0 0;
      position: absolute;
      top: -12px;
      #{$rdirection}: 12px;
      width: 22px;
      height: 12px;
    }
    &__wrapper {
      &--close {
        background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
        width: 18px;
        height: 18px;
        display: block;
        text-indent: -10000px;
        float: $rdirection;
        @include swap_direction(margin, 2px 15px);
        position: absolute;
        top: 10px;
        #{$rdirection}: 0;
      }
    }
    &__content {
      text-align: center;
      @include swap_direction(padding, 20px 40px);
    }
    &__message {
      margin-bottom: 17px;
      font-family: $brandon-text-medium;
      h1 {
        font-size: 18px;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-family: $brandon-text-bold;
      }
      h2 {
        font-size: 11px;
        margin-bottom: 15px;
      }
      a {
        display: none;
      }
    }
    &__overlay {
      -moz-transition: opacity 0.4s, visibility 0.4s;
      -o-transition: opacity 0.4s, visibility 0.4s;
      -webkit-transition: opacity 0.4s, visibility 0.4s;
      transition: opacity 0.4s, visibility 0.4s;
      visibility: visible;
      opacity: 1;
      position: absolute;
      z-index: 9998;
      top: 55px;
      #{$rdirection}: 0;
      #{$ldirection}: 660px;
      @include swap_direction(margin, auto);
      width: 360px;
      background-color: $color-white;
      .js-sticky-header & {
        position: fixed;
        top: 66px;
      }
      &--bg {
        cursor: pointer;
        visibility: visible;
        display: block;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        background-color: $color-black;
        border: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        #{$ldirection}: 0;
        z-index: 9997;
        overflow: hidden;
        outline: none;
      }
      &__warpper {
        position: relative;
        border: solid 1px $color-gray;
        background-color: $color-white;
        color: $color-black;
        z-index: 1;
        padding: 30px;
        float: none;
        @include swap_direction(margin, auto);
        width: 95%;
        &--close {
          background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
          width: 18px;
          height: 18px;
          display: block;
          text-indent: -10000px;
          float: $rdirection;
          @include swap_direction(margin, 10px 15px);
          position: absolute;
          top: 10px;
          #{$rdirection}: 0;
        }
        &__content {
          text-align: center;
          padding-bottom: 1px;
          p {
            @include swap_direction(padding, 0);
            @include swap_direction(margin, 0);
          }
          &--message {
            margin-bottom: 25px;
            &--confirm-message {
              font-family: $brandon-text-medium;
              strong {
                font-size: 23px;
                line-height: 35px;
                text-transform: uppercase;
                margin-bottom: 10px;
                display: block;
                letter-spacing: 0.2em;
              }
            }
          }
          &-cta {
            text-transform: uppercase;
            a {
              text-decoration: none;
              border: solid 1px;
              @include swap_direction(padding, 8px 16px);
              font-weight: bold;
              display: inline-block;
              letter-spacing: 4px;
            }
          }
        }
      }
    }
  }
  .viewcart {
    .viewcart-panel {
      .lbo-message {
        background: $color-gray-background none repeat scroll 0 0;
        @include swap_direction(padding, 16px 20px);
        border-top: 2px solid $color-black;
        text-align: $ldirection;
        h1 {
          line-height: normal;
          text-transform: uppercase;
        }
      }
      .lbo-msg {
        font-family: $brandon-text-bold;
        h1 {
          font-size: 20px;
          margin-bottom: 15px;
        }
        h2 {
          font-size: 11px;
          margin-bottom: 15px;
          display: inline;
        }
        a {
          margin-#{$ldirection}: 10px;
          font-size: 11px;
        }
      }
    }
    .lbo {
      &__header {
        display: none;
        span {
          display: none;
        }
      }
      &__link {
        display: none;
      }
    }
  }
}
