.cart_overlay {
  a {
    text-decoration: none;
  }
  @include transition(opacity 0.4s, visibility 0.4s);
  // position: relative;
  visibility: visible;
  opacity: 1;
  position: absolute;
  z-index: 999;
  top: 30px;
  @if $text-direction == 'rtl' {
    left: -8px;
  } @else {
    right: -15px;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    display: block !important; // !important wars...
  }
  .cart-caret {
    /* @include arrow(top, null, $color-white, $color-gray, 20px); - borders just don't look right... */
    background: url('/media/images/background-images/background-image2.png') no-repeat 0 0;
    position: absolute;
    top: -12px;
    @if $text-direction == 'rtl' {
      left: 5px;
    } @else {
      right: 12px;
    }
    width: 22px;
    height: 12px;
  }
  .kit-container {
    float: $ldirection;
    text-align: $ldirection;
    .cart-item {
      &__kit-item {
        margin-bottom: 5px;
        margin-#{$rdirection}: 10px;
        .product_name {
          display: none;
        }
      }
      &__desc-info {
        .color {
          .swatch {
            width: 18px;
            height: 18px;
            float: $ldirection;
            @include breakpoint($medium-up) {
              width: 22px;
              height: 22px;
            }
          }
          .shade {
            line-height: 18px;
            margin-left: 1.7em;
            @include breakpoint($medium-up) {
              line-height: 22px;
            }
          }
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 5px 0 1px 0);
          }
        }
      }
    }
  }
  .cart-confirm-wrapper {
    position: relative;
    width: 438px;
    border: solid 1px $color-gray;
    background-color: $color-white;
    color: $color-black;
    z-index: 1;
    margin-top: 37px;
    .close {
      background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
      width: 18px;
      height: 18px;
      display: block;
      text-indent: -10000px;
      float: $rdirection;
      @include swap_direction(margin, 10px 15px);
    }
    h2,
    .at-h2 {
      margin: 20px 20px 10px;
      font-size: 15px;
      font-family: $body-font-bold;
      letter-spacing: 2px;
      text-align: #{$ldirection};
    }
    .products-heading {
      display: none; /* BB-203 */
      span {
        font-family: $body-font;
        font-weight: normal;
        font-size: 14px;
        text-transform: none;
        &.h_product {
          padding: 0 210px 0 20px;
          @include breakpoint($small-down) {
            float: left;
            padding: 0 0 0 10px;
            text-align: left;
            width: 56%;
          }
        }
        &.h_qty {
          padding: 0 40px 0 0;
          @include breakpoint($small-down) {
            margin-right: 2%;
            padding: 0;
            width: 6%;
          }
        }
      }
    }
    .cart-products {
      height: auto;
      background: transparent;
      width: 100%;
      padding: 0 10px;
      @if $cr22 == false {
        max-height: 320px;
      } @else {
        max-height: 210px;
        @include breakpoint($tall-up) {
          max-height: 320px;
        }
      }
      @include breakpoint($medium-up) {
        padding: 0 20px;
      }
      overflow-x: hidden;
      overflow-y: auto;
      .prod {
        width: 100%;
        padding: 15px 0;
        line-height: 1.2em;
        border-bottom: solid 1px $color-gray;
        a {
          color: $color-black;
        }
        img {
          @if $text-direction == 'rtl' {
            margin-left: 20px;
            float: right;
          } @else {
            margin-right: 20px;
            float: left;
          }
          @include breakpoint($small-down) {
            width: 20%;
            margin-right: 2%;
          }
        }
        .prod-info {
          float: $ldirection;
          width: 190px;
          @include breakpoint($small-down) {
            width: 48%;
            margin-right: 2%;
          }
          a {
            display: block;
            font-weight: bold;
            font-size: 11px;
            font-family: $body-font-bold;
            letter-spacing: 3px;
            text-transform: uppercase;
            text-align: left;
            h3,
            div {
              font-size: 11px;
            }
          }
          .size {
            display: none; /* per BB-222 */
            font-family: $body-font;
            text-transform: none;
            line-height: 27px;
          }
          .shade_info,
          .size_info {
            text-transform: none;
            font-family: $body-font;
            font-size: 14px;
            line-height: 1.3em;
            text-align: #{$ldirection};
            letter-spacing: 1px;
            @include breakpoint($medium-up) {
              font-size: 16px;
            }
            &_label {
              letter-spacing: 1px;
              color: $color-black-2;
              &:after {
                content: ': ';
              }
            }
            .shade_thumb {
              width: 22px;
              height: 22px;
              background: $color-gray;
              border-radius: 50%;
              transition: all 0.2s ease-in-out;
              display: block;
              float: left;
              margin: 2px 5px 0 0;
            }
          }
        }
        .qty {
          float: $ldirection;
          font-size: 11px;
          @if $text-direction == 'rtl' {
            padding-right: 15px;
          }
          @include breakpoint($small-down) {
            width: 6%;
            margin-right: 2%;
          }
        }
        .price {
          font-size: 11px;
          font-family: $body-font-bold;
          letter-spacing: 3px;
          text-transform: uppercase;
          line-height: 12px;
          float: $rdirection;
          text-align: $rdirection;
          @include breakpoint($small-down) {
            width: 20%;
          }
          padding-right: 0px;
          padding-top: 3px;
          font-weight: bold;
          .currency_type {
            clear: both;
          }
        }
      }
      .view_bag {
        text-align: center;
        padding: 30px;
        a {
          color: $color-pink;
          border-bottom: solid 1px $color-pink;
          font-size: 14px;
        }
      }
    }
    .subtotal {
      padding: 22px 5px;
      line-height: 1em;
      font-size: 15px;
      font-family: $body-font-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      .items {
        .items-in-cart {
          display: none;
        }
        .item-in-cart {
          display: inline;
        }
        &.plural {
          .item-in-cart {
            display: none;
          }
          .items-in-cart {
            display: inline;
          }
        }
      }
      .left-copy {
        float: $ldirection;
      }
      .right-copy {
        float: $rdirection;
        text-align: $rdirection;
        .sub_t_text {
          float: $ldirection;
          @if $text-direction == 'rtl' {
            margin-left: 20px;
          }
        }
        .sub_t {
          float: $rdirection;
          @if $text-direction == 'rtl' {
            width: 85px;
          } @else {
            width: 100px;
          }
        }
      }
    }
    .checkout {
      padding: 20px 20px 0;
      text-align: $rdirection;
      clear: both;
      margin: 0;
    }
    .promos {
      width: 100%;
      /* default free shipping */
      .free_ship {
        width: 100%;
        height: 55px;
        background: $color-glossy-light-gray;
        color: $color-core-black;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
          color: $color-core-black;
          letter-spacing: 4px;
          line-height: 20px;
          font-family: $body-font;
          margin-bottom: 0;
          text-align: center;
        }
        div {
          padding-top: 7px;
          letter-spacing: 4px;
          line-height: 20px;
          font-family: $body-font;
          margin-bottom: 0;
          text-align: center;
          font-size: 11px;
          margin: 20px 20px 10px;
        }
        p {
          text-transform: none;
          font-family: $body-font;
          font-size: 12px;
        }
      }
    }
    /* add view only */
    .add_view {
      h2 {
        color: $color-pink;
        font-family: $body-font;
        font-size: 14px;
        text-transform: none;
      }
      .cart-products {
        background: none;
        .prod {
          border-top: solid 1px $color-gray;
          border-bottom: none;
        }
      }
      .checkout {
        padding: 20px 20px 0;
        text-align: right;
        clear: both;
      }
    }
    /* loading view */
    &.loading {
      text-align: center;
      padding: 20px;
    }
  }
}
