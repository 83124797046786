.foundation-dramming-page {
  .field-content,
  .block-container,
  .undertone {
    max-width: 1440px;
  }
  .field-content {
    @include breakpoint($xxxlarge-range) {
      max-width: inherit;
      @include swap_direction(padding, 0 10%);
    }
  }
  /* Header */
  .product-collection-hero,
  .product-collection-content {
    h2 {
      font-family: $bb-sans;
      font-size: 40px;
      text-align: #{$ldirection};
      line-height: 1;
      letter-spacing: 0;
      @include breakpoint($medium-up) {
        font-size: 60px;
        text-align: center;
        line-height: 0;
      }
    }
    p {
      font-family: $brandon-text;
      font-size: 15px;
      line-height: 1.5;
      text-align: #{$ldirection};
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
  }
  .product-collection-hero {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    &__body {
      position: absolute;
      #{$ldirection}: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.2;
      width: 90%;
    }
    &__title.at-h2 {
      font-size: 48px;
      text-align: center;
      @include breakpoint($medium-up) {
        font-size: 110px;
        line-height: 1;
      }
    }
    &__html_block {
      font-size: 16px;
      text-align: center;
    }
  }
  .product-collection-content {
    .product,
    .block-template-product-image-v2,
    .block-template-basic-responsive-v2 {
      @include swap_direction(padding, 0 20px);
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
  }
  /* Dramming section one */
  .field-content {
    @include swap_direction(margin, 0 auto);
    display: block;
    background: $color-filter-grey;
    .text-block {
      @include swap_direction(padding, 0 5% 7%);
      width: 100%;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 6% 7% 5%);
      }
      &__title {
        @include swap_direction(margin, 20px auto);
        text-transform: uppercase;
        margin-bottom: 9px;
        line-height: 1;
        text-align: #{$ldirection};
        @include breakpoint($medium-up) {
          h2 {
            text-align: center;
          }
        }
      }
      &__body {
        font-size: 20px;
        line-height: 1.5;
        span {
          font-size: 12px;
          vertical-align: top;
        }
      }
    }
    .product-image {
      width: 100%;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 5% 7% 0);
      }
    }
    @include breakpoint($medium-up) {
      display: flex;
      flex-direction: row;
      .text-block {
        width: 50%;
        order: 1;
      }
      .product-image {
        width: 50%;
        order: 2;
      }
    }
  }
  .field-mobile-template {
    background: $color-filter-grey;
    .text-block-dramming-page {
      @include swap_direction(padding, 5% 7% 0);
      .at-kbeauty-h4 {
        font-size: 40px;
        letter-spacing: 4px;
        text-transform: uppercase;
      }
    }
  }
  /* Dramming section - Three */
  .block-container {
    @include swap_direction(margin, 0 auto);
    &__title {
      @include swap_direction(margin, 30px auto);
      letter-spacing: 4px;
      text-transform: uppercase;
      padding-top: 25px;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 50px auto);
        padding-top: 46px;
      }
    }
    &__subtitle {
      text-align: center;
    }
    &__content {
      display: block;
      @include breakpoint($medium-up) {
        display: flex;
      }
      .product-image {
        @include swap_direction(margin, 35px 0);
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 20px);
        }
        &__title {
          @include swap_direction(margin, 10px 0);
          font-family: $bb-sans;
          font-size: 24px;
          font-weight: 600;
          @include breakpoint($medium-up) {
            font-size: 30px;
            padding-top: 20px;
          }
        }
        &__caption {
          @include breakpoint($medium-up) {
            font-size: 16px;
          }
        }
      }
      .product-step-one {
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 16%;
        }
      }
      .product-step-two {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 16%;
        }
      }
    }
  }
  .product {
    max-width: 1024px;
    @include swap_direction(padding, 17px 20px 0);
    @include breakpoint($xxxlarge-range) {
      max-width: 1440px;
    }
    .product-subtitle {
      text-align: #{$ldirection};
      font-size: 15px;
      @include breakpoint($medium-up) {
        text-align: center;
        font-size: 20px;
      }
    }
    .offerspick {
      @include swap_direction(padding, 15px 0 16px);
      &__offer {
        position: relative;
        border-bottom: 1px solid $color-gray-light-alt;
        width: 100%;
        @include swap_direction(margin, 41px 0 0 0);
        flex-wrap: wrap;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 70px 45px);
          position: static;
          flex-direction: column;
          flex-wrap: unset;
        }
      }
      &__offers {
        padding-#{$rdirection}: 0;
        padding-#{$ldirection}: 0;
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
      &__offer--header {
        text-align: #{$ldirection};
        line-height: 2;
        font-size: 20px;
        text-transform: none;
        letter-spacing: 0;
        @include breakpoint($medium-up) {
          text-align: center;
          line-height: 1.2;
        }
      }
      &__shades {
        margin-top: 0;
        padding: 0 20px;
        @include breakpoint($medium-up) {
          margin-top: 26px;
          padding: 0;
        }
        img {
          display: block;
        }
      }
      &__shade {
        @include swap_direction(padding, 0 5px 0 0);
      }
      &__shade--name {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        @include breakpoint($medium-up) {
          font-size: 13px;
        }
      }
      &__button--container {
        text-align: center;
        .button--outline {
          font-size: 15px;
          margin-bottom: 15px;
          position: absolute;
          top: 0;
          right: 0;
          border: 1px solid $color-black;
          color: $color-black;
          @include breakpoint($medium-up) {
            position: static;
            background: $color-black;
            color: $color-white;
          }
          &:hover {
            color: $color-white;
            cursor: pointer;
            @include breakpoint($medium-up) {
              background: $color-white;
              color: $color-black;
            }
          }
        }
      }
      &__button--remove {
        width: 144px;
        padding: 7px;
        @include breakpoint($medium-up) {
          width: auto;
          padding: 14px;
        }
      }
      .slick-track {
        display: inline-flex;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .slick-prev,
      .slick-next {
        &:before {
          font-size: 24px;
        }
      }
      .slick-next {
        @include font-icon-replace($pseudo: before);
        position: absolute;
      }
    }
  }
  .undertone {
    text-align: #{$ldirection};
    height: auto;
    margin: 30px auto 30px;
    position: relative;
    display: block;
    img {
      width: 100%;
    }
    &__container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      #{$ldirection}: 5%;
    }
    &__title {
      font-family: $bb-sans;
      font-size: 18px;
      color: $color-white;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 12px;
      @include breakpoint($medium-up) {
        font-size: 60px;
        margin-bottom: 10px;
      }
    }
    &__content {
      font-size: 12px;
      line-height: 1.25;
      color: $color-white;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
    .link {
      @include swap_direction(padding, 5px 0);
      @include swap_direction(margin, 10px 0 0);
      color: $color-white;
      border-color: $color-white;
      font-size: 12px;
      line-height: 1.1;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 22px 0 0);
        font-size: 14px;
      }
    }
  }
}
