// Search results pagination styling

#search-wrapper {
  .pagination-container {
    text-align: center;
    .pagination {
      @include swap_direction(padding, 14px 0);
      @include swap_direction(margin, 0 auto);
      a {
        text-decoration: none;
      }
      .viewall {
        padding-#{$rdirection}: 15px;
      }
      > span {
        margin-#{$ldirection}: 10px;
        &.first {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
}
