.skincare-finder {
  text-align: center;
  margin-bottom: 30px;
  &__header-wrapper {
    text-align: center;
  }
  &__title {
    text-align: center;
    text-transform: uppercase;
    font-family: $body-font;
    font-size: 21px;
    line-height: 1;
    @include swap_direction(padding, 0 40px);
    letter-spacing: 0.24em;
    margin-bottom: 32px;
    margin-top: 10px;
  }
  &__image-mobile {
    margin-bottom: 18px;
  }
  &__filters {
    text-align: center;
    @include breakpoint($large-up) {
      text-align: $ldirection;
    }
  }
  .product-filter {
    // At tablet they stack, above tablet one product filter floats left, other
    // one to the right.
    &:first-child {
      margin-bottom: 30px;
    }
    @include breakpoint($large-up) {
      vertical-align: top;
      float: $rdirection;
      &:first-child {
        float: $ldirection;
        margin-bottom: 0;
      }
    }
    &__label {
      text-align: $ldirection;
      text-transform: uppercase;
      font-family: $body-font-bold;
      font-size: 14px;
      margin-bottom: 5px;
      margin-#{$rdirection}: 10px;
      display: inline-block;
      @include breakpoint($large-up) {
        display: block;
        margin-#{$rdirection}: 0;
      }
    }
    &__list {
      display: inline-block;
      width: auto;
      @include breakpoint($large-up) {
        display: block;
        width: 100%;
      }
    }
    &__filter {
      @include swap_direction(padding, 0 10px);
    }
  }
  &__results {
    margin-top: 60px;
    &--no-abstract .product-thumb__abstract {
      display: none;
    }
  }
  .product-grid-alt--filtered .product-thumb__abstract {
    display: block;
  }
  .product-thumb {
    @include swap_direction(padding, 0);
    &__headline {
      margin-bottom: 0;
    }
    &__headline-link {
      font-family: $body-font;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.2em;
      // the psd uses brandon text set to bold, not the brandon text bold font.
      font-weight: bold;
    }
    &__image-wrapper {
      @include swap_direction(padding, 10px);
    }
    &--price {
      height: auto;
      border-top: none;
      border-bottom: none;
      line-height: 1.1em;
    }
    &__abstract {
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
    }
  }
}
