.duty-free {
  .outer-wrap {
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 0);
    max-width: 1024px;
  }
  &__hero {
    @include clearfix;
    @include swap_direction(margin, 0 0 60px 0);
    @include swap_direction(padding, 45px);
    font-family: $brandon-text-bold;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    background: #f4f1ec;
    h1,
    h2 {
      margin-bottom: 30px;
      letter-spacing: 5px;
    }
    p {
      margin-bottom: 20px;
      font-family: $brandon-text;
      text-transform: none;
    }
    select.country {
      display: inline;
    }
    a.selectBox {
      display: inline-block !important;
      @include swap_direction(margin, 0 10px 0 5px);
      width: 220px;
      height: 32px;
      line-height: 32px;
      text-align: $ldirection;
      .selectBox-arrow {
        line-height: 2em;
      }
    }
  }
  &__store-locator {
    display: inline-block;
    float: $rdirection;
    @include swap_direction(margin, 5px 10px 0 0);
    color: $color-pink;
    text-decoration: none;
    vertical-align: top;
    @include breakpoint($small-down) {
      float: none;
      @include swap_direction(margin, 20px 0 0 0);
    }
    &:hover {
      text-decoration: none;
    }
    .icon {
      font-size: 24px;
      line-height: 24px;
      vertical-align: middle;
    }
    .text {
      margin-bottom: 8px;
      font-size: 14px;
      letter-spacing: 3px;
      border-bottom: 2px solid $color-pink;
    }
  }
  &__content {
    @include clearfix;
    margin-bottom: 65px;
  }
  &__column {
    float: $ldirection;
    width: 50%;
    @include swap_direction(padding, 0 8%);
    text-align: $ldirection;
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
      margin-bottom: 60px;
    }
  }
  &__column-header,
  &__exclusives-header {
    font-family: $brandon-text-bold;
    letter-spacing: 3px;
    line-height: 0.8;
    text-align: center;
    text-transform: uppercase;
    span {
      display: inline-block;
      position: relative;
    }
    span:before,
    span:after {
      content: '';
      position: absolute;
      height: 7px;
      border-bottom: 2px solid $color-black;
      top: 0;
      width: 50px;
    }
    span:before {
      #{$rdirection}: 100%;
      margin-#{$rdirection}: 12px;
    }
    span:after {
      #{$ldirection}: 100%;
      margin-#{$ldirection}: 12px;
    }
  }
  &__store-block {
    @include clearfix;
    margin-top: 35px;
  }
  &__store-info {
    float: $ldirection;
    width: 55%;
    margin-#{$rdirection}: 15%;
    p {
      margin: 0;
    }
  }
  &__store-name {
    margin-bottom: 5px;
    font-family: $brandon-text-bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  &__store-hours {
    float: $rdirection;
    @include swap_direction(margin, 0);
  }
  &__exclusives-header.is-desktop {
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &__exclusives-header.is-mobile {
    display: none;
    @include breakpoint($small-down) {
      display: block;
    }
  }
  &__exclusive {
    float: $ldirection;
    width: 25.33333333%;
    @include swap_direction(margin, 40px 3% 0 3%);
    @include swap_direction(padding, 0);
    text-align: $ldirection;
    @include breakpoint($small-down) {
      width: 100%;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 8%);
      float: none;
      text-align: center;
    }
  }
  &__exclusive-title {
    height: 32px;
    @include swap_direction(margin, 32px 0);
    font-family: $brandon-text-bold;
    letter-spacing: 3px;
    text-align: $ldirection;
    text-transform: uppercase;
    @include breakpoint($small-down) {
      height: auto;
      @include swap_direction(margin, 8px 0 16px 0);
    }
  }
  &__exclusive-text {
    height: 196px;
    @include swap_direction(padding, 16px 0);
    border-top: 2px solid $color-gray;
    border-bottom: 2px solid $color-gray;
    text-align: $ldirection;
    @include breakpoint($small-down) {
      height: auto;
    }
  }
}
