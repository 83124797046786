.extra-skincare-collection {
  .block_container {
    @include swap_direction(margin, 60px 0);
  }
  .block_container {
    &__title {
      font-size: 20px;
      margin-bottom: 12px;
      @include breakpoint($small-down) {
        text-align: center;
      }
    }
    &__subtitle {
      font-family: $body-font-bold;
      font-size: 20px;
      text-transform: uppercase;
      @include breakpoint($small-down) {
        text-align: center;
      }
    }
  }
  .product_collection_hero {
    &__body {
      @include swap_direction(padding, 60px 0);
    }
    h1,
    h2.at-h2 {
      font-family: 'MillerBanner Light';
      font-size: 60px;
      line-height: 0.75em;
      letter-spacing: 0px;
    }
  }
  @include breakpoint($medium-up) {
    .product-image {
      float: $ldirection;
    }
  }
  .text-block {
    &_body {
      background-color: #eff0f0;
      @include swap_direction(padding, 18px);
      margin-bottom: 18px;
      p {
        font-size: 16px;
        @include swap_direction(margin, 0 0 12px 0);
      }
    }
  }
  .product-atb {
    @include breakpoint($small-down) {
      float: $ldirection;
      @include swap_direction(margin, 0 25%);
    }
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 360px;
    }
    a {
      background-color: $color-black;
      border: 2px solid $color-black;
      color: $color-white;
      display: inline-block;
      font-family: $body-font-bold;
      font-size: 14px;
      text-transform: uppercase;
      @include swap_direction(padding, 0.75em 1em);
      text-decoration: none;
      letter-spacing: 2px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: $color-pink;
        border: 2px solid $color-pink;
      }
    }
  }
  @include breakpoint($medium-up) {
    .block_container__title {
      margin-#{$ldirection}: 360px;
    }
    .block_container__subtitle {
      margin-#{$ldirection}: 360px;
      margin-bottom: 12px;
    }
    .text-block {
      margin-#{$ldirection}: 360px;
    }
  }
  .product-collection-footer_l,
  .product-collection-footer_r {
    min-height: 248px;
  }
}
