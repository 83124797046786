.review-submit {
  @include pie-clearfix;
  @include swap_direction(margin, 35px auto 50px auto);
  width: 746px;
  @include breakpoint($small-down) {
    width: 100%;
  }
  .review-submit__product-image {
    float: $ldirection;
    margin-#{$rdirection}: 30px;
    width: 58px;
    height: auto;
  }
  .review-submit__header {
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 11px;
  }
  .review-submit__product-head {
    font-size: 30px;
    line-height: 31px;
    @include swap_direction(margin, 0);
  }
}
