.makeup_workshops {
  a {
    text-decoration: none;
  }
  &_hero {
    font-size: 20px;
    line-height: 1.25em;
    background: $color-gray-background;
    background-repeat: no-repeat;
    background-size: 0;
    background-position: 50% 160px;
    position: relative;
    overflow: hidden;
    @include breakpoint($medium-up) {
      line-height: 1.5em;
      height: 360px;
      background-size: auto;
      background-position: 0 0;
    }
    @include breakpoint($large-range) {
      background-position: 15% 0;
    }
    h1,
    h2 {
      @include t3;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &__body {
      position: inherit;
      margin-top: 12px;
      @include breakpoint($medium-range) {
        width: 45%;
        margin-bottom: 0;
        position: absolute;
        #{$rdirection}: 2%;
        top: 25%;
      }
      @include breakpoint($large-range) {
        #{$rdirection}: 5%;
      }
    }
    &__title {
    }
    &__subtitle {
    }
    img {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &_head {
    font-size: 20px;
    line-height: 1.25em;
    @include swap_direction(padding, 20px 0 24px);
    @include breakpoint($medium-up) {
      width: 81%;
      @include swap_direction(margin, 30px auto);
    }
    &_title {
      font-family: $brandon-text-bold;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 12px;
      position: relative;
      .inner {
        background: $color-white;
        display: inline-block;
        position: relative;
        @include swap_direction(padding, 0 9px);
      }
      &:before {
        content: '';
        background: $color-black;
        width: 90%;
        height: 1px;
        display: inline-block;
        position: absolute;
        bottom: 13px;
        #{$ldirection}: 5%;
        @include breakpoint($medium-up) {
          width: 30%;
          bottom: 12px;
          #{$ldirection}: 35%;
        }
      }
    }
  }
  &_schedules {
    overflow: auto;
  }
  &_schedule {
    font-size: 16px;
    padding-bottom: 5%;
    margin-bottom: 5%;
    position: relative;
    @include breakpoint($medium-up) {
      width: 50%;
      height: 406px;
      @include swap_direction(padding, 0);
      margin-bottom: 0;
      position: relative;
      float: $ldirection;
    }
    @include breakpoint($medium-up) {
      &:nth-child(1) {
        padding-#{$rdirection}: 24px;
        border-#{$rdirection}: 1px solid $color-gray-background;
        .makeup_workshops_schedule_box {
          bottom: 24px;
        }
      }
      &:nth-child(2) {
        padding-#{$ldirection}: 24px;
        .makeup_workshops_schedule_box {
          bottom: 24px;
        }
      }
      &:nth-child(3) {
        padding-top: 24px;
        padding-#{$rdirection}: 24px;
        border-#{$rdirection}: 1px solid $color-gray-background;
        border-top: 1px solid $color-gray-background;
        .makeup_workshops_schedule_box {
          bottom: 0;
        }
      }
      &:nth-child(4) {
        padding-top: 24px;
        padding-#{$ldirection}: 24px;
        border-top: 1px solid $color-gray-background;
        .makeup_workshops_schedule_box {
          bottom: 0;
        }
      }
    }
    img {
      float: $ldirection;
      width: 45%;
      margin-#{$rdirection}: 3%;
      margin-bottom: 3%;
      border: 1px solid $color-gray-background;
      @include breakpoint($medium-up) {
        width: 36%;
        margin-#{$rdirection}: 20px;
        margin-bottom: 14px;
      }
    }
    h2,
    h3 {
      text-transform: uppercase;
      width: 52%;
      margin-bottom: 10px;
      float: $ldirection;
      font-family: $bb-sans;
      line-height: 1.5;
      letter-spacing: 1px;
      font-size: 1.5em;
      display: flex;
      align-items: center;
      @include breakpoint($medium-up) {
        width: 58%;
      }
    }
    @include breakpoint($small-down) {
      &_description {
        margin-#{$ldirection}: 48%;
      }
    }
    &_details {
      line-height: 1.5em;
      clear: both;
      @include swap_direction(padding, 12px);
      @include breakpoint($large-range) {
        clear: none;
      }
    }
    &_box {
      line-height: 1.5em;
      background: $color-gray-background;
      @include swap_direction(padding, 12px);
      overflow: auto;
      min-height: 108px;
      @include breakpoint($medium-up) {
        width: 95%;
        position: absolute;
      }
    }
    &_head {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        width: 81%;
        @include swap_direction(margin, 0 0 12px);
      }
      @include breakpoint($large-range) {
        letter-spacing: 3px;
      }
    }
    @include breakpoint($medium-up) {
      &_dates_left {
        float: $ldirection;
        width: 50%;
      }
      &_dates_right {
        min-height: 48px;
        float: $ldirection;
        width: 50%;
        padding-#{$ldirection}: 24px;
        padding-#{$ldirection}: 9px;
        border-#{$ldirection}: 2px solid white;
      }
    }
  }
  &_quote {
    @include swap_direction(margin, 24px 0);
    @include swap_direction(padding, 30px);
    border-top: 1px solid $color-gray-background;
    border-bottom: 1px solid $color-gray-background;
    &_body {
      font-size: 24px;
      font-style: italic;
      line-height: 1.5em;
    }
    &_bb {
      font-family: $brandon-text-bold;
      line-height: 1.5em;
      margin-top: 12px;
    }
  }
  &_locations {
    margin-top: 24px;
    border-top: 1px solid $color-gray-background;
    @include swap_direction(padding, 24px 0 0);
    @include breakpoint($medium-up) {
      display: flex;
    }
  }
  @include breakpoint($medium-range) {
    &:nth-child(1) {
      @include swap_direction(padding, 0 24px);
    }
  }
  @include breakpoint($medium-up) {
    &_location {
      &_details {
        width: 50%;
        &:nth-child(2) {
          @include swap_direction(padding, 0 24px);
          border-#{$ldirection}: 1px solid $color-gray-background;
        }
      }
    }
  }
  &_details {
    font-size: 18px;
    line-height: 1.5em;
    @include swap_direction(padding, 0 9px);
    overflow: auto;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
    }
    .directions {
      margin-bottom: 24px;
      a {
        text-transform: uppercase;
        padding-bottom: 6px;
        border-bottom: 1px solid $color-pink;
        &:hover {
          color: $color-black;
          border-bottom: 1px solid $color-black;
        }
      }
    }
    @include breakpoint($medium-up) {
      &_left {
        width: 50%;
        float: $ldirection;
      }
      &_right {
        padding-#{$ldirection}: 20px;
        border-#{$ldirection}: 1px solid $color-gray-background;
        width: 50%;
        float: $rdirection;
      }
    }
    &_title {
      text-transform: uppercase;
      font-family: $bb-sans;
      line-height: 1.5;
      letter-spacing: 1px;
      font-size: 1.5em;
      display: flex;
      align-items: center;
    }
    &_label {
      font-family: $brandon-text-bold;
      font-size: 14px;
      text-transform: uppercase;
    }
    &_body {
      margin-bottom: 12px;
      .phone {
        color: $color-pink;
      }
    }
  }
  &_instructors {
    margin-top: 24px;
    border-top: 1px solid #eff0f0;
    @include swap_direction(padding, 24px 0 0);
  }
  &_policy {
    @include swap_direction(padding, 24px 9px 0);
    margin-top: 24px;
    border-top: 1px solid $color-gray-background;
    overflow: auto;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 24px 0 0);
    }
    &_title {
      text-transform: uppercase;
      font-family: $bb-sans;
      line-height: 1.5;
      letter-spacing: 1px;
      font-size: 1.5em;
    }
    &_body {
      margin-bottom: 32px;
    }
  }
}
