.detail_hero-v6 {
  .menu {
    height: 46px;
    text-align: center;
    border-bottom: 1px solid #000000;
    ul.menu__list {
      display: inline-block;
    }
    li {
      font-weight: 600;
      font-size: 12px;
      @include breakpoint($landscape-up) {
        font-size: 14px;
      }
      text-transform: uppercase;
      @include swap_direction(padding, 13px 15px);
      display: inline-block;
      @include swap_direction(margin, 0);
      list-style: none;
      cursor: pointer;
      float: $ldirection;
      background-color: #eff0f0;
      border-#{$ldirection}: 1px solid #000000;
      &.active-trail {
        background-color: #ffffff;
        border-bottom: #ffffff;
        padding-bottom: 14px;
      }
      &.last {
        border-#{$rdirection}: 1px solid #000000;
      }
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
}
