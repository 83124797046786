.product-gallery {
  text-align: center;
  &__main {
    width: 100%;
    height: auto;
    @include swap_direction(margin, 0 0 5px);
  }
  &__photo--large {
    display: none;
    max-width: 415px;
    &.active {
      display: block;
      @include swap_direction(margin, auto);
    }
  }
  &__photo--thumb {
    cursor: pointer;
  }
  &__thumbs {
    @include pie-clearfix;
    @include swap_direction(padding, 5px 0 0);
    @include swap_direction(margin, 0);
    height: 80px;
    display: none;
    text-align: center;
    @include breakpoint($medium-up) {
      display: block;
      &.slick-initialized {
        .slick-slide {
          text-align: #{$ldirection};
          padding-#{$ldirection}: 60px;
        }
        .slick-slide.modiface-hided {
          text-align: center;
          padding-#{$rdirection}: 60px;
          .product-gallery__thumb {
            padding-#{$rdirection}: 22px;
          }
        }
      }
    }
    button.slick-next,
    button.slick-prev {
      top: 26%;
      height: 60px;
      width: 60px;
      @include breakpoint($landscape-up) {
        top: 45%;
      }
    }
    button.slick-next {
      #{$rdirection}: 0;
      &:before {
        #{$rdirection}: auto;
      }
    }
  }
  &__thumb {
    display: inline-block;
    height: 70px;
    width: 70px;
    @include breakpoint($landscape-up) {
      height: auto;
      width: auto;
    }
    padding-#{$ldirection}: 10px;
    vertical-align: top;
    overflow: hidden;
    a {
      display: block;
    }
    &.modiface-thumbnail {
      display: none;
      &.thumb-active {
        display: inline-block;
      }
    }
  }

  // Align with first thumb's padding-left
  &__main,
  &__shade {
    @include swap_direction(padding, 0 10px);
    @include breakpoint($medium-up) {
      display: block;
      padding-#{$rdirection}: 0;
    }
  }
  &__main {
    @include breakpoint($small-down) {
      padding-#{$ldirection}: 0;
    }
  }
  &__shade {
    text-align: center;
    position: relative;
    margin-bottom: 15px;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &__shade-name {
    @include h8;
  }
  &__shade-description {
    @include h9;
    line-height: 21px;
    min-height: 21px;
  }

  // SPP
  &--trigger {
    display: none;
  }
  &__zoom-trigger {
    color: $color-gray;
    font-size: 21px;
    text-decoration: none;
    position: absolute;
    #{$rdirection}: 0;
    top: 1em;
    &:hover {
      text-decoration: none;
    }
    span:hover {
      cursor: pointer;
    }
  }
  &__zoom-container {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0;
    width: 474px;
    height: 474px;
    z-index: 999;
  }
  //Mobile Only
  .spp-mobile-page & {
    // Slick navigation
    .slick-wrapper {
      width: 200px;
      @include swap_direction(margin, auto);
      .slick-track {
        height: 200px;
      }
    }
    .slick-prev,
    .slick-next {
      height: auto;
      &:before {
        @include transition(color 0.3s ease);
        font-size: 22px;
        @include breakpoint($medium-up) {
          font-size: 34px;
        }
      }
    }
    .slick-prev {
      #{$ldirection}: 8px;
      @include breakpoint($medium-up) {
        #{$ldirection}: 15px;
      }
      @include breakpoint($small-down) {
        #{$ldirection}: -20px;
      }
    }
    .slick-next {
      #{$rdirection}: 8px;
      @include breakpoint($medium-up) {
        #{$rdirection}: 15px;
      }
      @include breakpoint($small-down) {
        #{$rdirection}: -27px;
      }
      &:before {
        position: static;
      }
    }
  }
}

.shade-updating-message {
  display: none;
}
