$ml-thumb-margin-bottom: 47px;

// Landing page
.makeup-lessons-landing {
  @include swap_direction(padding, 0);
  .row {
    @include swap_direction(padding, 0);
  }
  .makeup-lessons__row {
    max-width: $max-width;
    @include swap_direction(margin, 0 auto);
    @include swap_direction(padding, 20px 0);
    border-top: 1px solid $color-gray;
    &:first-child {
      border-top: 0;
    }
  }
}

// Landing page header
.makeup-lessons__header {
  position: relative;
  color: $color-black;
  text-decoration: none;
  display: block;
  // Don't show the inline background image on mobile
  @include breakpoint($small-down) {
    background: none !important;
    &--image img {
      display: block;
    }
  }
  @include breakpoint($tablet) {
    margin-bottom: 5px;
  }
  background-repeat: no-repeat;
  background-position: $rdirection;
  max-width: $max-width;
  @include swap_direction(margin, 0 auto);
  /* Small trick to get the height of the header to assume the height of the bg image */
  &--image {
    //height: 328px;
    width: 100%;
    background-size: cover;
    @include breakpoint($tablet) {
      background: none;
      width: 1px;
      overflow: hidden;
      img {
        display: block;
        max-width: none;
        visibility: hidden;
      }
    }
  }
  &--content {
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    padding-left: 40%;
    text-align: center;
    @include breakpoint($tablet) {
      height: 100%;
      line-height: 100%;
    }
    @include breakpoint($large-only) {
      padding-left: 55%;
    }
    .inner {
      @include breakpoint($tablet) {
        top: 36%;
        @include vertical-center;
      }
    }
    h2,
    h4,
    #at-h2,
    .do-your-own-makeup {
      color: $color-black;
      text-align: center;
      margin-bottom: 0;
      @include breakpoint($tablet) {
        line-height: 44px;
        @include swap_direction(margin, 0);
      }
    }
    h2,
    #at-h2 {
      margin-top: 10px;
      font-size: 21px;
      line-height: 1em;
      letter-spacing: 4px;
      font-family: $brandon-text-bold;
      @include breakpoint($tablet) {
        font-size: 18px;
        letter-spacing: 3px;
        margin-bottom: 120px;
      }
    }
    h4,
    .do-your-own-makeup {
      font-size: 15px;
      @include breakpoint($tablet) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .multi {
      white-space: nowrap;
    }
    .multi--mobile-inline {
      display: inline-block;
      @include breakpoint($tablet) {
        display: block;
      }
    }
    .makeup-lessons__header--link {
      border: 1px solid $color-white;
      color: $color-white;
      display: inline-block;
      margin-top: 10px;
      @include swap_direction(padding, 5px 10px);
    }
  }
}

// Landing page rows and row formatter
.makeup-lessons-item {
  &__thumb,
  &__lesson {
    display: none;
  }
  &__thumb {
    border: 1px solid #d9d9d9;
    .makeup-lessons-items__thumbs & {
      display: inline-block;
    }
  }
  &__thumb-image {
    width: 100%;
  }
  &__lesson {
    a {
      white-space: nowrap;
    }
    .makeup-lessons-items__lessons & {
      display: inline-block;
      @include breakpoint($tablet) {
        display: block;
      }
    }
    .lesson_icon {
      display: none;
    }
    &.is-video {
      .makeup-lessons-item__lesson-button {
        background: url(/media/images/lessons/lesson_icon.png) no-repeat 0 -1px;
        background-position: 6px 6px;
        padding-#{$ldirection}: 27px;
        padding-#{$rdirection}: 5px;
        @include breakpoint($tablet) {
          background-position: 9px 12px;
          padding-#{$rdirection}: 10px;
          padding-#{$ldirection}: 33px;
        }
      }
      .lesson_icon {
        display: inline-block;
        display: none;
        background: url(/media/images/lessons/lesson_icon.png) no-repeat 0 -1px;
        margin-#{$rdirection}: 5px;
        height: 10px;
        width: 17px;
      }
    }
  }
  .makeup-lessons-item__lesson-button {
    font-size: 11px;
    border-width: 1px;
    @include breakpoint($tablet) {
      font-size: 14px;
      font-family: $brandon-text;
    }
  }
}

.makeup-lessons-items {
  position: relative;
  clear: both;
  overflow: hidden;
  @include swap_direction(padding, 0 20px);
  button {
    display: none;
  }

  // @TODO: we may want to globalize the slick carousel dots
  .slick-dots {
    bottom: -30px;
    li {
      @include swap_direction(margin, 0);
      border: 1px solid transparent;
      width: 12px;
      height: 12px;
      overflow: hidden;
      border: 2px solid $color-white;
      background: $color-gray;
      margin-#{$rdirection}: 5px;
      @include border-radius(50%);
      button {
        width: 12px;
        height: 12px;
        &:before {
          content: '';
          width: auto;
          height: auto;
          font-size: 45px;
        }
      }
      &:hover,
      &.slick-active {
        @include box-shadow(0 0 0 1px $color-pink);
      }
    }
  }
  &__thumbs {
    float: $ldirection;
    width: 106px;
    @include breakpoint($tablet) {
      width: 214px;
    }
  }
  &__meta,
  &__lessons {
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 250px;
    }
  }
  &__lessons {
    margin-top: 10px;
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 20px;
    }
  }
  &__meta {
    padding-#{$ldirection}: 120px;
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 0;
    }
    &--title {
      &__link {
        font-family: $brandon-text-medium;
        margin-top: -3px;
        margin-bottom: 3px;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1.2em;
        color: $color-black;
        text-decoration: none;
        &:hover {
          color: $color-pink;
          text-decoration: none;
        }
        @include breakpoint($tablet) {
          margin-top: 0;
          font-size: 24px;
        }
      }
    }
    &--description {
      @include breakpoint($tablet) {
        font-size: 19px;
        line-height: 1.5em;
      }
    }
  }
  &__lessons {
    @include breakpoint($tablet) {
      position: absolute;
      bottom: $ml-thumb-margin-bottom;
      #{$ldirection}: 0;
      width: 100%;
      clear: both;
    }
    .makeup-lessons-item {
      margin-bottom: 5px;
      display: inline-block;
      @include breakpoint($tablet) {
        display: block;
        float: $ldirection;
        margin-#{$ldirection}: 9px;
        margin-bottom: 0;
      }
      &:first-child {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .makeup-lessons-item__wrap {
    @include swap_direction(padding, 20px 0);
    border-bottom: 1px solid $color-gray;
    @include breakpoint($tablet) {
      @include swap_direction(padding, 30px 0 10px 0);
    }
  }
}

.makeup-lessons__tout {
  background: $color-light-brown;
  text-align: center;
  @include swap_direction(padding, 15px 10px);
  margin-top: -1px; //Hide makeup lessons border
  @include breakpoint($tablet) {
    @include swap_direction(padding, 25px 10px);
  }
  .section-header {
    margin-bottom: 10px;
  }
  .section-header__inner {
    background: $color-light-brown;
  }
  .icon-livechat {
    display: block;
    font-size: 24px;
    margin-bottom: 4px;
    @include breakpoint($tablet) {
      display: inline-block;
      font-size: 52px;
      margin-#{$rdirection}: 10px;
    }
  }
  &-header {
    text-transform: uppercase;
    font-family: $brandon-text-medium;
    margin-bottom: 5px;
    @include breakpoint($tablet) {
      font-size: 32px;
      letter-spacing: 7px;
    }
  }
  .button {
    margin-top: 10px;
    background: $color-light-brown;
    @include breakpoint($tablet) {
      margin-top: 35px;
      margin-bottom: 5px;
      border-width: 1px;
    }
  }
}

.makeup-lesson-page {
  &__share {
    float: $rdirection;
    margin-top: -4px;
    @include breakpoint($tablet) {
      margin-top: -1px;
    }
    a {
      font-size: 24px;
      margin-#{$ldirection}: 16px;
      color: $color-black;
      text-decoration: none;
      &:hover {
        color: $color-pink;
      }
    }
  }
  &__all-link {
    text-decoration: none;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    &:hover {
      text-decoration: none;
    }
  }
  &__video-icon {
    display: inline-block;
    background: url(/media/images/lessons/lesson_icon.png) no-repeat 0 -1px;
    margin-#{$rdirection}: 5px;
    height: 7px;
    width: 13px;
    background-size: 100% auto;
    @include breakpoint($tablet) {
      vertical-align: middle;
      width: 20px;
      height: 13px;
    }
  }
  &__slide-type {
    margin-top: 3px;
  }
  &__slider {
    background: $color-light-brown;
    margin-#{$ldirection}: -20px;
    margin-#{$rdirection}: -20px;
    padding-#{$ldirection}: 35px;
    padding-#{$rdirection}: 25px;
    .slick-track {
      @include swap_direction(margin, auto);
    }
    .slick-next,
    .slick-prev {
      height: 18px;
      width: 22px;
      &:before {
        font-size: 22px;
      }
      @include breakpoint($tablet) {
        height: 50px;
        width: 45px;
        margin-top: -29px;
        &:before {
          font-size: 50px;
        }
      }
    }
    .slick-prev {
      #{$ldirection}: 5px;
    }
    .slick-next {
      #{$rdirection}: 0;
      &:before {
        #{$rdirection}: 0;
      }
    }
    .makeup-lesson-page__slider-slide {
      text-align: center;
      margin-#{$rdirection}: 10px;
      @include transition(opacity 0.3s);
      @include opacity(0.5);
      &.slick-showing {
        @include opacity(1);
      }
      &.slick-showing .makeup-lesson-page__slide-wrap .makeup-lesson-page__slide-image {
        border-color: $color-black;
      }
      &-type {
        font-size: 11px;
        @include breakpoint($tablet) {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
    .makeup-lesson-page__slide-wrap {
      max-width: 100%;
      display: inline-block;
      @include swap_direction(padding, 15px 0 10px);
      @include breakpoint($tablet) {
        padding-top: 30px;
        padding-bottom: 15px;
      }
      // On hover change the image border color
      &:hover {
        cursor: pointer;
        .makeup-lesson-page__slide-image {
          border-color: $color-black;
        }
      }
    }
    .makeup-lesson-page__slide-image {
      @include swap_direction(margin, auto);
      max-width: 100%;
      width: 80px;
      border: 1px solid $color-gray;
      @include transition(border-color 0.3s);
      @include breakpoint($tablet) {
        width: 160px;
      }
    }
    .makeup-lesson-page__content {
      display: none;
    }
    .makeup-lesson__event {
      display: none;
    }
  }
  &__name {
    text-align: center;
    font-size: 18px;
    .section-header {
      @include breakpoint($tablet) {
        margin-bottom: 12px;
      }
    }
  }
  &__content-container .makeup-lesson-page__slide-wrap {
    display: none;
  }
  &__before-after {
    text-align: center;
    font-size: 0;
    margin-top: 15px;
    display: table;
    @include breakpoint($tablet) {
      max-width: 830px;
      @include swap_direction(margin, auto);
      margin-top: 28px;
    }
    &__column {
      display: table-cell;
      width: 50%;
      vertical-align: top;
      padding-#{$rdirection}: 5px;
      text-align: $ldirection;
      img {
        display: block;
        width: 100%;
      }
      &:last-child {
        text-align: $rdirection;
        padding-#{$rdirection}: 0;
        padding-#{$ldirection}: 5px;
      }
    }
    &__image {
      display: block;
      @include swap_direction(margin, auto);
      margin-bottom: 10px;
      @include breakpoint($tablet) {
        margin-bottom: 25px;
      }
    }
    &__text {
      font-size: 12px;
      text-align: center;
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include breakpoint($tablet) {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
  }
  &__video {
    .bobbis-lessons__footer .link--get-the-look .icon {
      display: none;
    }
    .bobbis-lessons {
      border-bottom: 2px solid $color-gray-alt;
      margin-bottom: 10px;
      .bobbis-lessons__footer .bobbis-lessons__social li a {
        color: $color-black;
      }
      .bobbis-lessons__footer .link--get-the-look {
        .icon-arrow_down,
        .icon-arrow_up {
          display: none;
        }
      }
      .bobbis-lessons__footer .link--get-the-look {
        border: 2px solid $color-pink;
        &:hover {
          border-color: $color-black;
        }
      }
      .link--get-the-look {
        @include button--outline;
      }
      .bobbis-lessons__container {
        background: $color-white;
        color: $color-black;
      }
      .bobbis-lessons__header,
      .bobbis-lessons__headline {
        color: $color-black;
      }
      .link--get-the-look {
        color: $color-pink;
      }
    }
    &--no-border .bobbis-lessons {
      border-bottom: none;
    }
  }
  &__products-link {
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $brandon-text-bold;
    border: 1px solid $color-gray-background;
    border-width: 1px 0;
    display: block;
    @include swap_direction(padding, 10px 0);
    letter-spacing: 3px;
    &:hover {
      text-decoration: none;
    }
  }
  &__shop-all-products {
    @include swap_direction(padding, 40px 0);
    .active-text {
      display: none;
    }
    &.active {
      .active-text {
        display: block;
      }
      .non-active-text {
        display: none;
      }
      .makeup-lesson-page__shop-all-products__grid {
        padding-top: 40px;
      }
    }
  }
  &__finished-look {
    text-align: center;
    &__header {
      font-family: $brandon-text-bold;
    }
  }
  .makeup-lesson-page__video + .makeup-lesson__step {
    border-top: none;
  }
  .makeup-lesson__event-image {
    display: table-cell;
    width: 50%;
    vertical-align: top;
    @include breakpoint($tablet) {
      width: 400px;
      max-width: 50%;
    }
  }
}

.makeup-lesson__step {
  border-top: 1px solid $color-gray-light-alt;
  position: relative;
  &:last-child {
    border-bottom: none;
    margin-bottom: 10px;
  }
  .icon-arrow {
    @include icon('arrow_down');
    @include transition(all 0.3s);
    position: absolute;
    #{$rdirection}: 5px;
    color: $color-gray-dark;
  }
  &-header--desktop {
    display: none;
  }
  &-header {
    text-transform: uppercase;
    @include swap_direction(padding, 5px 0);
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  &-info {
    display: none;
    &.active {
      display: table;
      width: 100%;
    }
    &__row {
      display: table-row;
    }
    &__cell {
      display: table-cell;
      vertical-align: top;
      position: relative;
      &--image {
        width: 106px;
        padding-bottom: 10px;
        padding-#{$rdirection}: 10px;
      }
      &--info {
        padding-#{$ldirection}: 0;
        padding-bottom: 50px;
      }
    }
    @include breakpoint($tablet) {
      display: table;
      width: 100%;
      @include swap_direction(padding, 40px 50px);
      &__row {
        display: table-row;
      }
      &__cell {
        display: table-cell;
        vertical-align: top;
        position: relative;
        &--image {
          width: 190px;
          padding-bottom: 0;
        }
        &--info {
          padding-#{$ldirection}: 30px;
        }
      }
    }
  }
  &-number {
    color: $color-black;
    font-family: $brandon-text-medium;
    display: inline-block;
  }
  &-name {
    display: inline-block;
  }
  &-image {
    width: 96px;
    height: auto;
    @include breakpoint($tablet) {
      width: 190px;
      float: none;
      @include swap_direction(margin, 0);
    }
  }
  &-description {
    font-size: 12px;
    margin-top: -3px;
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 0;
    }
  }
  &-tip {
    font-size: 10px;
    font-style: italic;
    @include swap_direction(margin, 15px 0 0 0);
    // WYSIWYG
    p {
      @include swap_direction(margin, 0);
    }
    strong,
    b {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      font-style: normal;
    }
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 0;
      font-size: 16px;
    }
  }
  &-cta .button {
    @include opacity(0);
    border-width: 1px;
    font-size: 9px;
    position: absolute;
    bottom: 10px;
    @include transition(all 0.3s);
    @include breakpoint($tablet) {
      bottom: 0;
      @include opacity(1);
      font-size: 14px;
    }
    .active-text {
      display: none;
    }
    &.active {
      .non-active-text {
        display: none;
      }
      .active-text {
        display: inline;
      }
    }
  }
  &.active .icon-arrow {
    @include transform(rotate(-180deg));
  }
  &.active .makeup-lesson__step-cta .button {
    @include opacity(1);
  }
  @include breakpoint($tablet) {
    &-header {
      display: none;
    }
    &-header--desktop {
      font-family: $brandon-text-medium;
      display: block;
      font-size: 24px;
      margin-bottom: 10px;
      &:hover {
        cursor: auto;
      }
    }
    &-description {
      font-size: 16px;
      @include swap_direction(margin, 0);
      line-height: 1.5em;
    }
    &-info {
      display: block;
    }
  }
  &-product-grid__wrapper {
    position: relative;
    .icon-close {
      position: absolute;
      #{$rdirection}: 0;
      top: 10px;
      font-size: 14px;
      @include breakpoint($tablet) {
        top: 20px;
        font-size: 24px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-product-grid {
    // @include icon("quickshop_arrow");
    // &:before {
    //   position: absolute;
    //   z-index: 2;
    //   color: $color-gray;
    //   background: $color-white;
    //   letter-spacing: 0;
    //   top: 0;
    //   #{$ldirection}: 75%;
    //   margin-#{$ldirection}: -13px;
    // }
    @include swap_direction(padding, 30px 0 0);
    border-top: 1px solid $color-gray-light-alt;
    @include breakpoint($tablet) {
      @include swap_direction(padding, 40px 0 0);
    }
    .product-grid__add_all_to_bag .product-thumb__cta {
      border-bottom-width: 1px;
      @include swap_direction(padding, 0.75em 1em);
    }
  }
}

.at-div_makeup-lesson {
  margin-bottom: 4px;
}

.makeup-lesson__event {
  @include swap_direction(padding, 40px 0);
  &--mobile {
    display: block;
  }
  &--desktop {
    display: none;
  }
  @include breakpoint($tablet) {
    &--mobile {
      display: none;
    }
    &--desktop {
      display: block;
    }
  }
  &-images {
    font-size: 0;
    text-align: center;
    display: table;
    width: 100%;
  }
  &-image {
    display: table-cell;
  }
  &-products {
    display: table;
    width: 100%;
    margin-top: 30px;
    &.makeup-lesson__event--desktop {
      display: none;
    }
    @include breakpoint($tablet) {
      &.makeup-lesson__event--mobile {
        display: none;
      }
      &.makeup-lesson__event--desktop {
        display: table;
      }
    }
    &__column {
      display: table-cell;
      width: 50%;
      padding-#{$rdirection}: 15px;
      vertical-align: top;
      &:last-child {
        padding-#{$ldirection}: 15px;
        padding-#{$rdirection}: 0;
        border-#{$ldirection}: 1px solid $color-black;
        @include breakpoint($tablet) {
          padding-#{$ldirection}: 50px;
        }
      }
    }
  }
  .makeup-lesson__event-products__container + .makeup-lesson__event-products__container {
    margin-top: 25px;
  }
  &-cta {
    text-align: center;
    @include swap_direction(margin, 25px 0);
    .button {
      .active-text {
        display: none;
      }
      &.active {
        .non-active-text {
          display: none;
        }
        .active-text {
          display: block;
        }
      }
    }
    @include breakpoint($tablet) {
      text-align: $ldirection;
    }
  }
  &-tip {
    text-align: center;
    background: $color-gray-background;
    @include swap_direction(padding, 10px 30px);
    @include breakpoint($tablet) {
      padding-#{$ldirection}: 80px;
      padding-#{$rdirection}: 50px;
      background: $color-white;
    }
    .section-header__inner {
      background: $color-gray-background;
      @include breakpoint($tablet) {
        background: $color-white;
      }
    }
    &__content {
      font-style: italic;
      @include breakpoint($tablet) {
        font-size: 24px;
        line-height: 1.5em;
      }
    }
    &__author {
      font-family: $brandon-text-bold;
      font-size: 12px;
      text-transform: uppercase;
      margin-top: 10px;
      @include breakpoint($tablet) {
        font-size: 18px;
      }
    }
  }
  &-product__header {
    text-transform: uppercase;
    font-family: $brandon-text-bold;
  }
  &-product-hex {
    display: inline-block;
    width: 12px;
    height: 12px;
    @include border-radius(50%);
    // Used for white shades
    &.light-border {
      border: 1px solid #ddd;
    }
  }
  &-product-grid__wrapper {
    position: relative;
    .icon-close {
      position: absolute;
      #{$rdirection}: 0;
      top: 10px;
      font-size: 14px;
      @include breakpoint($tablet) {
        top: 20px;
        font-size: 24px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-product-grid {
    @include swap_direction(padding, 40px 0);
    margin-top: 40px;
    border-top: 1px solid $color-gray-background;
    .link {
      border-bottom-width: 1px;
      padding-bottom: 0.75em;
    }
  }
}
