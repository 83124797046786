.range-slider {
  position: relative;
  // helping out if parent is using flex
  display: inline-block;
  max-width: 100%;
  width: 100%;

  $track-color: #e4d1a7;
  $track-color-end: #fdf1d9;
  $track-gradient: true;
  $track-border-width: 0px;
  $track-height: 30px;
  $thumb-border-width: 0px;

  $thumb-shadow-color: rgba(255, 255, 255, 0);
  $thumb-shadow-size: 0px;
  $thumb-shadow-blur: 0px;
  $thumb-shadow-blur-spread: 0px;

  $thumb-height: 1px;
  $thumb-width: 1px;
  $thumb-color: transparent !important;

  $track-radius: 0px;

  @import "inputrange";

  input[type=range] {
    padding: 0px;
  }
  &__labels {
    display: flex;
    flex-direction: row;
  }
  &__label {
    flex: 1 1;
    justify-content: space-between;
    &--start {
      text-align: left;
    }
    &--middle {
      text-align: center;
    }
    &--end {
      text-align: right;
    }
  }
}

.range-slider-carousel {
  margin: 10px auto;
  @include breakpoint($medium-up) {
    margin: 50px auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0px auto;
    max-width: 100vw;
    @include breakpoint($medium-up) {
      max-width: 1120px;
    }
  }
  &__carousel {
    max-width: 100%; // we need this cuz of the flex parent
    .range-slider-item {
      margin: 0px 5px;
    }
  }
  &__header {
    text-align: center;
    margin-bottom: 15px;
    order: 0;
  }
  &__title {
    text-align: center;
  }
  &__header-cta {
    text-align: center;
    margin-top: 4px;
  }
  &__slider-ticks {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    position: relative;
    @include breakpoint($medium-up) {
      margin: 0px;
    }
    &__inner {
      width: 95vw;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__item {
      flex: 1;
      text-align: center;
      &:first-child {
        flex: .5;
        text-align: left;
      }
      &:last-child {
        flex: .5;
        text-align: right;
      }
    }
  }

  &--hide-range-slider &__slider {
    display: none !important;
  }

  &__slider-ticks-menu {
    order: 1;
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 9px;
    width: calc(100% - 20px);
    @include breakpoint($medium-up) {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .basic-formatter {
      background: transparent;
    }

    .basic-formatter__items {
      display: flex;
      justify-content: space-between;
    }
  }

  &__slider {
    order: 0;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    width: calc(100% - 40px);
    position: relative;
    @include breakpoint($medium-up) {
      margin: 20px 0px 20px;
    }
    .js-slider-thumb {
      position: absolute;
      top: 0px;
      left: 50%; // default to left
      transform: translateX(-50%);
      z-index: 10;
      border: solid #000;
      border-color: #000 transparent transparent;
      border-width: 11px 9px;
      top: -4px;
    }
    [type='range'] {
      background-color: transparent;
      width: 100%;
      max-width: 100%;
      margin: 0px;
    }
    &__label {
      position: absolute;
      top: -30px;
      color: #c5b9ac;
      font-size: 12px;

      &--start {
        left: 5px;
      }
      &--end {
        right: 5px;
      }
    }
  }
  &__carousel {
    order: 1;
  }
  &__content-well {
    order: 5;
    display: none;
    flex-direction: column;;
    justify-content: center;
    align-items: center;
    max-width: 100%; // we need this cuz of the flex parent
    @include breakpoint($medium-up) {
    }

    &.slick-initialized {
      display: block;
    }

    > div {
      width: 100%;
      //display: none;
    }
    // we use product-full from partials
    // but we don't want any of its extra spacing
    .product-full {
      margin: 0px;
      padding: 0px;
    }
  }

  &__footer {
    order: 10;
    &_text {
      text-align: center;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.035em;
    }
  }
  .slide-active &__footer {
    display: none;
  }
}

.range-slider-item {
  position: relative;
  .sku-toggle {
    display: none;
  }
  &__image {
    position: relative;
    overflow: hidden;

    &-inner {
      position: relative;
    }

    &:before {
      top: 0px;
      left: 0px;
      z-index: 10;
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &__image-text {
    width: 80%;
    color: $color-white;
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 12px;
    left: 50%;
    @include transform(translateX(-50%));
    line-height: 1.2em;
    .range-slider-carousel--image-text-overlay & {
      background: rgba(0, 0, 0, .4);
      .sku-toggle {
        padding: 5px 3px;
      }
    }
  }
  .slide-active & {
    &__image {
      &:before {
        background-color: rgba(255, 2550, 255, .5);
      }
    }
  }

  .slide-selected & {
    &__image {
      &:before {
        display: none;
      }
    }
  }
}
