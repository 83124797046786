.anchored-menu-reference {
  ul {
    margin: 0 auto;
    padding: 20px;
    background-color: $color-white;
    display: block;
    text-align: center;
    .menu-mpp & {
      display: block;
    }
  }
  li {
    text-align: center;
    padding: 0 20px;
    display: inline-block;
    .mpp-menu-text & {
      margin: 0 20px;
    }
  }
  a {
    color: $color-black;
    text-decoration: none;
    &.active {
      text-decoration: underline;
    }
    .menu-mpp & {
      &.active {
        text-decoration: none;
      }
      &.menu-active,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.menu-mpp-sticky {
  .sticky-node {
    &__menu {
      font-family: $brandon-text-bold;
      color: $color-black;
      text-transform: uppercase;
      text-decoration: none;
      margin: 9px 18px;
      &.menu-active {
        text-decoration: underline;
      }
    }
    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__pipe {
      margin: 18px 0;
    }
    .basic-responsive {
      &::after {
        display: none;
      }
    }
  }
  .sticky-node-content {
    display: block;
  }
}

.sticky-node {
  .basic-responsive {
    position: relative;
    display: inline-block;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      #{$rdirection}: 10px;
      top: 22px;
      border: solid $color-black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: none;
      background: $color-white;
      font-family: $brandon-text-bold;
      min-width: 150px;
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
      margin: 0;
      line-height: 1.2;
      &::-ms-expand {
        display: none;
      }
    }
  }
  &-content {
    background: $color-white;
    text-align: center;
    border-bottom: 1px solid $color-gray-light;
    @include breakpoint($medium-up) {
      background: none;
      border: none;
      text-align: #{$ldirection};
    }
  }
}
