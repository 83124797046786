.device-mobile {
  .content-block {
    &--hero {
      .position--bare,
      .position-image,
      .position-text {
        .content-block {
          &.content-block {
            &__content-wrapper {
              position: static;
            }
          }
        }
      }
    }
    .button--bare {
      line-height: 1.8;
    }
  }
  .basic-carousel {
    &__container {
      position: relative;
    }
  }
  .carousel-dots {
    position: absolute;
    top: 420px;
    width: 100%;
  }
  .basic-carousel-formatter {
    ul {
      &.slick-dots {
        li {
          button {
            &:before {
              color: $color-black;
            }
          }
        }
      }
    }
  }
  .site-header {
    height: 90px;
    &__smart-menu {
      padding: 12px 5px;
    }
  }
  &.section-home {
    .site-content {
      margin-bottom: 40px;
    }
  }
}

.site-header {
  .site-email-signup {
    @include breakpoint($medium-up) {
      width: 400px;
    }
    &__submit {
      @include breakpoint($medium-up) {
        margin-top: 20px;
      }
    }
  }
}
