.customer-service-contact-form {
  @include clearfix;
  &__categories {
    @include clearfix;
    width: 100%;
    @include swap_direction(margin, 1em 0 !important);
    @include swap_direction(padding, 0);
  }
  &__category {
    display: block;
    float: $ldirection;
    width: 22%;
    height: 70px;
    @include swap_direction(margin, 0 3% 0 0);
    @include swap_direction(padding, 1em 20px);
    font-family: $body-font-bold;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    list-style-type: none;
    border: 1px solid $color-black;
    cursor: pointer;
    @include breakpoint($small-down) {
      width: 44%;
      @include swap_direction(margin, 1em 2%);
    }
  }
  &__category.active-category {
    color: $color-white;
    background-color: $color-pink;
    border: none;
  }
  input,
  .button-loading {
    width: 47%;
    @include swap_direction(margin, 0.5em 3% 0.5em 0);
    text-transform: capitalize;
    @include breakpoint($small-down) {
      width: 97%;
      height: 30px;
    }
    &[name='EMAIL_ADDRESS'],
    &[name='EMAIL_ADDRESS2'] {
      text-transform: none;
    }
  }
  .button-loading {
    padding-top: 6px;
  }
  textarea {
    width: 97%;
    @include swap_direction(margin, 0.5em 3% 0.5em 0);
    @include swap_direction(padding, 5px 10px);
  }
  select {
    width: 47%;
    @include swap_direction(margin, 0.5em 3% 0.5em 0);
    @include breakpoint($small-down) {
      width: 97%;
    }
  }
  &__errors {
    color: $color-pink;
  }
  &__radio-item,
  &__radio-item--use-products,
  &__radio-item--site-member {
    display: block !important;
    @include swap_direction(margin, 1em 0);
  }
  &__radio-item--use-products,
  &__radio-item--site-member {
  }
}
