.sticky-node {
  .filter-fixed-top {
    position: fixed;
    top: 54px;
    left: 0;
    right: 0;
    z-index: 995;
    @if $cr22 == true {
      @include transform(translateY(84px));
      @include transition(transform 150ms ease 0s);
      top: 0;
      @include breakpoint($large-up) {
        @include transform(translateY(97px));
      }
      .site-header-formatter--hidden & {
        @include transform(translateY(0));
      }
    }
    @include breakpoint($medium-up) {
      z-index: 1000;
    }
    .menu-mpp-sticky & {
      top: 94px;
      @include breakpoint($medium-up) {
        top: 54px;
      }
    }
  }
}
