.responsive-tabs--bazaarvoice {
  text-align: center;
  max-width: 1024px;
  @include swap_direction(
    margin,
    0 auto !important
  ); /* "!important" required to override the margin added by responsive tab js */

  .resp-tabs-list {
    @include breakpoint($tablet) {
      display: inline-flex;
    }
  }
  .resp-tab-item {
    margin-bottom: 0;
    background-color: $color-white;
    border-bottom-color: $color-black;
    &.resp-tab-active {
      background-color: $color-gray-background;
      border-bottom: solid 1px $color-gray-background;
    }
  }
  .resp-tab-content {
    @include swap_direction(padding, 0);
  }
}

.bv_hide {
  display: none;
}

.bv_auth_flow {
  min-height: 450px;
  .bv_prod_img {
    width: 49%;
    float: $ldirection;
  }
  .email_check,
  .full_sign_in {
    padding-top: 50px;
    width: 49%;
    float: $rdirection;
  }
  h3,
  form .row {
    padding-bottom: 20px;
  }
}
/* mobile */
.device-mobile {
  .resp-tabs-list {
    display: none;
  }
  .responsive-tabs--bazaarvoice h2.resp-accordion {
    color: black;
    @include swap_direction(padding, 30px 20px 10px 20px);
    background-color: $color-gray-background;
    text-align: center;
  }
}
