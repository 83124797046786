.callout-alt {
  text-align: center;
  padding: 25px 0;
  &__wrapper-link {
    color: $color-black;
    display: block;
    outline: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__title {
    font-family: $brandon-text;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      background-color: $color-white;
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.2em;
      text-align: center;
      position: relative;
      display: inline-block;
      @include swap_direction(margin, 0 auto 30px auto);
      @include swap_direction(padding, 0 33px);
      &:before {
        content: '';
        background: #000000;
        width: 100%;
        height: 2px;
        display: inline-block;
        position: absolute;
        top: 43%;
        #{$ldirection}: 0;
      }
      span {
        background: $color-white;
        display: inline-block;
        position: relative;
        @include swap_direction(padding, 0 7px 0 9px);
      }
    }
  }
  &__content {
    @include pie-clearfix;
    margin-bottom: 20px;
  }
  &__col {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      float: $ldirection;
      text-align: center;
      position: relative;
      width: 33.3333%;
    }
  }
  &__col--2 {
    &:before {
      content: '+';
      font-family: $brandon-text-bold;
      font-size: 60px;
      #{$ldirection}: -10px;
      margin-top: -30px;
      position: absolute;
      top: 50%;
    }
    &:after {
      content: '+';
      font-family: $brandon-text-bold;
      font-size: 60px;
      margin-top: -30px;
      position: absolute;
      #{$rdirection}: -10px;
      top: 50%;
    }
  }
  &__subtitle {
    color: $color-black;
    font-family: $brandon-text;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include breakpoint($large-up) {
      font-size: 27px;
      line-height: 32px;
    }
    span {
      color: $color-pink;
    }
  }
  &__image {
    margin-bottom: 20px;
    @include swap_direction(padding, 0 5%);
  }
  &__description {
    font-family: $brandon-text;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    @include swap_direction(margin, 0);
    text-transform: uppercase;
  }
  .button {
    background-color: #fff;
    border: 1px solid $color-pink;
    color: $color-pink;
  }
  &__mobile-image {
    display: block;
    @include swap_direction(margin, 0 auto);
    max-width: 300px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}
