///
/// @file style.scss
///
/// \brief Pull all parts of the theme scss together into one file
///
$base-theme-path: '/sites/bobbibrown/themes/bb_base/';
$img-base-url: '../img/';

// Virtual Appointment Enabling variable
$virtual-appointment: false !default;

$disable-bbv2-in-checkout: false !default;

@import "theme-bootstrap";
@import "mantle/mantle";

/// Library styles
@import 'vendor/colorbox';
@import 'vendor/responsive-tabs';
@import 'vendor/slick';
@import 'vendor/tooltipster';

///
/// SMACSS/Components
/// Styling of individual site components.
/// http://smacss.com/book/type-module

@import 'components/forms';
@import 'components/lists';
@import 'components/tables';
@import 'components/header';
@import 'components/site-404';
@import 'components/site-utilities';
@import 'components/homepage-hero';
@import 'components/homepage-slide-module';
@import 'components/slider-module';
@import 'components/swatches';
@import 'components/product-thumb';
@import 'components/about';
@import 'components/bobbis-lessons';
@import 'components/double-feature';
@import 'components/featured-products-carousel';
@import 'components/product-recommendation';
@import 'components/product-promo';
@import 'components/curtains';
@import 'components/callout';
@import 'components/callout-alt';
@import 'components/responsive-tabs';
@import 'components/overlay';
@import 'components/_bundle-hero.scss';
@import 'components/products/product-grid';
@import 'components/products/product-full';
@import 'components/products/product-gallery';
@import 'components/products/shade-picker';
@import 'components/products/auto-replenishment';
@import 'components/products/product-filters';
@import 'components/products/bazaarvoice';
@import 'components/products/foundation-finder';
@import 'components/products/skincare-finder';
@import 'components/products/brush-finder';
@import 'components/products/spp-how-to-use';
@import 'components/products/product-guide-item';
@import 'components/products/product-guide-item-bundle';
@import 'components/products/product_palette_overlay';
@import 'components/products/product-le-shades';
@import 'components/products/product-image-atb';
@import 'components/products/product-sku-quickview';
@import 'components/products/product-quote';
@import 'components/products/remedies-samples';
@import 'components/products/product_store_check';
@import 'components/products/shade-dot-filter';
@import 'components/products/_bundle-layout.scss';
@import 'components/products/_bundle-flow.scss';
@import 'components/products/_bundle-review.scss';
// @import "components/products/product-single";
@import 'components/afterpay-faq';
@import 'components/store_locator';
@import 'components/cart_confirm';
@import 'components/fave-confirm';
@import 'components/custom_scrollbar';
@import 'components/endeca/search-form';
@import 'components/endeca/search-results';
@import 'components/endeca/search-pagination';
@import 'components/endeca/esearch_nav';
@import 'components/endeca/esearch_nav_voice';
@import 'components/email-sign-up';
@import 'components/sign-in';
@import 'components/offers';
@import 'components/offerspick';
@import 'components/email-sms-signup';
@import 'components/email-sms-thank-you';
@import 'components/bobbi-pro';
@import 'components/makeup-lessons';
@import 'components/appointment-booking';
@import 'components/chat-landing';
@import 'components/online-services';
@import 'components/makeup_workshops';
@import 'components/duty-free';
@import 'components/popup-offer';
@import 'components/product-gtl';
@import 'components/product_collection_hero';
@import 'components/product_collection_hero_v3';
@import 'components/product_detail_hero_v6';
@import 'components/collection_hero_popup';
@import 'components/product_collection_footer';
@import 'components/auto-replenish';
@import 'components/foundation-dramming';
@import 'components/egiftcard_cashstar';
@import 'components/skin_foundation';
@import 'components/skin_foundation_weightless';
@import 'components/pro-how-to-block';
@import 'components/discover-foundation-block';
@import 'components/video_carousel_module';
@import 'components/video';
@import 'components/foundation-dramming-page';
@import 'components/appointment_booking_v2';
@import 'components/_terms-tooltip';

//Homepage Modules
@import 'components/mpp-custom-banner';
@import 'components/how-to';
@import 'components/basic-banner-clickable-image';

//LBO
@import 'components/link-based-offer';
@import 'common';
@import 'page-mpp';
@import 'page-spp';
@import 'mobile-nav';

///
/// SMACSS/State
/// Helper classes that allow us to control dynamic states throughout css, like
/// on/off, active/disabled, alternate, etc
///
/// These are placed last so that they can override any prior styling
///
//@import "state/state";

// PERLGEM
@import 'sections/perlgem-shared';

//LOYALTY
@import 'sections/loyalty/loyalty-points';
@import 'sections/loyalty/loyalty-offers';
@import 'sections/loyalty/loyalty-offers-sku';
@import 'sections/loyalty/loyalty-transactions';
@import 'sections/loyalty/loyalty-benefits';
@import 'sections/loyalty/loyalty-cancel';
@import 'sections/loyalty/loyalty-checkout';
@import 'sections/loyalty/loyalty-confirmation';

// CUSTOMER SERVICE
@import 'sections/service/customer-service';
@import 'sections/service/customer-service-header';
@import 'sections/service/customer-service-menu';
@import 'sections/service/customer-service-quick-info';
@import 'sections/service/customer-service-section';
@import 'sections/service/customer-service-landing-block';
@import 'sections/service/customer-service-contact-form';

// GIFTCARDS
@import 'sections/giftcards';

// GIFTWRAP
@import 'sections/giftwrap';

// Bazaarvoice
@import 'sections/reviews';

// Menu
@import 'components/anchored_menu';

// Evergreen
@import 'components/evergreen/corrector-concealer';
@import 'components/evergreen/top-10';

//Power reviews
@import 'sections/power_review/_power-review-base';

// Skin Glow
@import 'components/collections/skin-glow';

//Soothing Cleansing Milk
@import 'components/collections/soothing_milk';

// Skin Cushion
@import 'components/collections/skin_cushion';

// Extra Skincare
@import 'components/collections/extra-skincare';

// LW Liquid Liner
@import 'components/collections/liquid_liner';

//Skin Long-Wear Weightless
@import 'components/collections/slww';

//Skin Long-Wear Weightless Influncers
@import 'components/collections/slww-influencers';

//Extra Glow
@import 'components/collections/extra_glow_collection';

//Sticky Node Plus
@import 'components/sticky-node';

//Facebook Live
@import 'components/fb';

//HP Counter Banner
@import 'components/hp_counter_banner.scss';

// OVERLAY OFFER
@import 'components/overlay-offer/overlay-offer-signup';
@import 'components/overlay-offer/overlay-offer-thanks';
@import 'components/overlay-offer/overlay-offer-error';

// Back In Stock Functionality
@import 'components/bis-functionality/bis-signup';
@import 'components/bis-functionality/bis-thanks';

// Live Chat 2015
@import 'components/chat/mobile_livechat.scss';

// Olapic
@import 'components/olapic-block';
@import 'components/foundation-finder';

// Appointment Booking Reskin 2019
@if $ab_skin_v2 == true {
  @include appt_booking_skin_v2;
}

// Social Login
@import "components/_social_login";

.not-logged-in.page-user {
  .site-header__nav {
    display: none;
  }
}
