$iphone-portrait-only: max-width 375px !default; // iphone 6 portrait
// mobile specific adjustments
$screen: 'only screen' !default;
$landscape: '#{$screen} and (orientation: landscape)' !default;
$portrait: '#{$screen} and (orientation: portrait)' !default;
// large android tablets
$device-tablet-android: '#{$screen} and (min-width: 800px) and (max-width: 1280px)' !default; // large android tablets

.video-carousel__block__header {
  @include swap_direction(margin, 0 auto);
  color: $color-white;
  text-align: center;
  font-size: 35px;
  font-family: $miller-banner-light;
  line-height: 45px;
  background-color: $color-black;
  max-width: 1024px;
  @include swap_direction(padding, 30px 22px 22px 22px);
}

.video-carousel__block {
  background-color: $color-black;
  min-height: 695px;
  position: relative;
  max-width: 1024px;
  @include swap_direction(margin, 0 auto);
  @include breakpoint($tablet-down) {
    min-height: 545px;
  }
  .device-mobile & {
    @include breakpoint($iphone-portrait-only) {
      min-height: 395px;
    }
  }
  @media #{$device-tablet-android} {
    @media #{$portrait} {
      min-height: 735px;
    }
    @media #{$landscape} {
      min-height: 745px;
    }
  }
  .slick-dots {
    top: 0;
    height: 110px;
    @include breakpoint($tablet-down) {
      height: 85px;
    }
    .slick-active {
      outline-color: $color-white;
      outline-style: solid;
    }
  }
  .slick-dots li {
    height: 110px;
    width: 147px;
    @include breakpoint($tablet-down) {
      height: 85px;
      width: 114px;
    }
    border-width: 0.25px;
    border: 1px solid $color-gray-tooltip;
    @include swap_direction(margin, 0 4px);
  }
  .video-carousel__related_products__button {
    bottom: 35px;
    position: absolute;
    display: block;
    color: $color-white;
    @include swap_direction(margin, 0 auto);
    width: 100%;
    text-align: center;
    font-family: $brandon-text-bold;
    letter-spacing: 2px;
    text-decoration: underline;
    cursor: pointer;
    .device-mobile & {
      padding-top: 15px;
      padding-bottom: 15px;
      text-decoration: none;
      width: 75%;
      position: initial;
      border: 1px solid $color-white;
      margin-bottom: 25px;
    }
  }
  .video__image__thumb {
    display: table-cell;
    width: 147px;
    padding: 3px;
    @include breakpoint($tablet-down) {
      width: 112px;
      height: 85px;
      background-size: 112px 83px;
      font-size: 11px;
      line-height: 13px;
    }
    height: 108px;
    background-size: 147px;
    background-repeat: no-repeat;
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    font-family: $brandon-text-bold;
    letter-spacing: 2px;
  }
  .video-block__media {
    max-width: 768px;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($tablet-down) {
      max-width: 600px;
      @include swap_direction(margin, 0 auto);
    }
  }
  .slick-list {
    top: 120px;
    .device-mobile & {
      top: 0px;
    }
    @include breakpoint($tablet-down) {
      top: 93px;
    }
  }
  .video-block__content {
    @include swap_direction(margin, 0 auto);
    @include breakpoint($iphone-portrait-only) {
      iframe {
        max-height: 210px;
      }
    }
  }
  .video-block__media--title {
    color: $color-white;
    text-transform: uppercase;
    text-align: center;
    padding-top: 33px;
    font-family: $brandon-text-bold;
    letter-spacing: 2px;
    @include breakpoint($tablet-down) {
      display: none;
    }
  }
  .video-block__media--video {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    bottom: 0;
    ///max-width: 768px;
    width: 768px;
    max-height: 425px;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($tablet-down) {
      //width: 747px;
      //max-height: 363px;
      width: 100%;
    }
    @media #{$portrait} {
      //max-height: 333px;
      width: 100%;
    }
  }
  .video-carousel__menu .selectbox-arrow {
    background: none;
    line-height: none;
    color: $color-white;
  }
  .video-carousel__menu .selectbox {
    text-transform: uppercase;
    color: $color-white;
    //background: none;
    border: 1px solid $color-white;
    width: 100%;
    height: 60px;
    @include swap_direction(padding, 15px);
    font-weight: bold;
    @include breakpoint($tablet-down) {
      margin-bottom: 5%;
    }
    background-position: center $rdirection 30px;
    background-repeat: no-repeat;
    padding-#{$ldirection}: 30px;
    // font-size 16px and up - ios scales/zooms back after menu select
    font-size: 16px;
  }
  .video-carousel__menu a.selectbox {
    background: none;
    width: 100%;
  }
  .video-carousel__menu {
    width: 100%;
    color: $color-white;
  }
  .slick-next:before,
  .slick-prev:before {
    content: none;
  }
  .slick-disabled {
    opacity: 0.33;
  }
  .slick-next {
    #{$rdirection}: 17px;
    width: 33px;
    height: 50px;
    top: 60%;
  }
  .slick-prev {
    #{$ldirection}: 12px;
    width: 33px;
    height: 50px;
    top: 60%;
  }
}

.video-carousel__related_product-grids {
  & .inactive {
    height: 0;
    overflow: hidden;
    position: absolute;
  }
}
