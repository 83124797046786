.product-guide-item-bundle {
  border-bottom: 1px solid $color-light-gray;
  @include swap_direction(margin, 0 5px);
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 0 10px);
  }
  @include breakpoint($large-up) {
    max-width: 1024px;
    @include swap_direction(margin, 0 auto);
  }
  &__wrap {
    @include pie-clearfix;
    @include swap_direction(padding, 0 20px 30px);
    @include breakpoint($large-up) {
      padding-bottom: 50px;
    }
  }
  &__image-container {
    padding-top: 20px;
    text-align: center;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $ldirection;
      padding-top: 0;
      width: 35%;
    }
  }
  &__image {
    max-width: 360px;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
  }
  &__product {
    margin-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $rdirection;
      width: 63%;
    }
  }
  &__title {
    font-family: $brandon-text;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 25px;
    }
  }
  &__title-description {
    font-family: $brandon-text-bold;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  &__description {
    font-family: $brandon-text;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  &__shop-details {
    @include pie-clearfix;
  }
  &__price {
    font-family: $brandon-text-bold;
    font-size: 12px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      font-size: 18px;
      margin-bottom: 0;
      @include swap_direction(padding, 0.45em 15px 0 0);
    }
  }
  .product-guide-item-bundle__add-to-bag-wrapper {
    .product__button {
      @include breakpoint($medium-up) {
        float: $ldirection;
      }
      background-color: #fff;
      border: 2px solid #ff4661;
      color: #ff4661;
    }
  }
  &__sub-wrap {
    @include breakpoint($medium-up) {
      @include pie-clearfix;
      float: $ldirection;
      margin-top: 10px;
      width: 100%;
    }
  }
  &__product-2 {
    margin-bottom: 30px;
    width: 100%;
    @include breakpoint($medium-up) {
      float: $rdirection;
      width: 63%;
    }
  }
  &__image-container-2 {
    border-top: 1px solid $color-light-gray;
    padding-top: 20px;
    width: 100%;
    @include breakpoint($medium-up) {
    }
  }
  &__title-description-2 {
    font-family: $brandon-text-bold;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
      margin-#{$ldirection}: 3%;
    }
  }
  &__description-2 {
    font-family: $brandon-text;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
      margin-#{$ldirection}: 3%;
    }
  }
  &__shop-details-2 {
    @include pie-clearfix;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 3%;
    }
    .product-guide-item-bundle__add-to-bag-wrapper .product__button {
      border: none;
      border-bottom: 2px solid #ff4661;
      @include swap_direction(padding, 0.45em 0 5px 0);
      @include breakpoint($medium-up) {
        padding-top: 0.75em;
      }
    }
  }
  &__price-2 {
    font-family: $brandon-text-bold;
    font-size: 12px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      float: $ldirection;
      font-size: 18px;
      margin-bottom: 0;
      @include swap_direction(padding, 0.45em 15px 0 0);
    }
  }
  &__meta {
    border: solid 2px #ede9e4;
    @include swap_direction(padding, 20px 18px 20px 20px);
    @include breakpoint($medium-up) {
      float: $ldirection;
      position: relative;
      text-align: $ldirection;
      width: 35%;
    }
  }
  &__meta-title {
    font-family: $brandon-text-bold;
    font-size: 12px;
    margin-bottom: 15px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  &__meta-details {
    font-family: $brandon-text;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 0;
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .quickshop-wrapper.active:before {
    #{$ldirection}: 50%;
    margin-#{$ldirection}: -12px;
  }
}
