.homepage-slide {
  position: relative;
  width: 100%;
  #{$ldirection}: 0;
  top: 0;
  @include breakpoint($medium-up) {
    top: -$global-header-height;
    margin-bottom: -$global-header-height + 15px;
  }
  &__nav {
    @include transform(translateY(-50%));
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 50%;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($medium-up) {
      margin-top: $global-header-height / 2;
      top: 47.2%;
    }
  }
  &__slide {
    display: none; //Prevent FOUC
    &:first-child {
      display: block;
    }
    .slick-initialized & {
      display: block;
    }
    &__inner {
      @include swap_direction(margin, 0 auto);
      height: 326px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0;
      @include breakpoint($medium-up) {
        height: 700px;
      }
    }
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      color: $color-black;
      text-decoration: none;
    }
    &__wrap {
      height: 100%;
      @include swap_direction(padding, 0);
    }
  }
  &__abstract {
    position: absolute;
    top: 0;
    width: 50%;
    text-align: center;
    &--default-y-pos {
      @include transform(translateY(-50%));
      // Vertically center by default.
      top: 50%;
      // Tablet-up needs to account for the nav
      @include breakpoint($medium-up) {
        margin-top: $global-header-height / 2;
      }
    }
    &--center {
      width: 100%;
      .homepage-slide__abstract__inner {
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    &--right {
      #{$rdirection}: 0;
    }
    &--align-left {
      text-align: $ldirection;
    }
    &--align-right {
      text-align: $rdirection;
    }
    &__inner {
      width: 76.875%;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0 50px);
        width: 450px;
        &--large {
          width: 900px;
        }
      }
    }
    &__inner--overlay {
      // width: 76.875%;
      background: rgba(255, 255, 255, 0.8);
      @include swap_direction(padding, 28px 10px 30px);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 47px 20px 37px);
        // Add 40 px to make up for padding
        width: 490px;
        &.homepage-slide__abstract__inner--large {
          width: 940px;
        }
      }
    }
    &--light {
      .homepage-slide {
        &__link {
          color: $color-white;
          border-color: $color-white;
          &:hover {
            color: $color-black;
            border-color: $color-black;
          }
        }
        &__description {
          color: $color-white;
        }
        &__headline {
          color: $color-white;
        }
      }
      .pink-hover {
        color: $color-white;
        border-color: $color-white;
        &:hover {
          color: $color-pink;
          border-color: $color-pink;
        }
      }
    }
    @include breakpoint($small-down) {
      // Going 2 selectors deep because .touch .link...
      .homepage-slide__link {
        font-size: 12px;
        letter-spacing: 0.24em;
        border-top: none;
        border-#{$ldirection}: none;
        border-#{$rdirection}: none;
        border-bottom-width: 3px;
        @include swap_direction(padding, 0 0 6px);
        &:hover {
          border-color: $color-pink;
        }
      }
    }
  }
  &__headline {
    font-family: $brandon-text-medium;
    @include swap_direction(margin, 0 0 9px);
    font-size: 22.53px;
    letter-spacing: 0.24em;
    line-height: 23px;
    @include breakpoint($medium-up) {
      // @TODO: these text styles are from the original spec, which uses %h1.
      // The new designs completely ignore the styleguide.
      font-size: 39px;
      line-height: 1.2em;
      letter-spacing: 0.14em;
      @include swap_direction(margin, 0 0 17px);
    }
    text-transform: uppercase;
  }
  &__description {
    font-size: 14px;
    line-height: 1.1em;
    letter-spacing: 0.08em;
    // margin-bottom: 30px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      // @TODO: revisit, these text styles don't match latest designs
      font-size: 24px;
      line-height: 1.45em;
      letter-spacing: 0.042em;
      font-size: 18px;
      @include swap_direction(padding, 0 30px);
      margin-bottom: 50px;
    }
  }

  // Slick navigation
  .slick-prev,
  .slick-next {
    height: auto;
    &:before {
      @include transition(color 0.3s ease);
      font-size: 22px;
      @include breakpoint($medium-up) {
        font-size: 34px;
      }
    }
  }
  .slick-prev {
    #{$ldirection}: 8px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 15px;
    }
  }
  .slick-next {
    #{$rdirection}: 8px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 15px;
    }
    &:before {
      position: static;
    }
  }
  &--light-arrows {
    .slick-prev:before,
    .slick-next:before {
      color: $color-white;
    }
  }
  &__debug-layer {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0.5;
  }
} //.homepage-slide

.hp_video_slide {
  .width-1920 {
    max-height: 810px;
    max-width: 1439px;
  }
  .auto-auto {
    width: auto;
    height: auto;
  }
  .width-auto {
    width: auto;
    height: 100%;
  }
  .wide-auto-no-max {
    width: auto;
    height: 100%;
    max-width: none;
  }
  .height-auto {
    width: 100%;
    height: auto;
  }
  .hp-vid {
    max-width: none;
    height: 326px;
    @include breakpoint($medium-up) {
      height: 700px;
    }
  }
  .video_player_controls_wrapper,
  .video-info-container {
    display: none;
  }
  /* SIDEPAN */
  .homepage-slide__slide__inner img {
    margin-#{$ldirection}: 0px;
    -webkit-transition: margin 1s ease;
    -moz-transition: margin 1s ease;
    -o-transition: margin 1s ease;
    -ms-transition: margin 1s ease;
    transition: margin 1s ease;
  }
  .homepage-slide__slide__inner img:hover {
    margin-#{$ldirection}: -200px;
  }
  .video-player-wrapper {
    max-width: 100%;
  }
}

.homepage-slide.module {
  top: inherit;
  height: inherit;
  margin-bottom: inherit;
  .homepage-slide__slide.module {
    @include swap_direction(margin, 30px 0 0);
    .homepage-slide__slide__inner {
      max-height: 320px;
      background-size: contain;
      @include breakpoint($landscape-up) {
        max-height: 440px;
      }
    }
  }
  .homepage-slide__abstract--default-y-pos {
    @include swap_direction(margin, initial);
  }
  button {
    height: initial;
  }
  .homepage-slide__nav {
    @include swap_direction(margin, initial);
  }
}
