/* MPP Custom Banner */
.mpp-custom-header {
  .page-header {
    &__inner {
      @include swap_direction(padding, 0);
    }
    .lip-color {
      position: relative;
      #{$rdirection}: 160px;
      bottom: 10px;
      @include breakpoint($medium-down) {
        #{$rdirection}: 100px;
      }
      @include breakpoint($small-down) {
        @include breakpoint(orientation portrait) {
          #{$rdirection}: 54px;
          bottom: 0;
        }
        @include breakpoint(orientation landscape) {
          #{$rdirection}: 80px;
          bottom: 14px;
        }
      }
      &__bobbiline {
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        display: inline-block;
        @include swap_direction(padding, 0 90px);
        @include breakpoint($medium-down) {
          @include swap_direction(padding, 0 60px);
        }
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            @include swap_direction(padding, 0 16px);
          }
          @include breakpoint(orientation landscape) {
            @include swap_direction(padding, 0 45px);
          }
        }
        &:before {
          content: '';
          background: $color-black;
          width: 100%;
          height: 1px;
          display: inline-block;
          position: absolute;
          top: 54%;
          #{$rdirection}: 2px;
          @include breakpoint($medium-down) {
            #{$rdirection}: 3px;
          }
          @include breakpoint($small-down) {
            #{$rdirection}: 2px;
          }
        }
      }
      &__bobbiline-text {
        font-family: $brandon-text-medium;
        font-size: 24px;
        letter-spacing: 4px;
        background-color: #e4e4e4;
        position: relative;
        @include swap_direction(padding, 0 2px 0 8px);
        @include breakpoint($small-down) {
          @include swap_direction(padding, 0 0 0 6px);
          @include breakpoint(orientation portrait) {
            font-size: 14px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 18px;
          }
        }
      }
      &__title {
        font-family: $miller-banner-light;
        font-size: 92px;
        line-height: 68px;
        @include breakpoint($medium-down) {
          @include breakpoint(orientation portrait) {
            font-size: 46px;
            line-height: 38px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 56px;
            line-height: 48px;
          }
        }
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            font-size: 27px;
            line-height: 25px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 35px;
            line-height: 33px;
          }
        }
      }
    }
    .corrector-concealer {
      position: relative;
      #{$rdirection}: 150px;
      bottom: 9px;
      @include breakpoint($medium-down) {
        @include breakpoint(orientation portrait) {
          #{$rdirection}: 100px;
          bottom: 24px;
        }
        @include breakpoint(orientation landscape) {
          #{$rdirection}: 130px;
        }
      }
      @include breakpoint($small-down) {
        @include breakpoint(orientation portrait) {
          #{$rdirection}: 45px;
        }
        @include breakpoint(orientation landscape) {
          #{$rdirection}: 80px;
          bottom: 14px;
        }
      }
      &__bobbiline {
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        display: inline-block;
        @include swap_direction(padding, 0 90px);
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            @include swap_direction(padding, 0 16px);
          }
          @include breakpoint(orientation landscape) {
            @include swap_direction(padding, 0 45px);
          }
        }
        &:before {
          content: '';
          background: $color-black;
          width: 100%;
          height: 1px;
          display: inline-block;
          position: absolute;
          top: 54%;
          #{$rdirection}: 3px;
          @include breakpoint($small-down) {
            #{$rdirection}: 2px;
          }
        }
      }
      &__bobbiline-text {
        font-family: $brandon-text-medium;
        font-size: 24px;
        letter-spacing: 4px;
        background-color: #f3eee8;
        position: relative;
        @include swap_direction(padding, 0 2px 0 10px);
        @include breakpoint($small-down) {
          @include swap_direction(padding, 0 0 0 6px);
          @include breakpoint(orientation portrait) {
            font-size: 14px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 18px;
          }
        }
      }
      &__title {
        font-family: $miller-banner-light;
        font-size: 62px;
        line-height: 77px;
        @include breakpoint($medium-down) {
          line-height: 53px;
          @include breakpoint(orientation portrait) {
            font-size: 46px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 56px;
          }
        }
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            font-size: 19px;
            line-height: 20px;
          }
          @include breakpoint(orientation landscape) {
            font-size: 35px;
            line-height: 33px;
          }
        }
      }
    }
  }
}
