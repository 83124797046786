.callout {
  @include pie-clearfix;
  background-image: none;
  border-bottom: 1px solid $color-light-gray;
  position: relative;
  text-align: center;
  @include breakpoint($medium-up) {
    margin: 0 auto;
    padding-#{$ldirection}: 20px;
    max-width: 1024px;
  }
  &__wrapper-link {
    color: $color-black;
    display: block;
    outline: none;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__content {
    padding-top: 40px;
    @include breakpoint($medium-up) {
      @include transform(translateY(-50%));
      float: $ldirection;
      @include swap_direction(padding, 0);
      width: 60%;
      position: absolute;
      top: 50%;
    }
  }
  &__title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 40px;
      line-height: 45px;
    }
  }
  &__description {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 25px;
    @include swap_direction(padding, 0 15%);
    @include breakpoint($medium-up) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  .button {
    background-color: #fff;
    border: 1px solid $color-pink;
    color: $color-pink;
    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
    }
  }
  &__image {
    max-width: 320px;
    padding-top: 25px;
    @include breakpoint($medium-up) {
      float: $rdirection;
      max-width: none;
      width: 40%;
    }
  }
}

.callout--alt {
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: auto 100%;
  @include breakpoint($medium-up) {
    background-image: none !important;
  }
  .callout__wrap {
    margin: 0 auto;
    max-width: 640px;
    min-height: 300px;
    @include breakpoint($medium-up) {
      max-width: none;
      min-height: none;
    }
  }
  .callout__content {
    @include transform(translateY(-50%));
    float: $ldirection;
    text-align: $ldirection;
    @include swap_direction(padding, 0 20px);
    position: absolute;
    top: 50%;
    width: 70%;
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 0);
      width: 38%;
    }
  }
  .callout__description {
    @include swap_direction(padding, 0);
  }
  .callout__image {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      width: 60%;
    }
  }
}

.callout--mobile-image-full {
  .callout__content {
    @include breakpoint($medium-up) {
      text-align: $ldirection;
      width: 38%;
    }
  }
  .callout__description {
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 0;
    }
  }
  .callout__image {
    max-width: none;
    @include breakpoint($medium-up) {
      width: 60%;
    }
  }
}

.eyeliner-module .callout--alt .callout__wrap {
  @include breakpoint($medium-up) {
    min-height: 360px;
  }
}
