.chat-landing-page {
  &__header {
    margin-top: 25px;
    font-size: 37px;
    text-transform: uppercase;
    font-family: $brandon-text-bold;
    text-align: center;
    line-height: 1.4em;
    margin-bottom: 15px;
  }
  &__text {
    display: none;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    max-width: 730px;
    @include swap_direction(margin, auto);
    p {
      line-height: 2em;
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      color: $color-black;
      u {
        text-decoration: none;
      }
    }
    strong u,
    u strong,
    b u,
    u b {
      font-weight: normal;
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      border-bottom: 1px solid $color-black;
      text-decoration: none;
    }
  }
  &__text--user {
    display: block;
    margin-bottom: 70px;
  }
  &__cta {
    text-align: center;
    display: none;
  }
  &__offers {
    text-align: center;
    display: table;
    width: 100%;
    font-size: 0;
    @include swap_direction(margin, 0);
    margin-bottom: 30px;
    height: 100%;
    &-row {
      display: table-row;
      height: 100%;
    }
    &-cell {
      display: table-cell;
      height: 100%;
      width: 50%;
      padding-#{$rdirection}: 10px;
      &:last-child {
        padding-#{$rdirection}: 0;
        padding-#{$ldirection}: 10px;
      }
    }
  }
  &__offers-item {
    vertical-align: top;
    height: 100%;
    background: $color-beige-background;
    font-size: 14px;
    -moz-box-sizing: border-box;
    .section-header__inner {
      background: $color-beige-background;
    }
  }
  &__offers-item__wrap {
    @include swap_direction(padding, 0 25px);
    padding-top: 15px;
    padding-bottom: 25px;
  }
  &__offers-header {
    font-family: $brandon-text-bold;
    font-size: 12px;
    text-transform: uppercase;
    @include swap_direction(margin, auto);
    .section-header__inner {
      background: $color-beige-background;
    }
  }
  &__offers-name {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: $ldirection;
    line-height: 1.2em;
    padding-top: 20px;
  }
  &__offers-image {
    display: block;
    @include swap_direction(margin, auto);
    max-width: 130px;
    padding-#{$rdirection}: 10px;
    float: $ldirection;
    @include box-sizing(content-box);
  }
}

.mobile-chat-landing-page {
  font-size: 12px;
  &__header {
    margin-top: 25px;
    text-align: center;
  }
  &__subheader {
    font-family: $brandon-text-bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &__text {
    margin-bottom: 25px;
  }
  & + .featured-products-carousel {
    font-size: 0;
    .section-header {
      margin-bottom: 25px;
    }
    .product-grid__content {
      border: none;
    }
    .product-grid__item {
      width: 33.3333333%;
      display: inline-block;
      @include swap_direction(margin, 0);
    }
    .product-thumb__image-link {
      float: none;
      width: 100%;
    }
    .product-thumb__abstract {
      display: none;
    }
  }
}

.elc-user-state-anonymous {
  .chat-landing-page__text {
    display: none;
  }
  .chat-landing-page__text--guest {
    display: block;
  }
  .chat-landing-page__cta {
    display: block;
    margin-top: 25px;
    margin-bottom: 30px;
  }
}
