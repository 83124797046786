.site-nav--sticky {
  .hp-counter {
    .inner {
      line-height: 40px;
      vertical-align: top;
    }
    @include breakpoint(min-width $desktop) {
      width: 1024px;
      top: 54px;
      position: absolute;
    }
  }
}

.hp-counter {
  &-url {
    overflow: auto;
  }
  display: none;
  height: 41px;
  min-height: 41px;
  background-color: $color-redder-pink;
  text-align: center;
  z-index: 1;
  margin: 0 auto;
  color: $color-white;
  overflow: hidden;
  position: static;
  #{$ldirection}: 0;
  top: 54px;
  width: 100vw;
  .inner {
    display: inline-block;
    line-height: 40px;
    height: 100%;
    font-size: 15px;
    vertical-align: top;
    .timer {
      font-weight: bold;
    }
  }
  @include breakpoint(min-width $desktop) {
    top: 0;
  }
}

.hp-counter {
  top: 0;
  #{$rdirection}: 0;
  width: 100%;
  padding: 0 12px;
  .inner {
    font-size: 15px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    line-height: 1;
    @include breakpoint($medium-up) {
      display: inline-block;
      line-height: 40px;
      height: 100%;
      font-size: 15px;
      vertical-align: top;
    }
  }
}

.site-utils--sticky {
  .hp-counter {
    display: none;
    position: inherit;
  }
}

a.hp-counter-url {
  width: 100%;
}

.block-template-hp-counter-v1 {
  height: 40px;
}

@media (max-width: 767px) {
  .pg-checkout {
    &.opc__wp_samples,
    &.samples,
    &.opc__samples,
    &.index,
    &.confirm {
      .block-template-hp-counter-v1 {
        display: none;
      }
    }
  }
}

.pg-checkout {
  &.opc__registration {
    .block-template-hp-counter-v1 {
      display: none;
    }
  }
}
