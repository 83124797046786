.mantle-form__checkbox {
  $checkbox: &;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
  max-width: 210px;

  input[type="checkbox"] {
    @include input-styled-check("checkbox");
  }

  // default is to gray out the non selected check
  .has-selected-value & {
    filter: grayscale(100%);
  }
  .has-selected-value &.active {
    filter: none;
  }
  &__description {
    display: none;
  }
  &__image {
    position: relative;
    width: 81px;
    height: 81px;

    .mantle-media-asset {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .5s ease;
      &:last-child {
        opacity: 0;
        z-index: 0;
      }
      // note this is last so if we have only one asset. it will show
      &:first-child {
        z-index: 1;
        opacity: 1;
        position: static;
      }
      @at-root #{$checkbox}.active &
      {
        &:first-child {
          opacity: 0;
        }
        // note this is last so if we have only one asset. it will show
        &:last-child {
          opacity: 1;
        }
      }
      @at-root #{$checkbox}:hover &
      {
        &:first-child {
          opacity: 0;
        }
        // note this is last so if we have only one asset. it will show
        &:last-child {
          opacity: 1;
        }
      }
    }
  }

  &__description {
    text-align: center;
  }
}

