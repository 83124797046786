.slide_module {
  @include swap_direction(padding, 30px 0 0);
  max-width: 1024px;
  margin: 0 auto 60px;
  position: relative;
  a {
    color: $color-black;
    text-decoration: none;
  }
  .slick-dots {
    li {
      opacity: 0.3;
      width: initial;
      height: initial;
      &:hover,
      &.slick-active {
        opacity: 1;
      }
      button {
        width: 18px;
        height: 18px;
        border-radius: 9px;
        &:before {
          content: '';
        }
      }
    }
  }
  img {
    display: inline-block;
  }
  button.slick-prev {
    height: initial;
  }
  .slick-prev,
  .slick-next {
    top: 33%;
    height: auto;
    &:before {
      position: relative;
      height: auto;
    }
  }
  .slick-next {
    #{$rdirection}: 0;
    &:before {
      #{$rdirection}: 0;
    }
  }
  .shade_name {
    font-family: $brandon-text-bold;
    font-size: 18px;
    text-transform: uppercase;
  }
  &_qs {
    padding-bottom: 30px;
    h3 {
      font-family: $brandon-text-bold;
      font-size: 24px;
      margin-bottom: 6px;
    }
    h4 {
      font-family: $brandon-text;
      font-size: 18px;
      margin-bottom: 9px;
    }
    h5 {
      font-family: $brandon-text-bold;
      font-size: 18px;
      margin-bottom: 18px;
    }
  }
  .slide-module-slide-inner {
    position: relative;
    &:hover img {
      opacity: 0.8;
    }
  }
  .slide-module-slide-copy {
    text-align: center;
    padding: 12px;
  }
  .slide-module-slide-header {
    font-family: Lato Black;
    font-size: 20px;
    line-height: 1em;
    text-transform: uppercase;
  }
  .slide-module-slide-subheader {
    font-size: 18px;
    line-height: 1em;
  }
  .slide-module-slide-link {
    margin-top: 12px;
  }
}
