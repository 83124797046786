.product-collection-footer {
  h3,
  h2 {
    font-family: $brandon-text-bold;
    font-size: 22px;
    text-transform: uppercase;
    @include swap_direction(margin, 18px 0);
  }
  &__sub {
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 18px;
  }
  &__link a {
    font-family: $brandon-text-bold;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid;
    padding-bottom: 3px;
  }
}

.shop-all__button {
  @include swap_direction(margin, 27px 0);
}

.product-collection-footer_l,
.product-collection-footer_r {
  min-height: 238px;
  width: 510px;
  @include swap_direction(margin, 40px 0);
  @include swap_direction(padding, 24px);
  float: $ldirection;
  background-color: $color-gray-light-alt;
  @include breakpoint($tablet-down) {
    width: 100%;
    @include swap_direction(margin, 0 0 6px);
  }
}

.product-collection-footer_l {
  padding-top: 30px;
  span {
    font-size: 3em;
    text-align: center;
  }
}

.product-collection-footer_r {
  margin-#{$ldirection}: 4px;
  padding-top: 37px;
  .icon-freeshipping {
    position: relative;
    bottom: 12px;
  }
  h3 {
    @include swap_direction(margin, 0 0 18px 0);
  }
}
