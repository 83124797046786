.site-email-signup {
  .site-footer__main & {
    @include pie-clearfix;
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 20px;
    @include swap_direction(padding, 0 20px 25px 20px);
    position: relative;
    @include breakpoint($medium-up) {
      border-bottom: 0;
      // margin-bottom: 30px;
      @include swap_direction(padding, 0);
    }
    &__header {
      @include h8;
      @include swap_direction(margin, 0 0 15px 0);
    }
    &__form {
      overflow: hidden;
      width: 100%;
    }
    &__field {
      width: 55%;
      max-width: 150px;
      margin-bottom: 5px;
      line-height: 26px;
      float: $ldirection;
      margin-#{$rdirection}: 6px;
    }
    &__success.inline {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
    }
    &__submit {
      float: $ldirection;
    }
    &__success.inline {
      position: absolute;
      background: $color-white;
      text-transform: none;
    }
    &__error {
      @include swap_direction(margin, 0 0 17px);
      color: red;
      span {
        display: block;
      }
    }
  }
  .site-utils__dropdown & {
    @include swap_direction(padding, 22px 28px 20px 24px);
    position: relative;
    &__header {
      @include h9;
      margin-bottom: 18px;
    }
    &__field {
      width: 100%;
      margin-bottom: 18px;
    }
    &__success.inline {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      padding-top: 70px;
      text-align: center;
      z-index: 2;
    }
    &__submit {
      width: 100%;
      height: 34px;
      line-height: 34px;
    }
    &__error {
      margin-bottom: 3px;
      span {
        display: block;
      }
    }
  }
  &__legal {
    .site-utils__dropdown & {
      padding-top: 5px;
    }
    p {
      margin-bottom: 8px;
    }
  }
}

.optional-info,
.site-email-signup {
  .optional-info__header {
    text-indent: 0;
    @include swap_direction(margin, 0 0 10px 0);
    @include swap_direction(padding, 10px 0);
  }
}

.site-footer__column {
  .site-email-signup {
    .sign-in {
      &-component__birthday-program {
        a.selectBox {
          @include swap_direction(padding, 0 6px);
          .selectBox-arrow {
            width: 24px;
          }
        }
      }
    }
  }
}

.site-footer__column .site-email-signup .optional-info__header,
.site-footer__column .site-email-signup .sign-in-component__birthday-program {
  display: none;
}
