.product--full {
  @include pie-clearfix;
  // There is no .product inside of product--full, this is just used for
  // prefixing element-level BEM classes with the block identifier.
  .product {
    &-gallery {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 49%;
        float: $ldirection;
        .quickshop & {
          width: 50%;
        }
      }
    }
    &__details {
      @include swap_direction(padding, 0 20px);
      width: 100%;
      @include breakpoint($medium-up) {
        width: 49%;
        float: $rdirection;
        .quickshop & {
          width: 50%;
        }
      }
      .content {
        @include swap_direction(padding, 22px);
      }
    }
    &__title {
      @include h10;
      &-link {
        color: $color-black;
        text-decoration: none;
        display: block;
      }
      font-family: $body-font-bold;
      text-transform: uppercase;
      line-height: 21px;
    }
    &__subline {
      @include h6;
    }
    &__rating {
      @include h7;
      font-family: $body-font;
      text-transform: inherit;
      @include swap_direction(padding, 0 0 18px);
    }
    &__info {
      @include pie-clearfix;
      border-bottom: 1px solid #000;
      &--palette {
        border-top: 1px solid #000;
      }
    }
    &__sku-categories {
      @include breakpoint($medium-up) {
        min-height: 240px;
      }
      .resp-tab-active {
        border-bottom: solid 1px $color-white;
        background-color: $color-white;
      }
      .resp-tab-content {
        border: 0;
        @include swap_direction(padding, 0);
      }
    }
    &__sku-categories-nav {
      display: table;
      margin-top: 0;
      margin-bottom: 0;
      border-#{$ldirection}: solid 1px $color-black;
    }
    &__sku-categories-nav-item {
      @include h8;
      font-size: 13px;
      display: table-cell;
      @include swap_direction(padding, 10px);
      background-color: $color-beige;
      border: 1px solid #000000;
      border-width: 0 1px 1px 0;
      cursor: pointer;
      float: none;
      text-align: center;
      &.resp-tab-active {
        border-bottom: 1px solid $color-white;
        background-color: $color-white;
      }
    }
    &__sku-categories-nav-bottom {
      position: relative;
      z-index: -1;
      @include swap_direction(margin, -1px 0 20px 0);
      border-bottom: 1px solid $color-black;
    }
    &__weight {
      @include h9;
      @include breakpoint($medium-up) {
        line-height: 35px;
      }
      float: $ldirection;
    }
    &__unit-price {
      @include h9;
      line-height: 35px;
      float: $ldirection;
      margin-#{$ldirection}: 10px;
    }
    &__price {
      @include h8;
      line-height: 35px;
      letter-spacing: 0.24em;
      float: $rdirection;
      @include breakpoint($small-down) {
        line-height: 21px;
      }
      span.legal_msg {
        text-transform: none;
        color: $color-light-gray;
        letter-spacing: 0.1em;
        font-size: 12px;
      }
    }
    &__button--add-to-bag {
      @include breakpoint($small-down) {
        width: 100%;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &__favorite {
      padding-#{$rdirection}: 10px;
      font-size: 18px;
      text-decoration: none;
      span {
        display: inline-block;
        text-align: center;
        min-width: 22px;
        vertical-align: middle;
      }
      .icon {
        color: $color-black;
        &:hover,
        &.icon-heart {
          color: $color-pink;
        }
      }
    }
    &__actions {
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: $rdirection;
      }
      @include swap_direction(padding, 30px 0 48px);
      @include breakpoint($small-down) {
        @include swap_direction(padding, 0);
      }
      .product_full_details {
        float: $ldirection;
        margin-top: 9px;
      }
      .limited-remaining {
        color: $color-pink;
        font-size: 13px;
        line-height: 2.923;
        text-align: center;
        @include breakpoint($medium-up) {
          text-align: right;
          padding-right: 5px;
        }
      }
    }
    &__full-details-link-wrapper {
      float: $ldirection;
      margin-top: -2px;
    }
    &__social {
      position: absolute;
      @include breakpoint($small-down) {
        @include swap_direction(padding, 10px 0);
        text-align: $rdirection;
      }
      a {
        color: #000000;
        text-decoration: none;
        display: inline-block;
        margin-#{$rdirection}: 15px;
        font-size: 18px;
      }
    }
    &__promo {
      background: $color-light-brown;
      font-size: 12px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      text-align: $rdirection;
      bottom: 0;
      #{$rdirection}: 0;
      @include swap_direction(padding, 10px);
      line-height: 1.5;
      @include breakpoint(portrait) {
        width: 100%;
        #{$ldirection}: 0;
        border-top: 1px solid #d0d0d0;
        margin-#{$ldirection}: 0;
      }
      strong {
        font-family: $body-font-bold;
        font-weight: normal;
      }
    }
    &__rating--reviews-link {
      font-size: 12px;
      letter-spacing: 1px;
      text-decoration: none;
      color: #000000;
    }
    &__rating {
      @include h8;
      font-family: $body-font;
      text-transform: inherit;
      @include swap_direction(padding, 0);
    }
    &__header {
      @include breakpoint($small-down) {
        // setting up this as a position context, for mobile only items,
        // like the favorites button near the product name
        border-bottom: 1px solid #ccc;
        @include swap_direction(margin, 15px 0 15px 0);
        padding-bottom: 10px;
        position: relative;
        .product__favorite {
          position: absolute;
          top: 0;
          #{$rdirection}: 10px;
          font-size: 18px;
        }
        .product__title {
          max-width: 214px;
          display: block;
          float: $ldirection;
          font-size: 12px;
          line-height: 16px;
          margin-#{$ldirection}: 15px;
        }
        .mobile-price {
          float: $rdirection;
          @include swap_direction(margin, 0 15px);
        }
        .product__price,
        .product__ppu {
          float: none;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.2em;
          text-align: $ldirection;
        }
        .product__rating {
          width: 55%;
          @include swap_direction(margin, 3px auto 5px auto);
          padding-top: 0;
          text-align: center;
          border-top: 1px solid $color-gray;
          a div.mobile-block {
            display: inline;
          }
        }
        .product__ppu {
          font-size: 8px;
          font-weight: bold;
        }
      }
    }
    &__details {
      @include breakpoint($small-down) {
        .product__header {
          display: none;
        }
      }
    }
    &__tip {
      font-style: italic;
      text-align: center;
      @include swap_direction(padding, 50px 20px 85px 20px);
      font-size: 14px;
      border-top: 1px solid $color-black;
      .product__tip-title {
        @include h8;
        font-style: normal;
        margin-bottom: 25px;
      }
    }
    &__no-tip-spacer {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      height: 150px;
    }
  } /* end .product */
}

.spp-mobile-page {
  .non_shaded {
    float: none;
    @include swap_direction(margin, 0 auto);
    text-align: center;
  }
  .fluid-image {
    @include swap_direction(margin, 0 auto);
  }
  .shaded_details {
    background: #fff;
    border-bottom: 1px solid #cccccc;
    position: relative;
    text-align: center;
    z-index: 1;
    .product-palette {
      border-top: 1px solid #d4d4d4;
      margin-top: 25px;
      padding-top: 10px;
      position: static;
      &:before {
        top: 39px;
        z-index: 10;
      }
      .select-cta {
        float: $rdirection;
        width: 48%;
        .shade-picker__color-name select {
          border-top: 2px solid #ccc;
          border-bottom: 2px solid #ccc;
        }
        .product__notify-me {
          display: block;
        }
        .mobile-price {
          text-align: $ldirection;
          .product__price,
          .product__ppu {
            font-size: 10px;
            line-height: 10px;
            @include swap_direction(margin, 10px 0);
          }
          .product__price {
            float: $ldirection;
            font-weight: bold;
            .old_price {
              width: 100%;
              display: inline-block;
            }
          }
          .product__ppu {
            float: $rdirection;
            font-size: 8px;
          }
        }
      }
    }
    .product-gallery .slick-wrapper {
      margin-#{$ldirection}: 20px;
      width: 222px;
      .js-product-gallery__main {
        float: $ldirection;
        margin-top: 10px;
        width: 45%;
      }
      .slick-track {
        height: 100px;
      }
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
  .select_shade_cta {
    font-family: $body-font-bold;
    font-weight: normal;
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    font-size: 14px;
    color: #ff0000;
    cursor: pointer;
    border: 2px solid #ff0000;
    @include swap_direction(margin, 10px 0 15px);
    @include swap_direction(padding, 0 10px);
    display: inline-block;
    text-decoration: none;
  }
  .hide_select {
    border: 0;
    color: #d4d4d4;
    @include swap_direction(margin, 0 auto);
    .select_shade_text {
      border-bottom: 2px solid #d4d4d4;
    }
  }
  .product--full {
    .product__details {
      @include swap_direction(margin, 0 auto);
    }
    .palette_wrapper {
      text-align: center;
      .product-palette__actions {
        display: none;
      }
    }
    .product-gallery__photo--large {
      width: 200px;
    }
    .product-palette__link-launch {
      margin-top: 0;
      &.hide_select_grey {
        border-bottom: 2px solid #d4d4d4;
        color: #d4d4d4;
      }
    }
    .product-palette__details-group-title {
      padding-bottom: 15px;
    }
    .product-palette__details-group-shades.slick-slider {
      margin-bottom: 0;
    }
  }
  .rating_fav {
    @include swap_direction(padding, 10px 0);
    border-top: 1px solid #ccc;
    height: 46px;
    .product__rating {
      float: $ldirection;
      width: 180px;
      .BVRROverallRatingContainer {
        padding-#{$ldirection}: 18px;
      }
      .BVRRRatingSummary.BVRRPrimarySummary {
        width: 300px;
      }
      .BVRRRatingSummaryLinkWriteFirst {
        border-#{$rdirection}: 0;
        padding-#{$ldirection}: 5px;
        text-align: $ldirection;
        a {
          color: #ff4661;
          display: inline;
          text-decoration: underline;
        }
      }
    }
    .favourite-heart {
      float: $rdirection;
      margin-#{$rdirection}: 5px;
    }
  }
  .hide {
    display: none;
  }
}

.spp-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid $base-border-color;
  @include swap_direction(padding, 20px);
  .spp-testimonial__tooltip-quote {
    font-size: 32px;
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 14px;
  }
  .spp-testimonial__tooltip-name,
  .spp-testimonial__tooltip-location {
    font-size: 11px;
    letter-spacing: 4px;
    line-height: 1.5em;
    text-transform: uppercase;
  }
}
/*
.quickshop__rating--container {
  display: inline-block;
  border-top: 2px solid $base-border-color;
  margin-top: 3px;
  padding-top: 2px;
}
.ask-answer {
  border-#{$ldirection}: 1px solid $color-black;
  padding-#{$ldirection}: 10px;
}
*/
.sku-count {
  display: inline-block;
  &:after {
    content: ')';
  }
  &:before {
    content: '(';
  }
  @include breakpoint($medium-up) {
    display: none;
  }
}

.pre-order-tab-content {
  .resp-accordion {
    display: none;
  }
  .product {
    &__sku-categories-nav {
      border-top: solid 1px $color-light-gray;
      border-bottom: solid 1px $color-light-gray;
      border-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        border-#{$ldirection}: 1px solid $color-black;
        border-top: 0;
        border-bottom: 0;
      }
    }
    &__sku-categories-nav-item {
      border: 0;
      letter-spacing: 1px;
      background-color: $color-white;
      color: $color-light-gray;
      &.resp-tab-active {
        color: $color-black;
      }
      @include breakpoint($medium-up) {
        border-#{$rdirection}: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        background-color: $color-beige;
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        text-transform: none;
      }
    }
    &__sku-categories-nav-bottom {
      border: 0;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $color-black;
        position: relative;
        z-index: -1;
        @include swap_direction(margin, -1px 0 20px 0);
      }
    }
  }
  .product__sku-select-label {
    display: none;
  }
  .resp-tabs-list {
    .device-mobile & {
      display: block;
    }
  }
}

.selectBox-selected {
  border: 1px dotted #5e91ec !important;
}
