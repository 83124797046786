.mpp-grid {
  .product-brief {
    &__product-info-wrapper {
      margin-top: 10px;
    }
    &__abstract {
      text-align: center;
      max-width: 100%;
      @include breakpoint($medium-up) {
        max-width: 325px;
      }
    }
    &__header {
      text-align: center;
    }
    &__bottom {
      margin-top: 15px;
      @include breakpoint($medium-up) {
        margin-top: 10px;
      }
    }
    &__bottom-mobile {
      .product-brief {
        &__short-desc-link {
          display: none;
        }
        &__add-to-bag {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
}

.spp-product-layout {
  .product-full {
    &__data-main {
      border-bottom: none;
    }
    &__data-details {
      border-top: none;
    }
  }
  .sticky-add-to-bag {
    &__cta {
      width: 100%;
    }
  }
}
