/*
 * This file should host only mantle specific styles and not anything specific
 * to brands.
 * TODO: figure out this can live centrally.
 */

@import "mantlemixins";
@import "inputrange";
@import "form/mantleform";
@import "mantle_carousel";
@import "mantleplacement";
@import "hotspot_manager";
@import "mantle_video";
@import "range_slider_carousel";

.mantle-media-asset {
  width: 100%;
  height: 100%;
  img, video {
    width: 100%;
    height: 100%;
  }
}

@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

// TODO remove $gap entirely
@mixin grid-columns($gap, $columns) {
  grid-template-columns: $columns;
  @if $gap > 0 {
    grid-column-gap: $gap;
    -ms-grid-columns: box-gap($columns, $gap);
  }
  -ms-grid-columns: $columns;
}

/*
 * The idea here is that we don't show the arrow until we run the js and attach
 * the number of options available.
 */
@mixin mantle-select-single-option() {
  // don't show arrow until select has been processed
  select.mantle-select:not(.mantle-select-processed) {
    @content;
  }

  // hide arrow if only have 0/1 options
  select.mantle-select.mantle-select-processed{
    &[data-options-count="0"],
    &[data-options-count="1"] {
      @content;
    }
  }
}

@include mantle-select-single-option {
  background-image: none;
}

@mixin mantle-normal-list {
  ul {
    list-style: outside disc;
    padding-left: 20px;
    margin-left: 0px;
    margin-bottom: 15px;
  }
  ol {
    list-style: outside decimal;
    padding-left: 20px;
    margin-left: 0px;
    margin-bottom: 15px;
  }
  ul ul, ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }
  li {
    text-align: left;
    margin-top: 10px;
  }
  li:first-child {
    margin-top: 0px;
  }
}

.mantle-custom-text.style--bare {
  @include mantle-normal-list;
}
