.video-play-trigger {
  color: $color-white;
  cursor: pointer;
  &:before {
    content: ' ';
    background: url(#{$base-theme-path}/img/bb2-play.svg) no-repeat;
    // adjustments for the svg to match icon--play
    width: 1em;
    height: 1em;
    background-size: 90% 90%;
    background-position: center center;
  }
}

.videojs-video {
  &.videojs-video--loading &__landing {
    .video-play-trigger {
      &:after {
        position: absolute;
        top: -20px;
        left: -28px;
        content: ' ';
        display: block;
        margin: 0px;
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        border-radius: 50%;
        border: 5px solid transparent;
        border-color: $color-white transparent transparent transparent;
        animation: video-loading-circle 1.5s linear infinite;
      }
    }
  }
}

.bb-video-card {
  padding: 0px;
  max-width: none;
  &--editorial {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
}

.video-product-card {
  background: $color-white;
  padding: 20px;
  display: flex;
  &__top {
    order: 2;
    padding-left: 5px;
  }
  &__bottom {
    width: 33%;
    order: 1;
    @include breakpoint($medium-up) {
      padding-left: 0px;
      width: auto;
    }
  }
  &__top-add-to-cart {
    margin-top: 10px;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  @include breakpoint($medium-up) {
    width: 240px;
    flex-direction: column;
    &__top {
      order: 0;
    }
  }
  .product-brief {
    &--price {
      margin: 5px 0px;
      font-size: 15px;
    }
    &__header {
      text-align: left;
      font-size: 15px;
    }
    &__short-desc {
      text-align: left;
      font-size: 15px;
    }
    &__bottom {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__body-copy {
    text-align: left;
    font-size: 15px;
    margin-top: 3px;
  }
  &__hex {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .button {
    background-color: $color-black;
    color: $color-white;
  }
  &__shop-now {
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
    }
  }
  .product__button--add-to-bag {
    @include breakpoint($medium-up) {
      display: none !important;
    }
  }
  &__shade-name-container {
    display: flex;
    align-items: center;
  }
  .product-brief__add-to-bag {
    margin-top: 15px;
  }
  &__dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    opacity: 0.25;
    margin: 0 4px;
    background: $color-black-2;
    @include breakpoint($medium-up) {
      background: $color-white;
    }
    &.active {
      opacity: 1;
      background: $color-black-2;
      @include breakpoint($medium-up) {
        background: $color-white;
      }
    }
  }
  &__dots {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    background: $color-white;
    @include breakpoint($medium-up) {
      background: transparent;
    }
  }
}

.shoppable-video-block {
  &__intro {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    @include breakpoint($medium-up) {
      padding-bottom: 40px;
    }
  }
  &__header {
    .style--bare {
      font-family: $bb-sans;
      font-size: 45px;
      text-align: center;
      line-height: 1.1em;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        margin-bottom: 5px;
      }
    }
  }
  &__subheader {
    max-width: 75%;
    .style--bare {
      font-family: $brandon-text;
      font-size: 18px;
      text-align: center;
      line-height: 1.1em;
      @include breakpoint($medium-up) {
        font-size: 18px;
      }
    }
  }
  &__product-grid {
    height: 0px;
    opacity: 0;
    transition: opacity 0.5s ease;
    overflow: hidden;
    &.active {
      height: auto;
      opacity: 1;
      overflow: visible;
      padding-top: 30px;
    }
  }
}
