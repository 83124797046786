.product-image_atb {
  position: relative;
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
  }
  a:hover {
    background-color: #fff;
    opacity: 0.2;
  }
}
