$pro-block-bg-color: #fef6ec;

.pro-how-to-block {
  &__container {
    @include swap_direction(margin, 0 auto);
    max-width: 1024px;
    border: none;
    @include swap_direction(padding, 0);
  }
  &__formatter {
    background: $pro-block-bg-color;
    display: block;
    @include swap_direction(margin, 0 auto);
    max-width: 1024px;
    text-align: center;
    width: 100%;
    @include swap_direction(padding, 40px 0 0);
    .resp-tabs-container {
      background: $pro-block-bg-color;
      padding-bottom: 0px;
    }
  }
  &__header {
    font-size: 40px;
    margin-bottom: 30px;
    font-family: $miller-banner-light;
  }
  &__single-tip {
    #{$ldirection}: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0px, 50%);
    .pro-how-to-block__tip {
      #{$ldirection}: 50%;
      position: absolute;
      #{$rdirection}: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }
    &.pro-how-to-block__left-tip {
      #{$ldirection}: 0;
    }
    &.pro-how-to-block__right-tip {
      #{$rdirection}: 0;
    }
  }
  &__suggested-product {
    display: none;
    background: $color-white;
    @include swap_direction(padding, 20px 0);
    text-align: $ldirection;
  }
  &__tips-container {
    @include swap_direction(padding, 50px 0 20px);
  }
  &__link {
    background: $pro-block-bg-color;
    border: 1px solid $color-black;
    color: $color-black;
    @include swap_direction(margin, 20px 0 40px);
    &:hover {
      background: $color-black;
      color: $pro-block-bg-color;
    }
  }
  &__tabs {
    border: 1px solid $color-black;
    border-width: 1px 0;
    .resp-tab-active {
      background: $pro-block-bg-color;
      &::after {
        position: absolute;
        bottom: -3px;
        #{$ldirection}: 0;
        width: 100%;
        border: 3px solid $pro-block-bg-color;
        content: '';
      }
    }
    .resp-tab-item {
      position: relative;
      float: none;
      border-#{$ldirection}: 1px solid $color-black;
      font-family: $brandon-text-bold;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 1.5px;
      @include swap_direction(padding, 13px 30px);
      &:last-child {
        border-#{$rdirection}: 1px solid $color-black;
      }
      li {
        float: none;
      }
    }
  }
  &__tiptitle {
    text-align: center;
  }
  &__content {
    position: relative;
  }
  &__left-image {
    float: $rdirection;
    width: 50%;
  }
  &__left-tip {
    width: 50%;
  }
  &__right-image {
    float: $ldirection;
    width: 50%;
  }
  &__right-tip {
    width: 50%;
    float: $rdirection;
  }
  &__left-section {
    display: inline;
    float: $ldirection;
    position: relative;
    width: 49.5%;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__right-section {
    display: inline;
    float: $rdirection;
    position: relative;
    width: 49.7%;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__tip1 {
    top: 0;
  }
  &__tip2 {
    bottom: 0;
  }
  &__tip3 {
    top: 0;
  }
  &__tip4 {
    bottom: 0;
  }
  &__tip {
    width: 48%;
    padding-#{$ldirection}: 2%;
    @include breakpoint($large-up) {
      width: 45%;
      padding-#{$ldirection}: 5%;
    }
    h2,
    h3 {
      font-family: $brandon-text-bold;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 0;
      text-transform: uppercase;
      @include breakpoint($large-up) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    p {
      @include swap_direction(margin, 0);
      line-height: 17px;
      @include breakpoint($large-up) {
        line-height: 20px;
      }
    }
    font-size: 15px;
    line-height: 17px;
    position: absolute;
    @include breakpoint($large-up) {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

@include breakpoint($small-down) {
  .pro-how-to-block {
    &__formatter {
      @include swap_direction(padding, 30px 0 0);
      h2.resp-accordion {
        display: none;
      }
      select,
      a.selectBox {
        height: 40px;
        line-height: 20px;
        @include swap_direction(padding, 10px 20px);
        border: 1px solid $color-black;
        width: 100%;
        border-width: 1px 0;
        text-align: $ldirection;
        font-family: $brandon-text-bold;
      }
      select {
        background-position-x: 94%;
        background-size: 15px;
      }
      a.selectBox {
        background: $pro-block-bg-color;
      }
      .selectBox-options a {
        background: $pro-block-bg-color;
      }
      .selectBox-arrow {
        background: $pro-block-bg-color;
      }
    }
    &__header {
      font-size: 25px;
      margin-bottom: 20px;
    }
    &__link {
      @include swap_direction(margin, 0 0 30px);
      font-size: 12px;
    }
    &-small {
      &__header {
        font-size: 30px;
        margin-bottom: 20px;
        font-family: $miller-banner-light;
      }
      &__tips-container {
        h2 {
          font-family: $brandon-text-bold;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 0;
          text-transform: uppercase;
        }
        @include swap_direction(padding, 20px 20px 0);
      }
      &__image-container {
        width: 100%;
        margin-bottom: 20px;
      }
      &__left-image {
        float: $ldirection;
        margin-#{$rdirection}: 0.8%;
        width: 49.5%;
      }
      &__right-image {
        float: $rdirection;
        width: 49.7%;
      }
      &__tip {
        font-size: 14px;
        line-height: 16px;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        p {
          line-height: 16px;
          @include swap_direction(margin, 0);
        }
      }
    }
  }
}
