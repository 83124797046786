.site-404 {
  @include swap_direction(padding, 14px 0);
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 50px 0);
  }
  &__title {
    h2 {
      font-weight: bold;
    }
  }
  &__image {
    width: 90%;
    @include swap_direction(margin, 0 auto);
    padding-bottom: 12px;
    @include breakpoint($medium-up) {
      width: auto;
      max-width: $max-width;
    }
    img {
      max-width: 100%;
    }
  }
  &__content {
  }
  &__buttons {
  }
}
