.profile-page {
  &__content {
    .loyalty-membership-section {
      padding-bottom: 20px;
      margin-top: 20px;
      border-bottom: 1px solid $color-black;
      border-top: 1px solid $color-black;
      &__header {
        @include swap_direction(padding, 0 0 10px 20px);
        margin: 20px 0 0;
        border-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-family: $body-font-bold;
      }
      &__fieldset {
        padding-#{$ldirection}: 20px;
        .form-submit {
          @include breakpoint($medium-up) {
            padding: 0 37px;
            font-size: 13px;
            width: auto;
          }
          letter-spacing: 2.5px;
          font-size: 14px;
          background: $color-black;
          margin-#{$ldirection}: 0;
          height: auto;
          width: 100%;
          &:focus,
          &:hover,
          &:active {
            background: $color-black;
          }
        }
        &__link {
          color: $color-black;
        }
      }
      &__terms {
        @include breakpoint($medium-up) {
          margin: 15px 0 0;
        }
        margin: 15px 0 40px;
        color: $color-gray-border;
      }
      &__text {
        padding-#{$rdirection}: 65px;
        @include breakpoint($medium-up) {
          font-size: 15px;
          padding-#{$rdirection}: 20px;
        }
        margin-bottom: 15px;
      }
      &__join-now {
        margin: 25px 0;
        .form-submit {
          &.button {
            &--disabled {
              background: $color-light-gray;
            }
          }
        }
      }
      &__accept-terms {
        .label-content {
          @include breakpoint($medium-up) {
            width: auto;
          }
          width: 87%;
          color: $color-gray-dark;
          vertical-align: top;
          display: inline-block;
          padding-top: 2px;
          a {
            color: $color-gray-dark;
          }
        }
      }
      &__conditions {
        &__link {
          color: $color-gray-dark;
          text-decoration: underline;
        }
      }
    }
  }
}
