.bobbi-pro {
  margin-bottom: 10px;
  a {
    text-decoration: none;
    &.link {
      // padding-bottom: 0;
    }
  }
  &__hero {
    height: 328px;
    background-repeat: no-repeat;
    background-position: $rdirection bottom;
    background-size: 50%;
    @include breakpoint($desktop) {
      background-size: auto 100%;
    }
  }
  &__hero-callout {
    text-align: center;
    width: 530px;
    float: $ldirection;
    @include swap_direction(padding, 55px 60px 0 0);
    @include breakpoint($desktop) {
      width: 550px;
      @include swap_direction(padding, 55px 0 0 50px);
    }
    @include breakpoint($small-down) {
      @include swap_direction(padding, 2px 0 0);
      width: auto;
    }
  }
  &__hero-header {
    @include t3;
    letter-spacing: 8px;
    text-transform: uppercase;
  }
  &__hero-subheader {
    font-size: 24px;
    line-height: 1.2em;
  }
  &__tabs {
    text-align: center;
    margin-top: -38px !important;
    .resp-tab-item {
      background: $color-white;
      margin-bottom: 0;
      @include swap_direction(padding, 8px 32px);
      &.resp-tab-active {
        background: $color-gray-background;
        border-bottom: 1px solid $color-gray-background;
      }
    }
    .resp-tabs-container {
      text-align: $ldirection;
      background: $color-gray-background;
      margin-top: -6px;
      border-top: 1px solid $color-black;
    }
    .resp-tab-content {
      @include swap_direction(padding, 0);
    }
    .bobbi-pro__section {
      padding: 20px;
      @include breakpoint($tablet) {
        @include swap_direction(padding, 40px 60px 35px);
      }
      &.bobbi-pro__section--apply {
        padding-#{$ldirection}: 45px;
        padding-#{$rdirection}: 45px;
        @include breakpoint($small-down) {
          padding-#{$ldirection}: 20px;
          padding-#{$rdirection}: 20px;
        }
      }
      &.bobbi-pro__section--artist {
        @include swap_direction(padding, 40px 35px 35px 35px);
      }
    }
    .bobbi-pro__section--artist .bobbi-pro__section-header {
      margin-#{$ldirection}: 25px;
      margin-bottom: 15px;
    }
  }
  &__section-header {
    @include swap_direction (margin, 8px 0 18px 0);
    font-family: $bb-sans;
    line-height: 1;
    font-size: 2em;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &__section-content {
    font-size: 14px;
    text-align: $ldirection;
    @include breakpoint($tablet) {
      font-size: 18px;
    }
  }
  &__section-cta {
    border: 1px solid $color-pink;
    display: inline-block;
    @include swap_direction(padding, 10px 18px);
  }
  &__apply {
    display: table;
    width: 100%;
    &-row {
      display: table-row;
    }
    &-column {
      vertical-align: top;
      display: table-cell;
      width: 50%;
      @include breakpoint($small-down) {
        display: block;
        width: 100%;
      }
    }
    .bobbi-pro__section-header {
      margin-bottom: 21px;
    }
    .bobbi-pro__section-link {
      color: $color-pink;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $brandon-text-bold;
      text-decoration: none;
      letter-spacing: 2px;
    }
    .bobbi-pro__apply-row--content {
      .bobbi-pro__apply-column {
        &:first-child {
          padding-#{$ldirection}: 15px;
          padding-#{$rdirection}: 50px;
          @include breakpoint($small-down) {
            padding-#{$ldirection}: 0;
            padding-#{$rdirection}: 0;
          }
        }
        &:last-child {
          border-#{$ldirection}: 1px solid $color-black;
          padding-#{$ldirection}: 30px;
          @include breakpoint($small-down) {
            border-#{$ldirection}: 0 none;
            padding-#{$ldirection}: 0;
          }
        }

        // Last p element output from the backend WYSIWYG should have no margin on bottom
        .bobbi-pro__section-content p:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bobbi-pro__apply-row--links {
      .bobbi-pro__apply-column {
        padding-top: 30px;
      }
      .bobbi-pro__apply-content {
        border-top: 1px solid $color-black;
        @include swap_direction(padding, 25px 15px 0 15px);
      }
      .bobbi-pro__apply-column:last-child .bobbi-pro__apply-content {
        padding-#{$ldirection}: 30px;
      }
    }

    // WYSIWYG Output content
    .bobbi-pro__section-content--qualify {
      p {
        margin-bottom: 0;
      }
      strong,
      b {
        font-family: $brandon-text-bold;
        font-weight: normal;
      }

      // The child unordered list, which is the list containing 'two pieces of professional content' without the dashes
      > ul {
        margin-top: 0;
        margin-bottom: 25px;
      }

      // This needs explanation.
      // There is a bit of text with an underline -- the word 'AND' with a ul before and after it.
      // There needs to be spacing around this AND.
      // We also don't know if they will add additional ul elements in the future, so targeting the ul is hard.
      > p {
        > u > b,
        > b > u,
        > u > strong,
        > strong > u {
          display: inline-block;
          margin-bottom: 25px;
          text-decoration: none;
          border-bottom: 1px solid black;
          line-height: 1em;
        }
      }
      ul {
        padding-#{$ldirection}: 20px;
        li {
          font-family: $brandon-text-bold;
          font-size: 18px;
          list-style-type: disc;
          line-height: 1.4em;
        }
      }
      .bobbi-pro__list-seperator {
        display: inline-block;
        font-family: $brandon-text-bold;
        border-bottom: 1px solid $color-black;
        padding-bottom: 1px;
        margin-bottom: 15px;
      }
      .bobbi-pro__apply-requirements {
        // Previous UL elements have a margin bottom of 25px, margin-top -25px to offset that.
        ul {
          margin-top: -25px;
          margin-bottom: 25px;
        }
        li {
          list-style: none;
          &:before {
            content: '—';
          }
          font-family: $brandon-text;
          font-weight: normal;
        }
      }
    }
  }
  &__faq {
    margin: 30px 0 40px 0;
    @include breakpoint($tablet) {
      margin-top: 70px;
    }
    &-qa {
      border: 1px solid $color-black;
      border-width: 1px 0 0 0;
      margin-#{$ldirection}: -15px;
      padding: 0 15px;
      margin-#{$rdirection}: -15px;
      &:last-child {
        border-width: 1px 0;
      }
      .bobbi-pro__faq-question {
        @include swap_direction (padding, 15px 0 15px 0);
        font-family: $bb-sans;
        line-height: 1;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
      .bobbi-pro__faq-answer {
        display: none;
        margin-top: 20px;
        position: relative;
        @include breakpoint($tablet) {
          padding-bottom: 50px;
          padding-#{$ldirection}: 55px;
        }
        &:before {
          content: 'A.';
          position: absolute;
          #{$ldirection}: 0;
          top: 7px;
          font-size: 30px;
          font-family: $brandon-text-bold;
        }
        &__close {
          color: $color-black;
          position: absolute;
          top: 4px;
          #{$rdirection}: 0;
          font-size: 24px;
        }
        &__content {
          max-width: 550px;
          line-height: 1.4em;
          padding-top: 45px;
          @include breakpoint($tablet) {
            padding-top: 0;
          }
        }
      }
      &.active {
        .bobbi-pro__faq-question {
          color: $color-pink;
        }
        // .bobbi-pro__faq-answer {
        //   display: block;
        // }
      }
    }
  }
  &__artist {
    font-size: 0;
    text-align: center;
    @include breakpoint($tablet) {
      text-align: $ldirection;
    }
    &-bio {
      margin-top: 28px;
      display: inline-block;
      vertical-align: top;
      margin-#{$rdirection}: 42px;
      text-align: center;
      width: 290px;
      // Magic number -- 480.
      @include breakpoint(max-width 480px) {
        @include swap_direction(margin, 28px auto 0);
      }
      @include breakpoint($tablet) {
        width: 30%;
        margin-#{$rdirection}: 3%;
      }
      @include breakpoint($desktop) {
        &:nth-child(3n) {
          margin-#{$rdirection}: 0;
        }
      }
      &__link {
        padding-bottom: 0;
        .touch &,
        .touchevents & {
          border-width: 0 0 2px 0;
          @include swap_direction(padding, 0);
        }
      }
    }
    &__image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 15px;
    }
    &__name {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 4px;
    }
    &-bio__content {
      display: none;
      font-size: 18px;
      &.bobbi-pro__artist-bio__content--full {
        display: block;
        position: relative;
        margin-top: 32px;
        margin-bottom: 12px;
        background: $color-white;
        border: 1px solid $color-border-gray;
        @include swap_direction(padding, 18px 20px);
        a {
          text-decoration: underline;
        }
        .visually-hidden {
          border: 0;
          clip: rect(1px, 1px, 1px, 1px);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }
      &-wrapper {
        display: none;
      }
      &-arrow {
        position: absolute;
        top: 0;
        height: 13px;
        #{$ldirection}: 0;
        &:after,
        &:before {
          bottom: 100%;
          #{$ldirection}: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-color: rgba(100, 100, 100, 0);
          border-bottom-color: $color-white;
          border-width: 11px;
          margin-#{$ldirection}: -11px;
        }
        &:before {
          border-color: rgba(239, 240, 240, 0);
          border-bottom-color: $color-black;
          border-width: 12px;
          margin-#{$ldirection}: -12px;
        }
      }
    }
    &-bio__content-section {
      font-size: 14px;
      &__header {
        font-family: $brandon-text-bold;
        text-transform: uppercase;
      }
      &__description {
        margin-bottom: 15px;
      }
      &__products {
        border-#{$ldirection}: 1px solid $color-border-gray;
        float: $rdirection;
        margin-#{$ldirection}: 40px;
        margin-bottom: 20px;
        a {
          margin-#{$ldirection}: 10px;
          display: block;
          border-bottom: 1px solid $color-border-gray;
          &:last-child {
            border: none;
          }
        }
      }
      &__products-image {
        width: 148px;
        height: auto;
        display: block;
      }
    }
  }
}

// Mobile down
@include breakpoint(max-width $tablet) {
  // On mobile dont show these pages
  //#bp-apply, #bp-artist {
  #bp-artist {
    display: none;
  }
  // Hide 2nd and 4th (Apply and Artist Page) accordion content on mobile
  .bobbi-pro {
    .resp-tabs-container > * {
      //&[aria-controls="tab_item-1"], &[aria-controls="tab_item-3"] {
      &[aria-controls='tab_item-3'] {
        display: none;
      }
    }
  }
}
